import * as React from "react";
import ReactTooltip from "react-tooltip";
import {ReactComponent as Whatsapp} from "../../assets/Whatsapp.svg";
import {ReactComponent as InfoMobile} from "../../assets/InfoMobile.svg";
import {ReactComponent as FacebookMobile} from "../../assets/FacebookMobile.svg";
import {ReactComponent as Twitter} from "../../assets/Twitter.svg";
import { InfoText } from "../votes-distribution";
import "./style.scss";

export const MobileHeaderBar = (props: any) => {
  return (
    <div className="mobile-header-container">
      <span className="header-text">
        <h5>מפת הבחירות של ישראל</h5>
        <span className="info-icon-wrapper" data-tip data-for="mobile-info-tooltip" onClick={()=>{}}>
          <InfoMobile />
          <ReactTooltip data-event="touchstart focus mouseover"
data-event-off="mouseout" globalEventOff={'touchstart'} id="mobile-info-tooltip" place="bottom" type="dark" effect="solid">
            <InfoText/>
          </ReactTooltip>
        </span>
      </span>
      <div className="mobile-share-icons">
        <a href={`http://twitter.com/share?&url=${document.referrer}&hashtags=בחירות 2019, מדלן, ynet`} data-href="" target="_blank">
          <Twitter/>
        </a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${document.referrer}`} target="_blank">
          <FacebookMobile/>
        </a>          
        <a href={`https://api.whatsapp.com/send?text=https://z.ynet.co.il/short/content/ElectionMap2019/`} data-href="" target="_blank">
          <Whatsapp/>
        </a>
      </div>
    </div>)
}
