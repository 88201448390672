export function toFeatureCollection(features: any[]) :any {
  return {
    type: "FeatureCollection",
    features,
  }
}

function median(numbers: number[]) {
  const sorted = numbers.slice().sort();
  const middle = Math.floor(sorted.length / 2);
  if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
  }
  return sorted[middle];
}

export function getNothestPoint(coords: number[][]): [number, number] {
  const a = coords.map(x => x[0]);
  const b = coords.map(x => x[1]);
  return [median(a), Math.max(...b)]
}

export const decodeGooglePolyline = (str: any, reverse: any, precision: any = 5) => {
  let index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change,
    factor = Math.pow(10, precision);
  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;
    coordinates.push(
      reverse ? [ lng / factor, lat / factor ] : [ lat / factor, lng / factor ]
    );
  }

  return coordinates;
};
