
export type PartyId =
  "avoda"| 
  "hadash" |
  "ihud_yamin" |
  "israel_beiteinu" |
  "kahol_lavan"|
  "kulanu"|
  "likud"|
  "meretz"|
  "other"|
  "raam_balad"|
  "shas"|
  "tora"|
  "yamin_hadash"|
  "zehut"|
  "gesher";


interface Party {
  name: string;
  rgb: string;
}

export const PartiesDefinition: {[k in PartyId]: Party} = {
  "avoda": {
    name: "העבודה",
    rgb: "169, 53, 53"
  },
  "hadash": {
    name: "חד״ש-תע״ל",
    rgb: "98, 148, 52"
  },
  "ihud_yamin": {
    name: "איחוד מפלגות הימין",
    rgb: "5, 41, 92"
  },
  "israel_beiteinu": {
    name: "ישראל ביתנו",
    rgb: "60, 112, 181"
  },
  "kahol_lavan": {
    name: "כחול לבן",
    rgb: "220, 152, 31"
  },
  "kulanu": {
    name: "כולנו",
    rgb: "77, 181, 210"
  },
  "likud": {
    name: "הליכוד",
    rgb: "77, 151, 210"
  },
  "meretz": {
    name: "מרצ",
    rgb: "202, 0, 115"
  },
  "other": {
    name: "אחרות",
    rgb: "130, 128, 128"
  },
  "raam_balad": {
    name: "רע״מ-בל״ד",
    rgb: "9, 115, 43"
  },
  "shas": {
    name: "ש״ס",
    rgb: "97, 61, 50"
  },
  "tora": {
    name: "יהדות התורה",
    rgb: "55, 55, 55"
  },
  "yamin_hadash": {
    name: "הימין החדש",
    rgb: "28, 79, 149"
  },
  "zehut": {
    name: "זהות",
    rgb: "122, 134, 146"
  },
  "gesher": {
    name: "גשר",
    rgb: "220, 116, 31"
  }
}

export const partiesOrder = [
  "likud",
  "israel_beiteinu",
  "yamin_hadash",
  "ihud_yamin",
  "kulanu",
  "zehut",
  "tora",
  "shas",
  "kahol_lavan",
  "gesher",
  "avoda",
  "meretz",
  "raam_balad",
  "hadash",
  "other"
];