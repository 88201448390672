import * as React from "react";
import { ElectionsResultsItems } from "../../../types/elections-data";
import { PartiesDefinition, PartyId } from "../../../utils/parties";
import "./style.scss";

interface ScrollableMenuProps {
  parties: ElectionsResultsItems;
  onClick: (id: string) => void;
  selectedItemId: string;
  classNames?: string[];
}

export const ScrollableMenu = ({parties, onClick, selectedItemId, classNames}: ScrollableMenuProps) => {
  if(!parties) {
    return null;
  }
  return (
    <div className={`scroll-wrapper ${classNames}`}>
      <div className={`blur ${classNames}`}/>
      <div className="scroll-menu">
        {Object.keys(parties).sort((x, y) => parties[y] - parties[x]).map(partyId => {
          const party = PartiesDefinition[partyId as PartyId];
          return (
            <h2 className="scroll-party"
              key={partyId}
              style={{background: `rgba(${party.rgb}, ${selectedItemId === partyId ? 1: 0.08})`, color: selectedItemId === partyId ? "white" : `rgb(${party.rgb})`}}
              onClick={() => onClick(partyId)}>
              {party.name}
            </h2>)
        })}
      </div>
    </div>
  )
}