import * as React from "react";
import "../style.scss";

interface FooterProps {
  text: string;
  link: {text: string, url: string};
  classNames?: string[];
}

export class Footer extends React.Component<FooterProps> {

  private aRef: React.RefObject<HTMLAnchorElement> = React.createRef();

  private goToMadlan = () => {
    this.aRef.current.click();
  }

  render() {
    const {link, text, classNames} = this.props;

    return (
      <a ref={this.aRef} href={link.url} target="_blank">
        <div className={`statistics-footer ${classNames}`}>
          <h4>
            {text}
          </h4>
          <button onClick={this.goToMadlan}>
            {link.text}
          </button>
        </div>
      </a>)
  }
}