import * as React from "react";
import "./style.scss";
import { PartiesDefinition, PartyId } from "../../../utils/parties";
import { ElectionsResultsItems } from "../../../types/elections-data";
import { chunk } from "../../../utils/generals";

interface ElectionBulletsProps {
  parties: ElectionsResultsItems;
  activePartyId: PartyId;
  onPartyEnter?: (partyId: PartyId) => void;
  onPartyLeave?: (partyId?: PartyId) => void;
}

const ElectionBullets: React.SFC<ElectionBulletsProps> = ({ parties, activePartyId, onPartyEnter, onPartyLeave }: ElectionBulletsProps): JSX.Element => {
  const partyBullets = Object.keys(parties).map(party => {
    const isActive = !(activePartyId && activePartyId !== party);
    return (
      <div key={party} className="bullet-container" style={{color: isActive ? "#303030" : "#9b9b9b", opacity: isActive ? 1 : 0.5, fontWeight: isActive ? 400: "normal"}}
           onMouseEnter={(e) => onPartyEnter(party as PartyId)} onMouseLeave={(e) => onPartyLeave(party as PartyId)}> 
        <div className="party-bullet"
          style={{background: `rgb(${(PartiesDefinition as any)[party].rgb})`}} 
          />
        {PartiesDefinition[party as PartyId].name}
      </div>)
  })

  return (
		<div className="parties-names">
			{chunk(partyBullets, 4).map((partyGroup, i) => <div key={i} className="party-column">{partyGroup}</div>)}
		</div>
  );
};

export default ElectionBullets;