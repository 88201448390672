import * as React from "react";
import ContentLoader from "react-content-loader";


export const StatisticsLoadingIndicator = () => (
  <ContentLoader 
    height={265}
    width={600}
    speed={2}
    primaryColor="#c0c0c0"
    secondaryColor="#ebebeb"
  >
    <rect x="366" y="78" rx="0" ry="0" width="198" height="19" /> 
    <rect x="368" y="212" rx="0" ry="0" width="198" height="21" /> 
    <rect x="522" y="247" rx="3" ry="3" width="46" height="15" /> 
    <rect x="472" y="247" rx="3" ry="3" width="46" height="16" /> 
    <rect x="422" y="247" rx="3" ry="3" width="46" height="16" /> 
    <rect x="372" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="322" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="272" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="222" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="172" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="122" y="247" rx="3" ry="3" width="46" height="17" /> 
    <rect x="157" y="111" rx="0" ry="0" width="412" height="27" />
  </ContentLoader>
)
