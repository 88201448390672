import * as React from "react";
import "./style.scss";
import { ElectionsResultsItems, OverallResults } from "../../../types/elections-data";
import { PartiesDefinition, PartyId } from "../../../utils/parties";
import { Omit } from "../../../utils/generals";

interface ChartProps {
  header: string;
  percentage?: string;
  parties?: ElectionsResultsItems;
  activeParty?: PartyId;
  Icon?: React.ReactType;
  isMobile?: boolean;
  onFragmentEnter?: (partyId: PartyId) => void;
  onFragmentLeave?: (partyId?: PartyId) => void;
  removeBottomLabels?: boolean;
  addPercentageThreshold?: boolean;
}

export class Chart extends React.Component<ChartProps, any> {

  constructor(props: ChartProps) {
    super(props);

    this.state = {
      hoveredPartyId: null
    }
  }

  private onFragmentEnter = (partyId: PartyId) => {
    if(this.props.onFragmentEnter){
      this.props.onFragmentEnter(partyId);
    }
    this.setState({hoveredPartyId: partyId});
  }

  private onFragmentLeave = (partyId: PartyId) => {
    if(this.props.onFragmentLeave) {
      this.props.onFragmentLeave(partyId);
    }
    this.setState({hoveredPartyId: null});
  }

  public render() {
    
    const { header, percentage, parties, Icon, isMobile, removeBottomLabels, addPercentageThreshold } = this.props;
    const {props} = this;
    const { hoveredPartyId } = this.state;
    const isEmptyData = parties && Object.keys(parties).length === 0 ;

    return (
      <React.Fragment>
        <section className="chart-container">
          <div className="chart-header">
            <h2 className="area-name">{header || "תוצאות איזוריות"}</h2>
              {/* {Icon && percentage &&
                <div className="vote-percentage">
                {parties && <Icon/>}
                <h4 className="vote-number">{parties ? `${percentage}%` : "סמנו על המפה יישוב שמעניין אתכם"}</h4>
                {parties && `אחוזי הצבעה`}
              </div>} */}
              {!parties &&
                <div className="vote-percentage">
                  {<h4 className="vote-number">{removeBottomLabels ? "" : "סמנו על המפה יישוב שמעניין אתכם"}</h4>}
              </div>}
              {addPercentageThreshold && <h4 className="vote-number">אחוז החסימה: 3.25%</h4>}
          </div>
          <div className="chart">
            {parties && !isEmptyData ?
            Object.keys(parties).sort((x, y) => parties[y] - parties[x]).map((partyId: string, i: number) => 
                <ChartFragment 
                  key={partyId}
                  id={partyId as PartyId}
                  name={PartiesDefinition[partyId as PartyId].name}
                  votingPercentage={parties[partyId]}
                  color={PartiesDefinition[partyId as PartyId].rgb}
                  onMouseEnter={this.onFragmentEnter}
                  onMouseLeave={this.onFragmentLeave}
                  addBottomLabel={!removeBottomLabels && !isMobile && i < 2 || partyId === hoveredPartyId || props.activeParty === partyId}
                  isSelected={partyId === hoveredPartyId || props.activeParty === partyId || (!props.activeParty && !hoveredPartyId)}
                  hidePartyName={isMobile}
                  alignRight={i > 2}
              />) : <div style={{background: "rgba(95, 94, 94, 0.05)", height: 32, width: "100%", marginTop: 13}} />}
          </div>
        </section>
        {isEmptyData && <span style={{alignSelf: "baseline", marginTop: -30, color: "#767676", fontSize: 13}}>תוצאות ראשונות בקרוב!</span>}
      </React.Fragment>
    )
  }
}

interface ChartFragmentProps {
  id: PartyId;
  name: string;
  color: string;
  votingPercentage: number;
  onMouseEnter: (partyId: PartyId) => void;
  onMouseLeave: (partyId?: PartyId) => void;
  addBottomLabel: boolean;
  isSelected: boolean;
  hidePartyName? :boolean;
  alignRight: boolean;
}

const ChartFragment = ({id, name, color, votingPercentage, onMouseEnter, onMouseLeave, addBottomLabel, isSelected, hidePartyName, alignRight}: ChartFragmentProps) => {
  if(votingPercentage == 0) {
    return null
  }

  const votes = votingPercentage === 1 ? 100 : (votingPercentage * 100).toPrecision(2);
  return (
    <div
      className="chart-fragment"
      onMouseEnter={() => onMouseEnter(id)}
      onMouseLeave={() => onMouseLeave(id)}  
      style={{width: `${votes}%`, background: `rgba(${color}, ${isSelected ? 1 : 0.4})`}}>
      {addBottomLabel && 
        <FragmentLabel alignRight={alignRight} color={color} isSelected={isSelected} votingPercentage={votes} name={name} hidePartyName={hidePartyName}/>}
    </div>
  )
}

interface FragmentLabelProps extends Omit<Partial<ChartFragmentProps>, "votingPercentage"> {
  votingPercentage: string | number;
}

export const FragmentLabel = ({color, isSelected, hidePartyName, votingPercentage, name, alignRight}: FragmentLabelProps) => {
  (name)
  return (
    <div className="fragment-label" style={{background: `rgba(${color}, 0.08)`, color: `rgb(${color})`, opacity: isSelected ? 1 : 0.2, left: alignRight ? 0 : "auto" }}>
      <span className="vote-number" style={{fontWeight: "bold", color: `rgb(${color})`}}>{`${votingPercentage}%`}</span>
      {!hidePartyName && name}
    </div>)
}
