import * as React from "react";
import { ReactComponent as SearchIcon} from "../../assets/Search.svg";
import "./style.scss";
import Autosuggest from "react-autosuggest";
import { areas } from "../../areas.js";
import { AreaType } from "../App";
import { isMobile } from "../../utils/generals";

export interface Area {
  name: string;
  center: [number,  number];
  type: AreaType;
  id: string;
}

interface SearchProps {
  onPickGeoResult: (area: Area) => void;
  onMobileSearchOpen: () => void;
  onSearchBlur: () => void;
  location: string;
  isSearchClosed: boolean;
}

export class GeoSearch extends React.Component<SearchProps, any> {

  constructor(props: SearchProps) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      isValueSelected: false,
    }
  }

  componentDidMount() {
    if(isMobile()) {
      const searchinput: HTMLInputElement =  document.getElementsByClassName("react-autosuggest__input")[0] as HTMLInputElement;
      searchinput.onblur = (...params) => () => {
        this.props.onSearchBlur();
      }
      searchinput.onfocus = (...params) => this.props.onMobileSearchOpen();
    }
  }

  componentDidUpdate(prevPropvs: SearchProps) {
    if(this.props.location !== prevPropvs.location) {
      this.setState({ value: this.props.location})
    }
  }

  private getSuggestionValue = (suggestion: any) => suggestion.name

  private changeText = (event: React.FormEvent<any>, { newValue }: any) => {
    this.setState({ value: newValue })
  }

  private onSuggestionsClearRequested = () => {
    this.setState({ suggestions: []})
  }

  private renderSuggestions = (suggestion: Area, {query}: any) => {
    let [a, b] = suggestion.name.split(query);

    return (
      <div className="geo-suggestion">
        <span>
          {a}
          <mark className="suggestion-text">{query}</mark>
          {b}
        </span>
        <span className="suggestion-type">{suggestion.type === "city" ? "יישוב" : "שכונה"}</span>
      </div>)
  }

  private onSuggestionSelected = (event: React.FormEvent<any>, { suggestion }: any) => {
    const searchInput = document.getElementsByClassName("react-autosuggest__input")[0] as HTMLInputElement;
    this.props.onPickGeoResult(suggestion);
    searchInput.blur();
    this.props.onSearchBlur();
    this.setState({
      isValueSelected: true,
    });
  }

  private updateSuggestions = ({value}: any) => {
    this.setState({
      suggestions: areas.filter(x => x.name.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1).slice(0, isMobile() ? 3 : 7)
    });
  }

  private openMobileSearch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.state.isValueSelected) {
      this.setState({
        isValueSelected: false,
      });
    }
  }

  public closeSearch() {
    this.setState({ isValueSelected: true});
  }

  render() {
    const { value, suggestions, isValueSelected } = this.state;

    return (
      <div className="geo-search" onClick={this.openMobileSearch}>
        <div className={`geo-search-wrapper ${suggestions.length ? "with-suggestions" : ""} ${isValueSelected ? 'react-autosuggest__input--selected' : ''}`}>
          <SearchIcon/>
          <Autosuggest
            onSuggestionsFetchRequested={this.updateSuggestions}
            suggestions={suggestions}
            inputProps={{onChange: this.changeText, value, placeholder: "חפשו ישוב או שכונה" }}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            renderSuggestion={this.renderSuggestions}
            shouldRenderSuggestions={(value: string) => value.trim().length > 1}
            onSuggestionSelected={this.onSuggestionSelected}
            highlightFirstSuggestion
            focusInputOnSuggestionClick={false}
            />
          <div className="search-button">חפש</div>
        </div>
      </div>)
  }
}

