import { ElectionsProperties } from "../types/elections-data";

const baseUrl = "https://elections.madlan.co.il/data";

const testUrl = "https://s3-eu-west-1.amazonaws.com/cms.doordawn.com/data"

export async function getElections(areaId: string): Promise<GeoJSON.FeatureCollection | ElectionsProperties> {
  const response = await fetch(`${baseUrl}/${areaId}.json`);
  const json = await response.json();
  return json;
}
