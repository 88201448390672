import * as React from "react";
import "./style.scss";
import { renderToStaticMarkup } from "react-dom/server";
import * as mapboxgl from "mapbox-gl";
import { FeaturesItem, Geometry, ElectionsProperties } from "../../types/elections-data";
import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css"
import { toFeatureCollection, getNothestPoint } from "../../utils/geo-utils";
import { FeatureCollection, Feature } from "geojson";
import { paint, partiesMapColors, linePaint } from "./mapStyle";
import { AreaType } from "../App";
import { ReactComponent as Minus} from "../../assets/Minus.svg";
import { ReactComponent as Plus} from "../../assets/Plus.svg";
import { isMobile } from "../../utils/generals";

(mapboxgl as any).accessToken = "pk.eyJ1Ijoid2FtaXI3OSIsImEiOiJjanQ2czlqMnAwaWUyNDRvY284dmt0NTR2In0.6k-XpNGb8hXkCdhDUp2YZA";
(mapboxgl as any).setRTLTextPlugin("https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.1/mapbox-gl-rtl-text.js");

enum mapBoxSources {
  cities = "cities",
  selectedCity = "selected-city",
  citiesReadOnly = "cities-read-only",
  neighbourhoods = "neighbourhoods",
  selectedNeighbourhoods = "selected-neighbourhoods"
}

enum mapBoxLayers {
  citiesHover = "cities-fill",
  citiesBorder = "cities-border",
  citySelected = "city-selected",
  neighborhoodsHover = "neighborhoods-fill",
  neighboorhoodsBorder = "neighborhoods-border",
  neighborhoodSelected = "neighborhoods-selected",
}

interface MapProps {
  cities: FeatureCollection;
  neighs: FeatureCollection;
  selectedCity: Feature;
  selectedNeighborhood: Feature;
  minZoom: number;
  maxZoom: number;
  center: [number, number];
  onPolygonClick?: (properties: ElectionsProperties) => void;
  getHoverElement: (feature: mapboxgl.MapboxGeoJSONFeature) => JSX.Element;
  preventHoverAreaId?: string;
  type: AreaType;
  onInitMap: () => void;
}

export class Map extends React.Component<MapProps, any> {

  private container: React.RefObject<HTMLDivElement> = React.createRef();
  private map: mapboxgl.Map;
  private isMapLoaded: boolean;
  private hoveredPolyId: any = null;
  private popUp: React.RefObject<HTMLDivElement> = React.createRef();
  private isBorderLoaded: boolean = false;

  private initlDefaultLayers = () => {

    this.isMapLoaded = true;
    this.map.resize();
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "bottom-left");
    const zoomIn = document.getElementsByClassName("mapboxgl-ctrl-zoom-in")[0];
    if(zoomIn) {
      zoomIn.innerHTML = renderToStaticMarkup(<Plus/>);
    }

    const zoomOut = document.getElementsByClassName("mapboxgl-ctrl-zoom-out")[0];
    if(zoomOut) {
      zoomOut.innerHTML = renderToStaticMarkup(<Minus/>);
    }
    
    const zooms = document.getElementsByClassName("mapboxgl-ctrl-bottom-left")[0] as any;
    if(zooms) {
      zooms.style.left = "18px";
    }


    // cities 
    this.map.addSource(mapBoxSources.cities, {
        type: "geojson",
        data: null
    });

    this.map.addSource(mapBoxSources.citiesReadOnly, {
      type: "geojson",
      data: null
    })

    this.map.addSource(mapBoxSources.selectedCity, {
      type: "geojson",
      data: null
    })


    this.map.addLayer({
      "id": mapBoxLayers.citySelected,
      "type": "line",
      "source": mapBoxSources.selectedCity,
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "line-round-limit": 1.05,
      },
      "paint": linePaint as any,
    }, "places labels - suburbs");

    this.map.addLayer({
        "id": mapBoxLayers.citiesHover,
        "type": "fill",
        "source": mapBoxSources.cities,
        "layout": {},
        "paint": paint,
    } as any, mapBoxLayers.citySelected);

    // neighborhoods
    this.map.addSource(mapBoxSources.neighbourhoods, {
      type: "geojson",
      data: null
    });

    this.map.addSource(mapBoxSources.selectedNeighbourhoods, {
      type: "geojson",
      data: null
    })

    this.map.addLayer({
      "id": mapBoxLayers.citiesBorder,
      "type": "line",
      "source": mapBoxSources.citiesReadOnly,
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "line-round-limit": 1.05,
      },
      "paint": {
        "line-color": "hsl(60, 43%, 96%)",
        "line-width": [ "interpolate", ["linear"], ["zoom"], 10, 1.5, 11, 3],
        "line-opacity": [ "interpolate", ["linear"], ["zoom"], 10, 0, 11, 1],
        "line-offset": ["interpolate",["linear"],["zoom"],7,-3,9,-1]
      }
    }, mapBoxLayers.citySelected);

    this.map.addLayer({
      "id": mapBoxLayers.neighborhoodsHover,
      "type": "fill",
      "source": mapBoxSources.neighbourhoods,
      "layout": {},
      "paint": paint,
    } as any, mapBoxLayers.citySelected)

    this.map.addLayer({
      "id": mapBoxLayers.neighboorhoodsBorder,
      "type": "line",
      "source": mapBoxSources.neighbourhoods,
      "layout": {
        "line-cap": "butt",
        "line-join": "round",
        "line-round-limit": 1.05,
      },
      "paint": {
        "line-color": "hsl(60, 43%, 96%)",
        "line-width": ["interpolate",["linear"],["zoom"],11,1,12,2],
        "line-opacity": 1
      }
    }, mapBoxLayers.citySelected);

    this.map.addLayer({
      "id": mapBoxLayers.neighborhoodSelected,
      "type": "fill",
      "source": mapBoxSources.selectedNeighbourhoods,
      "layout": {},
      "paint": {
        "fill-antialias": true,
        "fill-color": partiesMapColors,
        "fill-opacity": 1,
      }
    } as any, mapBoxLayers.neighboorhoodsBorder)

    this.attachMouseMove(mapBoxLayers.citiesHover, mapBoxSources.cities);
    this.attachMouseMove(mapBoxLayers.neighborhoodsHover, mapBoxSources.neighbourhoods);
    this.attachMouseLeave(mapBoxLayers.citiesHover, mapBoxSources.cities);
    this.attachMouseLeave(mapBoxLayers.neighborhoodsHover, mapBoxSources.neighbourhoods);
    this.attachClick(mapBoxLayers.citiesHover)
    this.attachClick(mapBoxLayers.neighborhoodsHover)

    this.map.on("mouseenter", mapBoxLayers.citiesHover, (e) => {
      this.map.getCanvas().style.cursor = "pointer";
    })

    this.props.onInitMap();
    this.updateGeoData();
  }

  componentDidMount() {
    const { minZoom, maxZoom } = this.props;
    this.initMap(maxZoom, minZoom);
  }

  componentDidUpdate(prevProps: MapProps) {
    const { props } = this;

    if ((props.cities !== prevProps.cities) || (props.neighs !== prevProps.neighs) && this.isMapLoaded) {
      this.updateGeoData()
    }
    if (props.center !== prevProps.center) {
      const center =  isMobile() ? [props.center[0], props.center[1] - 0.06] as [number, number] : props.center
      this.map.flyTo({center: center, zoom: isMobile() ? 10 : 11})
    }
    if ((props.cities || props.neighs) && props.selectedCity && props.selectedCity !== prevProps.selectedCity) {
      const selectedCity = this.map.getSource(mapBoxSources.selectedCity) as mapboxgl.GeoJSONSource;
      selectedCity.setData(props.selectedCity);
    }
    if ((props.cities || props.neighs) && props.selectedNeighborhood && props.selectedNeighborhood !== prevProps.selectedNeighborhood) {
      const selectedNeigh = this.map.getSource(mapBoxSources.selectedNeighbourhoods) as mapboxgl.GeoJSONSource;
      const selectedCity = this.map.getSource(mapBoxSources.selectedCity) as mapboxgl.GeoJSONSource;
      selectedNeigh.setData(props.selectedNeighborhood);
      selectedCity.setData(props.selectedCity);
    }
    
  }

  private updateHoverElement(feature: mapboxgl.MapboxGeoJSONFeature, {x, y}: mapboxgl.Point ) {
    if(x < 380) {
      this.popUp.current.style.transform = "none";
      x += 30;
    }
    else {
      this.popUp.current.style.transform = "translate(-100%)";
      x -= 10;
    }
    if(y > 426) {
      y -= 120;
    }

    const popUpElement = this.props.getHoverElement(feature);;
    this.popUp.current.style.display = "block";
    this.popUp.current.style.top = `${y}px`;
    this.popUp.current.innerHTML = renderToStaticMarkup(popUpElement);
    this.popUp.current.style.left = `${x}px`;
  }

  private removeHoverElement() {
    this.popUp.current.style.display = "none";
  }

  // subscription to map events
  
  private attachMouseMove(layer: mapBoxLayers, source: mapBoxSources) {
    this.map.on("mousemove", layer, (e) => {
      if (e.features.length > 0) {
        if (this.hoveredPolyId) {
          this.map.setFeatureState({source: source, id: this.hoveredPolyId}, { hover: false });
        }
        if (e.features[0].properties.areaId !== this.props.preventHoverAreaId) {
          // for hovering neighborhoods inside city polygon
          this.hoveredPolyId = e.features[0].id;
          this.map.setFeatureState({source: source, id: this.hoveredPolyId}, { hover: true });
        }
        this.map.getCanvas().style.cursor = "pointer";
        this.updateHoverElement(e.features[0], e.point);
      }
    });
  }

  private attachMouseLeave(layer: mapBoxLayers, source: mapBoxSources) {
    this.map.on("mouseleave", layer, (e) => {
      if (this.hoveredPolyId) {
        this.map.setFeatureState({source: source, id: this.hoveredPolyId}, { hover: false});
        this.removeHoverElement();
      }
      this.hoveredPolyId =  null;
      this.map.getCanvas().style.cursor = '';
    });
  }

  private attachClick(layer: mapBoxLayers) {
    this.map.on("click", layer, (e) => {
      if(this.props.onPolygonClick) {
        this.props.onPolygonClick(e.features[0].properties as ElectionsProperties);
      }

      if ((this.props.type as any) !== 'neighbourhood') {
        const center = isMobile() ? [e.lngLat.lng, e.lngLat.lat - 0.06] as [number,number] : e.lngLat;
        this.map.flyTo({center, zoom: isMobile() ?  10 : 11})
      }
      else {
        const center = e.lngLat; 
        this.map.jumpTo({center});
      }
    })
  }

  updateGeoData() {
    if (!(this.props.neighs || this.props.cities)) return;
    
    const { cities, neighs } = this.props;
    const citiesSource = this.map.getSource(mapBoxSources.cities) as mapboxgl.GeoJSONSource;

    if (citiesSource && cities) {
      citiesSource.setData(toFeatureCollection(cities.features));
    }

    const neighsSource = this.map.getSource(mapBoxSources.neighbourhoods) as mapboxgl.GeoJSONSource;
    if (neighsSource && neighs) {
      neighsSource.setData(toFeatureCollection(neighs.features));
    }

    if(!this.isBorderLoaded) {
      const borderSource = this.map.getSource(mapBoxSources.citiesReadOnly) as mapboxgl.GeoJSONSource;
      if(borderSource) {
        borderSource.setData(toFeatureCollection(this.props.cities.features));
        this.isBorderLoaded = true;
      }
    }
  }

  private initMap(maxZoom: number, minZoom: number): any {
    this.map = new mapboxgl.Map({
      center: [34.776563, 32.074045],
      container: this.container.current,
      maxZoom,
      minZoom,
      scrollZoom: false,
      style: "mapbox://styles/wamir79/cjty4ppr60nwt1fo7dfatw9cy",
      zoom: 9,
      fadeDuration: 0,
    });

    this.map.once("load", this.initlDefaultLayers);
    this.map.scrollZoom.enable();
  }

  render() {
    
    return (
      <div className="map-wrapper">
        {this.props.children}
        <div className="map" ref={this.container} />
        <div className="polygon-hover-popup" ref={this.popUp} />
      </div>)
  }
}

