import * as React from "react";
import {Map} from "./map/Map";
import { getElections } from "../api";
import { ElectionsResponse, ElectionsResultsItems, ElectionsProperties, OverallResults } from "../types/elections-data";
import ElectionsStatistics from "./votes-distribution";
import { Chart as PartiesChart, FragmentLabel } from "./votes-distribution/Chart";
import "./style.scss";
import { PartiesDefinition, PartyId, partiesOrder } from "../utils/parties";
import { ReactComponent as Minimize } from "../assets/Minimize.svg";
import { ReactComponent as Maximize } from "../assets/Maximize.svg"; 
import { ReactComponent as MadlanRound } from "../assets/MadlanRound.svg"; 
import { isMobile, deepClone } from "../utils/generals";
import { toFeatureCollection } from "../utils/geo-utils";
import { GeoSearch, Area } from "./search";
import { Feature } from "geojson";
import { MobileHeaderBar } from "./mobile-header-bar";
import { ReactComponent as Madlan } from "../assets/Madlan.svg";
import { ReactComponent as Ynet } from "../assets/Ynet.svg";
import mapboxgl from "mapbox-gl";
import { areas } from "../areas.js";
import * as query from "query-string";


export class AppState {
  areaId: string;
  center: [number, number] = [34.776563, 32.074045];
  zoom: {max: number, min: number} = {max: 14, min: 7}
  mapBoxToken: string = "pk.eyJ1Ijoid2FtaXI3OSIsImEiOiJjanQ2czlqMnAwaWUyNDRvY284dmt0NTR2In0.6k-XpNGb8hXkCdhDUp2YZA";
  mapBoxStyle: string = "mapbox://tyles/mapbox/streets-v9";
}

export type AreaType = "city" | "neighborhood";

interface State {
  neighs: GeoJSON.FeatureCollection
  cities: GeoJSON.FeatureCollection
  selectedCity: GeoJSON.Feature
  selectedNeighborhood: GeoJSON.Feature
  currentAreaId: string;
  preventHoverAreaId: string;
  currentAreaVotingPercentage: number;
  overallVotingPercentage: number;
  areaParties: ElectionsResultsItems;
  center: [number, number];
  areaType: AreaType;
  loading: boolean;
  hideStatistics: boolean;
  votesCount: number;
  lastUpdateTime: string;
  isFullScreen: boolean;
  isDimensionsValid: boolean;
  unsupportedBrowesr: boolean;
  isFirstInteractionMade: boolean;
}

class App extends React.Component<any, State> {

  private appState = new AppState();
  private hoverPopup: React.RefObject<any> = React.createRef();
  private countryResults: ElectionsResultsItems;
  private citiesResults: GeoJSON.FeatureCollection = {type: "FeatureCollection", features: []};
  private neighboorhoodResults: GeoJSON.FeatureCollection = {type: "FeatureCollection", features: []};
  private appRef: React.RefObject<HTMLDivElement> = React.createRef();
  private statisticsRef: React.RefObject<ElectionsStatistics> = React.createRef();
  private searchRef: React.RefObject<GeoSearch> = React.createRef();

  constructor(props: any) {
    super(props);

    if (!mapboxgl.supported() || (window.navigator.userAgent.indexOf("MSIE ")) > -1 || (window.navigator.userAgent.indexOf("Trident/")) > -1) {
      window.location.href = "https://elections.madlan.co.il/UnsupportedBrowser.html";
    }

    this.state = {
        cities: null,
        neighs: null,
        currentAreaId: null,
        currentAreaVotingPercentage: null,
        overallVotingPercentage: null,
        preventHoverAreaId: null,
        areaParties: null,
        center: this.appState.center,
        areaType: "city",
        loading: true,
        hideStatistics: false,
        votesCount: null,
        lastUpdateTime: "",
        isFullScreen: false,
        isDimensionsValid: true,
        unsupportedBrowesr: false,
        selectedNeighborhood: null,
        selectedCity: null,
        isFirstInteractionMade: false
    }

  }

  async componentDidMount() {
    try {
      const electionsData = await getElections("elections") as any;
      const countryData = electionsData.countryData.results["ישראל"][0];
      this.countryResults = this.orderParties(countryData.electionsResults); 
      this.citiesResults = electionsData.citiesData.results;
      this.neighboorhoodResults = electionsData.neighbourhoodsData.results;
      let date = new Date(electionsData.lastUpdate).toLocaleDateString().split("/");

      this.setState({
        loading: false,
        cities: this.citiesResults, 
        neighs: null,
        selectedCity: null,
        selectedNeighborhood: null,
        lastUpdateTime: [date[1], date[0], date[2]].join("/"),
        votesCount: electionsData.votersCountedPct,
        overallVotingPercentage: countryData.votingPercentage
      });

    }
    catch {
      this.setState({ loading: false });
    }
  }

  private cityIdToNeighsFeatures(cityId: string) :Feature<any>[] {
    return this.neighboorhoodResults.features.filter(neigh => neigh.properties.cityAreaId === cityId);
  }

  private cityFeature(cityId: string): Feature<any> {
    return this.citiesResults.features.filter( city => city.properties.areaId === cityId)[0];
  }

  private loadAreaStatistics = async ({areaId, cityAreaId, areaType, votingPercentage, electionsResults}: ElectionsProperties, updateGeo = true ) => {
    
    if(areaType === "city") {
      const citytoRemoveIndex = this.citiesResults.features.findIndex(x => x.properties.areaId === areaId);
      const cities = deepClone(this.citiesResults);
      if(citytoRemoveIndex > -1) {
        cities.features.splice(citytoRemoveIndex, 1);
      }
      // filtering current city to hide its polygon
      const cityNeighboorhoods = this.cityIdToNeighsFeatures(areaId);
       this.setState({ 
        cities: cities,
        neighs: updateGeo ? toFeatureCollection(cityNeighboorhoods) : this.state.neighs,
        selectedCity: this.citiesResults.features[citytoRemoveIndex],
        selectedNeighborhood: null,
        preventHoverAreaId: areaId,
        currentAreaVotingPercentage: votingPercentage,
      })
    }
    else {
      const neighToRemove = this.neighboorhoodResults.features.findIndex(x => x.properties.areaId === areaId);
      const neighs = deepClone(this.neighboorhoodResults);
      if(neighToRemove > -1) {
        neighs.features.splice(neighToRemove, 1);
      }
      const citytoRemoveIndex = this.citiesResults.features.findIndex(x => x.properties.areaId === cityAreaId);
      const cities = deepClone(this.citiesResults);
      if(citytoRemoveIndex > -1) {
        cities.features.splice(citytoRemoveIndex, 1);
      }
      this.setState({
        cities: cities,
        selectedCity: this.cityFeature(cityAreaId),
        selectedNeighborhood: this.neighboorhoodResults.features[neighToRemove]
      })
    }
    this.setState({
      currentAreaId: areaId, 
      areaType,
      currentAreaVotingPercentage: votingPercentage,
      isFirstInteractionMade: true,
      areaParties: typeof electionsResults === "object" ? electionsResults : JSON.parse(electionsResults as any)});
    
      if(this.statisticsRef.current) {
        this.statisticsRef.current.openDrawer();
      }
  }

  private neighToCity(neighId: string) {
    const neigh = this.neighboorhoodResults.features.find(n => n.properties.areaId === neighId);
    if (neigh) {
      return neigh.properties.cityAreaId;
    }
  }

  private handleSearchPoint = ({id, type, center}: Area) => {
    let feature;

    if(type === "neighborhood") {
      const cityId = this.neighToCity(id);
      const cityNeighboorhoods = this.cityIdToNeighsFeatures(cityId || id);
      feature = this.neighboorhoodResults.features.filter(x => x.properties.areaId === id);
      if(feature.length > 0) {
        this.loadAreaStatistics(feature[0].properties as ElectionsProperties, false);
      }
      this.setState({ 
        neighs: toFeatureCollection(cityNeighboorhoods),
      })
    }
    else {
      feature = this.citiesResults.features.find(x => x.properties.areaId === id);
      this.loadAreaStatistics(feature.properties as ElectionsProperties);
    }

    this.setState({ center });
  }

  private orderParties(parties: ElectionsResultsItems): ElectionsResultsItems {
    return partiesOrder.reduce((acc: any, val: string) => (
      (acc[val] = parties[val]), acc
    ), {})
  }

  private getHoverElement = (feature: mapboxgl.MapboxGeoJSONFeature) => {
    if(isMobile()) {
      return;
    }
    const { areaId, electionsResults } = feature.properties;
    const featureParties = Object.entries(JSON.parse(electionsResults) as ElectionsResultsItems)

    if(featureParties.length === 0) {
      return (
        <div className="hover-chart">
          <PartiesChart header={`${areaId} - ספירת הקולות החלה`} ref={this.hoverPopup} removeBottomLabels/>
          <span style={{alignSelf: "baseline", marginTop: -30, color: "#767676", fontSize: 13}}>תוצאות ראשונות בקרוב!</span>
        </div>)
    }

    const sortedResults = featureParties.sort((x: [string, number], y: [string, number]) => y[1] - x[1])
    const maxParty = PartiesDefinition[sortedResults[0][0] as PartyId];
    const secondMaxParty = PartiesDefinition[sortedResults[1][0] as PartyId];

    return (
      <div className="hover-chart">
        <PartiesChart header={areaId} parties={JSON.parse(electionsResults)} ref={this.hoverPopup} removeBottomLabels/>
        <div className="hover-chart-labels-wrapper">
          מובילות:
          <FragmentLabel color={maxParty.rgb} name={maxParty.name} isSelected votingPercentage={sortedResults[0][1] % 1 === 0 ? sortedResults[0][1]*100 : parseFloat((sortedResults[0][1]*100).toPrecision(2))}/>
          {sortedResults[1][1] != 0 && <FragmentLabel color={secondMaxParty.rgb} name={secondMaxParty.name} isSelected votingPercentage={sortedResults[1][1] % 1 === 0 ? sortedResults[1][1]*100 : parseFloat((sortedResults[1][1]*100).toPrecision(2))}/>}
        </div>
      </div>)
  }

  private hideStatistics = () => {
    this.setState({ hideStatistics: true});
  }

  private showStatistics = () => {
    this.setState({ hideStatistics: false});
  }

  private enterFullScreen = () => {
    if (this.appRef.current.requestFullscreen) {
      this.appRef.current.requestFullscreen();
    }
    this.setState({ isFullScreen: true});
  }

  private exitFullScreen = () => {
    if (document.fullscreen && document.exitFullscreen) {
      document.exitFullscreen();
    }
    this.setState({ isFullScreen: false});
  }

  handleOrientation = () => {
    if(window.orientation === 90 || window.orientation === -90) {
      this.setState({ isDimensionsValid: false })
    }
    else {
      this.setState({ isDimensionsValid: true })
    }
  }

  private onInitMap = () => {
    const { city } = query.parse(document.location.search);

    if(city) {
      const pickedCityGeo = this.citiesResults.features.find(c => c.properties.areaId === city);
      if (pickedCityGeo) {
        this.loadAreaStatistics(pickedCityGeo.properties as any);
        const pickedCityProp = areas.find(a => a.id === city);
        this.searchRef.current.closeSearch();
        if( pickedCityProp ) {
          const center = isMobile() ? [pickedCityProp.center[0], pickedCityProp.center[1] + 0.06] : pickedCityProp.center;
          this.setState({ center: center as [number, number] })
        }
      }
    }
  }

  componentWillMount() {
    
    window.addEventListener("orientationchange", this.handleOrientation);

    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreen) {
        this.enterFullScreen();
      }
      else {
        this.exitFullScreen();
      }
    });
  }

  render() {
    const { currentAreaId, preventHoverAreaId, currentAreaVotingPercentage,
            overallVotingPercentage, areaParties, areaType, loading, hideStatistics, votesCount, lastUpdateTime, isFullScreen, isDimensionsValid, unsupportedBrowesr,
            selectedNeighborhood, selectedCity, neighs, cities, isFirstInteractionMade } = this.state;
    const { zoom } = this.appState;
    const { center } = this.state;


    if(!isDimensionsValid || unsupportedBrowesr) {
      return (
        <div className="invalid-state">
          <h2>
            {!isDimensionsValid ? "בבקשה סובבו את המסך" : "מפת הבחירות אינה נתמכת בדפדפן זה"}
          </h2>
          <div className="invalid-state-logos">
            <a href="https://www.ynet.co.il" target="_blank"><Ynet/></a>
            <a href="https://www.madlan.co.il" target="_blank"><Madlan/></a>
          </div>
        </div>)
    }

    return (
      <div ref={this.appRef} className="App">
        {!hideStatistics && 
          <div className="statistics-wrapper">
            <ElectionsStatistics 
              ref={this.statisticsRef}
              lastUpdateTime={lastUpdateTime}
              votesCount={votesCount * 100}
              loading={loading}
              overallParties={this.countryResults}
              areaParties={areaParties}
              overallVotingPercentage={(overallVotingPercentage * 100).toPrecision(2)} 
              areaVotingPercentage={(currentAreaVotingPercentage * 100).toPrecision(2)} 
              currentAreaId={currentAreaId} />
          </div>}
        <Map
            cities={cities}
            neighs={neighs}
            selectedCity={selectedCity}
            selectedNeighborhood={selectedNeighborhood}
            maxZoom={zoom.max}
            minZoom={zoom.min}
            center={center}
            onPolygonClick={this.loadAreaStatistics}
            getHoverElement={this.getHoverElement}
            preventHoverAreaId={preventHoverAreaId}
            type={areaType}
            onInitMap={this.onInitMap}>
          <MobileHeaderBar />
          {/* <FullScreen onMaximize={this.enterFullScreen} onMinimize={this.exitFullScreen} current={isFullScreen ? "minimized" : "maximized"} /> */}
          {this.countryResults && <GeoSearch ref={this.searchRef} isSearchClosed={isFirstInteractionMade} location={currentAreaId} onMobileSearchOpen={this.hideStatistics} onSearchBlur={this.showStatistics} onPickGeoResult={this.handleSearchPoint}/>}
          <a className="madlan-logo" href={`https://www.madlan.co.il${currentAreaId ? `/local/${currentAreaId}` : ""}?utm_source=ynet&utm_campaign=election_2019&utm_content=logo`} target="_blank">
            {/* <img className="madlan-logo" src="https://assets.madlan.co.il/widgets/ynetElectionWidget/2019/logo.png"/> */}
            <MadlanRound />
          </a>
        </Map>
      </div>);
  }
}

interface FullScreen {
  current: "minimized"|"maximized";
  onMinimize: () => void;
  onMaximize: () => void;
}

const FullScreen = ({ onMinimize, onMaximize, current }: FullScreen) => {
  return (
    <div onClick={ current === "maximized" ? onMaximize : onMinimize} className="resize-button">
      <div className="svg-container">
        {current === "maximized" ? <Maximize /> : <Minimize />}
      </div>
    </div>
  )
}

export default App;
