import * as React from "react";
import {Chart as PartiesChart} from "../votes-distribution/Chart";
import PartyBullets  from "./Bullet";
import { PartyId } from "../../utils/parties";
import { ElectionsResultsItems, OverallResults } from "../../types/elections-data";
import { Footer } from "../votes-distribution/Footer";
import { ReactComponent as Box } from "../../assets/Box.svg";
import { ReactComponent as Facebook } from "../../assets/Facebook.svg";
import { ReactComponent as Link } from "../../assets/Link.svg";
import { ReactComponent as Info } from "../../assets/Info.svg";
import { ReactComponent as BallotBox } from "../../assets/Kalpi.svg";
import { ReactComponent as Madlan } from "../../assets/Madlan.svg";
import { ReactComponent as Ynet } from "../../assets/Ynet.svg";
import { ReactComponent as ArrowUp } from "../../assets/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../assets/ArrowDown.svg";
import { isMobile, copyToClipboard, isAfter10pm } from "../../utils/generals";
import { ScrollableMenu } from "../mobile-votes-distribution/scrollable-menu";
import CircularProgressbar from "react-circular-progressbar";
import ReactTooltip from "react-tooltip";
import { Swipeable } from 'react-swipeable';
import "react-circular-progressbar/dist/styles.css";
import { StatisticsLoadingIndicator } from "../loading-indicator";

interface ElectionsStatisticsProps {
  areaParties: ElectionsResultsItems;
  overallParties: ElectionsResultsItems;
  currentAreaId: string;
  areaVotingPercentage: string;
  overallVotingPercentage: string;
  loading: boolean;
  votesCount: number;
  lastUpdateTime: string;
}

class ElectionsStatistics extends React.Component<ElectionsStatisticsProps, any> {

  private copiedTooltip: any = React.createRef();

  constructor(props: ElectionsStatisticsProps) {
    super(props);

    this.state = {
      activePartyId: null,
      isFullSize: true
    }
  }

  private partyEnter = (partyId: PartyId|string) => {
    this.setState({activePartyId: partyId})
  }

  private partyLeave = () => {
    this.setState({activePartyId: null})
  }

  private resize = () => {
    this.setState({isFullSize: !this.state.isFullSize})
  }

  private copyToClipboard = () => {
    copyToClipboard(document.referrer);
    this.setState({showCopyTooltip: true});
    setTimeout(()=>this.setState({showCopyTooltip: false}), 4000);
  }

   private closeDrawer = () => {
    if (this.state.isFullSize) {
      this.setState({ isFullSize: false });
    }
   };

   public openDrawer = () => {
    if (!this.state.isFullSize) {
      this.setState({ isFullSize: true });
    }
   };

   private toggleDrawer = () => {
    this.setState({ isFullSize: !this.state.isFullSize });
   }

  render() {
    const {activePartyId, isFullSize, showCopyTooltip} = this.state;
    const { currentAreaId, overallParties, areaParties, areaVotingPercentage, overallVotingPercentage, loading, votesCount, lastUpdateTime } = this.props;
    const mobile = isMobile();
    let Icon: React.ReactType;

    if(!mobile) {
      // Desktop

      Icon = Box;

      return (
        <div className="statistics-container">
          <div className="main-content" style={{justifyContent: overallParties ? "space-between" : "unset" }}>
            {loading ? <StatisticsLoadingIndicator />
              : <React.Fragment>
                <div className="statistics-header">
                    <div className="icons-row">
                      <h4 style={{marginTop: 5}}>מדלן ו-ynet מציגים:</h4>
                      <span className="share-icons">
                        <Info data-tip data-for="info-tooltip"/>
                        <ReactTooltip id="info-tooltip" place="bottom" type="dark" effect="solid">
                          <InfoText/>
                        </ReactTooltip>
                          {showCopyTooltip && <h4 className="copied-link">קישור הועתק!</h4>}
                        <Link data-tip data-for="copy-tooltip" onClick={this.copyToClipboard} data-event="click" ref={this.copiedTooltip}/>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${document.referrer}`} target="_blank">
                          <Facebook/>
                        </a>
                      </span>
                    </div>
                  {overallParties && <h2 style={{marginTop: 0, marginBottom: 10, fontWeight: 400}}>מפת הבחירות של ישראל</h2>}
                </div>
                {overallParties ?
                  <React.Fragment>
                    <PartiesChart header={currentAreaId} Icon={Icon} percentage={areaVotingPercentage} parties={areaParties} activeParty={activePartyId} onFragmentEnter={this.partyEnter} onFragmentLeave={this.partyLeave}/>
                    <PartiesChart header={"תוצאות ארציות"} addPercentageThreshold Icon={Icon} percentage={overallVotingPercentage} parties={overallParties} activeParty={activePartyId} onFragmentEnter={this.partyEnter} onFragmentLeave={this.partyLeave}/>
                    <PartyBullets activePartyId={activePartyId} parties={overallParties} onPartyEnter={this.partyEnter} onPartyLeave={this.partyLeave}/>
                    <ElectionsMeta votesCount={votesCount} lastUpdateTime={lastUpdateTime} />
                  </React.Fragment> :
                  <ElectionsPlaceHolder/>}
              </React.Fragment>}
          </div>
          <Footer text="רוצים לדעת עוד על השכנים שלכם?" link={{text: "לוח מדלן", url: `https://www.madlan.co.il${currentAreaId ? `/local/${currentAreaId}` : ""}?utm_source=ynet&utm_campaign=election_2019&utm_content=cta_button
`}}/>
        </div>)
    }

    else {
      // Mobile

      Icon = () => <CircularProgressbar strokeWidth={20} styles={{path: {stroke: "#767676"}}} percentage={Number(areaVotingPercentage)}/>
      const OverallIcon = () => <CircularProgressbar strokeWidth={20} styles={{path: {stroke: "#767676"}}} percentage={Number(overallVotingPercentage)}/>

      return (
          <div className="mobile-statistics-container">
              {loading ? <StatisticsLoadingIndicator/> 
                : <React.Fragment>
                    <div className="hidable-wrapper" onClick={this.toggleDrawer}>
                    {isFullSize ? 
                      <ArrowDown />:
                      <ArrowUp />}
                    </div>
                        <Swipeable draggable="true" onSwipedUp={this.openDrawer} onSwipedDown={this.closeDrawer} preventDefaultTouchmoveEvent={true} className="hidable-container">
                          {overallParties ? 
                            <div className="charts">
                              <ScrollableMenu classNames={isFullSize ? [] : ["hiden"]} parties={overallParties} onClick={this.partyEnter} selectedItemId={activePartyId}/>
                              <PartiesChart isMobile header={currentAreaId} Icon={Icon} percentage={areaVotingPercentage} parties={areaParties} activeParty={activePartyId} onFragmentEnter={this.partyEnter} onFragmentLeave={this.partyLeave}/>
                              <PartiesChart isMobile addPercentageThreshold header={"תוצאות ארציות"} Icon={OverallIcon} percentage={overallVotingPercentage} parties={overallParties} activeParty={activePartyId} onFragmentEnter={this.partyEnter} onFragmentLeave={this.partyLeave}/>
                              <ElectionsMeta votesCount={votesCount} lastUpdateTime={lastUpdateTime} />
                            </div> :
                            <ElectionsPlaceHolder/>}
                        </Swipeable>
                  </React.Fragment>}
              <Footer classNames={isFullSize ? [] : ["hiden"]} text="רוצים לדעת עוד על השכנים שלכם?" link={{text: "לוח מדלן", url: `https://www.madlan.co.il${currentAreaId ? `/local/${currentAreaId}` : ""}?utm_source=ynet&utm_campaign=election_2019&utm_content=cta_button
`}}/>
          </div>)
    }
  }
}

const ElectionsPlaceHolder = () => {
  return (
    <div className="elections-place-holder">
      <div className="companies-logos">
        <a href="https://www.ynet.co.il" target="_blank"><Ynet/></a>
        <a href="https://www.madlan.co.il" target="_blank"><Madlan/></a>
      </div>
      <h2>מפת הבחירות של ישראל</h2>
      <div className="svg-container"><BallotBox/></div>
        <div className="place-holder-text">
          {isAfter10pm() ?
            <React.Fragment>
              <h3 style={{fontWeight: "bold"}}>ספירת הקולות החלה!</h3>
              <h5 style={{fontWeight: 500}}>תוצאות ראשונות בקרוב</h5>
            </React.Fragment> :
            <React.Fragment>
              <h5 style={{fontWeight: 500, marginBottom: 0}}>הקלפיות עדיין פתוחות - </h5>
              <h5 style={{fontStyle: "bold"}}>לכו להצביע!</h5>
            </React.Fragment>}
        </div>
    </div>)
}

export default ElectionsStatistics;

export const InfoText = () => {
  return (
    <React.Fragment>
      המידע המוצג מבוסס על עיבוד שערך אתר
      <br/>
      ״מדלן - הכל על בתים ושכונות״ לנתוני ועדת
      <br/>
      הבחירות המרכזית. נתוני השכונות מקורבים
      <br/>
      לפי התאמת קלפיות. כל המידע מוצג תחת
      <br/>
      תנאי השימוש באתר מדלן. מפה: Mapbox
      <br/>
      <strong>
        פותח ע״י מדלן
      </strong>
    </React.Fragment>)
}

interface ElectionsMetaProps {
  votesCount: number;
  lastUpdateTime: string;
}

const ElectionsMeta = ({votesCount, lastUpdateTime}: ElectionsMetaProps) => {
  return (
    <div className="votes-time-update">
      <span>
        <CircularProgressbar strokeWidth={20} styles={{path: {stroke: "#767676"}}} percentage={votesCount}/>
        <h5>{`אחרי ספירת ${votesCount}% מהקולות`}</h5>
      </span>
      <span>
      <h5>{`עדכון אחרון: ${lastUpdateTime}`}</h5>
      </span>
    </div>
  )
}