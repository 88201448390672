

// js files due long reloading times with typescript

export const areas = [
  { name: "משכיות", type: "city", center: [35.49176806053063, 32.31375161563194], id: "משכיות" },
  { name: "אוהד", type: "city", center: [34.43216223304331, 31.23727464169814], id: "אוהד" },
  { name: "יסעור", type: "city", center: [35.16599752927557, 32.901105394727615], id: "יסעור" },
  { name: "כפר עזה", type: "city", center: [34.53270086406486, 31.48377289529945], id: "כפר עזה" },
  { name: "חלמיש", type: "city", center: [35.12495852982265, 32.00492759238857], id: "חלמיש" },
  { name: "עתלית", type: "city", center: [34.94285482704165, 32.692747733742955], id: "עתלית" },
  { name: "אבני חפץ", type: "city", center: [35.079634238997265, 32.2794000849565], id: "אבני חפץ" },
  { name: "בחן", type: "city", center: [35.019572147147166, 32.35157436730048], id: "בחן" },
  { name: "עילוט", type: "city", center: [35.26198410212117, 32.71756999617276], id: "עילוט" },
  { name: "בני עי\"ש", type: "city", center: [34.76023218075209, 31.789440591808795], id: "בני עי\"ש" },
  { name: "מכמנים", type: "city", center: [35.32714090227121, 32.906531377712774], id: "מכמנים" },
  { name: "גת (קיבוץ)", type: "city", center: [34.79398565596202, 31.627190426581784], id: "גת (קיבוץ)" },
  { name: "קדומים", type: "city", center: [35.15925192455084, 32.220006914723854], id: "קדומים" },
  { name: "צוחר", type: "city", center: [34.427926632183585, 31.236090993780035], id: "צוחר" },
  { name: "נווה זוהר", type: "city", center: [35.3652872400031, 31.152440682053097], id: "נווה זוהר" },
  { name: "נורדיה", type: "city", center: [34.89574340478435, 32.31561914027433], id: "נורדיה" },
  { name: "אליאב", type: "city", center: [34.9309729281199, 31.529619948631037], id: "אליאב" },
  { name: "גזית", type: "city", center: [35.44686488694517, 32.638146973583595], id: "גזית" },
  { name: "סח'נין", type: "city", center: [35.3009525872011, 32.86489537571861], id: "סח'נין" },
  { name: "גדות", type: "city", center: [35.619150101946545, 33.01840598613664], id: "גדות" },
  { name: "שמרת", type: "city", center: [35.09567526520561, 32.950646738162774], id: "שמרת" },
  { name: "נופך", type: "city", center: [34.92090916157193, 32.04338914963883], id: "נופך" },
  { name: "יונתן", type: "city", center: [35.795441793538835, 32.93886449962124], id: "יונתן" },
  { name: "פקיעין (בוקייעה)", type: "city", center: [35.329520101767685, 32.972533950841616], id: "פקיעין (בוקייעה)" },
  { name: "מגדל", type: "city", center: [35.50129835363346, 32.84060462525679], id: "מגדל" },
  { name: "רעננה", type: "city", center: [34.86915713476134, 32.186833651885884], id: "רעננה" },
  { name: "מעגן", type: "city", center: [35.60085179925529, 32.70601134191854], id: "מעגן" },
  { name: "גורן", type: "city", center: [35.235418416229585, 33.05647459113509], id: "גורן" },
  { name: "בוסתן הגליל", type: "city", center: [35.08228589348339, 32.951562593621716], id: "בוסתן הגליל" },
  { name: "מרחביה-מושב", type: "city", center: [35.316457639431604, 32.603707902196305], id: "מרחביה-מושב" },
  { name: "נחף", type: "city", center: [35.31884224186802, 32.93485430442674], id: "נחף" },
  { name: "שעל", type: "city", center: [35.71822488370912, 33.11708520479061], id: "שעל" },
  { name: "מגן שאול", type: "city", center: [35.30667789622215, 32.52136579986771], id: "מגן שאול" },
  { name: "מבטחים", type: "city", center: [34.40843765313661, 31.240796347517993], id: "מבטחים" },
  { name: "נווה אילן", type: "city", center: [35.080136949059586, 31.808310307632773], id: "נווה אילן" },
  { name: "עין הנצי\"ב", type: "city", center: [35.50099587281903, 32.471289753339505], id: "עין הנצי\"ב" },
  { name: "שפרעם", type: "city", center: [35.1738104816224, 32.805381130554146], id: "שפרעם" },
  { name: "אור יהודה", type: "city", center: [34.855524397626255, 32.02872484474157], id: "אור יהודה" },
  { name: "בית רבן", type: "city", center: [34.729265083476015, 31.816977530166394], id: "בית רבן" },
  { name: "להבות חביבה", type: "city", center: [35.00959701373884, 32.395044507839934], id: "להבות חביבה" },
  { name: "צאלים", type: "city", center: [34.533672860157154, 31.203918025885038], id: "צאלים" },
  { name: "הרדוף", type: "city", center: [35.17376099157572, 32.763425434109344], id: "הרדוף" },
  { name: "כפר תפוח", type: "city", center: [35.25060417085256, 32.11876326521446], id: "כפר תפוח" },
  { name: "מצר", type: "city", center: [35.04827203229779, 32.43996113425616], id: "מצר" },
  { name: "כפר אוריה", type: "city", center: [34.9460335123057, 31.793103267118468], id: "כפר אוריה" },
  { name: "ניר עקיבא", type: "city", center: [34.646411877385894, 31.46999618111037], id: "ניר עקיבא" },
  { name: "כפר שמאי", type: "city", center: [35.45843465077923, 32.95626889141344], id: "כפר שמאי" },
  { name: "נווה אטי\"ב", type: "city", center: [35.74211826298402, 33.26248738883675], id: "נווה אטי\"ב" },
  { name: "כדורי", type: "city", center: [35.40669801430552, 32.70373708114105], id: "כדורי" },
  { name: "יבול", type: "city", center: [34.319345631938134, 31.18862831501178], id: "יבול" },
  { name: "כפר קיש", type: "city", center: [35.44905402018677, 32.666368852540494], id: "כפר קיש" },
  { name: "עין חצבה", type: "city", center: [35.26638708068775, 30.810454357214905], id: "עין חצבה" },
  { name: "ניר עם", type: "city", center: [34.57949338095074, 31.519695197279805], id: "ניר עם" },
  { name: "נוגה", type: "city", center: [34.694378774406715, 31.624149857629934], id: "נוגה" },
  { name: "בארות יצחק", type: "city", center: [34.90990450150079, 32.04191229008439], id: "בארות יצחק" },
  { name: "הזורעים", type: "city", center: [35.50210105997668, 32.746531281105796], id: "הזורעים" },
  { name: "נצרת", type: "city", center: [35.291949405323884, 32.70347318062417], id: "נצרת" },
  { name: "ערערה בנגב", type: "city", center: [35.020091896191296, 31.156606827678942], id: "ערערה בנגב" },
  { name: "עכו", type: "city", center: [35.08438491089224, 32.92878623783456], id: "עכו" },
  { name: "געתון", type: "city", center: [35.21454932570364, 33.005965088003286], id: "געתון" },
  { name: "פרדס חנה כרכור", type: "city", center: [34.97246781684522, 32.47634382581353], id: "פרדס חנה כרכור" },
  { name: "עזריקם", type: "city", center: [34.697678510323975, 31.750472598170294], id: "עזריקם" },
  { name: "גינתון", type: "city", center: [34.914135895564485, 31.963097235542104], id: "גינתון" },
  { name: "פוריה כפר עבודה", type: "city", center: [35.54652948986351, 32.71948640791142], id: "פוריה כפר עבודה" },
  { name: "שדות מיכה", type: "city", center: [34.920696360352544, 31.721023498985936], id: "שדות מיכה" },
  { name: "פתח תקווה", type: "city", center: [34.88586962589644, 32.09031159977354], id: "פתח תקווה" },
  { name: "קרית ים", type: "city", center: [35.07169999296889, 32.84891349826893], id: "קרית ים" },
  { name: "תל אביב יפו", type: "city", center: [34.792313682647745, 32.08421358092923], id: "תל אביב יפו" },
  { name: "כפר מל\"ל", type: "city", center: [34.89405785631378, 32.16786257024631], id: "כפר מל\"ל" },
  { name: "יאנוח ג'ת", type: "city", center: [35.24458397641183, 32.979827034039694], id: "יאנוח ג'ת" },
  { name: "גן יאשיה", type: "city", center: [34.99511741739035, 32.3506331298603], id: "גן יאשיה" },
  { name: "כפר גלעדי", type: "city", center: [35.573962366472976, 33.24245425534574], id: "כפר גלעדי" },
  { name: "גלעד (אבן יצחק)", type: "city", center: [35.07607732246347, 32.556686417793465], id: "גלעד (אבן יצחק)" },
  { name: "מוקיבלה", type: "city", center: [35.29607561224328, 32.51383577602205], id: "מוקיבלה" },
  { name: "אביעזר", type: "city", center: [35.01551645193674, 31.681715253879776], id: "אביעזר" },
  { name: "באר שבע", type: "city", center: [34.78793262188577, 31.259500158476737], id: "באר שבע" },
  { name: "כרמי יוסף", type: "city", center: [34.920708980003795, 31.848320874398055], id: "כרמי יוסף" },
  { name: "הר אדר", type: "city", center: [35.1296280706783, 31.826580568063356], id: "הר אדר" },
  { name: "מבוא חמה", type: "city", center: [35.65527874885248, 32.73719289919761], id: "מבוא חמה" },
  { name: "אבטליון", type: "city", center: [35.35184120129079, 32.83658719331117], id: "אבטליון" },
  { name: "דוב\"ב", type: "city", center: [35.406699371086575, 33.0518194527638], id: "דוב\"ב" },
  { name: "נתיב הל\"ה", type: "city", center: [34.983434574490886, 31.68771463113178], id: "נתיב הל\"ה" },
  { name: "ירחיב", type: "city", center: [34.96878167455184, 32.153496126037915], id: "ירחיב" },
  { name: "מגשימים", type: "city", center: [34.89916830111473, 32.04682889935647], id: "מגשימים" },
  { name: "אניעם", type: "city", center: [35.74140005503208, 32.95726190725967], id: "אניעם" },
  { name: "כפר תבור", type: "city", center: [35.42114776779464, 32.689446190898444], id: "כפר תבור" },
  { name: "שריד", type: "city", center: [35.225107192659294, 32.66320143334763], id: "שריד" },
  { name: "אבנת", type: "city", center: [35.43743978138086, 31.679230917035436], id: "אבנת" },
  { name: "מנוף", type: "city", center: [35.23591737941365, 32.853920882524406], id: "מנוף" },
  { name: "רמלה", type: "city", center: [34.87156809280932, 31.92677095111325], id: "רמלה" },
  { name: "אחוזם", type: "city", center: [34.77033495653416, 31.55340666911461], id: "אחוזם" },
  { name: "בארותיים", type: "city", center: [34.982925319627796, 32.32045377649992], id: "בארותיים" },
  { name: "בית הגדי", type: "city", center: [34.608732719202315, 31.423353972413132], id: "בית הגדי" },
  { name: "בן שמן (מושב)", type: "city", center: [34.922587234922005, 31.952699536505943], id: "בן שמן (מושב)" },
  { name: "מורשת", type: "city", center: [35.23338908967882, 32.826385722709084], id: "מורשת" },
  { name: "מנות", type: "city", center: [35.194889470034475, 33.038384036566775], id: "מנות" },
  { name: "רועי", type: "city", center: [35.48857548855688, 32.247597792328826], id: "רועי" },
  { name: "נווה אבות", type: "city", center: [34.96710283554794, 32.48569756764392], id: "נווה אבות" },
  { name: "אפרת", type: "city", center: [35.159757719520464, 31.666109814465138], id: "אפרת" },
  { name: "נווה איתן", type: "city", center: [35.53228463547702, 32.49196485081313], id: "נווה איתן" },
  { name: "סופה", type: "city", center: [34.34054641201098, 31.236729628476073], id: "סופה" },
  { name: "חיבת ציון", type: "city", center: [34.912872851233224, 32.397464904795434], id: "חיבת ציון" },
  { name: "גדעונה", type: "city", center: [35.358715645822, 32.54899829673463], id: "גדעונה" },
  { name: "דיר ראפאת", type: "city", center: [34.9470433709843, 31.776416880223454], id: "דיר ראפאת" },
  { name: "סער", type: "city", center: [35.10889010409937, 33.02904163560435], id: "סער" },
  { name: "שדי אברהם", type: "city", center: [34.33719025830416, 31.210560175917838], id: "שדי אברהם" },
  { name: "האון", type: "city", center: [35.621348799546766, 32.72497635034273], id: "האון" },
  { name: "בית אלעזרי", type: "city", center: [34.80276732002239, 31.84476545058121], id: "בית אלעזרי" },
  { name: "חמאם", type: "city", center: [35.48949106878916, 32.83039679356265], id: "חמאם" },
  { name: "גן הדרום", type: "city", center: [34.70069696428058, 31.803887799200844], id: "גן הדרום" },
  { name: "איבים", type: "city", center: [34.610402193174274, 31.53354511675233], id: "איבים" },
  { name: "גברעם", type: "city", center: [34.611788497153356, 31.59122219048447], id: "גברעם" },
  { name: "ריחן", type: "city", center: [35.13555346814637, 32.46874989023435], id: "ריחן" },
  { name: "רמת ישי", type: "city", center: [35.1684394537274, 32.70560309591129], id: "רמת ישי" },
  { name: "קליה", type: "city", center: [35.46666621545322, 31.749378286968373], id: "קליה" },
  { name: "כפר מנדא", type: "city", center: [35.25970989731956, 32.80861012474556], id: "כפר מנדא" },
  { name: "רבבה", type: "city", center: [35.12740419646359, 32.11881378902631], id: "רבבה" },
  { name: "כפר כמא", type: "city", center: [35.440620139425384, 32.7207406014974], id: "כפר כמא" },
  { name: "כפר ביאליק", type: "city", center: [35.086935800283804, 32.82013992150254], id: "כפר ביאליק" },
  { name: "ניר עוז", type: "city", center: [34.403432010046124, 31.312953014478424], id: "ניר עוז" },
  { name: "כישור", type: "city", center: [35.249469565346324, 32.94709994479213], id: "כישור" },
  { name: "אשדוד", type: "city", center: [34.65934659021152, 31.810971786352024], id: "אשדוד" },
  { name: "צוקי ים", type: "city", center: [34.858973287, 32.361423315179316], id: "צוקי ים" },
  { name: "מעלה עמוס", type: "city", center: [35.22959285149913, 31.596189810482542], id: "מעלה עמוס" },
  { name: "מטע", type: "city", center: [35.06165163305759, 31.71803583369615], id: "מטע" },
  { name: "פעמי תש\"ז", type: "city", center: [34.69253135521633, 31.438749347668928], id: "פעמי תש\"ז" },
  { name: "כפר גלים", type: "city", center: [34.958624885403076, 32.76627488737454], id: "כפר גלים" },
  { name: "לבון", type: "city", center: [35.286340184647656, 32.942967667766254], id: "לבון" },
  { name: "מורן", type: "city", center: [35.393217621297794, 32.92189664799416], id: "מורן" },
  { name: "זכרון יעקב", type: "city", center: [34.95458106727923, 32.56967516577748], id: "זכרון יעקב" },
  { name: "מזרעה", type: "city", center: [35.102038642426336, 32.983486934498444], id: "מזרעה" },
  { name: "כפר חיים", type: "city", center: [34.90010601747757, 32.35411329354451], id: "כפר חיים" },
  { name: "נעלה", type: "city", center: [35.06357214994467, 31.9623704386893], id: "נעלה" },
  { name: "שדה אילן", type: "city", center: [35.42372954468533, 32.74836021987121], id: "שדה אילן" },
  { name: "זבדיאל", type: "city", center: [34.760020567358666, 31.658651806643938], id: "זבדיאל" },
  { name: "מיצר", type: "city", center: [35.73672841151037, 32.76838003803223], id: "מיצר" },
  { name: "אור עקיבא", type: "city", center: [34.921304648252374, 32.50653876387298], id: "אור עקיבא" },
  { name: "מסדה", type: "city", center: [35.598423175714856, 32.6830777075861], id: "מסדה" },
  { name: "תרום", type: "city", center: [34.981923366709054, 31.783081363701687], id: "תרום" },
  { name: "עין נקובא", type: "city", center: [35.12050675714972, 31.79376703377144], id: "עין נקובא" },
  { name: "בני ציון", type: "city", center: [34.867896600561934, 32.218998883836576], id: "בני ציון" },
  { name: "סואעד (חמרייה)", type: "city", center: [35.15507366775592, 32.78750956666848], id: "סואעד (חמרייה)" },
  { name: "אבן יהודה", type: "city", center: [34.8850411275251, 32.2715097432829], id: "אבן יהודה" },
  { name: "אזור", type: "city", center: [34.806455518135586, 32.024355613184376], id: "אזור" },
  { name: "צורית", type: "city", center: [35.25123210950468, 32.902705404487804], id: "צורית" },
  { name: "אום בטין", type: "city", center: [34.88086914010356, 31.275303414646455], id: "אום בטין" },
  { name: "כפר המכבי", type: "city", center: [35.115312687248114, 32.79020937576893], id: "כפר המכבי" },
  { name: "מנרה", type: "city", center: [35.54468180978618, 33.19584078904242], id: "מנרה" },
  { name: "גבעולים", type: "city", center: [34.59100484506455, 31.396299000999264], id: "גבעולים" },
  { name: "כרמיה", type: "city", center: [34.54244628937196, 31.605093504298303], id: "כרמיה" },
  { name: "אריאל", type: "city", center: [35.18835353728895, 32.10373096479775], id: "אריאל" },
  { name: "ברק", type: "city", center: [35.26502541577141, 32.54258624547192], id: "ברק" },
  { name: "כוכב השחר", type: "city", center: [35.34950391372109, 31.95936738565557], id: "כוכב השחר" },
  { name: "פורת", type: "city", center: [34.95020475988622, 32.27640801678423], id: "פורת" },
  { name: "מעגלים", type: "city", center: [34.59892926798634, 31.396698065985436], id: "מעגלים" },
  { name: "כפר רות", type: "city", center: [35.03496343117861, 31.90980787701894], id: "כפר רות" },
  { name: "יפית", type: "city", center: [35.47407723067116, 32.063081827608755], id: "יפית" },
  { name: "דאלית אל-כרמל", type: "city", center: [35.04978608691777, 32.69341732592714], id: "דאלית אל-כרמל" },
  { name: "פלמחים", type: "city", center: [34.70611997602998, 31.933568405476237], id: "פלמחים" },
  { name: "אור הנר", type: "city", center: [34.60062835753784, 31.556806864237217], id: "אור הנר" },
  { name: "רמות השבים", type: "city", center: [34.88445324294975, 32.166557802925226], id: "רמות השבים" },
  { name: "זיקים", type: "city", center: [34.52123241593697, 31.608039936085923], id: "זיקים" },
  { name: "שבי שומרון", type: "city", center: [35.18483482277071, 32.26355884187242], id: "שבי שומרון" },
  { name: "נס הרים", type: "city", center: [35.05734723248183, 31.744570815680486], id: "נס הרים" },
  { name: "בית זיד", type: "city", center: [35.115587878058555, 32.70133231017489], id: "בית זיד" },
  { name: "כפר הס", type: "city", center: [34.93403937264524, 32.245651892155564], id: "כפר הס" },
  { name: "עופרה", type: "city", center: [35.26230477355858, 31.953943570547967], id: "עופרה" },
  { name: "שעלבים", type: "city", center: [34.98264984447315, 31.871013063973287], id: "שעלבים" },
  { name: "אעבלין", type: "city", center: [35.189997906631525, 32.82370720738097], id: "אעבלין" },
  { name: "מכחול", type: "city", center: [35.06636876865683, 31.28993861564984], id: "מכחול" },
  { name: "קדמה", type: "city", center: [34.77616762014441, 31.700321155298198], id: "קדמה" },
  { name: "חורשים", type: "city", center: [34.971420347162116, 32.13692223935565], id: "חורשים" },
  { name: "כפר כנא", type: "city", center: [35.341960364132724, 32.74644504715457], id: "כפר כנא" },
  { name: "נופית", type: "city", center: [35.14541439081246, 32.759268997102296], id: "נופית" },
  { name: "נס עמים", type: "city", center: [35.12108557448527, 32.965947021937374], id: "נס עמים" },
  { name: "שעב", type: "city", center: [35.23936240306, 32.88921770952721], id: "שעב" },
  { name: "קורנית", type: "city", center: [35.25302472496507, 32.84354350977097], id: "קורנית" },
  { name: "הודיה", type: "city", center: [34.6406355662025, 31.674473829965347], id: "הודיה" },
  { name: "שדה ורבורג", type: "city", center: [34.904122441157305, 32.20698615681803], id: "שדה ורבורג" },
  { name: "גרופית", type: "city", center: [35.06431575695652, 29.942049420791523], id: "גרופית" },
  { name: "אביאל", type: "city", center: [34.99279282313107, 32.53200251761329], id: "אביאל" },
  { name: "שלווה", type: "city", center: [34.767819223647265, 31.56432479966001], id: "שלווה" },
  { name: "צפת", type: "city", center: [35.50652748696816, 32.959943595375506], id: "צפת" },
  { name: "גפן", type: "city", center: [34.878469855303656, 31.74073307417757], id: "גפן" },
  { name: "הדר עם", type: "city", center: [34.90058840379828, 32.34942890417705], id: "הדר עם" },
  { name: "כפר רופין", type: "city", center: [35.55687014965115, 32.458559014692625], id: "כפר רופין" },
  { name: "ראמה", type: "city", center: [35.36902069915728, 32.93817384331408], id: "ראמה" },
  { name: "לפידות", type: "city", center: [35.26212653592381, 32.95899679763879], id: "לפידות" },
  { name: "ניר אליהו", type: "city", center: [34.9477826264476, 32.19829785405481], id: "ניר אליהו" },
  { name: "משמר השבעה", type: "city", center: [34.82383599850445, 32.009772828065415], id: "משמר השבעה" },
  { name: "עשרת", type: "city", center: [34.747210449569174, 31.82460706735251], id: "עשרת" },
  { name: "מסעדה", type: "city", center: [35.75553956650636, 33.22993630983032], id: "מסעדה" },
  { name: "יחיעם", type: "city", center: [35.22043000598588, 32.996102273892916], id: "יחיעם" },
  { name: "שדה בוקר", type: "city", center: [34.79176040644317, 30.873849935509547], id: "שדה בוקר" },
  { name: "מרחביה קיבוץ", type: "city", center: [35.307877200702244, 32.60619668403107], id: "מרחביה קיבוץ" },
  { name: "כברי", type: "city", center: [35.1491414329033, 33.021254080033415], id: "כברי" },
  { name: "אל סייד", type: "city", center: [34.91429683800993, 31.28478887129836], id: "אל סייד" },
  { name: "מבוא ביתר", type: "city", center: [35.105925613422606, 31.7227035875713], id: "מבוא ביתר" },
  { name: "חצבה", type: "city", center: [35.27974318922049, 30.768732350965784], id: "חצבה" },
  { name: "כינרת-קבוצה", type: "city", center: [35.562470614821116, 32.713335954162865], id: "כינרת-קבוצה" },
  { name: "מרכז שפירא", type: "city", center: [34.70665417441095, 31.695615191711358], id: "מרכז שפירא" },
  { name: "זרזיר", type: "city", center: [35.222613563433235, 32.72566171989125], id: "זרזיר" },
  { name: "בר גיורא", type: "city", center: [35.071554432003474, 31.729448821474747], id: "בר גיורא" },
  { name: "צוקים", type: "city", center: [35.16613374835335, 30.491021530816283], id: "צוקים" },
  { name: "ערב אל נעים", type: "city", center: [35.289778546073784, 32.88818534988038], id: "ערב אל נעים" },
  { name: "מסלול", type: "city", center: [34.58898867406905, 31.322842983517052], id: "מסלול" },
  { name: "רמת רחל", type: "city", center: [35.21848297150559, 31.73925094784254], id: "רמת רחל" },
  { name: "קרית אונו", type: "city", center: [34.85857657987775, 32.05493377806609], id: "קרית אונו" },
  { name: "גדיש", type: "city", center: [35.245041884903124, 32.558433373030276], id: "גדיש" },
  { name: "אום אל-קוטוף", type: "city", center: [35.05454927405094, 32.46945991496475], id: "אום אל-קוטוף" },
  { name: "רעים", type: "city", center: [34.46023622160219, 31.386140209781292], id: "רעים" },
  { name: "כפר זוהרים", type: "city", center: [34.924218882927924, 31.622300660021782], id: "כפר זוהרים" },
  { name: "יד מרדכי", type: "city", center: [34.55849475879129, 31.5888642570131], id: "יד מרדכי" },
  { name: "דלתון", type: "city", center: [35.48864508897371, 33.016784867953334], id: "דלתון" },
  { name: "רינתיה", type: "city", center: [34.92828942040984, 32.04402632756353], id: "רינתיה" },
  { name: "יכיני", type: "city", center: [34.59922520092138, 31.482253452337275], id: "יכיני" },
  { name: "פרוד", type: "city", center: [35.43349898345056, 32.93179220765708], id: "פרוד" },
  { name: "עזריה", type: "city", center: [34.90939265857025, 31.889566634263254], id: "עזריה" },
  { name: "טירת יהודה", type: "city", center: [34.9332369745208, 32.01364129261682], id: "טירת יהודה" },
  { name: "אשבל", type: "city", center: [35.30569995665744, 32.87665452172811], id: "אשבל" },
  { name: "חמד", type: "city", center: [34.839176064502844, 32.01898000530126], id: "חמד" },
  { name: "שילת", type: "city", center: [35.01787438877354, 31.920302332463038], id: "שילת" },
  { name: "תלמי ביל\"ו", type: "city", center: [34.64506110460685, 31.43786612127174], id: "תלמי ביל\"ו" },
  { name: "תומר", type: "city", center: [35.438708650899024, 32.018713889369955], id: "תומר" },
  { name: "בית גוברין", type: "city", center: [34.89534840131885, 31.612289497444745], id: "בית גוברין" },
  { name: "אושרת", type: "city", center: [35.155516029524414, 32.97123784078758], id: "אושרת" },
  { name: "בית עובד", type: "city", center: [34.77394016701451, 31.9219858677618], id: "בית עובד" },
  { name: "גלאון", type: "city", center: [34.84859856700158, 31.633453088107856], id: "גלאון" },
  { name: "נווה זיו", type: "city", center: [35.184287561849295, 33.02693332160491], id: "נווה זיו" },
  { name: "מולדה", type: "city", center: [34.972885726895726, 31.25550884352999], id: "מולדה" },
  { name: "אלעזר", type: "city", center: [35.141561451199465, 31.660735063523262], id: "אלעזר" },
  { name: "נחלה", type: "city", center: [34.79478986222899, 31.65861415994775], id: "נחלה" },
  { name: "סוסיה", type: "city", center: [35.11338332078377, 31.39189303982073], id: "סוסיה" },
  { name: "רם און", type: "city", center: [35.259888263361255, 32.52705646832002], id: "רם און" },
  { name: "אודם", type: "city", center: [35.74808321384702, 33.19502515773351], id: "אודם" },
  { name: "אשבול", type: "city", center: [34.66722316816368, 31.445693433804315], id: "אשבול" },
  { name: "בית זית", type: "city", center: [35.16176285183311, 31.781974248473478], id: "בית זית" },
  { name: "תעוז", type: "city", center: [34.97470089494625, 31.80119441875688], id: "תעוז" },
  { name: "אחווה", type: "city", center: [34.769098595349995, 31.743679921534834], id: "אחווה" },
  { name: "כרם יבנה (ישיבה)", type: "city", center: [34.72242316308287, 31.81835881828468], id: "כרם יבנה (ישיבה)" },
  { name: "מצפה יריחו", type: "city", center: [35.39423666704945, 31.815959814356752], id: "מצפה יריחו" },
  { name: "מגדל עוז", type: "city", center: [35.143077467863385, 31.640493167786005], id: "מגדל עוז" },
  { name: "ינוב", type: "city", center: [34.950469227312, 32.30660628668959], id: "ינוב" },
  { name: "קדימה צורן", type: "city", center: [34.9201786598813, 32.28068402804154], id: "קדימה צורן" },
  { name: "עין יעקב", type: "city", center: [35.23092155926717, 33.00984408912039], id: "עין יעקב" },
  { name: "אחיעזר", type: "city", center: [34.87136840387624, 31.981379580738775], id: "אחיעזר" },
  { name: "שער הגולן", type: "city", center: [35.60332595171279, 32.6870724135927], id: "שער הגולן" },
  { name: "בית יצחק שער חפר", type: "city", center: [34.890303043207496, 32.33382756946152], id: "בית יצחק שער חפר" },
  { name: "עראמשה", type: "city", center: [35.22550513414658, 33.08819036698399], id: "עראמשה" },
  { name: "עותניאל", type: "city", center: [35.0305969403259, 31.439298355861567], id: "עותניאל" },
  { name: "שני", type: "city", center: [35.069358093217396, 31.355952177003353], id: "שני" },
  { name: "ריינה", type: "city", center: [35.31396095392965, 32.72075752208617], id: "ריינה" },
  { name: "מגל", type: "city", center: [35.033257899809755, 32.38576457066992], id: "מגל" },
  { name: "טנא", type: "city", center: [34.9568865052867, 31.37493613687988], id: "טנא" },
  { name: "ניצן ב'", type: "city", center: [34.63639037584436, 31.736286673067752], id: "ניצן ב'" },
  { name: "להבות הבשן", type: "city", center: [35.6478046755738, 33.14055088440342], id: "להבות הבשן" },
  { name: "חצרים", type: "city", center: [34.714313418077325, 31.239400901570086], id: "חצרים" },
  { name: "זרועה", type: "city", center: [34.62410386773056, 31.458818497704836], id: "זרועה" },
  { name: "כרם שלום", type: "city", center: [34.284463829590024, 31.227714163112346], id: "כרם שלום" },
  { name: "אבן מנחם", type: "city", center: [35.29435344302635, 33.074293322978555], id: "אבן מנחם" },
  { name: "חרשים", type: "city", center: [35.328090163076446, 32.95671941668948], id: "חרשים" },
  { name: "כפר אחים", type: "city", center: [34.75686207701021, 31.744853030470775], id: "כפר אחים" },
  { name: "חיננית", type: "city", center: [35.17301352056038, 32.480474043828984], id: "חיננית" },
  { name: "בית שמש", type: "city", center: [34.98223990703861, 31.72304946439418], id: "בית שמש" },
  { name: "כמהין", type: "city", center: [34.43155665329216, 30.91018406303986], id: "כמהין" },
  { name: "קציר", type: "city", center: [35.104715710247284, 32.48472595982826], id: "קציר" },
  { name: "יושיביה", type: "city", center: [34.609809177159306, 31.44263581155345], id: "יושיביה" },
  { name: "בית נקופה", type: "city", center: [35.12537092304799, 31.804707131946568], id: "בית נקופה" },
  { name: "כפר נטר", type: "city", center: [34.87203786666132, 32.27553894078177], id: "כפר נטר" },
  { name: "קרני שומרון", type: "city", center: [35.10141409027262, 32.17145710724484], id: "קרני שומרון" },
  { name: "עדי", type: "city", center: [35.17420707976102, 32.78332766547566], id: "עדי" },
  { name: "ירדנה", type: "city", center: [35.56512194858617, 32.56528783179539], id: "ירדנה" },
  { name: "משמר דוד", type: "city", center: [34.90110831259948, 31.82233857674201], id: "משמר דוד" },
  { name: "אלישיב", type: "city", center: [34.90977179006262, 32.38101920690317], id: "אלישיב" },
  { name: "גאליה", type: "city", center: [34.76596526532673, 31.884441500901865], id: "גאליה" },
  { name: "ניל\"י", type: "city", center: [35.047955348797004, 31.962778741025993], id: "ניל\"י" },
  { name: "מגן", type: "city", center: [34.42843909015173, 31.298128521045147], id: "מגן" },
  { name: "מפלסים", type: "city", center: [34.56296892668571, 31.5014997706482], id: "מפלסים" },
  { name: "נתיבות", type: "city", center: [34.580345066769986, 31.424880014743525], id: "נתיבות" },
  { name: "יערה", type: "city", center: [35.18562398988528, 33.06755286285825], id: "יערה" },
  { name: "גן שלמה", type: "city", center: [34.7991986731381, 31.87824744312199], id: "גן שלמה" },
  { name: "כפר מרדכי", type: "city", center: [34.756122869316094, 31.830723148358103], id: "כפר מרדכי" },
  { name: "מכורה", type: "city", center: [35.422951734593234, 32.16504984701142], id: "מכורה" },
  { name: "כפר חרוב", type: "city", center: [35.663247121207604, 32.76300504728551], id: "כפר חרוב" },
  { name: "גבים", type: "city", center: [34.596607259881836, 31.508541580690956], id: "גבים" },
  { name: "אלון הגליל", type: "city", center: [35.22089281315285, 32.75671940353329], id: "אלון הגליל" },
  { name: "כפר הנוער הדתי", type: "city", center: [35.0994430442295, 32.74267961679026], id: "כפר הנוער הדתי" },
  { name: "כפר ברא", type: "city", center: [34.97145342493165, 32.13084175497826], id: "כפר ברא" },
  { name: "געש", type: "city", center: [34.82452864420533, 32.22875000615759], id: "געש" },
  { name: "לכיש", type: "city", center: [34.84209831112366, 31.560423442433372], id: "לכיש" },
  { name: "ברוש", type: "city", center: [34.63287171621017, 31.370852472427394], id: "ברוש" },
  { name: "אורים", type: "city", center: [34.523544797827455, 31.30430257293351], id: "אורים" },
  { name: "זכריה", type: "city", center: [34.94469768970936, 31.709892297101835], id: "זכריה" },
  { name: "עין גב", type: "city", center: [35.6384241632923, 32.782447491735375], id: "עין גב" },
  { name: "מגדים", type: "city", center: [34.95878850354614, 32.729183209754325], id: "מגדים" },
  { name: "בית גמליאל", type: "city", center: [34.76144716538826, 31.856897472619465], id: "בית גמליאל" },
  { name: "תלמי אליהו", type: "city", center: [34.42873507058745, 31.229279944790008], id: "תלמי אליהו" },
  { name: "נס ציונה", type: "city", center: [34.795486077947814, 31.924747776515225], id: "נס ציונה" },
  { name: "שובל", type: "city", center: [34.74306377542375, 31.41289133186563], id: "שובל" },
  { name: "מייסר", type: "city", center: [35.04222850440251, 32.44499138286702], id: "מייסר" },
  { name: "כפר ורדים", type: "city", center: [35.25861497919515, 32.99921560655013], id: "כפר ורדים" },
  { name: "טל שחר", type: "city", center: [34.90265464848399, 31.805367135240342], id: "טל שחר" },
  { name: "מעוז חיים", type: "city", center: [35.550489833448445, 32.492649239536036], id: "מעוז חיים" },
  { name: "כפר ורבורג", type: "city", center: [34.72552228990619, 31.719333277493938], id: "כפר ורבורג" },
  { name: "נירית", type: "city", center: [34.985093360365305, 32.145772182533456], id: "נירית" },
  { name: "שדה יצחק", type: "city", center: [34.99328494304526, 32.404919761041874], id: "שדה יצחק" },
  { name: "כפר הנגיד", type: "city", center: [34.749388793913134, 31.88773788385108], id: "כפר הנגיד" },
  { name: "דמיידה", type: "city", center: [35.228081633383624, 32.81515479950689], id: "דמיידה" },
  { name: "רמת מגשימים", type: "city", center: [35.80745304782657, 32.844221540777035], id: "רמת מגשימים" },
  { name: "מגאר", type: "city", center: [35.41377091297676, 32.88820570112777], id: "מגאר" },
  { name: "גרנות הגליל", type: "city", center: [35.24975014879953, 33.0596304549082], id: "גרנות הגליל" },
  { name: "רמת השופט", type: "city", center: [35.09336705654416, 32.61089627013065], id: "רמת השופט" },
  { name: "מלילות", type: "city", center: [34.595500626932875, 31.389436108277344], id: "מלילות" },
  { name: "טובא זנגריה", type: "city", center: [35.59101014624015, 32.96471623109194], id: "טובא זנגריה" },
  { name: "יהוד מונוסון", type: "city", center: [34.887915154085505, 32.03209871013966], id: "יהוד מונוסון" },
  { name: "בורגתה", type: "city", center: [34.96211838208443, 32.324863797900115], id: "בורגתה" },
  { name: "הרצליה", type: "city", center: [34.82695390714312, 32.168547523043394], id: "הרצליה" },
  { name: "נורית", type: "city", center: [35.353769678896725, 32.54336584283789], id: "נורית" },
  { name: "חרות", type: "city", center: [34.91443336626945, 32.2398671351515], id: "חרות" },
  { name: "שרשרת", type: "city", center: [34.60300216860059, 31.404046736228533], id: "שרשרת" },
  { name: "טפחות", type: "city", center: [35.42193851412543, 32.86899445643053], id: "טפחות" },
  { name: "עין דור", type: "city", center: [35.416768583227054, 32.65743702313815], id: "עין דור" },
  { name: "שבלי - אום אל-גנם", type: "city", center: [35.39809015571802, 32.691298814455145], id: "שבלי - אום אל-גנם" },
  { name: "תלמים", type: "city", center: [34.67197285092453, 31.5647760127995], id: "תלמים" },
  { name: "איתן", type: "city", center: [34.748368357240416, 31.572319770163993], id: "איתן" },
  { name: "קרית יערים", type: "city", center: [35.09982997124634, 31.803450795857266], id: "קרית יערים" },
  { name: "בית ינאי", type: "city", center: [34.863426306253544, 32.381269786971735], id: "בית ינאי" },
  { name: "מחולה", type: "city", center: [35.51431442603064, 32.36576201519962], id: "מחולה" },
  { name: "טל אל", type: "city", center: [35.178868478480645, 32.92695372454236], id: "טל אל" },
  { name: "כפר פינס", type: "city", center: [35.002224588793965, 32.48388726563519], id: "כפר פינס" },
  { name: "סלמה", type: "city", center: [35.370755316651874, 32.89270169822742], id: "סלמה" },
  { name: "פוריה עילית", type: "city", center: [35.54532237264414, 32.73131682171959], id: "פוריה עילית" },
  { name: "צוריאל", type: "city", center: [35.31419481194113, 33.00677786991144], id: "צוריאל" },
  { name: "עין הבשור", type: "city", center: [34.4440715638607, 31.281387302064907], id: "עין הבשור" },
  { name: "עמינדב", type: "city", center: [35.14190946409446, 31.751891674147917], id: "עמינדב" },
  { name: "ג'ש (גוש חלב)", type: "city", center: [35.447294969279376, 33.02228569206088], id: "ג'ש (גוש חלב)" },
  { name: "יבנה", type: "city", center: [34.734444329984164, 31.874948614725326], id: "יבנה" },
  { name: "שדות ים", type: "city", center: [34.89328480627208, 32.49191395486608], id: "שדות ים" },
  { name: "מצובה", type: "city", center: [35.15766864192411, 33.062373050498174], id: "מצובה" },
  { name: "כפר יעבץ", type: "city", center: [34.96607542959486, 32.27321951879798], id: "כפר יעבץ" },
  { name: "גבעת כ\"ח", type: "city", center: [34.93586964263333, 32.030436383619616], id: "גבעת כ\"ח" },
  { name: "משהד", type: "city", center: [35.32245122723167, 32.74071676914505], id: "משהד" },
  { name: "גבת", type: "city", center: [35.212420446666904, 32.6748593131825], id: "גבת" },
  { name: "גיתית", type: "city", center: [35.396195893041394, 32.10175231227497], id: "גיתית" },
  { name: "שוקדה", type: "city", center: [34.52430124404051, 31.42194039834079], id: "שוקדה" },
  { name: "בית חורון", type: "city", center: [35.128801169261926, 31.876458100840264], id: "בית חורון" },
  { name: "בית השיטה", type: "city", center: [35.438706628426694, 32.552306875649975], id: "בית השיטה" },
  { name: "יסודות", type: "city", center: [34.86589518169329, 31.814624238829992], id: "יסודות" },
  { name: "דימונה", type: "city", center: [35.03163214566698, 31.069871243820028], id: "דימונה" },
  { name: "כפר סאלד", type: "city", center: [35.65748430987606, 33.195102066375156], id: "כפר סאלד" },
  { name: "רוחמה", type: "city", center: [34.70573467731521, 31.497397515030173], id: "רוחמה" },
  { name: "עין השופט", type: "city", center: [35.09964777305916, 32.59628753012039], id: "עין השופט" },
  { name: "מלאה", type: "city", center: [35.237150589227944, 32.56424614868198], id: "מלאה" },
  { name: "יזרעאל", type: "city", center: [35.32099541574805, 32.562085861115605], id: "יזרעאל" },
  { name: "משגב עם", type: "city", center: [35.54865792128345, 33.247437017538836], id: "משגב עם" },
  { name: "כיסופים", type: "city", center: [34.39857685768869, 31.375048718849424], id: "כיסופים" },
  { name: "דיר חנא", type: "city", center: [35.369135776221654, 32.86263766951565], id: "דיר חנא" },
  { name: "מבוא חורון", type: "city", center: [35.03570872586891, 31.849618215010256], id: "מבוא חורון" },
  { name: "בת שלמה", type: "city", center: [35.003553196644575, 32.59996529284995], id: "בת שלמה" },
  { name: "כוכב יעקב", type: "city", center: [35.24240728072168, 31.880451763483613], id: "כוכב יעקב" },
  { name: "דגניה ב'", type: "city", center: [35.57565802535074, 32.70011674185052], id: "דגניה ב'" },
  { name: "אמירים", type: "city", center: [35.449457353950656, 32.938044417255796], id: "אמירים" },
  { name: "עין זיוון", type: "city", center: [35.79594081896902, 33.09644127579794], id: "עין זיוון" },
  { name: "אלון שבות", type: "city", center: [35.12611402681465, 31.655060440070844], id: "אלון שבות" },
  { name: "מזכרת בתיה", type: "city", center: [34.844395587717635, 31.850480650638623], id: "מזכרת בתיה" },
  { name: "טירת צבי", type: "city", center: [35.52821725018914, 32.42036597153128], id: "טירת צבי" },
  { name: "חניתה", type: "city", center: [35.17466793370371, 33.088086476041845], id: "חניתה" },
  { name: "שפר", type: "city", center: [35.43682868139894, 32.943056518285715], id: "שפר" },
  { name: "ייט\"ב", type: "city", center: [35.424440358750665, 31.947396862418824], id: "ייט\"ב" },
  { name: "לפיד", type: "city", center: [35.03230297552832, 31.917452824282385], id: "לפיד" },
  { name: "רמת השרון", type: "city", center: [34.83436934861903, 32.13564024422224], id: "רמת השרון" },
  { name: "מישר", type: "city", center: [34.75432787446484, 31.818822336079933], id: "מישר" },
  { name: "גבעת ישעיהו", type: "city", center: [34.94780729152411, 31.67060111729049], id: "גבעת ישעיהו" },
  { name: "ראש פינה", type: "city", center: [35.54241048446538, 32.96910575351911], id: "ראש פינה" },
  { name: "אדירים", type: "city", center: [35.27219806961682, 32.548963733411846], id: "אדירים" },
  { name: "מעגן מיכאל", type: "city", center: [34.91511333051707, 32.554833720018756], id: "מעגן מיכאל" },
  { name: "חוסנייה", type: "city", center: [35.322159544492266, 32.89903195290811], id: "חוסנייה" },
  { name: "צפרירים", type: "city", center: [34.94341529234463, 31.66080379264482], id: "צפרירים" },
  { name: "כפר בן-נון", type: "city", center: [34.94801075697687, 31.86114226103377], id: "כפר בן-נון" },
  { name: "ברקאי", type: "city", center: [35.028678289910175, 32.47505327539987], id: "ברקאי" },
  { name: "הזורע", type: "city", center: [35.12053192773341, 32.64339898259272], id: "הזורע" },
  { name: "קדרון", type: "city", center: [34.79675753987869, 31.814683950505238], id: "קדרון" },
  { name: "מדרשת רופין", type: "city", center: [34.91229473682658, 32.34212350985468], id: "מדרשת רופין" },
  { name: "משמר השרון", type: "city", center: [34.90420734116898, 32.35660494559087], id: "משמר השרון" },
  { name: "נטף", type: "city", center: [35.06784905631381, 31.832543271155917], id: "נטף" },
  { name: "ג'לג'וליה", type: "city", center: [34.954529388846986, 32.1512670229805], id: "ג'לג'וליה" },
  { name: "בני ברק", type: "city", center: [34.83578874929869, 32.090176770931], id: "בני ברק" },
  { name: "אלי עד", type: "city", center: [35.73433363523454, 32.80435235795832], id: "אלי עד" },
  { name: "ברעם", type: "city", center: [35.43460488249976, 33.05765295292604], id: "ברעם" },
  { name: "גילון", type: "city", center: [35.236347569525364, 32.90364603346584], id: "גילון" },
  { name: "רומאנה", type: "city", center: [35.310735237566156, 32.7879571442253], id: "רומאנה" },
  { name: "עין גדי", type: "city", center: [35.38510165084729, 31.452517671395324], id: "עין גדי" },
  { name: "כפר סילבר", type: "city", center: [34.61186361617981, 31.67344371022554], id: "כפר סילבר" },
  { name: "עלמה", type: "city", center: [35.50017680328959, 33.05135881524022], id: "עלמה" },
  { name: "אורנים", type: "city", center: [35.108880806033035, 32.71215654820071], id: "אורנים" },
  { name: "חולתה", type: "city", center: [35.61003436116223, 33.051477817453296], id: "חולתה" },
  { name: "ביתן אהרון", type: "city", center: [34.86979872321, 32.36547284531782], id: "ביתן אהרון" },
  { name: "בית הערבה", type: "city", center: [35.48731484064847, 31.808236066074546], id: "בית הערבה" },
  { name: "נווה אור", type: "city", center: [35.55277455733341, 32.58939438570126], id: "נווה אור" },
  { name: "איבטין", type: "city", center: [35.11356091642295, 32.761398610195656], id: "איבטין" },
  { name: "שער העמקים", type: "city", center: [35.1119432095011, 32.722895734652475], id: "שער העמקים" },
  { name: "טייבה (בעמק)", type: "city", center: [35.444847286955664, 32.60409894734293], id: "טייבה (בעמק)" },
  { name: "יד נתן", type: "city", center: [34.70561595856107, 31.651722442950813], id: "יד נתן" },
  { name: "חפץ חיים", type: "city", center: [34.80111222591859, 31.78864683413281], id: "חפץ חיים" },
  { name: "נין", type: "city", center: [35.34882877061802, 32.63145821499412], id: "נין" },
  { name: "ראס עלי", type: "city", center: [35.15448488220372, 32.771952918516796], id: "ראס עלי" },
  { name: "גת", type: "city", center: [35.03645559882094, 32.39956278151032], id: "גת" },
  { name: "כורזים", type: "city", center: [35.550879958235264, 32.90958135231804], id: "כורזים" },
  { name: "בן זכאי", type: "city", center: [34.72856278745419, 31.856333770458722], id: "בן זכאי" },
  { name: "אשקלון", type: "city", center: [34.56882534789175, 31.664199013297033], id: "אשקלון" },
  { name: "תל מונד", type: "city", center: [34.91771327073567, 32.25282209484433], id: "תל מונד" },
  { name: "בית זרע", type: "city", center: [35.57322802741928, 32.68886722549336], id: "בית זרע" },
  { name: "מרגליות", type: "city", center: [35.545327918702135, 33.21425525232649], id: "מרגליות" },
  { name: "נשר", type: "city", center: [35.04073046387085, 32.76698103842807], id: "נשר" },
  { name: "אודים", type: "city", center: [34.848685428587885, 32.26606189800259], id: "אודים" },
  { name: "גבעתיים", type: "city", center: [34.81028701874014, 32.070390741385644], id: "גבעתיים" },
  { name: "מי עמי", type: "city", center: [35.14822759845271, 32.50407217883784], id: "מי עמי" },
  { name: "נאעורה", type: "city", center: [35.39163331255509, 32.61432860204478], id: "נאעורה" },
  { name: "שקד", type: "city", center: [35.16872816908056, 32.47338743020219], id: "שקד" },
  { name: "בקוע", type: "city", center: [34.92596193687801, 31.828540429131156], id: "בקוע" },
  { name: "בר יוחאי", type: "city", center: [35.44874455239623, 32.99765922577719], id: "בר יוחאי" },
  { name: "תרבין א-צאנע (יישוב)", type: "city", center: [34.737532507772485, 31.344235035729156], id: "תרבין א-צאנע (יישוב)" },
  { name: "נחליאל", type: "city", center: [35.1396284449025, 31.97407943494271], id: "נחליאל" },
  { name: "יראון", type: "city", center: [35.456448876561645, 33.07765214894789], id: "יראון" },
  { name: "רימונים", type: "city", center: [35.34093471820388, 31.93324453920366], id: "רימונים" },
  { name: "אחוזת ברק", type: "city", center: [35.337449229335895, 32.6432652446761], id: "אחוזת ברק" },
  { name: "אורטל", type: "city", center: [35.75981379523883, 33.08819367347943], id: "אורטל" },
  { name: "קיסריה", type: "city", center: [34.90493702215836, 32.5010577844347], id: "קיסריה" },
  { name: "חיפה", type: "city", center: [35.01055453280342, 32.802513063354084], id: "חיפה" },
  { name: "עין חוד", type: "city", center: [34.999945532045665, 32.69141164488437], id: "עין חוד" },
  { name: "טמרה (יזרעאל)", type: "city", center: [35.40349322978025, 32.634353664112666], id: "טמרה (יזרעאל)" },
  { name: "נאות סמדר", type: "city", center: [35.026709864138176, 30.049723630714386], id: "נאות סמדר" },
  { name: "אבשלום", type: "city", center: [34.33152873027044, 31.19581456049711], id: "אבשלום" },
  { name: "כפר גדעון", type: "city", center: [35.29153409933727, 32.64381963639371], id: "כפר גדעון" },
  { name: "אומן", type: "city", center: [35.242533011359406, 32.56325842376503], id: "אומן" },
  { name: "גן שמואל", type: "city", center: [34.951665301187475, 32.451708367282095], id: "גן שמואל" },
  { name: "כפר אדומים", type: "city", center: [35.3315978550028, 31.824234329883783], id: "כפר אדומים" },
  { name: "קלנסווה", type: "city", center: [34.9769204594183, 32.29210665217632], id: "קלנסווה" },
  { name: "ארסוף", type: "city", center: [34.81551889913673, 32.21364138802541], id: "ארסוף" },
  { name: "חמדיה", type: "city", center: [35.51985332908394, 32.52065250600823], id: "חמדיה" },
  { name: "חדרה", type: "city", center: [34.91409414612949, 32.436465583891284], id: "חדרה" },
  { name: "מג'דל א-שמס", type: "city", center: [35.769180349727215, 33.26788137172983], id: "מג'דל א-שמס" },
  { name: "מצפה אילן", type: "city", center: [35.06754496999204, 32.461118105638676], id: "מצפה אילן" },
  { name: "אום אל-פחם", type: "city", center: [35.15033992383807, 32.524191729990335], id: "אום אל-פחם" },
  { name: "שדה צבי", type: "city", center: [34.71168092824203, 31.448873844122613], id: "שדה צבי" },
  { name: "כפר יונה", type: "city", center: [34.92806252937752, 32.31689292089817], id: "כפר יונה" },
  { name: "סתריה", type: "city", center: [34.84463082892262, 31.889831628275612], id: "סתריה" },
  { name: "מנוחה", type: "city", center: [34.777738800530635, 31.658002967149166], id: "מנוחה" },
  { name: "חצב", type: "city", center: [34.769444203665614, 31.780948856600155], id: "חצב" },
  { name: "משען", type: "city", center: [34.62395074422602, 31.656497090648056], id: "משען" },
  { name: "גבע כרמל", type: "city", center: [34.953767875955734, 32.662387004321076], id: "גבע כרמל" },
  { name: "אספר", type: "city", center: [35.18763792512539, 31.585668936215537], id: "אספר" },
  { name: "חזון", type: "city", center: [35.39490986495103, 32.90637866124633], id: "חזון" },
  { name: "חבצלת השרון", type: "city", center: [34.8627601084234, 32.3602714741397], id: "חבצלת השרון" },
  { name: "חריש", type: "city", center: [35.04814268613709, 32.46004042019088], id: "חריש" },
  { name: "רמת יוחנן", type: "city", center: [35.12244883704405, 32.79340566756883], id: "רמת יוחנן" },
  { name: "עבדון", type: "city", center: [35.178550006252856, 33.04756590211188], id: "עבדון" },
  { name: "קרית ביאליק", type: "city", center: [35.09727029602541, 32.841870274280346], id: "קרית ביאליק" },
  { name: "משגב דב", type: "city", center: [34.740086739929644, 31.819591907742378], id: "משגב דב" },
  { name: "ישע", type: "city", center: [34.40312202735604, 31.247275707771713], id: "ישע" },
  { name: "סולם", type: "city", center: [35.334691346778826, 32.6061097603748], id: "סולם" },
  { name: "בית העמק", type: "city", center: [35.144788619699476, 32.971381446112986], id: "בית העמק" },
  { name: "כסלון", type: "city", center: [35.04960045121658, 31.773883847860727], id: "כסלון" },
  { name: "ניר ישראל", type: "city", center: [34.63571884433531, 31.68792070639568], id: "ניר ישראל" },
  { name: "אליפלט", type: "city", center: [35.54763848492306, 32.9478213054199], id: "אליפלט" },
  { name: "עיילבון", type: "city", center: [35.40351261562166, 32.83689833552159], id: "עיילבון" },
  { name: "שדמות דבורה", type: "city", center: [35.43546123660884, 32.69684393303783], id: "שדמות דבורה" },
  { name: "יעל", type: "city", center: [35.30851820601063, 32.55200271380734], id: "יעל" },
  { name: "כפר ביל\"ו", type: "city", center: [34.82603904632263, 31.87256728880675], id: "כפר ביל\"ו" },
  { name: "גני טל", type: "city", center: [34.792307294685834, 31.788395963614082], id: "גני טל" },
  { name: "בית מאיר", type: "city", center: [35.03662422773058, 31.79429115329305], id: "בית מאיר" },
  { name: "נצר סרני", type: "city", center: [34.82320860513607, 31.92252247093969], id: "נצר סרני" },
  { name: "בת הדר", type: "city", center: [34.5970170789458, 31.648076828356036], id: "בת הדר" },
  { name: "שער מנשה", type: "city", center: [35.01597704003406, 32.44952575940816], id: "שער מנשה" },
  { name: "גאולי תימן", type: "city", center: [34.901964525241404, 32.391738249065796], id: "גאולי תימן" },
  { name: "קידר", type: "city", center: [35.309844889084154, 31.75404701456092], id: "קידר" },
  { name: "עמיעד", type: "city", center: [35.54121042382307, 32.92861845112127], id: "עמיעד" },
  { name: "עזוז", type: "city", center: [34.472951184414455, 30.79246761734482], id: "עזוז" },
  { name: "טלמון", type: "city", center: [35.13295155911122, 31.94341834780301], id: "טלמון" },
  { name: "עטרת", type: "city", center: [35.17691334634188, 32.00064245387987], id: "עטרת" },
  { name: "מאיר שפיה", type: "city", center: [34.97155960134971, 32.59105916057689], id: "מאיר שפיה" },
  { name: "לוחמי הגטאות", type: "city", center: [35.09785042721432, 32.96368068238136], id: "לוחמי הגטאות" },
  { name: "רמות נפתלי", type: "city", center: [35.55300594545731, 33.101457892960276], id: "רמות נפתלי" },
  { name: "ריחאניה", type: "city", center: [35.48749202972146, 33.04914917745904], id: "ריחאניה" },
  { name: "מעברות", type: "city", center: [34.90499610135381, 32.3635899585806], id: "מעברות" },
  { name: "יפעת", type: "city", center: [35.224144641935716, 32.67616132260516], id: "יפעת" },
  { name: "ניצני עוז", type: "city", center: [35.00407275694501, 32.30598475755983], id: "ניצני עוז" },
  { name: "אומץ", type: "city", center: [34.9943069744123, 32.370702374122075], id: "אומץ" },
  { name: "קרית גת", type: "city", center: [34.77181244863245, 31.609647682422306], id: "קרית גת" },
  { name: "קרית מוצקין", type: "city", center: [35.08271941060785, 32.84191254800357], id: "קרית מוצקין" },
  { name: "ליבנים", type: "city", center: [35.493082204201656, 32.863773015073654], id: "ליבנים" },
  { name: "עוצם", type: "city", center: [34.703941782263776, 31.637012351227412], id: "עוצם" },
  { name: "מעלה אדומים", type: "city", center: [35.31168006635973, 31.787395354664067], id: "מעלה אדומים" },
  { name: "מעון", type: "city", center: [35.162594304691034, 31.413739217938446], id: "מעון" },
  { name: "תל שבע", type: "city", center: [34.8651438668321, 31.2490800082379], id: "תל שבע" },
  { name: "עלי זהב", type: "city", center: [35.0558516645543, 32.069963972110585], id: "עלי זהב" },
  { name: "כפר סירקין", type: "city", center: [34.924041786626844, 32.07715657819721], id: "כפר סירקין" },
  { name: "גאולים", type: "city", center: [34.944867166508665, 32.29893531452737], id: "גאולים" },
  { name: "אלמגור", type: "city", center: [35.602994760644116, 32.913179015980475], id: "אלמגור" },
  { name: "עולש", type: "city", center: [34.984840294775964, 32.33269690691238], id: "עולש" },
  { name: "צפריה", type: "city", center: [34.856624919069446, 32.002656135383695], id: "צפריה" },
  { name: "בית עזרא", type: "city", center: [34.65530754310647, 31.737166206625876], id: "בית עזרא" },
  { name: "עמיקם", type: "city", center: [35.02080336688639, 32.563612161345546], id: "עמיקם" },
  { name: "כפר מצר", type: "city", center: [35.423381345911295, 32.645498631341326], id: "כפר מצר" },
  { name: "יודפת", type: "city", center: [35.27299681767934, 32.83715990433862], id: "יודפת" },
  { name: "כפר ויתקין", type: "city", center: [34.87799119369281, 32.382079451878994], id: "כפר ויתקין" },
  { name: "נוף איילון", type: "city", center: [34.98878333576883, 31.868715247074707], id: "נוף איילון" },
  { name: "כפר חסידים ב'", type: "city", center: [35.087587734802106, 32.75112271671778], id: "כפר חסידים ב'" },
  { name: "צור יצחק", type: "city", center: [34.99721926456215, 32.24047851349767], id: "צור יצחק" },
  { name: "בני עטרות", type: "city", center: [34.914042177341905, 32.02367119060898], id: "בני עטרות" },
  { name: "ניצן", type: "city", center: [34.631594243448326, 31.73967500272586], id: "ניצן" },
  { name: "קרית עקרון", type: "city", center: [34.8211158153215, 31.861821557608707], id: "קרית עקרון" },
  { name: "קרית נטפים", type: "city", center: [35.11171020616028, 32.117117308929345], id: "קרית נטפים" },
  { name: "ניר עציון", type: "city", center: [34.991680466327765, 32.69803114287238], id: "ניר עציון" },
  { name: "פדויים", type: "city", center: [34.61158700490419, 31.32665018238154], id: "פדויים" },
  { name: "שמעה", type: "city", center: [35.01254384665207, 31.387737095108722], id: "שמעה" },
  { name: "מבוא מודיעים", type: "city", center: [34.986966698524824, 31.93386838467226], id: "מבוא מודיעים" },
  { name: "יד רמב\"ם", type: "city", center: [34.900456155793705, 31.900036487310924], id: "יד רמב\"ם" },
  { name: "צנדלה", type: "city", center: [35.32367215417648, 32.522757535810165], id: "צנדלה" },
  { name: "לוזית", type: "city", center: [34.88614073391128, 31.687532228699247], id: "לוזית" },
  { name: "יפתח", type: "city", center: [35.55091200988125, 33.128954416899546], id: "יפתח" },
  { name: "זמר", type: "city", center: [35.03648208235131, 32.363435759549844], id: "זמר" },
  { name: "ורדון", type: "city", center: [34.7823560277205, 31.66373980225223], id: "ורדון" },
  { name: "דברת", type: "city", center: [35.349957866315535, 32.64772884656047], id: "דברת" },
  { name: "תפרח", type: "city", center: [34.67722438028238, 31.325874904807232], id: "תפרח" },
  { name: "אבירים", type: "city", center: [35.28705535516068, 33.0383655372697], id: "אבירים" },
  { name: "גן חיים", type: "city", center: [34.90583532844351, 32.19517955575598], id: "גן חיים" },
  { name: "שלומי", type: "city", center: [35.14638428504036, 33.07110467746545], id: "שלומי" },
  { name: "שדה יואב", type: "city", center: [34.681242329638366, 31.643622190642386], id: "שדה יואב" },
  { name: "שדה משה", type: "city", center: [34.8014980613507, 31.611295717713272], id: "שדה משה" },
  { name: "רמות", type: "city", center: [35.66672740709126, 32.85028663227219], id: "רמות" },
  { name: "ניר יצחק", type: "city", center: [34.35719958370068, 31.237313266321614], id: "ניר יצחק" },
  { name: "אילון", type: "city", center: [35.218281273614494, 33.06324665237064], id: "אילון" },
  { name: "קרית אתא", type: "city", center: [35.109567704514795, 32.80841718731842], id: "קרית אתא" },
  { name: "כפר קרע", type: "city", center: [35.04810134571838, 32.50498863590996], id: "כפר קרע" },
  { name: "עלמון", type: "city", center: [35.29527906240308, 31.830526537238292], id: "עלמון" },
  { name: "צור משה", type: "city", center: [34.914404820441696, 32.29793715321505], id: "צור משה" },
  { name: "תלמי יחיאל", type: "city", center: [34.762545132242096, 31.75448037140988], id: "תלמי יחיאל" },
  { name: "בית חשמונאי", type: "city", center: [34.91912695359937, 31.88849502392279], id: "בית חשמונאי" },
  { name: "שדה ניצן", type: "city", center: [34.41946149485987, 31.230355471376896], id: "שדה ניצן" },
  { name: "פדואל", type: "city", center: [35.051831940491255, 32.0616798182859], id: "פדואל" },
  { name: "רהט", type: "city", center: [34.75376205956518, 31.385540157917312], id: "רהט" },
  { name: "זיתן", type: "city", center: [34.88997543714261, 31.975869283042197], id: "זיתן" },
  { name: "מסד", type: "city", center: [35.424447207613724, 32.84360786641032], id: "מסד" },
  { name: "גבעון החדשה", type: "city", center: [35.15829500220314, 31.848467866115087], id: "גבעון החדשה" },
  { name: "גנות", type: "city", center: [34.82878337078517, 32.017913867742976], id: "גנות" },
  { name: "אלונים", type: "city", center: [35.14413505773131, 32.720151899279344], id: "אלונים" },
  { name: "הילה", type: "city", center: [35.244407207119025, 33.03628561828477], id: "הילה" },
  { name: "תושיה", type: "city", center: [34.541884789705406, 31.43343419103869], id: "תושיה" },
  { name: "חלוץ", type: "city", center: [35.31235770169943, 32.95014429885147], id: "חלוץ" },
  { name: "אשלים", type: "city", center: [34.699723149085294, 30.965277002945868], id: "אשלים" },
  { name: "כרם מהר\"ל", type: "city", center: [34.99091616364411, 32.64547593782515], id: "כרם מהר\"ל" },
  { name: "צופר", type: "city", center: [35.18004538652826, 30.56041643571455], id: "צופר" },
  { name: "זנוח", type: "city", center: [34.999188826022944, 31.732271070453432], id: "זנוח" },
  { name: "תדהר", type: "city", center: [34.62761546317772, 31.379510171737067], id: "תדהר" },
  { name: "זרעית", type: "city", center: [35.28849826266178, 33.09970961811541], id: "זרעית" },
  { name: "שמיר", type: "city", center: [35.66019740655054, 33.16560405630931], id: "שמיר" },
  { name: "דולב", type: "city", center: [35.13318734814001, 31.926430664040872], id: "דולב" },
  { name: "עוזייר", type: "city", center: [35.32462501505887, 32.790106651849534], id: "עוזייר" },
  { name: "בת ים", type: "city", center: [34.74900089315717, 32.0142968545601], id: "בת ים" },
  { name: "רשפים", type: "city", center: [35.47598395830606, 32.481742676210594], id: "רשפים" },
  { name: "שדה נחמיה", type: "city", center: [35.62490179335773, 33.18670024762986], id: "שדה נחמיה" },
  { name: "שמשית", type: "city", center: [35.24656490476265, 32.73303658076691], id: "שמשית" },
  { name: "כפר חסידים א'", type: "city", center: [35.09282407311371, 32.75075881508065], id: "כפר חסידים א'" },
  { name: "קרית ענבים", type: "city", center: [35.11955551852464, 31.810645702103418], id: "קרית ענבים" },
  { name: "גבעת חן", type: "city", center: [34.87415435584196, 32.16837254425725], id: "גבעת חן" },
  { name: "יסוד המעלה", type: "city", center: [35.60199781982819, 33.05616134408079], id: "יסוד המעלה" },
  { name: "רמות מנשה", type: "city", center: [35.057373455390014, 32.596835489030035], id: "רמות מנשה" },
  { name: "סעד", type: "city", center: [34.53478060920595, 31.47101996938071], id: "סעד" },
  { name: "פתחיה", type: "city", center: [34.88583868763116, 31.867215551903396], id: "פתחיה" },
  { name: "ביר הדאג'", type: "city", center: [34.702943387919994, 31.024660077234596], id: "ביר הדאג'" },
  { name: "פארן", type: "city", center: [35.15490807741939, 30.362810970253662], id: "פארן" },
  { name: "כפר האורנים", type: "city", center: [35.037315733309924, 31.91983669324186], id: "כפר האורנים" },
  { name: "רמות מאיר", type: "city", center: [34.855422915124684, 31.87397991073965], id: "רמות מאיר" },
  { name: "באר גנים", type: "city", center: [34.60885586825027, 31.699726661537422], id: "באר גנים" },
  { name: "דבוריה", type: "city", center: [35.37216328858892, 32.69118435654707], id: "דבוריה" },
  { name: "חולדה", type: "city", center: [34.88325098098309, 31.831321991560475], id: "חולדה" },
  { name: "שקף", type: "city", center: [34.936386929668934, 31.514914700840848], id: "שקף" },
  { name: "א-שייח' דנון", type: "city", center: [35.14804516558211, 32.993201474693514], id: "א-שייח' דנון" },
  { name: "שפיר", type: "city", center: [34.729872612575164, 31.696183523574614], id: "שפיר" },
  { name: "ארבל", type: "city", center: [35.48379484126169, 32.81264588094529], id: "ארבל" },
  { name: "אביגדור", type: "city", center: [34.74391524214585, 31.709620889600927], id: "אביגדור" },
  { name: "אחיסמך", type: "city", center: [34.90798415375543, 31.935221874807066], id: "אחיסמך" },
  { name: "חולון", type: "city", center: [34.78329533234125, 32.010607867756455], id: "חולון" },
  { name: "הררית", type: "city", center: [35.366286486735596, 32.84574153994769], id: "הררית" },
  { name: "רווחה", type: "city", center: [34.733475423671244, 31.648697240972183], id: "רווחה" },
  { name: "אלקנה", type: "city", center: [35.03631680936057, 32.11269185006613], id: "אלקנה" },
  { name: "נחלים", type: "city", center: [34.912784919383554, 32.05904821085915], id: "נחלים" },
  { name: "סגולה", type: "city", center: [34.7808939612199, 31.66951327978612], id: "סגולה" },
  { name: "מעיין ברוך", type: "city", center: [35.60650021231481, 33.23971429752689], id: "מעיין ברוך" },
  { name: "עדנים", type: "city", center: [34.90536538611013, 32.139761918836406], id: "עדנים" },
  { name: "בית אריה", type: "city", center: [35.05066166359399, 32.03266985454008], id: "בית אריה" },
  { name: "חד נס", type: "city", center: [35.639091158659745, 32.932097267935816], id: "חד נס" },
  { name: "אמציה", type: "city", center: [34.91385662199388, 31.532081855100753], id: "אמציה" },
  { name: "מצפה רמון", type: "city", center: [34.80039112052807, 30.612724618378625], id: "מצפה רמון" },
  { name: "ברכה", type: "city", center: [35.26542631080548, 32.19268870993787], id: "ברכה" },
  { name: "אל-רום", type: "city", center: [35.77208774441089, 33.17987835060378], id: "אל-רום" },
  { name: "מצפה נטופה", type: "city", center: [35.38625472230374, 32.80259628347903], id: "מצפה נטופה" },
  { name: "נתניה", type: "city", center: [34.85871473799949, 32.306375035568905], id: "נתניה" },
  { name: "אבן ספיר", type: "city", center: [35.1345246176702, 31.763150555157186], id: "אבן ספיר" },
  { name: "נועם", type: "city", center: [34.788913092423826, 31.567552003354955], id: "נועם" },
  { name: "תל יוסף", type: "city", center: [35.40023505116616, 32.55672498139703], id: "תל יוסף" },
  { name: "קריית יערים (מוסד)", type: "city", center: [35.10386613556617, 31.811666468175112], id: "קריית יערים (מוסד)" },
  { name: "כפר זיתים", type: "city", center: [35.46368285136417, 32.81169029082408], id: "כפר זיתים" },
  { name: "מצפה", type: "city", center: [35.509443516431574, 32.790526967469795], id: "מצפה" },
  { name: "כפר מסריק", type: "city", center: [35.099496009031064, 32.89021699450678], id: "כפר מסריק" },
  { name: "סמר", type: "city", center: [35.02245951352045, 29.83208084807111], id: "סמר" },
  { name: "עראבה", type: "city", center: [35.33885399781922, 32.851537028954255], id: "עראבה" },
  { name: "ניצנה (קהילת חינוך)", type: "city", center: [34.42269410868612, 30.885335772535562], id: "ניצנה (קהילת חינוך)" },
  { name: "ניר יפה", type: "city", center: [35.24499076976455, 32.56953804844221], id: "ניר יפה" },
  { name: "מיתר", type: "city", center: [34.92628543920917, 31.32852553266942], id: "מיתר" },
  { name: "חפצי בה", type: "city", center: [35.425630725190295, 32.518100341994646], id: "חפצי בה" },
  { name: "נחשונים", type: "city", center: [34.94863241337939, 32.06074207609814], id: "נחשונים" },
  { name: "אפק", type: "city", center: [35.127972660608705, 32.838249979905505], id: "אפק" },
  { name: "נוקדים", type: "city", center: [35.24462682377708, 31.644077247707543], id: "נוקדים" },
  { name: "אלון מורה", type: "city", center: [35.334491365943826, 32.23438076946406], id: "אלון מורה" },
  { name: "ברכיה", type: "city", center: [34.62546800567526, 31.670159065923414], id: "ברכיה" },
  { name: "רשפון", type: "city", center: [34.82498173435166, 32.201373186113315], id: "רשפון" },
  { name: "כרם בן שמן", type: "city", center: [34.93424252471344, 31.958468115123132], id: "כרם בן שמן" },
  { name: "בית רימון", type: "city", center: [35.330312819418026, 32.78285605686452], id: "בית רימון" },
  { name: "עין כרם-בי\"ס חקלאי", type: "city", center: [35.153179708499344, 31.778446449448968], id: "עין כרם-בי\"ס חקלאי" },
  { name: "כרם בן זמרה", type: "city", center: [35.46832092378074, 33.038243990129104], id: "כרם בן זמרה" },
  { name: "אושה", type: "city", center: [35.11377262553914, 32.79580285668137], id: "אושה" },
  { name: "חשמונאים", type: "city", center: [35.02391144304386, 31.931448166254853], id: "חשמונאים" },
  { name: "שדרות", type: "city", center: [34.59581086902161, 31.52871519818325], id: "שדרות" },
  { name: "באר יעקב", type: "city", center: [34.83866393302229, 31.940068310293753], id: "באר יעקב" },
  { name: "רחובות", type: "city", center: [34.805389188222854, 31.893493552882855], id: "רחובות" },
  { name: "מגדלים", type: "city", center: [35.342233142119255, 32.09014063260348], id: "מגדלים" },
  { name: "שאר ישוב", type: "city", center: [35.64671369826645, 33.22602920723066], id: "שאר ישוב" },
  { name: "לוטם", type: "city", center: [35.35738635478547, 32.883256533419235], id: "לוטם" },
  { name: "גונן", type: "city", center: [35.64695984989395, 33.12373235848503], id: "גונן" },
  { name: "כפר דניאל", type: "city", center: [34.932642933092524, 31.93318744880393], id: "כפר דניאל" },
  { name: "הר עמשא", type: "city", center: [35.10091360804599, 31.343101922879455], id: "הר עמשא" },
  { name: "פרדסיה", type: "city", center: [34.91015461998846, 32.3075710722084], id: "פרדסיה" },
  { name: "חורפיש", type: "city", center: [35.34633597720028, 33.01663564083874], id: "חורפיש" },
  { name: "עמוקה", type: "city", center: [35.52467184823709, 32.9978984166814], id: "עמוקה" },
  { name: "זימרת", type: "city", center: [34.55242630853709, 31.44807766547877], id: "זימרת" },
  { name: "כפר ידידיה", type: "city", center: [34.899000250045944, 32.34567530552896], id: "כפר ידידיה" },
  { name: "חוגלה", type: "city", center: [34.92549046920551, 32.38744102175144], id: "חוגלה" },
  { name: "תלם", type: "city", center: [35.03110447933123, 31.563676120413557], id: "תלם" },
  { name: "עין שמר", type: "city", center: [35.00806092592359, 32.46340928554474], id: "עין שמר" },
  { name: "גבעת עוז", type: "city", center: [35.198354020208335, 32.555163040130054], id: "גבעת עוז" },
  { name: "נווה מיכאל", type: "city", center: [35.00650012109322, 31.672916573971538], id: "נווה מיכאל" },
  { name: "אבו קורינאת (יישוב)", type: "city", center: [34.963173179814504, 31.140502676195876], id: "אבו קורינאת (יישוב)" },
  { name: "עץ אפרים", type: "city", center: [35.047192792270685, 32.12012950653377], id: "עץ אפרים" },
  { name: "כסיפה", type: "city", center: [35.08613569773256, 31.242651510122574], id: "כסיפה" },
  { name: "צור הדסה", type: "city", center: [35.09528062148586, 31.7157858606603], id: "צור הדסה" },
  { name: "נחשולים", type: "city", center: [34.92125625907884, 32.613654911954896], id: "נחשולים" },
  { name: "גבולות", type: "city", center: [34.465569608049535, 31.210620876582208], id: "גבולות" },
  { name: "קלחים", type: "city", center: [34.677385811025665, 31.449004407801347], id: "קלחים" },
  { name: "בת חפר", type: "city", center: [35.01305072194543, 32.333686261352156], id: "בת חפר" },
  { name: "שתולה", type: "city", center: [35.31475710096773, 33.08541215888244], id: "שתולה" },
  { name: "מצדות יהודה", type: "city", center: [35.11253461544929, 31.365904138658895], id: "מצדות יהודה" },
  { name: "שדמות מחולה", type: "city", center: [35.531748090544205, 32.3475047469038], id: "שדמות מחולה" },
  { name: "שניר", type: "city", center: [35.678074938869926, 33.24129273751972], id: "שניר" },
  { name: "חגור", type: "city", center: [34.945721895634435, 32.137299666418585], id: "חגור" },
  { name: "שוהם", type: "city", center: [34.94500255425604, 31.995910054799285], id: "שוהם" },
  { name: "רמת דוד", type: "city", center: [35.20300437056294, 32.67822320615681], id: "רמת דוד" },
  { name: "בית נחמיה", type: "city", center: [34.954888129718384, 31.976656531162146], id: "בית נחמיה" },
  { name: "רנן", type: "city", center: [34.60156524104205, 31.337444003412312], id: "רנן" },
  { name: "שדה אליהו", type: "city", center: [35.512933688743864, 32.43950986922536], id: "שדה אליהו" },
  { name: "קשת", type: "city", center: [35.80855319967489, 32.98013957061879], id: "קשת" },
  { name: "ג'סר א זרקא", type: "city", center: [34.91151496372467, 32.5366700169022], id: "ג'סר א זרקא" },
  { name: "נבטים", type: "city", center: [34.883378207842796, 31.2233900060028], id: "נבטים" },
  { name: "בית שאן", type: "city", center: [35.50043266810611, 32.49920328370663], id: "בית שאן" },
  { name: "ערוגות", type: "city", center: [34.77120054587968, 31.734379761123645], id: "ערוגות" },
  { name: "פסגות", type: "city", center: [35.2244417367188, 31.898948349536777], id: "פסגות" },
  { name: "יוקנעם (מושבה)", type: "city", center: [35.11516708195736, 32.65616856010164], id: "יוקנעם (מושבה)" },
  { name: "שורשים", type: "city", center: [35.258861758180586, 32.893461167741776], id: "שורשים" },
  { name: "המעפיל", type: "city", center: [34.98270078813813, 32.37866435009623], id: "המעפיל" },
  { name: "בנימינה גבעת עדה", type: "city", center: [34.97131480182219, 32.51942740560611], id: "בנימינה גבעת עדה" },
  { name: "מעיין צבי", type: "city", center: [34.94021596276783, 32.567607851187454], id: "מעיין צבי" },
  { name: "עמיעוז", type: "city", center: [34.41310834003388, 31.24794328160692], id: "עמיעוז" },
  { name: "כפר גליקסון", type: "city", center: [35.005000676228555, 32.50583085989718], id: "כפר גליקסון" },
  { name: "סביון", type: "city", center: [34.878337838996565, 32.04713636443621], id: "סביון" },
  { name: "עינב", type: "city", center: [35.12698135615632, 32.28581820828016], id: "עינב" },
  { name: "אבני איתן", type: "city", center: [35.76553275331521, 32.82391329637703], id: "אבני איתן" },
  { name: "עין ראפה", type: "city", center: [35.11330898213318, 31.790326197329453], id: "עין ראפה" },
  { name: "כמאנה", type: "city", center: [35.34750261767464, 32.90820470055407], id: "כמאנה" },
  { name: "דקל", type: "city", center: [34.34757924416177, 31.194645010574423], id: "דקל" },
  { name: "דחי", type: "city", center: [35.344614364104324, 32.6212848268527], id: "דחי" },
  { name: "דגניה א'", type: "city", center: [35.57499567443316, 32.707387928438145], id: "דגניה א'" },
  { name: "בית צבי", type: "city", center: [34.96994104529769, 32.719584516465076], id: "בית צבי" },
  { name: "בית עוזיאל", type: "city", center: [34.90556377154068, 31.870609989165317], id: "בית עוזיאל" },
  { name: "פני חבר", type: "city", center: [35.1652095621993, 31.486047087735066], id: "פני חבר" },
  { name: "הראל", type: "city", center: [34.94807378147509, 31.809143306217127], id: "הראל" },
  { name: "דריג'את", type: "city", center: [35.07702288552226, 31.299046509934254], id: "דריג'את" },
  { name: "עידן", type: "city", center: [35.299981560896256, 30.80616870436611], id: "עידן" },
  { name: "נווה מבטח", type: "city", center: [34.73992295882316, 31.80497593492215], id: "נווה מבטח" },
  { name: "כפר חנניה", type: "city", center: [35.423013295243386, 32.91602501860504], id: "כפר חנניה" },
  { name: "בית יוסף", type: "city", center: [35.55163352586198, 32.55890658998773], id: "בית יוסף" },
  { name: "להבים", type: "city", center: [34.81208550153501, 31.367307561312085], id: "להבים" },
  { name: "שדמה", type: "city", center: [34.74089051447388, 31.832372055573163], id: "שדמה" },
  { name: "כפר ראש הניקרה", type: "city", center: [35.11557052768155, 33.08607054753585], id: "כפר ראש הניקרה" },
  { name: "בצרה", type: "city", center: [34.87821510074233, 32.213367491278355], id: "בצרה" },
  { name: "גניגר", type: "city", center: [35.25808870314045, 32.66393888918144], id: "גניגר" },
  { name: "אלעד", type: "city", center: [34.95676481339648, 32.05093041655337], id: "אלעד" },
  { name: "אלפי מנשה", type: "city", center: [35.01330707000189, 32.172118700221205], id: "אלפי מנשה" },
  { name: "מעלה עירון", type: "city", center: [35.171226145973684, 32.54965819327509], id: "מעלה עירון" },
  { name: "דבירה", type: "city", center: [34.824173370777, 31.412987899085135], id: "דבירה" },
  { name: "מדרך עוז", type: "city", center: [35.15874850609628, 32.596456057556004], id: "מדרך עוז" },
  { name: "עמיר", type: "city", center: [35.61998571318916, 33.17849517060851], id: "עמיר" },
  { name: "נטועה", type: "city", center: [35.32297525251166, 33.06467454938838], id: "נטועה" },
  { name: "קדש ברנע", type: "city", center: [34.397632619338374, 30.9044824190775], id: "קדש ברנע" },
  { name: "מצפה שלם", type: "city", center: [35.40075490149118, 31.56909933176055], id: "מצפה שלם" },
  { name: "קצרין", type: "city", center: [35.69488485940634, 32.99114234944399], id: "קצרין" },
  { name: "ג'דיידה מכר", type: "city", center: [35.14681228290548, 32.929946611623485], id: "ג'דיידה מכר" },
  { name: "גיזו", type: "city", center: [34.93853734540205, 31.805087579910516], id: "גיזו" },
  { name: "גליל ים", type: "city", center: [34.83069268298137, 32.15746182540586], id: "גליל ים" },
  { name: "נהריה", type: "city", center: [35.09981007256929, 33.01212554650045], id: "נהריה" },
  { name: "אליקים", type: "city", center: [35.06728887512662, 32.63176653566142], id: "אליקים" },
  { name: "בית חלקיה", type: "city", center: [34.812279687081485, 31.792000317487407], id: "בית חלקיה" },
  { name: "אור הגנוז", type: "city", center: [35.44669993546246, 33.00514626357144], id: "אור הגנוז" },
  { name: "נירן", type: "city", center: [35.45523596397529, 31.96682363921458], id: "נירן" },
  { name: "איכסאל", type: "city", center: [35.325503370523485, 32.68186182436392], id: "איכסאל" },
  { name: "מיטב", type: "city", center: [35.300814929832356, 32.54602200104396], id: "מיטב" },
  { name: "כפר חושן", type: "city", center: [35.43939388304224, 33.0111927899729], id: "כפר חושן" },
  { name: "משואות יצחק", type: "city", center: [34.688585960045906, 31.702942552809713], id: "משואות יצחק" },
  { name: "מעיליא", type: "city", center: [35.25833559186901, 33.027161382392194], id: "מעיליא" },
  { name: "אדורה", type: "city", center: [35.01815643152742, 31.552371775449952], id: "אדורה" },
  { name: "עין חרוד מאוחד", type: "city", center: [35.39157089714965, 32.55936299344944], id: "עין חרוד מאוחד" },
  { name: "בת חן", type: "city", center: [34.87187600166067, 32.36029072601569], id: "בת חן" },
  { name: "אורה", type: "city", center: [35.15294845190295, 31.753281847960505], id: "אורה" },
  { name: "זוהר", type: "city", center: [34.69288184779079, 31.595421421903325], id: "זוהר" },
  { name: "ורד יריחו", type: "city", center: [35.431429733393266, 31.82662706033974], id: "ורד יריחו" },
  { name: "גבעת זאב", type: "city", center: [35.163128043908245, 31.85818225646359], id: "גבעת זאב" },
  { name: "ע'ג'ר", type: "city", center: [35.62313891295061, 33.27341207132131], id: "ע'ג'ר" },
  { name: "יציץ", type: "city", center: [34.85935527083238, 31.862495003565762], id: "יציץ" },
  { name: "שדה עוזיהו", type: "city", center: [34.67718331156065, 31.75612605715635], id: "שדה עוזיהו" },
  { name: "מטולה", type: "city", center: [35.57767198647987, 33.27920905696645], id: "מטולה" },
  { name: "נגבה", type: "city", center: [34.68396177230736, 31.660727076465268], id: "נגבה" },
  { name: "אורנית", type: "city", center: [34.99410596389523, 32.130024345788364], id: "אורנית" },
  { name: "גנות הדר", type: "city", center: [34.900932617929556, 32.320097791529136], id: "גנות הדר" },
  { name: "נגוהות", type: "city", center: [34.98333628439168, 31.492941137843236], id: "נגוהות" },
  { name: "אלישמע", type: "city", center: [34.92829490639502, 32.15295730344464], id: "אלישמע" },
  { name: "מנחמיה", type: "city", center: [35.555161756836625, 32.66626973084136], id: "מנחמיה" },
  { name: "אשכולות", type: "city", center: [34.903012811465025, 31.39172708689769], id: "אשכולות" },
  { name: "גזר", type: "city", center: [34.919022594439795, 31.876569137587783], id: "גזר" },
  { name: "רתמים", type: "city", center: [34.69030590167244, 31.05419630727144], id: "רתמים" },
  { name: "ביריה", type: "city", center: [35.499214034824625, 32.97888433874576], id: "ביריה" },
  { name: "עברון", type: "city", center: [35.10078905578933, 32.99152507713176], id: "עברון" },
  { name: "סלעית", type: "city", center: [35.05175451262846, 32.242766816398415], id: "סלעית" },
  { name: "רמת רזיאל", type: "city", center: [35.07191064335513, 31.773722218066013], id: "רמת רזיאל" },
  { name: "דליה", type: "city", center: [35.0742913090207, 32.58922403320647], id: "דליה" },
  { name: "נתיב השיירה", type: "city", center: [35.135790494609175, 32.99397560158003], id: "נתיב השיירה" },
  { name: "יבנאל", type: "city", center: [35.500731021016406, 32.70889802571654], id: "יבנאל" },
  { name: "רמת גן", type: "city", center: [34.82731443976388, 32.06418185437794], id: "רמת גן" },
  { name: "שואבה", type: "city", center: [35.07820559744272, 31.79805640113962], id: "שואבה" },
  { name: "מענית", type: "city", center: [35.026096671251054, 32.45789052168122], id: "מענית" },
  { name: "שדה יעקב", type: "city", center: [35.141548637842405, 32.69837932222935], id: "שדה יעקב" },
  { name: "בית דגן", type: "city", center: [34.830174973701205, 32.000120165114566], id: "בית דגן" },
  { name: "אילת", type: "city", center: [34.946536924115875, 29.552152012868042], id: "אילת" },
  { name: "כליל", type: "city", center: [35.20117072662711, 32.98091915681419], id: "כליל" },
  { name: "מירב", type: "city", center: [35.422183354660255, 32.45251641908175], id: "מירב" },
  { name: "חניאל", type: "city", center: [34.94868091640158, 32.33327035877097], id: "חניאל" },
  { name: "טירה", type: "city", center: [34.95473952394874, 32.23347605306305], id: "טירה" },
  { name: "קדרים", type: "city", center: [35.47242909666015, 32.898390565292246], id: "קדרים" },
  { name: "אירוס", type: "city", center: [34.77638029310701, 31.92829763809097], id: "אירוס" },
  { name: "יוקנעם עילית", type: "city", center: [35.09481754355502, 32.64982848841543], id: "יוקנעם עילית" },
  { name: "נוב", type: "city", center: [35.783219225668475, 32.83255532684151], id: "נוב" },
  { name: "בועיינה-נוג'ידאת", type: "city", center: [35.36919314801742, 32.808106465662824], id: "בועיינה-נוג'ידאת" },
  { name: "מבואות ים", type: "city", center: [34.86838647955205, 32.4029320314387], id: "מבואות ים" },
  { name: "שומריה", type: "city", center: [34.884769051380964, 31.431959562378406], id: "שומריה" },
  { name: "שתולים", type: "city", center: [34.68362208660399, 31.77367260340288], id: "שתולים" },
  { name: "בית אורן", type: "city", center: [35.004979123380835, 32.731345798622485], id: "בית אורן" },
  { name: "חצור הגלילית", type: "city", center: [35.54519002997453, 32.98381865816557], id: "חצור הגלילית" },
  { name: "שחרות", type: "city", center: [35.000011642486015, 29.904689453753665], id: "שחרות" },
  { name: "נען", type: "city", center: [34.857716925687285, 31.883574310079517], id: "נען" },
  { name: "כפר עציון", type: "city", center: [35.1157136630924, 31.64902452533958], id: "כפר עציון" },
  { name: "חמדת", type: "city", center: [35.52684697141601, 32.25129571817925], id: "חמדת" },
  { name: "ארז", type: "city", center: [34.56654098725391, 31.560456482613457], id: "ארז" },
  { name: "כנף", type: "city", center: [35.69782595479975, 32.87006298146912], id: "כנף" },
  { name: "גבעת ברנר", type: "city", center: [34.800933221821616, 31.867237637932586], id: "גבעת ברנר" },
  { name: "חלץ", type: "city", center: [34.65816788944775, 31.577524099169747], id: "חלץ" },
  { name: "שיטים", type: "city", center: [35.02155684354946, 30.175824871280163], id: "שיטים" },
  { name: "מרום גולן", type: "city", center: [35.77628234937399, 33.13350913011926], id: "מרום גולן" },
  { name: "עין איילה", type: "city", center: [34.94511455786151, 32.62861476299915], id: "עין איילה" },
  { name: "תלמי יפה", type: "city", center: [34.6141913688704, 31.61772106109364], id: "תלמי יפה" },
  { name: "בלפוריה", type: "city", center: [35.296209994518755, 32.63027235058695], id: "בלפוריה" },
  { name: "עזריאל", type: "city", center: [34.970541576307035, 32.26211224779105], id: "עזריאל" },
  { name: "מתת", type: "city", center: [35.35832943989414, 33.04127260954505], id: "מתת" },
  { name: "נאות גולן", type: "city", center: [35.69246850160402, 32.78683037630297], id: "נאות גולן" },
  { name: "ערד", type: "city", center: [35.2085666480764, 31.2581489370676], id: "ערד" },
  { name: "בני דרור", type: "city", center: [34.8989845668758, 32.26199021945356], id: "בני דרור" },
  { name: "דבורה", type: "city", center: [35.26359394841882, 32.55340488887103], id: "דבורה" },
  { name: "באר טוביה", type: "city", center: [34.72466647452132, 31.734209810456633], id: "באר טוביה" },
  { name: "קוממיות", type: "city", center: [34.730564187937375, 31.661859831123355], id: "קוממיות" },
  { name: "גן נר", type: "city", center: [35.33785433693319, 32.53129550655684], id: "גן נר" },
  { name: "צרופה", type: "city", center: [34.947228122276286, 32.64810059957387], id: "צרופה" },
  { name: "שגב-שלום", type: "city", center: [34.83857773583767, 31.197051960384858], id: "שגב-שלום" },
  { name: "יהל", type: "city", center: [35.12871663269563, 30.082955102127578], id: "יהל" },
  { name: "סאג'ור", type: "city", center: [35.34306579495553, 32.94255058880485], id: "סאג'ור" },
  { name: "עמנואל", type: "city", center: [35.136626994293735, 32.161389871303825], id: "עמנואל" },
  { name: "אדמית", type: "city", center: [35.20912566625342, 33.07956956670624], id: "אדמית" },
  { name: "להב", type: "city", center: [34.869410793657394, 31.37974304950032], id: "להב" },
  { name: "דפנה", type: "city", center: [35.63852769143085, 33.23036401884521], id: "דפנה" },
  { name: "עין יהב", type: "city", center: [35.237164186162026, 30.658034167364214], id: "עין יהב" },
  { name: "נווה דניאל", type: "city", center: [35.14293853732394, 31.678327445548412], id: "נווה דניאל" },
  { name: "אופקים", type: "city", center: [34.62445244115703, 31.30639517543624], id: "אופקים" },
  { name: "מג'ד אל-כרום", type: "city", center: [35.262325643834494, 32.92785866912232], id: "מג'ד אל-כרום" },
  { name: "אשדות יעקב מאוחד", type: "city", center: [35.583363062262926, 32.665182460563095], id: "אשדות יעקב מאוחד" },
  { name: "כרמל", type: "city", center: [35.183627771238164, 31.430473300720195], id: "כרמל" },
  { name: "מלכיה", type: "city", center: [35.51133021902044, 33.0987815101509], id: "מלכיה" },
  { name: "מקוה ישראל", type: "city", center: [34.782845090690515, 32.02871644115526], id: "מקוה ישראל" },
  { name: "גשר", type: "city", center: [35.55161256256825, 32.621421991548765], id: "גשר" },
  { name: "חרוצים", type: "city", center: [34.8648185125523, 32.22745060742263], id: "חרוצים" },
  { name: "עיר אובות", type: "city", center: [35.23263429136993, 30.797251238683273], id: "עיר אובות" },
  { name: "ירקונה", type: "city", center: [34.89892060217369, 32.145406632351694], id: "ירקונה" },
  { name: "כפר מונש", type: "city", center: [34.91625265770626, 32.34760953016734], id: "כפר מונש" },
  { name: "ירכא", type: "city", center: [35.208478284013786, 32.95532777473709], id: "ירכא" },
  { name: "יפיע", type: "city", center: [35.274732647901246, 32.68328189114718], id: "יפיע" },
  { name: "כפר בלום", type: "city", center: [35.60637474410846, 33.17492966734738], id: "כפר בלום" },
  { name: "עין הוד", type: "city", center: [34.983139216555955, 32.69915329696351], id: "עין הוד" },
  { name: "טייבה", type: "city", center: [35.00790942739795, 32.261613105445], id: "טייבה" },
  { name: "תלמי אלעזר", type: "city", center: [34.976664502442375, 32.44548870577311], id: "תלמי אלעזר" },
  { name: "אשל הנשיא", type: "city", center: [34.697199779383666, 31.325306797251823], id: "אשל הנשיא" },
  { name: "אלוני יצחק", type: "city", center: [35.00408791836262, 32.51046319055063], id: "אלוני יצחק" },
  { name: "חצור אשדוד", type: "city", center: [34.72041170952588, 31.772185949659022], id: "חצור אשדוד" },
  { name: "בית עריף", type: "city", center: [34.9340960860414, 31.996094714560936], id: "בית עריף" },
  { name: "אדרת", type: "city", center: [34.99534452606034, 31.659822238781956], id: "אדרת" },
  { name: "היוגב", type: "city", center: [35.20464836847539, 32.6124121774551], id: "היוגב" },
  { name: "בית ברל", type: "city", center: [34.926387285095444, 32.20136259333686], id: "בית ברל" },
  { name: "גדרה", type: "city", center: [34.77944919825896, 31.810711064011226], id: "גדרה" },
  { name: "דור", type: "city", center: [34.92337085345795, 32.607122440844776], id: "דור" },
  { name: "נווה ימין", type: "city", center: [34.93870379660166, 32.171277820583], id: "נווה ימין" },
  { name: "אבו גוש", type: "city", center: [35.10797567433251, 31.807228997469938], id: "אבו גוש" },
  { name: "בית לחם הגלילית", type: "city", center: [35.19111021133152, 32.73510503786304], id: "בית לחם הגלילית" },
  { name: "שזור", type: "city", center: [35.35254643199041, 32.93234861474598], id: "שזור" },
  { name: "משמר הירדן", type: "city", center: [35.59961460003847, 33.004763674592944], id: "משמר הירדן" },
  { name: "חוסן", type: "city", center: [35.29574274540884, 32.99808783493439], id: "חוסן" },
  { name: "בית אל", type: "city", center: [35.22376104221558, 31.94207193151556], id: "בית אל" },
  { name: "שלוחות", type: "city", center: [35.47955381514057, 32.47099194694284], id: "שלוחות" },
  { name: "נופים", type: "city", center: [35.101860045498, 32.15504135135498], id: "נופים" },
  { name: "תאשור", type: "city", center: [34.64376341827201, 31.37159227914464], id: "תאשור" },
  { name: "עין תמר", type: "city", center: [35.36199417127492, 30.943261716707184], id: "עין תמר" },
  { name: "כפר יחזקאל", type: "city", center: [35.35888808614162, 32.56685377617486], id: "כפר יחזקאל" },
  { name: "איתנים", type: "city", center: [35.09570764986435, 31.77867060239685], id: "איתנים" },
  { name: "ראשון לציון", type: "city", center: [34.78894922539749, 31.975138935023725], id: "ראשון לציון" },
  { name: "ביר אל-מכסור", type: "city", center: [35.22344155326622, 32.784224271554145], id: "ביר אל-מכסור" },
  { name: "ניר גלים", type: "city", center: [34.68207566657571, 31.824304069958032], id: "ניר גלים" },
  { name: "נתיב העשרה", type: "city", center: [34.53906549877247, 31.571808137748434], id: "נתיב העשרה" },
  { name: "ג'ולס", type: "city", center: [35.18455931670952, 32.94204339063564], id: "ג'ולס" },
  { name: "צופים", type: "city", center: [35.01005408996007, 32.19804272563359], id: "צופים" },
  { name: "דישון", type: "city", center: [35.51695047727455, 33.081446192730546], id: "דישון" },
  { name: "מעונה", type: "city", center: [35.260179799724625, 33.01616674037668], id: "מעונה" },
  { name: "אמונים", type: "city", center: [34.6759880556543, 31.743585337944], id: "אמונים" },
  { name: "מסילות", type: "city", center: [35.47458377616654, 32.495260775772294], id: "מסילות" },
  { name: "משמרת", type: "city", center: [34.92013868403106, 32.22775388508829], id: "משמרת" },
  { name: "שחר", type: "city", center: [34.72568536168093, 31.61896558092772], id: "שחר" },
  { name: "שדי חמד", type: "city", center: [34.94304399807001, 32.15956532383446], id: "שדי חמד" },
  { name: "תל תאומים", type: "city", center: [35.49612738078001, 32.44191117057873], id: "תל תאומים" },
  { name: "מבוא דותן", type: "city", center: [35.17331436095454, 32.42028809981553], id: "מבוא דותן" },
  { name: "כיסרא סומיע", type: "city", center: [35.30411157153976, 32.97073133422582], id: "כיסרא סומיע" },
  { name: "ניר משה", type: "city", center: [34.631225235662825, 31.477672501235354], id: "ניר משה" },
  { name: "גיאה", type: "city", center: [34.60330680389869, 31.62801418471312], id: "גיאה" },
  { name: "לימן", type: "city", center: [35.11296553574671, 33.05933223933382], id: "לימן" },
  { name: "שושנת העמקים", type: "city", center: [34.85696054368049, 32.35412533876458], id: "שושנת העמקים" },
  { name: "כפר הרי\"ף", type: "city", center: [34.794440602231866, 31.744625758868654], id: "כפר הרי\"ף" },
  { name: "נצר חזני", type: "city", center: [34.86266416877992, 31.821292019409313], id: "נצר חזני" },
  { name: "כוכב יאיר צור יגאל", type: "city", center: [34.99455006987963, 32.22193141131647], id: "כוכב יאיר צור יגאל" },
  { name: "קרית מלאכי", type: "city", center: [34.74478361964748, 31.731101993363673], id: "קרית מלאכי" },
  { name: "מעלה גמלא", type: "city", center: [35.68413522144623, 32.888194236657355], id: "מעלה גמלא" },
  { name: "בית ג'ן", type: "city", center: [35.377063923916126, 32.96328243486326], id: "בית ג'ן" },
  { name: "צרעה", type: "city", center: [34.9679115662387, 31.763508026010232], id: "צרעה" },
  { name: "פסוטה", type: "city", center: [35.310509346024084, 33.047922064368414], id: "פסוטה" },
  { name: "רקפת", type: "city", center: [35.264643479808235, 32.855101776767654], id: "רקפת" },
  { name: "בני רא\"ם", type: "city", center: [34.7898437636951, 31.766987408772113], id: "בני רא\"ם" },
  { name: "רביד", type: "city", center: [35.46356283794522, 32.85032041048003], id: "רביד" },
  { name: "גבעתי", type: "city", center: [34.68048343310291, 31.73227922299293], id: "גבעתי" },
  { name: "בית חנניה", type: "city", center: [34.92560170549501, 32.52836999533031], id: "בית חנניה" },
  { name: "בית חרות", type: "city", center: [34.8697347326702, 32.37803400574342], id: "בית חרות" },
  { name: "כפר הנשיא", type: "city", center: [35.60317066510197, 32.97511012790442], id: "כפר הנשיא" },
  { name: "אלומה", type: "city", center: [34.744027550171786, 31.65159285437473], id: "אלומה" },
  { name: "רמת צבי", type: "city", center: [35.41377291385365, 32.59140601811199], id: "רמת צבי" },
  { name: "אלוני אבא", type: "city", center: [35.17132974654841, 32.728801319610334], id: "אלוני אבא" },
  { name: "עין כרמל", type: "city", center: [34.95223570140823, 32.67779423360864], id: "עין כרמל" },
  { name: "חספין", type: "city", center: [35.79324280562579, 32.84485279453362], id: "חספין" },
  { name: "רומת היב", type: "city", center: [35.30448732466971, 32.77808132482194], id: "רומת היב" },
  { name: "תימורים", type: "city", center: [34.75826609984621, 31.715202256176745], id: "תימורים" },
  { name: "רחוב", type: "city", center: [35.48883533289196, 32.45130454762866], id: "רחוב" },
  { name: "אליכין", type: "city", center: [34.92467106975801, 32.40783226840082], id: "אליכין" },
  { name: "עין מאהל", type: "city", center: [35.35304462038263, 32.72192925593401], id: "עין מאהל" },
  { name: "נחל עוז", type: "city", center: [34.497707350510105, 31.472357494381054], id: "נחל עוז" },
  { name: "אפיק", type: "city", center: [35.69990489539632, 32.77861121532667], id: "אפיק" },
  { name: "כרמיאל", type: "city", center: [35.29772298055749, 32.91445396348061], id: "כרמיאל" },
  { name: "נווה ירק", type: "city", center: [34.92415514028168, 32.13276934021642], id: "נווה ירק" },
  { name: "יצהר", type: "city", center: [35.234416082129954, 32.16865322250792], id: "יצהר" },
  { name: "ישעי", type: "city", center: [34.96561306669816, 31.751324568185012], id: "ישעי" },
  { name: "יעף", type: "city", center: [34.96460047186278, 32.26771512551248], id: "יעף" },
  { name: "תלמי יוסף", type: "city", center: [34.36139864927693, 31.199898880369012], id: "תלמי יוסף" },
  { name: "יגור", type: "city", center: [35.07786347165052, 32.742154770348144], id: "יגור" },
  { name: "עפולה", type: "city", center: [35.29902592166107, 32.61342873031522], id: "עפולה" },
  { name: "עין חרוד (איחוד)", type: "city", center: [35.39119087961525, 32.5629468605589], id: "עין חרוד (איחוד)" },
  { name: "כפר יהושע", type: "city", center: [35.152155964746335, 32.681854004237294], id: "כפר יהושע" },
  { name: "רמת הכובש", type: "city", center: [34.93812858531329, 32.216914383398354], id: "רמת הכובש" },
  { name: "כפר אביב", type: "city", center: [34.72124322469454, 31.831279351089005], id: "כפר אביב" },
  { name: "סאסא", type: "city", center: [35.39404157048333, 33.02736576481951], id: "סאסא" },
  { name: "בן עמי", type: "city", center: [35.12395067233152, 33.00561656616192], id: "בן עמי" },
  { name: "אייל", type: "city", center: [34.97918024471316, 32.21234862932581], id: "אייל" },
  { name: "אמנון", type: "city", center: [35.571937017296534, 32.904810411786286], id: "אמנון" },
  { name: "גת רימון", type: "city", center: [34.880019699898135, 32.068559860376084], id: "גת רימון" },
  { name: "מכמורת", type: "city", center: [34.87343705316996, 32.40625603544277], id: "מכמורת" },
  { name: "רביבים", type: "city", center: [34.72277105033323, 31.043382445913622], id: "רביבים" },
  { name: "מתן", type: "city", center: [34.97298306368984, 32.15849468622822], id: "מתן" },
  { name: "קלע", type: "city", center: [35.68630815042614, 33.1319829121383], id: "קלע" },
  { name: "שערי תקווה", type: "city", center: [35.02633054711176, 32.12271227536625], id: "שערי תקווה" },
  { name: "פרזון", type: "city", center: [35.31088483255005, 32.54557926985026], id: "פרזון" },
  { name: "משמר איילון", type: "city", center: [34.94512067852625, 31.871324865088067], id: "משמר איילון" },
  { name: "בסמ\"ה", type: "city", center: [35.102798977557846, 32.53114603372229], id: "בסמ\"ה" },
  { name: "מבשרת ציון", type: "city", center: [35.150270067769554, 31.798747272420417], id: "מבשרת ציון" },
  { name: "טבריה", type: "city", center: [35.52743255974623, 32.78164654629531], id: "טבריה" },
  { name: "איתמר", type: "city", center: [35.30824382814122, 32.1733056564969], id: "איתמר" },
  { name: "קרית טבעון", type: "city", center: [35.123667483169136, 32.71318581100248], id: "קרית טבעון" },
  { name: "צופית", type: "city", center: [34.921527280475885, 32.19253788830735], id: "צופית" },
  { name: "תובל", type: "city", center: [35.24552277379585, 32.92871814463442], id: "תובל" },
  { name: "אשתאול", type: "city", center: [35.00810531707558, 31.78135273261991], id: "אשתאול" },
  { name: "איילת השחר", type: "city", center: [35.576743480607654, 33.02295644614881], id: "איילת השחר" },
  { name: "עין שריד", type: "city", center: [34.9356400492236, 32.27366385737643], id: "עין שריד" },
  { name: "גבעת שמש", type: "city", center: [34.95143625343097, 31.77503727109433], id: "גבעת שמש" },
  { name: "גבעת יערים", type: "city", center: [35.09226587071718, 31.786699579344294], id: "גבעת יערים" },
  { name: "בן שמן (כפר נוער)", type: "city", center: [34.927616438992985, 31.959502712833334], id: "בן שמן (כפר נוער)" },
  { name: "כפר מימון", type: "city", center: [34.534988869076926, 31.43319105977534], id: "כפר מימון" },
  { name: "מירון", type: "city", center: [35.43975868130143, 32.986480712849705], id: "מירון" },
  { name: "עזר", type: "city", center: [34.67118941276164, 31.73680568314199], id: "עזר" },
  { name: "מעלה לבונה", type: "city", center: [35.240485575847075, 32.05466557296146], id: "מעלה לבונה" },
  { name: "ניצנים", type: "city", center: [34.6334740544493, 31.71765285456533], id: "ניצנים" },
  { name: "אל-עזי", type: "city", center: [34.81427012188128, 31.71848631373274], id: "אל-עזי" },
  { name: "הוד השרון", type: "city", center: [34.894682746594206, 32.15053183019753], id: "הוד השרון" },
  { name: "בני יהודה", type: "city", center: [35.68979802248711, 32.79852769502827], id: "בני יהודה" },
  { name: "אביבים", type: "city", center: [35.472367278159346, 33.08844135300832], id: "אביבים" },
  { name: "טירת הכרמל", type: "city", center: [34.97071973629131, 32.760226124719296], id: "טירת הכרמל" },
  { name: "אשחר", type: "city", center: [35.30073134522377, 32.886101189934045], id: "אשחר" },
  { name: "תל עדשים", type: "city", center: [35.301392601925684, 32.655332147140676], id: "תל עדשים" },
  { name: "נהלל", type: "city", center: [35.19742442132575, 32.69069434107397], id: "נהלל" },
  { name: "עצמון שגב", type: "city", center: [35.25225100833696, 32.865813055834174], id: "עצמון שגב" },
  { name: "עיינות", type: "city", center: [34.76802165911404, 31.915525011322142], id: "עיינות" },
  { name: "יקיר", type: "city", center: [35.11041865903901, 32.14822942503553], id: "יקיר" },
  { name: "אלמוג", type: "city", center: [35.46102234341149, 31.789767135803235], id: "אלמוג" },
  { name: "צבעון", type: "city", center: [35.41697767783804, 33.026631281339434], id: "צבעון" },
  { name: "אלומות", type: "city", center: [35.54698910140604, 32.70759306869121], id: "אלומות" },
  { name: "בית ניר", type: "city", center: [34.87342189803197, 31.648566396543924], id: "בית ניר" },
  { name: "לוד", type: "city", center: [34.8901202012926, 31.951660466529095], id: "לוד" },
  { name: "בית הלל", type: "city", center: [35.60530383929845, 33.20782355914581], id: "בית הלל" },
  { name: "כחל", type: "city", center: [35.510697143777946, 32.89027576185498], id: "כחל" },
  { name: "גני יוחנן", type: "city", center: [34.838187029646704, 31.858228802208977], id: "גני יוחנן" },
  { name: "בניה", type: "city", center: [34.75202873449385, 31.843409967310702], id: "בניה" },
  { name: "כפר החורש", type: "city", center: [35.272568176883, 32.700400950987344], id: "כפר החורש" },
  { name: "משמר העמק", type: "city", center: [35.142165343479654, 32.60976364249638], id: "משמר העמק" },
  { name: "נחשון", type: "city", center: [34.95480782803484, 31.830707565836796], id: "נחשון" },
  { name: "כנות", type: "city", center: [34.75217943957429, 31.80205950403265], id: "כנות" },
  { name: "מחניים", type: "city", center: [35.57130124892862, 32.988944970579404], id: "מחניים" },
  { name: "פוריה נווה עובד", type: "city", center: [35.53745375586732, 32.74302423181675], id: "פוריה נווה עובד" },
  { name: "נווה ים", type: "city", center: [34.93012440913789, 32.6785701763247], id: "נווה ים" },
  { name: "באקה אל-גרביה", type: "city", center: [35.042112401869524, 32.41996372007997], id: "באקה אל-גרביה" },
  { name: "טללים", type: "city", center: [34.769849742130035, 30.992136978187276], id: "טללים" },
  { name: "כוכב מיכאל", type: "city", center: [34.66942596318093, 31.62937184016774], id: "כוכב מיכאל" },
  { name: "מודיעין מכבים רעות", type: "city", center: [34.992388724961515, 31.8994240957239], id: "מודיעין מכבים רעות" },
  { name: "כלנית", type: "city", center: [35.45357275203925, 32.87515776653579], id: "כלנית" },
  { name: "שער אפרים", type: "city", center: [34.99745074754929, 32.28901739091891], id: "שער אפרים" },
  { name: "צור נתן", type: "city", center: [35.012627848879944, 32.24079728432977], id: "צור נתן" },
  { name: "אפיקים", type: "city", center: [35.57817598491916, 32.68055468237856], id: "אפיקים" },
  { name: "גבעת אלה", type: "city", center: [35.24432878087854, 32.72153775789359], id: "גבעת אלה" },
  { name: "מזרע", type: "city", center: [35.28610204878761, 32.650504723233276], id: "מזרע" },
  { name: "כפר שמריהו", type: "city", center: [34.821134682825964, 32.18695198106511], id: "כפר שמריהו" },
  { name: "גבע", type: "city", center: [35.37149466121487, 32.5664976069909], id: "גבע" },
  { name: "תל יצחק", type: "city", center: [34.87065002709756, 32.2519876201465], id: "תל יצחק" },
  { name: "חמרה", type: "city", center: [35.43672990533582, 32.19997459265716], id: "חמרה" },
  { name: "פרי גן", type: "city", center: [34.35608255008308, 31.222735969171367], id: "פרי גן" },
  { name: "לביא", type: "city", center: [35.44179264720352, 32.786450062246914], id: "לביא" },
  { name: "עין המפרץ", type: "city", center: [35.096526052887455, 32.90311595455726], id: "עין המפרץ" },
  { name: "גבעת אבני", type: "city", center: [35.43832038682829, 32.7748241414874], id: "גבעת אבני" },
  { name: "עין עירון", type: "city", center: [35.009612336153374, 32.48379940210986], id: "עין עירון" },
  { name: "צופייה", type: "city", center: [34.73809032480917, 31.854635553549393], id: "צופייה" },
  { name: "תנובות", type: "city", center: [34.96291030482527, 32.30718655970902], id: "תנובות" },
  { name: "שיבולים", type: "city", center: [34.6079121203604, 31.39605186515945], id: "שיבולים" },
  { name: "עיספיא", type: "city", center: [35.064939894697964, 32.71644942655121], id: "עיספיא" },
  { name: "צביה", type: "city", center: [35.33116740372028, 32.889983265445586], id: "צביה" },
  { name: "גבעות בר", type: "city", center: [34.757312249256536, 31.35595061402313], id: "גבעות בר" },
  { name: "ביתר עילית", type: "city", center: [35.11256537617423, 31.698409595051018], id: "ביתר עילית" },
  { name: "אלקוש", type: "city", center: [35.323502748713246, 33.03343786230132], id: "אלקוש" },
  { name: "כעביה טבאש חג'אג'רה", type: "city", center: [35.18121762827812, 32.75141454708017], id: "כעביה טבאש חג'אג'רה" },
  { name: "כפר עבודה", type: "city", center: [34.94060420839972, 32.256259435710284], id: "כפר עבודה" },
  { name: "קדמת צבי", type: "city", center: [35.69745848725263, 33.03020032880379], id: "קדמת צבי" },
  { name: "מבקיעים", type: "city", center: [34.577554448127124, 31.62203180539926], id: "מבקיעים" },
  { name: "תירוש", type: "city", center: [34.886758334355804, 31.750397095172882], id: "תירוש" },
  { name: "דורות", type: "city", center: [34.64546253610338, 31.50651017158241], id: "דורות" },
  { name: "עומר", type: "city", center: [34.84510247536011, 31.27051755019843], id: "עומר" },
  { name: "עין צורים", type: "city", center: [34.71974991366336, 31.69532069235913], id: "עין צורים" },
  { name: "קצר א-סר", type: "city", center: [34.98255801208814, 31.08012828268349], id: "קצר א-סר" },
  { name: "מצליח", type: "city", center: [34.87094791446535, 31.90925366629779], id: "מצליח" },
  { name: "מאור", type: "city", center: [35.00526702312566, 32.42450090472594], id: "מאור" },
  { name: "שילה", type: "city", center: [35.29820159309279, 32.053668011897344], id: "שילה" },
  { name: "יתד", type: "city", center: [34.32718070999689, 31.20736690493904], id: "יתד" },
  { name: "עין ורד", type: "city", center: [34.93156051774842, 32.26431299826363], id: "עין ורד" },
  { name: "פטיש", type: "city", center: [34.55937265123031, 31.327269970597587], id: "פטיש" },
  { name: "כרמים", type: "city", center: [34.91794123569821, 31.332558844657637], id: "כרמים" },
  { name: "שפיים", type: "city", center: [34.82419918331586, 32.217435136786264], id: "שפיים" },
  { name: "עגור", type: "city", center: [34.91180356737106, 31.69713236042251], id: "עגור" },
  { name: "ניר בנים", type: "city", center: [34.75505973686038, 31.67109332015019], id: "ניר בנים" },
  { name: "יעד", type: "city", center: [35.24408815499331, 32.8782079984688], id: "יעד" },
  { name: "ראש צורים", type: "city", center: [35.126616834861565, 31.66775122661227], id: "ראש צורים" },
  { name: "בית הלוי", type: "city", center: [34.932340225401354, 32.35430279570209], id: "בית הלוי" },
  { name: "קרית שמונה", type: "city", center: [35.57534976551438, 33.211974296184216], id: "קרית שמונה" },
  { name: "עין החורש", type: "city", center: [34.93921007340066, 32.3859205771813], id: "עין החורש" },
  { name: "בקעות", type: "city", center: [35.453961288684084, 32.242612393751024], id: "בקעות" },
  { name: "כמון", type: "city", center: [35.36025450641281, 32.911405489829534], id: "כמון" },
  { name: "כפר טרומן", type: "city", center: [34.92437193561545, 31.980678057969868], id: "כפר טרומן" },
  { name: "ח'ואלד", type: "city", center: [35.13658815804804, 32.769831061965306], id: "ח'ואלד" },
  { name: "נווה חריף", type: "city", center: [35.03732730373452, 30.03756846038546], id: "נווה חריף" },
  { name: "בסמת טבעון", type: "city", center: [35.14753766756862, 32.74105084745028], id: "בסמת טבעון" },
  { name: "לוטן", type: "city", center: [35.08785405258196, 29.986442454484983], id: "לוטן" },
  { name: "אורות", type: "city", center: [34.730539428997794, 31.74432580323766], id: "אורות" },
  { name: "גני תקווה", type: "city", center: [34.874516960771984, 32.06131568216397], id: "גני תקווה" },
  { name: "כאוכב אבו אל היגא", type: "city", center: [35.248926676783285, 32.83048492392559], id: "כאוכב אבו אל היגא" },
  { name: "כפר ברוך", type: "city", center: [35.19016519933516, 32.646083065611606], id: "כפר ברוך" },
  { name: "מרחב עם", type: "city", center: [34.828264368428236, 30.88756548774853], id: "מרחב עם" },
  { name: "רגבה", type: "city", center: [35.09915090822523, 32.977875558221776], id: "רגבה" },
  { name: "בית שערים", type: "city", center: [35.177212793605015, 32.69576006734571], id: "בית שערים" },
  { name: "בית קמה", type: "city", center: [34.76147625659632, 31.44742137430561], id: "בית קמה" },
  { name: "חוקוק", type: "city", center: [35.49558134467737, 32.879670280706925], id: "חוקוק" },
  { name: "ניר דוד (תל עמל)", type: "city", center: [35.45647973028261, 32.5043220670561], id: "ניר דוד (תל עמל)" },
  { name: "גיתה", type: "city", center: [35.248824087165545, 32.96719637349672], id: "גיתה" },
  { name: "כפר חיטים", type: "city", center: [35.50277308153788, 32.801332636765174], id: "כפר חיטים" },
  { name: "עין השלושה", type: "city", center: [34.40194665532863, 31.35114463665867], id: "עין השלושה" },
  { name: "חולית", type: "city", center: [34.326624501295015, 31.229893220852073], id: "חולית" },
  { name: "בת עין", type: "city", center: [35.10168191208509, 31.657948312906456], id: "בת עין" },
  { name: "פצאל", type: "city", center: [35.441811981919386, 32.04357627587936], id: "פצאל" },
  { name: "מגידו", type: "city", center: [35.18003247348432, 32.57967122321608], id: "מגידו" },
  { name: "שדה אליעזר", type: "city", center: [35.563931888836535, 33.04493434232811], id: "שדה אליעזר" },
  { name: "פוריידיס", type: "city", center: [34.95668743007068, 32.597689493273926], id: "פוריידיס" },
  { name: "נחושה", type: "city", center: [34.9526855823021, 31.62819243501099], id: "נחושה" },
  { name: "מעלה מכמש", type: "city", center: [35.30631743501522, 31.87913209940998], id: "מעלה מכמש" },
  { name: "כפר שמואל", type: "city", center: [34.93361738275283, 31.889618974838093], id: "כפר שמואל" },
  { name: "הודיות", type: "city", center: [35.43513805452415, 32.78869952489737], id: "הודיות" },
  { name: "מעלות תרשיחא", type: "city", center: [35.27752289181984, 33.01329045253259], id: "מעלות תרשיחא" },
  { name: "יקום", type: "city", center: [34.84195206704887, 32.24943444171316], id: "יקום" },
  { name: "חדיד", type: "city", center: [34.93174957616203, 31.96901266820211], id: "חדיד" },
  { name: "קרית ארבע", type: "city", center: [35.12096435222444, 31.535517157307872], id: "קרית ארבע" },
  { name: "גן השומרון", type: "city", center: [34.99745205586788, 32.461606432938105], id: "גן השומרון" },
  { name: "שכניה", type: "city", center: [35.24674337433379, 32.84828329502809], id: "שכניה" },
  { name: "חופית", type: "city", center: [34.875048882741915, 32.3855530507489], id: "חופית" },
  { name: "באר אורה", type: "city", center: [34.98814437285042, 29.71099217751439], id: "באר אורה" },
  { name: "מתתיהו", type: "city", center: [35.034636848780664, 31.929704978204793], id: "מתתיהו" },
  { name: "שרונה", type: "city", center: [35.46536685552737, 32.72697017986218], id: "שרונה" },
  { name: "אליפז", type: "city", center: [35.0109762006278, 29.79530284485506], id: "אליפז" },
  { name: "גילגל", type: "city", center: [35.44511489278528, 31.999468412970437], id: "גילגל" },
  { name: "רגבים", type: "city", center: [35.03373166194458, 32.52403164956457], id: "רגבים" },
  { name: "לקיה", type: "city", center: [34.86537069089548, 31.3234227290198], id: "לקיה" },
  { name: "ארגמן", type: "city", center: [35.5227500403503, 32.17282077265547], id: "ארגמן" },
  { name: "ביטחה", type: "city", center: [34.63320496827986, 31.334226595986944], id: "ביטחה" },
  { name: "משאבי שדה", type: "city", center: [34.7865088894451, 31.00383455172696], id: "משאבי שדה" },
  { name: "חגי", type: "city", center: [35.08064416701503, 31.492407348967998], id: "חגי" },
  { name: "טורעאן", type: "city", center: [35.37498711830584, 32.77743518083467], id: "טורעאן" },
  { name: "בית יהושע", type: "city", center: [34.86553785940986, 32.26051351575176], id: "בית יהושע" },
  { name: "נצרת עילית", type: "city", center: [35.33211250319533, 32.71194169812585], id: "נצרת עילית" },
  { name: "גילת", type: "city", center: [34.65194071486278, 31.328007220198117], id: "גילת" },
  { name: "רחלים", type: "city", center: [35.25661760624057, 32.102088142784176], id: "רחלים" },
  { name: "הסוללים", type: "city", center: [35.23730031518211, 32.75080513288957], id: "הסוללים" },
  { name: "ברקן", type: "city", center: [35.10522964539995, 32.10894688868784], id: "ברקן" },
  { name: "כאבול", type: "city", center: [35.20861989627288, 32.86977473622232], id: "כאבול" },
  { name: "מוצא עילית", type: "city", center: [35.156744352203916, 31.79386009820199], id: "מוצא עילית" },
  { name: "שורש", type: "city", center: [35.06598598013505, 31.796672624280816], id: "שורש" },
  { name: "כפר חב\"ד", type: "city", center: [34.85038651347644, 31.989452478795638], id: "כפר חב\"ד" },
  { name: "אביחיל", type: "city", center: [34.871354458944566, 32.35040951164709], id: "אביחיל" },
  { name: "בצת", type: "city", center: [35.13533267802122, 33.0705530223777], id: "בצת" },
  { name: "שדה נחום", type: "city", center: [35.48170742735353, 32.52591573757432], id: "שדה נחום" },
  { name: "מעלה גלבוע", type: "city", center: [35.418260799971726, 32.4772851424613], id: "מעלה גלבוע" },
  { name: "אילות", type: "city", center: [34.963336845352785, 29.580897558651063], id: "אילות" },
  { name: "גבעת ניל\"י", type: "city", center: [35.04175319035449, 32.54792172566217], id: "גבעת ניל\"י" },
  { name: "שריגים לי און", type: "city", center: [34.93469238020045, 31.677499881788535], id: "שריגים לי און" },
  { name: "נאות מרדכי", type: "city", center: [35.59702866956002, 33.159938537576444], id: "נאות מרדכי" },
  { name: "שומרה", type: "city", center: [35.28508311954856, 33.084319056038105], id: "שומרה" },
  { name: "החותרים", type: "city", center: [34.95673640075625, 32.75185921707065], id: "החותרים" },
  { name: "משואה", type: "city", center: [35.49081206659303, 32.11267502145108], id: "משואה" },
  { name: "פלך", type: "city", center: [35.22514553612505, 32.933726538010745], id: "פלך" },
  { name: "העוגן", type: "city", center: [34.92393983418081, 32.36191238586558], id: "העוגן" },
  { name: "קטורה", type: "city", center: [35.06109258291893, 29.969408749800753], id: "קטורה" },
  { name: "כינרת", type: "city", center: [35.56408133855904, 32.72235065025058], id: "כינרת" },
  { name: "נעורים", type: "city", center: [34.86207645934234, 32.373947640955976], id: "נעורים" },
  { name: "שדי תרומות", type: "city", center: [35.48561376119516, 32.44061430493911], id: "שדי תרומות" },
  { name: "אשדות יעקב איחוד", type: "city", center: [35.58138066112911, 32.658766424982474], id: "אשדות יעקב איחוד" },
  { name: "גן שורק", type: "city", center: [34.76083419485909, 31.94455265573206], id: "גן שורק" },
  { name: "עין אל-אסד", type: "city", center: [35.39903728971772, 32.940300441824164], id: "עין אל-אסד" },
  { name: "מזור", type: "city", center: [34.92480550216965, 32.05125589774885], id: "מזור" },
  { name: "טמרה", type: "city", center: [35.19142213738457, 32.85244163820139], id: "טמרה" },
  { name: "זרחיה", type: "city", center: [34.74542870574579, 31.68176484522351], id: "זרחיה" },
  { name: "כרכום", type: "city", center: [35.608065610051916, 32.93000376267231], id: "כרכום" },
  { name: "עוזה", type: "city", center: [34.76468619413562, 31.59352568717577], id: "עוזה" },
  { name: "גינוסר", type: "city", center: [35.52337673132212, 32.84758808328823], id: "גינוסר" },
  { name: "גבע בנימין", type: "city", center: [35.27468959207813, 31.84931801514943], id: "גבע בנימין" },
  { name: "מולדת", type: "city", center: [35.43934496924245, 32.58633277391024], id: "מולדת" },
  { name: "נטור", type: "city", center: [35.75292455552135, 32.85419354189364], id: "נטור" },
  { name: "גשור", type: "city", center: [35.718675680652055, 32.8178845059931], id: "גשור" },
  { name: "נהורה", type: "city", center: [34.70532681926168, 31.622522880842343], id: "נהורה" },
  { name: "ידידה", type: "city", center: [35.105451723542146, 31.8130601185194], id: "ידידה" },
  { name: "נתיב הגדוד", type: "city", center: [35.44487876377982, 31.988699194084774], id: "נתיב הגדוד" },
  { name: "ניר ח\"ן", type: "city", center: [34.71501291730761, 31.6090958042079], id: "ניר ח\"ן" },
  { name: "משמר הנגב", type: "city", center: [34.718135992609255, 31.364103517685596], id: "משמר הנגב" },
  { name: "רבדים", type: "city", center: [34.81633173851909, 31.77265697829884], id: "רבדים" },
  { name: "מודיעין עילית", type: "city", center: [35.042541788505766, 31.931014384165664], id: "מודיעין עילית" },
  { name: "אילניה", type: "city", center: [35.40571663113321, 32.76001362411943], id: "אילניה" },
  { name: "גבעת שפירא", type: "city", center: [34.87661724587258, 32.35754730034411], id: "גבעת שפירא" },
  { name: "ניר צבי", type: "city", center: [34.86321617438182, 31.95283336442918], id: "ניר צבי" },
  { name: "ביצרון", type: "city", center: [34.73071124098285, 31.795867015399924], id: "ביצרון" },
  { name: "אבו סנאן", type: "city", center: [35.16924588847669, 32.96110442158748], id: "אבו סנאן" },
  { name: "אביטל", type: "city", center: [35.30727149204902, 32.55698166613929], id: "אביטל" },
  { name: "דן", type: "city", center: [35.65359478200461, 33.23984878485431], id: "דן" },
  { name: "גן יבנה", type: "city", center: [34.70597942735019, 31.786625055162318], id: "גן יבנה" },
  { name: "מבועים", type: "city", center: [34.65571837345746, 31.44892768664797], id: "מבועים" },
  { name: "ברור חיל", type: "city", center: [34.647924498671586, 31.557665193782753], id: "ברור חיל" },
  { name: "אחיטוב", type: "city", center: [34.990005508972295, 32.388415840806836], id: "אחיטוב" },
  { name: "צלפון", type: "city", center: [34.93350875985582, 31.804197265677182], id: "צלפון" },
  { name: "גבעת יואב", type: "city", center: [35.681168334956375, 32.79857318645565], id: "גבעת יואב" },
  { name: "מגדל תפן", type: "city", center: [35.27605070537707, 32.97554623914822], id: "מגדל תפן" },
  { name: "כפר הרא\"ה", type: "city", center: [34.911647661117314, 32.39207809864843], id: "כפר הרא\"ה" },
  { name: "גבעת חיים איחוד", type: "city", center: [34.93205274986177, 32.399234003518956], id: "גבעת חיים איחוד" },
  { name: "משמרות", type: "city", center: [34.984937718580355, 32.48769937842077], id: "משמרות" },
  { name: "נירים", type: "city", center: [34.39489037681112, 31.336882013981064], id: "נירים" },
  { name: "יוטבתה", type: "city", center: [35.060776918533016, 29.895157508681994], id: "יוטבתה" },
  { name: "יד השמונה", type: "city", center: [35.08983633333334, 31.809371333333335], id: "יד השמונה" },
  { name: "הגושרים", type: "city", center: [35.623704102019566, 33.2204651557895], id: "הגושרים" },
  { name: "רוויה", type: "city", center: [35.473216196886476, 32.448500776748226], id: "רוויה" },
  { name: "בארי", type: "city", center: [34.49108431592987, 31.424051156563152], id: "בארי" },
  { name: "יד חנה", type: "city", center: [35.00601026812532, 32.3256472105049], id: "יד חנה" },
  { name: "נעמ\"ה", type: "city", center: [35.467061077356774, 31.906725532903668], id: "נעמ\"ה" },
  { name: "הר גילה", type: "city", center: [35.169289547641036, 31.722841680282507], id: "הר גילה" },
  { name: "רותם", type: "city", center: [35.50790472860169, 32.312779002343866], id: "רותם" },
  { name: "בוקעאתא", type: "city", center: [35.77808243160161, 33.20075375145049], id: "בוקעאתא" },
  { name: "בני דרום", type: "city", center: [34.69255318680451, 31.82053818858891], id: "בני דרום" },
  { name: "עלומים", type: "city", center: [34.51436684407681, 31.453376390370707], id: "עלומים" },
  { name: "בית חנן", type: "city", center: [34.77266934702174, 31.933218575166556], id: "בית חנן" },
  { name: "בית אלפא", type: "city", center: [35.430899294220964, 32.51664731059151], id: "בית אלפא" },
  { name: "גבעת חיים מאוחד", type: "city", center: [34.9301215686417, 32.391635165157766], id: "גבעת חיים מאוחד" },
  { name: "בית קשת", type: "city", center: [35.394929499328164, 32.718175089687186], id: "בית קשת" },
  { name: "מעלה אפרים", type: "city", center: [35.40048344987129, 32.07045734377601], id: "מעלה אפרים" },
  { name: "גני עם", type: "city", center: [34.9010574490949, 32.15127762823457], id: "גני עם" },
  { name: "יגל", type: "city", center: [34.879403036487055, 31.98838396704196], id: "יגל" },
  { name: "חרמש", type: "city", center: [35.11916834253944, 32.423342849172506], id: "חרמש" },
  { name: "ראס אל-עין", type: "city", center: [35.37252499719945, 32.91624866694371], id: "ראס אל-עין" },
  { name: "עלי", type: "city", center: [35.26206659684636, 32.07026534463845], id: "עלי" },
  { name: "עופר", type: "city", center: [34.981559745239224, 32.62212373025583], id: "עופר" },
  { name: "צובה", type: "city", center: [35.11860236970954, 31.783944655749508], id: "צובה" },
  { name: "ינון", type: "city", center: [34.782497132772406, 31.742464206326154], id: "ינון" },
  { name: "תל קציר", type: "city", center: [35.6175502090003, 32.70570845766493], id: "תל קציר" },
  { name: "ראש העין", type: "city", center: [34.96391137054185, 32.092122519781164], id: "ראש העין" },
  { name: "נחם", type: "city", center: [35.004038558688706, 31.766615376042495], id: "נחם" },
  { name: "חרב לאת", type: "city", center: [34.917409108249224, 32.40185184873551], id: "חרב לאת" },
  { name: "מעלה החמישה", type: "city", center: [35.10987109335705, 31.817533973607436], id: "מעלה החמישה" },
  { name: "חנתון", type: "city", center: [35.24510780052121, 32.78409483232871], id: "חנתון" },
  { name: "אל-עריאן", type: "city", center: [35.1252248790803, 32.498014292070046], id: "אל-עריאן" },
  { name: "ציפורי", type: "city", center: [35.27758075454639, 32.74549844479709], id: "ציפורי" },
  { name: "קריית שלמה", type: "city", center: [34.859814847832574, 32.2347701129667], id: "קריית שלמה" },
  { name: "הושעיה", type: "city", center: [35.29203211892165, 32.75830112350665], id: "הושעיה" },
  { name: "יובלים", type: "city", center: [35.27080026456912, 32.878894152079965], id: "יובלים" },
  { name: "כפר יאסיף", type: "city", center: [35.16238763269219, 32.9531772859694], id: "כפר יאסיף" },
  { name: "שבי ציון", type: "city", center: [35.082881542262285, 32.980418062683746], id: "שבי ציון" },
  { name: "גיבתון", type: "city", center: [34.80004077335675, 31.88883269846839], id: "גיבתון" },
  { name: "חורה", type: "city", center: [34.93748703352222, 31.29750871929178], id: "חורה" },
  { name: "כפר מנחם", type: "city", center: [34.83372208488638, 31.72965904964199], id: "כפר מנחם" },
  { name: "תקוע", type: "city", center: [35.22912966439642, 31.65318401502435], id: "תקוע" },
  { name: "גבעת שמואל", type: "city", center: [34.85187490428081, 32.07676731894697], id: "גבעת שמואל" },
  { name: "מנשיית א זבדה", type: "city", center: [35.19352936927748, 32.706064038974056], id: "מנשיית א זבדה" },
  { name: "עינת", type: "city", center: [34.939010606196206, 32.082651038718176], id: "עינת" },
  { name: "מגדל העמק", type: "city", center: [35.238105493172014, 32.67878444109835], id: "מגדל העמק" },
  { name: "תמרת", type: "city", center: [35.22513728257085, 32.7035921992991], id: "תמרת" },
  { name: "מדרשת בן-גוריון", type: "city", center: [34.7828368810647, 30.852393491713247], id: "מדרשת בן-גוריון" },
  { name: "בית שקמה", type: "city", center: [34.60802455418086, 31.638313681045368], id: "בית שקמה" },
  { name: "כרמי צור", type: "city", center: [35.10006765503192, 31.609128651708204], id: "כרמי צור" },
  { name: "אבן שמואל", type: "city", center: [34.76447287624321, 31.575095100469625], id: "אבן שמואל" },
  { name: "כפר סבא", type: "city", center: [34.91423587698998, 32.18121131826222], id: "כפר סבא" },
  { name: "רכסים", type: "city", center: [35.10267960218141, 32.7513133543392], id: "רכסים" },
  { name: "פדיה", type: "city", center: [34.88181833832718, 31.85798024450485], id: "פדיה" },
  { name: "ירושלים", type: "city", center: [35.21095991018203, 31.78157712371831], id: "ירושלים" },
  { name: "עמקה", type: "city", center: [35.16191461632576, 32.97900912259103], id: "עמקה" },
  { name: "ערערה", type: "city", center: [35.092704543153665, 32.500715161237636], id: "ערערה" },
  { name: "מעלה שומרון", type: "city", center: [35.06997259888414, 32.16596359584721], id: "מעלה שומרון" },
  { name: "קבוצת יבנה", type: "city", center: [34.71880751151032, 31.815095926548498], id: "קבוצת יבנה" },
  { name: "ספיר", type: "city", center: [35.184410515345824, 30.613724820092973], id: "ספיר" },
  { name: "פקיעין חדשה", type: "city", center: [35.32290357920214, 32.983496689542406], id: "פקיעין חדשה" },
  { name: "גבעת השלושה", type: "city", center: [34.92122356038172, 32.09830802378724], id: "גבעת השלושה" },
  { name: "כפר מעש", type: "city", center: [34.89003829166433, 32.06421123607523], id: "כפר מעש" },
  { name: "הבונים", type: "city", center: [34.93287178727438, 32.6353296826187], id: "הבונים" },
  { name: "מחסיה", type: "city", center: [35.00709659165749, 31.74838448133329], id: "מחסיה" },
  { name: "עין קיניא", type: "city", center: [35.73155472216679, 33.23580317904769], id: "עין קיניא" },
  { name: "מצפה אבי\"ב", type: "city", center: [35.2018496163323, 32.83549907752777], id: "מצפה אבי\"ב" },
  { name: "מלכישוע", type: "city", center: [35.41511685325196, 32.43781838529403], id: "מלכישוע" },
  { name: "שדה דוד", type: "city", center: [34.68340984663576, 31.57636244182077], id: "שדה דוד" },
  { name: "יובל", type: "city", center: [35.5975394854178, 33.24657168821446], id: "יובל" },
  { name: "אחיהוד", type: "city", center: [35.17401816422112, 32.90706326829442], id: "אחיהוד" },
  { name: "גני הדר", type: "city", center: [34.85397938365494, 31.87847911032133], id: "גני הדר" },
  { name: "גשר הזיו", type: "city", center: [35.11158182972545, 33.039721071903706], id: "גשר הזיו" },
  { name: "אלוני הבשן", type: "city", center: [35.83882188864651, 33.04413402773165], id: "אלוני הבשן" },
  { name: "ברקת", type: "city", center: [34.94503010471809, 32.01513892394206], id: "ברקת" },
  { name: "מסילת ציון", type: "city", center: [35.011162638098455, 31.801572414160376], id: "מסילת ציון" },
  { name: "ירוחם", type: "city", center: [34.923988692511124, 30.987153428091013], id: "ירוחם" },
  { name: "שובה", type: "city", center: [34.54437261412555, 31.451919107622334], id: "שובה" },
  { name: "ישרש", type: "city", center: [34.848367710495985, 31.914937950936796], id: "ישרש" },
  { name: "נטעים", type: "city", center: [34.77510599686858, 31.943972569863867], id: "נטעים" },
  { name: "תקומה", type: "city", center: [34.57941855238474, 31.45174171748643], id: "תקומה" },
  { name: "כפר קאסם", type: "city", center: [34.97639637265854, 32.114366190667006], id: "כפר קאסם" },
  { name: "יד בנימין", type: "city", center: [34.82196949796093, 31.797189027882553], id: "יד בנימין" },
  { name: "גימזו", type: "city", center: [34.94099853995086, 31.927668842351004], id: "גימזו" },
  { name: "חבר", type: "city", center: [35.26447771254967, 32.547728054969475], id: "חבר" },
  { name: "עין העמק", type: "city", center: [35.08535790654114, 32.628445977525196], id: "עין העמק" },
  { name: "נווה שלום", type: "city", center: [34.978552893069775, 31.817910181956723], id: "נווה שלום" },
  { name: "נאות הכיכר", type: "city", center: [35.37412870316277, 30.938872209942673], id: "נאות הכיכר" },


  { name: "כפר גנים ב, פתח תקווה", type: "neighborhood", center: [34.883465096394545, 32.074674156190916], id: "פתח תקווה/כפר גנים ב" },
  { name: "מעוז, כפר סבא", type: "neighborhood", center: [34.90177412592806, 32.18223130062733], id: "כפר סבא/מעוז" },
  { name: "2003, רעננה", type: "neighborhood", center: [34.863542960684235, 32.19044457704066], id: "רעננה/2003" },
  { name: "רחובות החדשה, רחובות", type: "neighborhood", center: [34.81266576680646, 31.879773679721346], id: "רחובות/רחובות החדשה" },
  { name: "דרומית, נצרת עילית", type: "neighborhood", center: [35.31691229955625, 32.69529816693886], id: "נצרת עילית/דרומית" },
  { name: "הנוטר, אבן יהודה", type: "neighborhood", center: [34.884142435061165, 32.27659774481383], id: "אבן יהודה/הנוטר" },
  { name: "רמת בית הכרם, ירושלים", type: "neighborhood", center: [35.191028615641116, 31.771546569031067], id: "ירושלים/רמת בית הכרם" },
  { name: "מרכז העיר ג', רמת גן", type: "neighborhood", center: [34.817953291320244, 32.08632003716137], id: "רמת גן/מרכז העיר ג'" },
  { name: "מצפה אפק, ראש העין", type: "neighborhood", center: [34.957005873234394, 32.08728331431409], id: "ראש העין/מצפה אפק" },
  { name: "נווה עמית, רחובות", type: "neighborhood", center: [34.800186025018384, 31.909982161343727], id: "רחובות/נווה עמית" },
  { name: "צמרות המושבה, באר יעקב", type: "neighborhood", center: [34.82733303450469, 31.93852544451676], id: "באר יעקב/צמרות המושבה" },
  { name: "שיכון עמידר, אור יהודה", type: "neighborhood", center: [34.85177496457212, 32.02620494820469], id: "אור יהודה/שיכון עמידר" },
  { name: "צור שלום, קרית ביאליק", type: "neighborhood", center: [35.097078666310814, 32.85516100750624], id: "קרית ביאליק/צור שלום" },
  { name: "גיורא, טירת הכרמל", type: "neighborhood", center: [34.97075349314278, 32.75445196464006], id: "טירת הכרמל/גיורא" },
  { name: "לב תל אביב, תל אביב יפו", type: "neighborhood", center: [34.77715208503914, 32.068206510230965], id: "תל אביב יפו/לב תל אביב" },
  { name: "רובע יז, אשדוד", type: "neighborhood", center: [34.62461708255791, 31.76788254158065], id: "אשדוד/רובע יז" },
  { name: "פרדס כץ ויסודות, בני ברק", type: "neighborhood", center: [34.83601089143687, 32.09462718501365], id: "בני ברק/פרדס כץ ויסודות" },
  { name: "גן אברהם, קרית ים", type: "neighborhood", center: [35.079237378985304, 32.848768288743166], id: "קרית ים/גן אברהם" },
  { name: "רמת ויזניץ, חיפה", type: "neighborhood", center: [35.00278027599155, 32.798841344267586], id: "חיפה/רמת ויזניץ" },
  { name: "מרום כנען איביקור, צפת", type: "neighborhood", center: [35.50936530113714, 32.98475530707475], id: "צפת/מרום כנען איביקור" },
  { name: "יצחק רבין, צפת", type: "neighborhood", center: [35.51222010265453, 32.963155340310855], id: "צפת/יצחק רבין" },
  { name: "'גבעת אולגה א' ו-ב', חדרה", type: "neighborhood", center: [34.88615951884156, 32.44158908257772], id: "חדרה/'גבעת אולגה א' ו-ב'" },
  { name: "אזור התעשייה רמת גבריאל, מגדל העמק", type: "neighborhood", center: [35.250218949928765, 32.687209527598924], id: "מגדל העמק/אזור התעשייה רמת גבריאל" },
  { name: "קרית אליעזר, חיפה", type: "neighborhood", center: [34.97899015388164, 32.82589241308067], id: "חיפה/קרית אליעזר" },
  { name: "שכונת הורדים, סח'נין", type: "neighborhood", center: [35.28583037567353, 32.86080869500756], id: "סח'נין/שכונת הורדים" },
  { name: "שיכון גיורא, הוד השרון", type: "neighborhood", center: [34.87709526734821, 32.152924368319816], id: "הוד השרון/שיכון גיורא" },
  { name: "פסגות אפק, ראש העין", type: "neighborhood", center: [34.97738425410989, 32.081360268038445], id: "ראש העין/פסגות אפק" },
  { name: "גני אביב, לוד", type: "neighborhood", center: [34.88019303560029, 31.959964951622474], id: "לוד/גני אביב" },
  { name: "כוכב הצפון, תל אביב יפו", type: "neighborhood", center: [34.7877109336721, 32.10090127387748], id: "תל אביב יפו/כוכב הצפון" },
  { name: "נוף כינרת, צפת", type: "neighborhood", center: [35.528972547032595, 32.95034186227822], id: "צפת/נוף כינרת" },
  { name: "נאות הללי, דימונה", type: "neighborhood", center: [35.03615846131908, 31.07845891135636], id: "דימונה/נאות הללי" },
  { name: "גיורא, רמלה", type: "neighborhood", center: [34.85655511895469, 31.934361077529815], id: "רמלה/גיורא" },
  { name: "מתחם האלף, ראשון לציון", type: "neighborhood", center: [34.750024650477506, 31.98527777336605], id: "ראשון לציון/מתחם האלף" },
  { name: "עיר גנים ב',  ג',ירושלים", type: "neighborhood", center: [35.168572681240136, 31.754029313754923], id: "ירושלים/עיר גנים ב',  ג'" },
  { name: "קרית הרב סלומון, פתח תקווה", type: "neighborhood", center: [34.898365536203116, 32.098612182547136], id: "פתח תקווה/קרית הרב סלומון" },
  { name: "גבעת הדגן, אפרת", type: "neighborhood", center: [35.172675997253506, 31.68165722484646], id: "אפרת/גבעת הדגן" },
  { name: "פרדס רייספלד, קרית אונו", type: "neighborhood", center: [34.85611191263664, 32.05629888672252], id: "קרית אונו/פרדס רייספלד" },
  { name: "קרית גנים, רעננה", type: "neighborhood", center: [34.85730621794011, 32.18941778900179], id: "רעננה/קרית גנים" },
  { name: "ביל\"ו סנטר ועקרון 2000, קרית עקרון", type: "neighborhood", center: [34.814720465540425, 31.862400137240144], id: "קרית עקרון/ביל\"ו סנטר ועקרון 2000" },
  { name: "הגוש הגדול, תל אביב יפו", type: "neighborhood", center: [34.78774306455421, 32.115420923688866], id: "תל אביב יפו/הגוש הגדול" },
  { name: "בימת תל חי / מורדות הרי נפתלי, קרית שמונה", type: "neighborhood", center: [35.5703996788105, 33.226080940413645], id: "קרית שמונה/בימת תל חי ; מורדות הרי נפתלי" },
  { name: "החוצבים, מבשרת ציון", type: "neighborhood", center: [35.14264011187858, 31.78930693526628], id: "מבשרת ציון/החוצבים" },
  { name: "קרית מנחם, נתיבות", type: "neighborhood", center: [34.579303083369176, 31.419456323125047], id: "נתיבות/קרית מנחם" },
  { name: "פרדס הגדוד, נתניה", type: "neighborhood", center: [34.864893685226384, 32.34382297547823], id: "נתניה/פרדס הגדוד" },
  { name: "רמת שלמה, ירושלים", type: "neighborhood", center: [35.21828068936721, 31.810973908963575], id: "ירושלים/רמת שלמה" },
  { name: "השבטים(בוכמן צפון), מודיעין מכבים רעות", type: "neighborhood", center: [35.0033139408238, 31.88752965489603], id: "מודיעין מכבים רעות/השבטים(בוכמן צפון)" },
  { name: "שיכון עליה, כפר סבא", type: "neighborhood", center: [34.89897356400616, 32.1908494078777], id: "כפר סבא/שיכון עליה" },
  { name: "צה\"ל, ראש העין", type: "neighborhood", center: [34.94856729120839, 32.101024095348635], id: "ראש העין/צה\"ל" },
  { name: "כרמל ותיק, חיפה", type: "neighborhood", center: [34.97873158060981, 32.80326132019678], id: "חיפה/כרמל ותיק" },
  { name: "בן גוריון כנען, צפת", type: "neighborhood", center: [35.50405048896904, 32.97006599135509], id: "צפת/בן גוריון כנען" },
  { name: "אגרובנק, חולון", type: "neighborhood", center: [34.778882315040995, 32.025232783267214], id: "חולון/אגרובנק" },
  { name: "מעוז ציון א', מבשרת ציון", type: "neighborhood", center: [35.14845303114575, 31.79173439736716], id: "מבשרת ציון/מעוז ציון א'" },
  { name: "יפה נוף וגני דן, רמלה", type: "neighborhood", center: [34.85144171933038, 31.92563329578184], id: "רמלה/יפה נוף וגני דן" },
  { name: "אזור תעשיה ברוש, בית שמש", type: "neighborhood", center: [34.95653620698505, 31.734694022982666], id: "בית שמש/אזור תעשיה ברוש" },
  { name: "רמות, ירושלים", type: "neighborhood", center: [35.19694145903092, 31.816018751156825], id: "ירושלים/רמות" },
  { name: "צמרת גנים, פתח תקווה", type: "neighborhood", center: [34.90124984053641, 32.07980048278033], id: "פתח תקווה/צמרת גנים" },
  { name: "רימון, קרית אונו", type: "neighborhood", center: [34.86677948204565, 32.04796105013598], id: "קרית אונו/רימון" },
  { name: "רסקו, פרדס חנה כרכור", type: "neighborhood", center: [34.973083585984405, 32.48088072003802], id: "פרדס חנה כרכור/רסקו" },
  { name: "קרית שטנר, קרית אתא", type: "neighborhood", center: [35.08456839257549, 32.80083345296046], id: "קרית אתא/קרית שטנר" },
  { name: "בקמה, אשדוד", type: "neighborhood", center: [34.65434013633999, 31.810821292464542], id: "אשדוד/בקמה" },
  { name: "מרכז יפו, תל אביב יפו", type: "neighborhood", center: [34.758188924036645, 32.04760715892832], id: "תל אביב יפו/מרכז יפו" },
  { name: "נוף ים, אור עקיבא", type: "neighborhood", center: [34.917099978755196, 32.50391683178539], id: "אור עקיבא/נוף ים" },
  { name: "מרכז העיר מזרח, ירושלים", type: "neighborhood", center: [35.22891899404066, 31.78566587217343], id: "ירושלים/מרכז העיר מזרח" },
  { name: "קיט ושיט - שכונת המשולשים, קיסריה", type: "neighborhood", center: [34.89179219742573, 32.48231097175815], id: "קיסריה/קיט ושיט - שכונת המשולשים" },
  { name: "עמידר, גן יבנה", type: "neighborhood", center: [34.69826131681761, 31.78198828596991], id: "גן יבנה/עמידר" },
  { name: "לבאות, ערד", type: "neighborhood", center: [35.21219336505956, 31.260897628791255], id: "ערד/לבאות" },
  { name: "ערמונים, רמת גן", type: "neighborhood", center: [34.83460776271935, 32.062769303819664], id: "רמת גן/ערמונים" },
  { name: "גבעת טל, ראש העין", type: "neighborhood", center: [34.96489450854006, 32.09475555157921], id: "ראש העין/גבעת טל" },
  { name: "מחנה יהודה, פתח תקווה", type: "neighborhood", center: [34.89537374086287, 32.07967590296111], id: "פתח תקווה/מחנה יהודה" },
  { name: "קריית יוסף, גבעתיים", type: "neighborhood", center: [34.80397044581709, 32.07596677098952], id: "גבעתיים/קריית יוסף" },
  { name: "נוף ים, הרצליה", type: "neighborhood", center: [34.8103224667143, 32.18886077155858], id: "הרצליה/נוף ים" },
  { name: "נווה מדבר,  יעלים,אילת", type: "neighborhood", center: [34.95341136794199, 29.566226397777783], id: "אילת/נווה מדבר,  יעלים" },
  { name: "הרכבת,  פרדס שניר,לוד", type: "neighborhood", center: [34.87734513233202, 31.95371486349405], id: "לוד/הרכבת,  פרדס שניר" },
  { name: "מעלה השמורה, כפר ורדים", type: "neighborhood", center: [35.259033070378166, 33.00033705110017], id: "כפר ורדים/מעלה השמורה" },
  { name: "רכס חלילים, מבשרת ציון", type: "neighborhood", center: [35.161752151662505, 31.80759438275605], id: "מבשרת ציון/רכס חלילים" },
  { name: "הקוממיות, קרית גת", type: "neighborhood", center: [34.75950452489784, 31.609616398285983], id: "קרית גת/הקוממיות" },
  { name: "אלוונסה, דאלית אל-כרמל", type: "neighborhood", center: [35.041396755461804, 32.70332426471709], id: "דאלית אל-כרמל/אלוונסה" },
  { name: "שיכון ותיקים, חולון", type: "neighborhood", center: [34.76756801375156, 32.0074277950195], id: "חולון/שיכון ותיקים" },
  { name: "רובע גמלא, קצרין", type: "neighborhood", center: [35.6876079060057, 32.992315114022745], id: "קצרין/רובע גמלא" },
  { name: "רוממה הישנה, חיפה", type: "neighborhood", center: [34.997493390000734, 32.789449278083396], id: "חיפה/רוממה הישנה" },
  { name: "בית\"ר, חדרה", type: "neighborhood", center: [34.9069173225534, 32.43689646618603], id: "חדרה/בית\"ר" },
  { name: "אפולוניה, הרצליה", type: "neighborhood", center: [34.81345674417165, 32.19403537701884], id: "הרצליה/אפולוניה" },
  { name: "נוה יעקב, ירושלים", type: "neighborhood", center: [35.24230742853634, 31.840597161908274], id: "ירושלים/נוה יעקב" },
  { name: "נווה נוף, ירוחם", type: "neighborhood", center: [34.913058943779376, 30.98793261370761], id: "ירוחם/נווה נוף" },
  { name: "מרכז העיר, בית שמש", type: "neighborhood", center: [34.98701235088328, 31.750173432721976], id: "בית שמש/מרכז העיר" },
  { name: "סנסן, צור הדסה", type: "neighborhood", center: [35.10051224920096, 31.712062361781587], id: "צור הדסה/סנסן" },
  { name: "נוף הדר, כפר יונה", type: "neighborhood", center: [34.93948495131431, 32.31169628490792], id: "כפר יונה/נוף הדר" },
  { name: "נרקיסים, ראשון לציון", type: "neighborhood", center: [34.831832100174466, 31.959393634888404], id: "ראשון לציון/נרקיסים" },
  { name: "שיכון דרום, באר שבע", type: "neighborhood", center: [34.78281618392272, 31.237526689072354], id: "באר שבע/שיכון דרום" },
  { name: "הנחל, פרדס חנה כרכור", type: "neighborhood", center: [34.99512381942467, 32.46856901387016], id: "פרדס חנה כרכור/הנחל" },
  { name: "מתחם 200, הוד השרון", type: "neighborhood", center: [34.90949591634402, 32.16459794485122], id: "הוד השרון/מתחם 200" },
  { name: "רמת בית שמש א', בית שמש", type: "neighborhood", center: [34.99560278832134, 31.71366577975677], id: "בית שמש/רמת בית שמש א'" },
  { name: "דרום מזרחית, עילוט", type: "neighborhood", center: [35.26622786364923, 32.71271059710525], id: "עילוט/דרום מזרחית" },
  { name: "לבונה, כפר ורדים", type: "neighborhood", center: [35.264806104784874, 32.99265684225712], id: "כפר ורדים/לבונה" },
  { name: "שיכון עממי, פרדס חנה כרכור", type: "neighborhood", center: [34.98456136463381, 32.47621271954293], id: "פרדס חנה כרכור/שיכון עממי" },
  { name: "גדרה הצעירה, גדרה", type: "neighborhood", center: [34.786154429423355, 31.802435864669203], id: "גדרה/גדרה הצעירה" },
  { name: "פרדס בחסכון, אור יהודה", type: "neighborhood", center: [34.85771319042647, 32.037199950797174], id: "אור יהודה/פרדס בחסכון" },
  { name: "עיר ימים, נתניה", type: "neighborhood", center: [34.84064634684973, 32.27792007132582], id: "נתניה/עיר ימים" },
  { name: "הדר עליון, חיפה", type: "neighborhood", center: [34.992934193954376, 32.806609335911], id: "חיפה/הדר עליון" },
  { name: "סביוני הכפר, כפר סבא", type: "neighborhood", center: [34.8975775433331, 32.18584786293837], id: "כפר סבא/סביוני הכפר" },
  { name: "הצפון הישן החלק המרכזי, תל אביב יפו", type: "neighborhood", center: [34.77675678985237, 32.08613522482491], id: "תל אביב יפו/הצפון הישן החלק המרכזי" },
  { name: "משולש עין כרמל, עתלית", type: "neighborhood", center: [34.94647146284774, 32.687237039469395], id: "עתלית/משולש עין כרמל" },
  { name: "מגד, פרדס חנה כרכור", type: "neighborhood", center: [34.97182539325724, 32.46378228604812], id: "פרדס חנה כרכור/מגד" },
  { name: "אור עקיבא מזרח, אור עקיבא", type: "neighborhood", center: [34.92689095794439, 32.51632660929427], id: "אור עקיבא/אור עקיבא מזרח" },
  { name: "שיכון מזרחי, כפר סבא", type: "neighborhood", center: [34.90857131387967, 32.18218195414101], id: "כפר סבא/שיכון מזרחי" },
  { name: "גלילות, תל אביב יפו", type: "neighborhood", center: [34.79450296623605, 32.141230157584246], id: "תל אביב יפו/גלילות" },
  { name: "הדר גנים, פתח תקווה", type: "neighborhood", center: [34.90673540458192, 32.074204812348725], id: "פתח תקווה/הדר גנים" },
  { name: "אחוזת דניה, חדרה", type: "neighborhood", center: [34.94086252998815, 32.43517379022882], id: "חדרה/אחוזת דניה" },
  { name: "שכונה ב, באר שבע", type: "neighborhood", center: [34.79297570359293, 31.255107116051754], id: "באר שבע/שכונה ב" },
  { name: "כפר שלם, תל אביב יפו", type: "neighborhood", center: [34.80525883753611, 32.050802344797205], id: "תל אביב יפו/כפר שלם" },
  { name: "הקריה, אשדוד", type: "neighborhood", center: [34.63848489190104, 31.7931324821779], id: "אשדוד/הקריה" },
  { name: "רמת אהרון, בני ברק", type: "neighborhood", center: [34.84025202080228, 32.084014300617575], id: "בני ברק/רמת אהרון" },
  { name: "שכונה 10 - המעוף, קיסריה", type: "neighborhood", center: [34.90897379107047, 32.52858336287349], id: "קיסריה/שכונה 10 - המעוף" },
  { name: "קרית ראשון, ראשון לציון", type: "neighborhood", center: [34.784060499660406, 31.972595327702948], id: "ראשון לציון/קרית ראשון" },
  { name: "מרמורק, רחובות", type: "neighborhood", center: [34.80394428047053, 31.885469069868535], id: "רחובות/מרמורק" },
  { name: "שפרינצק, רמלה", type: "neighborhood", center: [34.86909507658932, 31.9351990562725], id: "רמלה/שפרינצק" },
  { name: "רמת מנחם, הוד השרון", type: "neighborhood", center: [34.8898169504941, 32.1419525342202], id: "הוד השרון/רמת מנחם" },
  { name: "רצועת המלונות, נתניה", type: "neighborhood", center: [34.85011063726824, 32.33045047178167], id: "נתניה/רצועת המלונות" },
  { name: "תל יהודה, רמת גן", type: "neighborhood", center: [34.82163845810797, 32.07257858135813], id: "רמת גן/תל יהודה" },
  { name: "הנצחון, דימונה", type: "neighborhood", center: [35.030607199757426, 31.069709237644336], id: "דימונה/הנצחון" },
  { name: "נוה יוסף, חיפה", type: "neighborhood", center: [35.020247852013725, 32.79198850337151], id: "חיפה/נוה יוסף" },
  { name: "מערב כפר יונה, כפר יונה", type: "neighborhood", center: [34.91709454911768, 32.31948134016895], id: "כפר יונה/מערב כפר יונה" },
  { name: "נחלת מערב, ראשון לציון", type: "neighborhood", center: [34.79594790045015, 31.979151948827802], id: "ראשון לציון/נחלת מערב" },
  { name: "נוף שאן, בית שאן", type: "neighborhood", center: [35.49027239341016, 32.50128177415777], id: "בית שאן/נוף שאן" },
  { name: "נאות הדקל, שדרות", type: "neighborhood", center: [34.59802308808282, 31.520566545522787], id: "שדרות/נאות הדקל" },
  { name: "שיכון ד' (רמת אגוז), טבריה", type: "neighborhood", center: [35.51239410087105, 32.77737284829867], id: "טבריה/שיכון ד' (רמת אגוז)" },
  { name: "בן גוריון, רמלה", type: "neighborhood", center: [34.8603105319123, 31.923444276996133], id: "רמלה/בן גוריון" },
  { name: "נאות שושנים, חולון", type: "neighborhood", center: [34.78073941856579, 32.01546385061045], id: "חולון/נאות שושנים" },
  { name: "שבעת המינים, דימונה", type: "neighborhood", center: [35.02684089389284, 31.064880168107596], id: "דימונה/שבעת המינים" },
  { name: "גבעולים, שוהם", type: "neighborhood", center: [34.941766357575375, 32.003492678780766], id: "שוהם/גבעולים" },
  { name: "הרקפות, ראשון לציון", type: "neighborhood", center: [34.82958562314495, 31.948070068610733], id: "ראשון לציון/הרקפות" },
  { name: "כפר סבא הצעירה, כפר סבא", type: "neighborhood", center: [34.92723429430842, 32.18627269373656], id: "כפר סבא/כפר סבא הצעירה" },
  { name: "הרעפים, ירוחם", type: "neighborhood", center: [34.93384092839266, 30.98103872368805], id: "ירוחם/הרעפים" },
  { name: "בר אילן, רמת גן", type: "neighborhood", center: [34.84487943581433, 32.0700330069815], id: "רמת גן/בר אילן" },
  { name: "גבעת הרקפות, קרית ביאליק", type: "neighborhood", center: [35.102801183266315, 32.83406937145155], id: "קרית ביאליק/גבעת הרקפות" },
  { name: "הערבה, כרמיאל", type: "neighborhood", center: [35.29760238624807, 32.905567754350784], id: "כרמיאל/הערבה" },
  { name: "רמת הדקלים-בית וקאנטרי, גבעת שמואל", type: "neighborhood", center: [34.85021202469094, 32.08398372183458], id: "גבעת שמואל/רמת הדקלים-בית וקאנטרי" },
  { name: "חצבים, ערד", type: "neighborhood", center: [35.21974491060129, 31.25876398425341], id: "ערד/חצבים" },
  { name: "פרדסיה צפון, פרדסיה", type: "neighborhood", center: [34.90939467983509, 32.31146510545868], id: "פרדסיה/פרדסיה צפון" },
  { name: "רצועת הנופש מזרח, רמת השרון", type: "neighborhood", center: [34.84201390086179, 32.12631241829746], id: "רמת השרון/רצועת הנופש מזרח" },
  { name: "פארק המושבה, באר יעקב", type: "neighborhood", center: [34.83310674993344, 31.9395010740312], id: "באר יעקב/פארק המושבה" },
  { name: "גבעת אשכול, כפר סבא", type: "neighborhood", center: [34.938528531605584, 32.18060899072365], id: "כפר סבא/גבעת אשכול" },
  { name: "נוה שלמה, גדרה", type: "neighborhood", center: [34.78684228794118, 31.81379580070285], id: "גדרה/נוה שלמה" },
  { name: "וייצמן, קרית מלאכי", type: "neighborhood", center: [34.74004800970526, 31.732498750325295], id: "קרית מלאכי/וייצמן" },
  { name: "צה\"ל, קרית גת", type: "neighborhood", center: [34.754302768811044, 31.606500795968422], id: "קרית גת/צה\"ל" },
  { name: "שמורת מליבו, נס ציונה", type: "neighborhood", center: [34.78275811148601, 31.924414469144512], id: "נס ציונה/שמורת מליבו" },
  { name: "אזור התעשייה הדרומי, אור יהודה", type: "neighborhood", center: [34.86051432375529, 32.02194830133732], id: "אור יהודה/אזור התעשייה הדרומי" },
  { name: "אזור תעשיה דרומי, קרית שמונה", type: "neighborhood", center: [35.5733695036442, 33.19575653231594], id: "קרית שמונה/אזור תעשיה דרומי" },
  { name: "נאות יצחק, לוד", type: "neighborhood", center: [34.890115887509495, 31.934452376016555], id: "לוד/נאות יצחק" },
  { name: "הדרים, כפר סבא", type: "neighborhood", center: [34.93294890999266, 32.180542943903944], id: "כפר סבא/הדרים" },
  { name: "אזור תעשייה זעירה, גדרה", type: "neighborhood", center: [34.779451462340724, 31.817786127800144], id: "גדרה/אזור תעשייה זעירה" },
  { name: "יפה נוף, גני תקווה", type: "neighborhood", center: [34.88308488412374, 32.061442668328326], id: "גני תקווה/יפה נוף" },
  { name: "שיכון מזרחי, רמת גן", type: "neighborhood", center: [34.830582724896665, 32.0666791131478], id: "רמת גן/שיכון מזרחי" },
  { name: "נווה גן, גן יבנה", type: "neighborhood", center: [34.7089826414451, 31.777332948302387], id: "גן יבנה/נווה גן" },
  { name: "נופש, גדרה", type: "neighborhood", center: [34.78259498193183, 31.809449406426477], id: "גדרה/נופש" },
  { name: "בן גוריון ב', שדרות", type: "neighborhood", center: [34.6052012503294, 31.532959273516802], id: "שדרות/בן גוריון ב'" },
  { name: "קרית דוד אלעזר, פתח תקווה", type: "neighborhood", center: [34.90452077346311, 32.084886456271384], id: "פתח תקווה/קרית דוד אלעזר" },
  { name: "מתחם מ-6, אשקלון", type: "neighborhood", center: [34.60142901174162, 31.697214776849584], id: "אשקלון/מתחם מ-6" },
  { name: "משכנות זבולון, נתניה", type: "neighborhood", center: [34.87692812483464, 32.30887541878658], id: "נתניה/משכנות זבולון" },
  { name: "טלביה, ירושלים", type: "neighborhood", center: [35.21998083521424, 31.772109073959854], id: "ירושלים/טלביה" },
  { name: "רמת בית שמש ד 3, בית שמש", type: "neighborhood", center: [34.970121314811315, 31.700649878567063], id: "בית שמש/רמת בית שמש ד 3" },
  { name: "מאור חיים, צפת", type: "neighborhood", center: [35.48979480282005, 32.963028945496056], id: "צפת/מאור חיים" },
  { name: "נאות הפסגה, מודיעין עילית", type: "neighborhood", center: [35.05083228522223, 31.930981144997755], id: "מודיעין עילית/נאות הפסגה" },
  { name: "אחוזה ירוקה, פרדס חנה כרכור", type: "neighborhood", center: [35.001794809400394, 32.47403814156751], id: "פרדס חנה כרכור/אחוזה ירוקה" },
  { name: "חורשים, ראש העין", type: "neighborhood", center: [34.94628911386512, 32.10665804205344], id: "ראש העין/חורשים" },
  { name: "הותיקה, גדרה", type: "neighborhood", center: [34.78002787546203, 31.81093469357478], id: "גדרה/הותיקה" },
  { name: "אזור תעשיה טירת הכרמל, טירת הכרמל", type: "neighborhood", center: [34.968844273471106, 32.77030657852482], id: "טירת הכרמל/אזור תעשיה טירת הכרמל" },
  { name: "גן רש\"ל, הרצליה", type: "neighborhood", center: [34.84685307115431, 32.17819506421506], id: "הרצליה/גן רש\"ל" },
  { name: "יעלים, שוהם", type: "neighborhood", center: [34.95017685745855, 31.999888318099355], id: "שוהם/יעלים" },
  { name: "תלמי מנשה הצעירה, באר יעקב", type: "neighborhood", center: [34.84380870826675, 31.941807148266445], id: "באר יעקב/תלמי מנשה הצעירה" },
  { name: "אזור ב (הירדן), קרית שמונה", type: "neighborhood", center: [35.57376333202826, 33.20479910205119], id: "קרית שמונה/אזור ב (הירדן)" },
  { name: "הבורסה, רמת גן", type: "neighborhood", center: [34.80287636598936, 32.08527759501829], id: "רמת גן/הבורסה" },
  { name: "שער העליה, חיפה", type: "neighborhood", center: [34.957852668814176, 32.816501538351474], id: "חיפה/שער העליה" },
  { name: "נאות יצחק רבין, רמלה", type: "neighborhood", center: [34.85586104191599, 31.921501474911736], id: "רמלה/נאות יצחק רבין" },
  { name: "הפרחים (מירומי), מודיעין מכבים רעות", type: "neighborhood", center: [35.01256461918655, 31.906575138300198], id: "מודיעין מכבים רעות/הפרחים (מירומי)" },
  { name: "צמרות, ראשון לציון", type: "neighborhood", center: [34.828347763594884, 31.956294203481832], id: "ראשון לציון/צמרות" },
  { name: "תל גיבורים, בני ברק", type: "neighborhood", center: [34.825909002608704, 32.092389132395716], id: "בני ברק/תל גיבורים" },
  { name: "צפון מזרח העיר, רחובות", type: "neighborhood", center: [34.81461958525968, 31.9016960065585], id: "רחובות/צפון מזרח העיר" },
  { name: "עבאס, חיפה", type: "neighborhood", center: [34.98638368735314, 32.81523583497667], id: "חיפה/עבאס" },
  { name: "בית חולים סורוקה, באר שבע", type: "neighborhood", center: [34.80134107653315, 31.25883617068422], id: "באר שבע/בית חולים סורוקה" },
  { name: "חפציבה, בית שמש", type: "neighborhood", center: [34.999485026836325, 31.747377575736046], id: "בית שמש/חפציבה" },
  { name: "קרית ים ב, קרית ים", type: "neighborhood", center: [35.06643930468888, 32.84555005978125], id: "קרית ים/קרית ים ב" },
  { name: "אברמסקי, קרית אתא", type: "neighborhood", center: [35.101899020242946, 32.8096514773242], id: "קרית אתא/אברמסקי" },
  { name: "נווה דקלים, פתח תקווה", type: "neighborhood", center: [34.890619906307485, 32.099369148674725], id: "פתח תקווה/נווה דקלים" },
  { name: "נאות פרס, חיפה", type: "neighborhood", center: [34.96901698556227, 32.78406117431097], id: "חיפה/נאות פרס" },
  { name: "גנים ב, אילת", type: "neighborhood", center: [34.94637633862607, 29.547512013858718], id: "אילת/גנים ב" },
  { name: "שכונה 1, קיסריה", type: "neighborhood", center: [34.909709739287734, 32.521191805157045], id: "קיסריה/שכונה 1" },
  { name: "עורף הנמל, אשדוד", type: "neighborhood", center: [34.64745233448352, 31.825448814212006], id: "אשדוד/עורף הנמל" },
  { name: "מורדות גוראל, חיפה", type: "neighborhood", center: [34.9758578874711, 32.774269700693104], id: "חיפה/מורדות גוראל" },
  { name: "שכונה א, מבשרת ציון", type: "neighborhood", center: [35.143703789858066, 31.801385838391802], id: "מבשרת ציון/שכונה א" },
  { name: "אזור ג, קרית שמונה", type: "neighborhood", center: [35.56696966829093, 33.20618993747599], id: "קרית שמונה/אזור ג" },
  { name: "החורש, חריש", type: "neighborhood", center: [35.041178559653126, 32.460453537488654], id: "חריש/החורש" },
  { name: "אור המזרח, חריש", type: "neighborhood", center: [35.05981968276099, 32.45467611947907], id: "חריש/אור המזרח" },
  { name: "יערת העמק, מגדל העמק", type: "neighborhood", center: [35.24036841914731, 32.680940100093125], id: "מגדל העמק/יערת העמק" },
  { name: "דרום פלורנטין, תל אביב יפו", type: "neighborhood", center: [34.770135123303334, 32.05310283477182], id: "תל אביב יפו/דרום פלורנטין" },
  { name: "נווה דקלים, אשקלון", type: "neighborhood", center: [34.58428562690364, 31.67751413103698], id: "אשקלון/נווה דקלים" },
  { name: "קרית בן גוריון, חולון", type: "neighborhood", center: [34.770562690916755, 31.997334588477713], id: "חולון/קרית בן גוריון" },
  { name: "המיסדים, קרית מלאכי", type: "neighborhood", center: [34.74899562208747, 31.73335852974777], id: "קרית מלאכי/המיסדים" },
  { name: "קפלן, כפר סבא", type: "neighborhood", center: [34.93849860727642, 32.188476777610774], id: "כפר סבא/קפלן" },
  { name: "שכונה א, באר שבע", type: "neighborhood", center: [34.79089051003643, 31.248343904718837], id: "באר שבע/שכונה א" },
  { name: "קרית מנחם, אופקים", type: "neighborhood", center: [34.61578372066468, 31.30168544970896], id: "אופקים/קרית מנחם" },
  { name: "המושבה, זכרון יעקב", type: "neighborhood", center: [34.94868781100172, 32.575444773164385], id: "זכרון יעקב/המושבה" },
  { name: "גבעת סוקולוב, בני ברק", type: "neighborhood", center: [34.834694753323035, 32.08911128529089], id: "בני ברק/גבעת סוקולוב" },
  { name: "נאות יצחק רבין, שדרות", type: "neighborhood", center: [34.58546329938108, 31.53049382481717], id: "שדרות/נאות יצחק רבין" },
  { name: "גינות סביון, רחובות", type: "neighborhood", center: [34.79498984552702, 31.90603291778684], id: "רחובות/גינות סביון" },
  { name: "בית ונוף, כפר סבא", type: "neighborhood", center: [34.93431786493187, 32.18556017101225], id: "כפר סבא/בית ונוף" },
  { name: "רמב\"ם, ראשון לציון", type: "neighborhood", center: [34.809701276306654, 31.966357716747698], id: "ראשון לציון/רמב\"ם" },
  { name: "קרית משה, ירושלים", type: "neighborhood", center: [35.19567096934217, 31.78656687066403], id: "ירושלים/קרית משה" },
  { name: "נוה אפק, ראש העין", type: "neighborhood", center: [34.977595418737735, 32.09315071047894], id: "ראש העין/נוה אפק" },
  { name: "נווה רסקו, רמת השרון", type: "neighborhood", center: [34.83915613625965, 32.134522609601916], id: "רמת השרון/נווה רסקו" },
  { name: "2004, רעננה", type: "neighborhood", center: [34.88523109232866, 32.1895369067977], id: "רעננה/2004" },
  { name: "נאות הראשונים, מזכרת בתיה", type: "neighborhood", center: [34.845221602884564, 31.84972764892862], id: "מזכרת בתיה/נאות הראשונים" },
  { name: "מע\"ר דרום, אשדוד", type: "neighborhood", center: [34.635712347425276, 31.789303354133743], id: "אשדוד/מע\"ר דרום" },
  { name: "גבעת ציון, אשקלון", type: "neighborhood", center: [34.583926431706416, 31.65499612733997], id: "אשקלון/גבעת ציון" },
  { name: "קריית שרת, חולון", type: "neighborhood", center: [34.792054671754286, 32.00608495109349], id: "חולון/קריית שרת" },
  { name: "הגבעה הצרפתית, ירושלים", type: "neighborhood", center: [35.23870938525452, 31.803190704610675], id: "ירושלים/הגבעה הצרפתית" },
  { name: "מזרחי א', כפר סבא", type: "neighborhood", center: [34.913753280658035, 32.17830023768023], id: "כפר סבא/מזרחי א'" },
  { name: "ג'סי כהן, חולון", type: "neighborhood", center: [34.76383134972708, 32.00626078766953], id: "חולון/ג'סי כהן" },
  { name: "עץ חיים, טבריה", type: "neighborhood", center: [35.536702149325656, 32.783883218920785], id: "טבריה/עץ חיים" },
  { name: "אבני חן (קייזר), מודיעין מכבים רעות", type: "neighborhood", center: [34.99550208117148, 31.90400466656221], id: "מודיעין מכבים רעות/אבני חן (קייזר)" },
  { name: "עבר הירדן, קרית אונו", type: "neighborhood", center: [34.8568426509716, 32.051388481223356], id: "קרית אונו/עבר הירדן" },
  { name: "נאות בגין, יבנה", type: "neighborhood", center: [34.7383969685108, 31.870994125371976], id: "יבנה/נאות בגין" },
  { name: "ביאליק, טירת הכרמל", type: "neighborhood", center: [34.97117949709947, 32.76727224344044], id: "טירת הכרמל/ביאליק" },
  { name: "גבעת הסלעים, ראש העין", type: "neighborhood", center: [34.960950218390586, 32.101935177363444], id: "ראש העין/גבעת הסלעים" },
  { name: "רובע האומנים, ערד", type: "neighborhood", center: [35.19354563812828, 31.24817826267603], id: "ערד/רובע האומנים" },
  { name: "מצפה ים, אילת", type: "neighborhood", center: [34.9349924570844, 29.55612817221941], id: "אילת/מצפה ים" },
  { name: "הגפן, אפרת", type: "neighborhood", center: [35.14859902544726, 31.655685739957267], id: "אפרת/הגפן" },
  { name: "גרינברג, הוד השרון", type: "neighborhood", center: [34.883496605807395, 32.14834550134341], id: "הוד השרון/גרינברג" },
  { name: "נווה אורנים, צפת", type: "neighborhood", center: [35.513088410493914, 32.96802112925361], id: "צפת/נווה אורנים" },
  { name: "אזור תעשיה מרכז, לוד", type: "neighborhood", center: [34.890973565583444, 31.954781622360322], id: "לוד/אזור תעשיה מרכז" },
  { name: "יד התשעה, הרצליה", type: "neighborhood", center: [34.85397805272293, 32.1757687810875], id: "הרצליה/יד התשעה" },
  { name: "קרית חב\"ד, צפת", type: "neighborhood", center: [35.5058043055743, 32.96427064231637], id: "צפת/קרית חב\"ד" },
  { name: "רובע ז, אשדוד", type: "neighborhood", center: [34.66362040566611, 31.790580962424546], id: "אשדוד/רובע ז" },
  { name: "אזור תעשייה, נס ציונה", type: "neighborhood", center: [34.79660970791916, 31.92215649969637], id: "נס ציונה/אזור תעשייה" },
  { name: "אזור תעשייה, רעננה", type: "neighborhood", center: [34.88183129075072, 32.19558290163539], id: "רעננה/אזור תעשייה" },
  { name: "רמת חן, רמת גן", type: "neighborhood", center: [34.81919673338146, 32.0562349917585], id: "רמת גן/רמת חן" },
  { name: "הר יונה, נצרת עילית", type: "neighborhood", center: [35.33352307893933, 32.72692047530729], id: "נצרת עילית/הר יונה" },
  { name: "הצפון הישן החלק הדרום מערבי, תל אביב יפו", type: "neighborhood", center: [34.771195531189285, 32.08066136659177], id: "תל אביב יפו/הצפון הישן החלק הדרום מערבי" },
  { name: "נוה עמל, הרצליה", type: "neighborhood", center: [34.858276543495066, 32.166056072430344], id: "הרצליה/נוה עמל" },
  { name: "גני הדר, צפת", type: "neighborhood", center: [35.48877008556934, 32.962868319907265], id: "צפת/גני הדר" },
  { name: "אפק, קרית ביאליק", type: "neighborhood", center: [35.09503533247642, 32.84778325480757], id: "קרית ביאליק/אפק" },
  { name: "קיבוץ גלויות, חיפה", type: "neighborhood", center: [35.00949363664562, 32.804357768856036], id: "חיפה/קיבוץ גלויות" },
  { name: "שדרת המגדלים, חולון", type: "neighborhood", center: [34.776357436319, 32.01242639470649], id: "חולון/שדרת המגדלים" },
  { name: "שיפר, פתח תקווה", type: "neighborhood", center: [34.89194019289861, 32.09706514175666], id: "פתח תקווה/שיפר" },
  { name: "קרית יערים, רמת השרון", type: "neighborhood", center: [34.83404537972847, 32.15005877542827], id: "רמת השרון/קרית יערים" },
  { name: "יפו העתיקה, תל אביב יפו", type: "neighborhood", center: [34.75136881533333, 32.05257877932359], id: "תל אביב יפו/יפו העתיקה" },
  { name: "השכונה הצפונית, כפר ורדים", type: "neighborhood", center: [35.27905878529552, 32.99645347819412], id: "כפר ורדים/השכונה הצפונית" },
  { name: "נווה אילן, אשקלון", type: "neighborhood", center: [34.57896341532462, 31.66691013567078], id: "אשקלון/נווה אילן" },
  { name: "אל עתיקה, חיפה", type: "neighborhood", center: [34.98742653111944, 32.82771789938817], id: "חיפה/אל עתיקה" },
  { name: "מערבית ב', מגדל העמק", type: "neighborhood", center: [35.22104534392264, 32.69083266687735], id: "מגדל העמק/מערבית ב'" },
  { name: "גני אילן, קרית אונו", type: "neighborhood", center: [34.84802406796679, 32.064217378592254], id: "קרית אונו/גני אילן" },
  { name: "רמת אשכול, מגדל העמק", type: "neighborhood", center: [35.2424940017697, 32.67110452211394], id: "מגדל העמק/רמת אשכול" },
  { name: "א.ת. עטרות, ירושלים", type: "neighborhood", center: [35.219355354953066, 31.854951667214156], id: "ירושלים/א.ת. עטרות" },
  { name: "קריית שמואל, חיפה", type: "neighborhood", center: [35.069107861490544, 32.83636718397807], id: "חיפה/קריית שמואל" },
  { name: "שכונה 9 - העדי, קיסריה", type: "neighborhood", center: [34.905500300105196, 32.49400742312101], id: "קיסריה/שכונה 9 - העדי" },
  { name: "נוף הירדן, בית שאן", type: "neighborhood", center: [35.49798289747811, 32.489995914368016], id: "בית שאן/נוף הירדן" },
  { name: "נווה נאמן, הוד השרון", type: "neighborhood", center: [34.8998211750692, 32.13822412923467], id: "הוד השרון/נווה נאמן" },
  { name: "הרצל, קרית מלאכי", type: "neighborhood", center: [34.73943228546258, 31.73573907695578], id: "קרית מלאכי/הרצל" },
  { name: "קרית שלום, תל אביב יפו", type: "neighborhood", center: [34.77729545717259, 32.04423388395066], id: "תל אביב יפו/קרית שלום" },
  { name: "שיכון ותיקים, רמת גן", type: "neighborhood", center: [34.81339601468245, 32.093610236882114], id: "רמת גן/שיכון ותיקים" },
  { name: "ביאליק על הפארק, קרית ביאליק", type: "neighborhood", center: [35.106445072845524, 32.8402913999857], id: "קרית ביאליק/ביאליק על הפארק" },
  { name: "שכונה ד, אריאל", type: "neighborhood", center: [35.19475484995838, 32.10600355021733], id: "אריאל/שכונה ד" },
  { name: "הרמב\"ם, טבריה", type: "neighborhood", center: [35.538935377788555, 32.789910305519165], id: "טבריה/הרמב\"ם" },
  { name: "קרית גנים, ראשון לציון", type: "neighborhood", center: [34.78001176940493, 31.9633393855255], id: "ראשון לציון/קרית גנים" },
  { name: "נווה חן, קרית אתא", type: "neighborhood", center: [35.085082308034714, 32.80419408146226], id: "קרית אתא/נווה חן" },
  { name: "תל בנימין, רמת גן", type: "neighborhood", center: [34.80786039023918, 32.08565083942044], id: "רמת גן/תל בנימין" },
  { name: "עין הים, חדרה", type: "neighborhood", center: [34.882617483484566, 32.42891204178609], id: "חדרה/עין הים" },
  { name: "גני תעשייה מורדות הכרמל, יוקנעם עילית", type: "neighborhood", center: [35.0975930884937, 32.65752552127518], id: "יוקנעם עילית/גני תעשייה מורדות הכרמל" },
  { name: "נופים, מודיעין מכבים רעות", type: "neighborhood", center: [34.98354007425537, 31.896890045018385], id: "מודיעין מכבים רעות/נופים" },
  { name: "קרית מטלון, פתח תקווה", type: "neighborhood", center: [34.849841670087635, 32.089741393584625], id: "פתח תקווה/קרית מטלון" },
  { name: "מונטיפיורי, תל אביב יפו", type: "neighborhood", center: [34.78934866078652, 32.06850060439364], id: "תל אביב יפו/מונטיפיורי" },
  { name: "ארגמן, נס ציונה", type: "neighborhood", center: [34.78680856985781, 31.929634635192667], id: "נס ציונה/ארגמן" },
  { name: "הרימון, אפרת", type: "neighborhood", center: [35.149259711174786, 31.64958889502116], id: "אפרת/הרימון" },
  { name: "אכזיב, נהריה", type: "neighborhood", center: [35.102489789948756, 33.04058565333935], id: "נהריה/אכזיב" },
  { name: "קיראון, קרית אונו", type: "neighborhood", center: [34.863105043990245, 32.05434008082936], id: "קרית אונו/קיראון" },
  { name: "רקפות, שוהם", type: "neighborhood", center: [34.948140677447924, 31.99085495527396], id: "שוהם/רקפות" },
  { name: "גבעת אלונים, יוקנעם עילית", type: "neighborhood", center: [35.089991058787795, 32.63728229391608], id: "יוקנעם עילית/גבעת אלונים" },
  { name: "כרמל צרפתי, חיפה", type: "neighborhood", center: [34.97539577543057, 32.81886419769826], id: "חיפה/כרמל צרפתי" },
  { name: "הרובע המוסלמי, ירושלים", type: "neighborhood", center: [35.23429392501952, 31.779808886764435], id: "ירושלים/הרובע המוסלמי" },
  { name: "השמורה, זכרון יעקב", type: "neighborhood", center: [34.94146340835714, 32.56341471453333], id: "זכרון יעקב/השמורה" },
  { name: "בר יהודה, פתח תקווה", type: "neighborhood", center: [34.868946649315475, 32.083634064398936], id: "פתח תקווה/בר יהודה" },
  { name: "גולדה, אשקלון", type: "neighborhood", center: [34.554446335871404, 31.650120621924543], id: "אשקלון/גולדה" },
  { name: "ח-500, חולון", type: "neighborhood", center: [34.78837941554564, 31.997148238407394], id: "חולון/ח-500" },
  { name: "ואדי סליב, חיפה", type: "neighborhood", center: [35.00271078414817, 32.8114444763303], id: "חיפה/ואדי סליב" },
  { name: "מגדל אור, מגדל העמק", type: "neighborhood", center: [35.2331595386111, 32.67510415930298], id: "מגדל העמק/מגדל אור" },
  { name: "גבעת קוזלובסקי, גבעתיים", type: "neighborhood", center: [34.81656328484855, 32.07136802014211], id: "גבעתיים/גבעת קוזלובסקי" },
  { name: "רמת אמיר, קדימה צורן", type: "neighborhood", center: [34.93695272704348, 32.29195096696663], id: "קדימה צורן/רמת אמיר" },
  { name: "נוה הלל, ראשון לציון", type: "neighborhood", center: [34.78931543865165, 31.9595071476028], id: "ראשון לציון/נוה הלל" },
  { name: "רמת שקמה, רמת גן", type: "neighborhood", center: [34.81725888360918, 32.05039149859736], id: "רמת גן/רמת שקמה" },
  { name: "רמת אביב ג, תל אביב יפו", type: "neighborhood", center: [34.80151419529825, 32.12529181503873], id: "תל אביב יפו/רמת אביב ג" },
  { name: "גבעת רם, כרמיאל", type: "neighborhood", center: [35.27501479510138, 32.91497344843388], id: "כרמיאל/גבעת רם" },
  { name: "רובע הים, חדרה", type: "neighborhood", center: [34.88843720615389, 32.45534649142039], id: "חדרה/רובע הים" },
  { name: "רמת בית שמש ד 4, בית שמש", type: "neighborhood", center: [34.960160824668186, 31.706521339975165], id: "בית שמש/רמת בית שמש ד 4" },
  { name: "היובלים, קרית שמונה", type: "neighborhood", center: [35.588116437537195, 33.225262153221706], id: "קרית שמונה/היובלים" },
  { name: "גרין ועם, חולון", type: "neighborhood", center: [34.76989158585145, 32.02660723210657], id: "חולון/גרין ועם" },
  { name: "פאר, חדרה", type: "neighborhood", center: [34.91812165520327, 32.43028458396047], id: "חדרה/פאר" },
  { name: "נוף הרים, מבשרת ציון", type: "neighborhood", center: [35.14998333244543, 31.80200290283282], id: "מבשרת ציון/נוף הרים" },
  { name: "הורדים, קרית שמונה", type: "neighborhood", center: [35.5805343221163, 33.21537328070761], id: "קרית שמונה/הורדים" },
  { name: "אחווה, טבריה", type: "neighborhood", center: [35.5408087453011, 32.783944467793546], id: "טבריה/אחווה" },
  { name: "נאות אפקה ב, תל אביב יפו", type: "neighborhood", center: [34.822618762573356, 32.119350312985695], id: "תל אביב יפו/נאות אפקה ב" },
  { name: "האחים ישראלית, פתח תקווה", type: "neighborhood", center: [34.87766699396048, 32.09937203274395], id: "פתח תקווה/האחים ישראלית" },
  { name: "קרית ים א, קרית ים", type: "neighborhood", center: [35.06279128263602, 32.83905928972392], id: "קרית ים/קרית ים א" },
  { name: "הכרמים (ציפור), מודיעין מכבים רעות", type: "neighborhood", center: [35.00911303217106, 31.91575376361177], id: "מודיעין מכבים רעות/הכרמים (ציפור)" },
  { name: "נוה שמיר, בית שמש", type: "neighborhood", center: [34.9978626967147, 31.698221646988568], id: "בית שמש/נוה שמיר" },
  { name: "רובע 12, קצרין", type: "neighborhood", center: [35.68564746605976, 32.985229226335285], id: "קצרין/רובע 12" },
  { name: "נוף העמק, מגדל העמק", type: "neighborhood", center: [35.25433976202224, 32.676558128866134], id: "מגדל העמק/נוף העמק" },
  { name: "מרכז העיר, בית שאן", type: "neighborhood", center: [35.500764383317275, 32.49580460971293], id: "בית שאן/מרכז העיר" },
  { name: "נאות אביב, שדרות", type: "neighborhood", center: [34.59076777099645, 31.52146594409078], id: "שדרות/נאות אביב" },
  { name: "נווה רמז, זכרון יעקב", type: "neighborhood", center: [34.95474157622276, 32.561307585531765], id: "זכרון יעקב/נווה רמז" },
  { name: "בבא סאלי, נתיבות", type: "neighborhood", center: [34.59213519294639, 31.423845414369136], id: "נתיבות/בבא סאלי" },
  { name: "גבעת שאול, ירושלים", type: "neighborhood", center: [35.192570219655394, 31.79067150613656], id: "ירושלים/גבעת שאול" },
  { name: "גבעת יערה, יוקנעם עילית", type: "neighborhood", center: [35.08643403007965, 32.641869670285914], id: "יוקנעם עילית/גבעת יערה" },
  { name: "תל דן, הוד השרון", type: "neighborhood", center: [34.882800356462575, 32.138564448550106], id: "הוד השרון/תל דן" },
  { name: "צור יגאל, כוכב יאיר צור יגאל", type: "neighborhood", center: [34.98952305208366, 32.214324041749535], id: "כוכב יאיר צור יגאל/צור יגאל" },
  { name: "גבעת דאונס, חיפה", type: "neighborhood", center: [34.99308409939454, 32.78205002391917], id: "חיפה/גבעת דאונס" },
  { name: "המייסדים, כרמיאל", type: "neighborhood", center: [35.30491754796113, 32.9172961697615], id: "כרמיאל/המייסדים" },
  { name: "2008, רעננה", type: "neighborhood", center: [34.88802067222381, 32.18553603246673], id: "רעננה/2008" },
  { name: "רשפים, גדרה", type: "neighborhood", center: [34.78890681547166, 31.808341919880167], id: "גדרה/רשפים" },
  { name: "חב\"ד, לוד", type: "neighborhood", center: [34.876255268109595, 31.94273989837154], id: "לוד/חב\"ד" },
  { name: "מלחה, ירושלים", type: "neighborhood", center: [35.18350376620167, 31.75214368150945], id: "ירושלים/מלחה" },
  { name: "בן גוריון, שדרות", type: "neighborhood", center: [34.60416110243088, 31.52836707592442], id: "שדרות/בן גוריון" },
  { name: "הכלניות, באר שבע", type: "neighborhood", center: [34.753162880027205, 31.27038916566692], id: "באר שבע/הכלניות" },
  { name: "דקר, תל אביב יפו", type: "neighborhood", center: [34.75919056603206, 32.04082196897651], id: "תל אביב יפו/דקר" },
  { name: "מורדות ארנונה, ירושלים", type: "neighborhood", center: [35.224172045996404, 31.74367569422903], id: "ירושלים/מורדות ארנונה" },
  { name: "עג'מי, תל אביב יפו", type: "neighborhood", center: [34.74809317937536, 32.042387044673035], id: "תל אביב יפו/עג'מי" },
  { name: "ואדי חילוה, ירושלים", type: "neighborhood", center: [35.234778323444175, 31.773747687506496], id: "ירושלים/ואדי חילוה" },
  { name: "הדר הכרמל, חיפה", type: "neighborhood", center: [34.997939437683215, 32.80997759562628], id: "חיפה/הדר הכרמל" },
  { name: "ראס אל עמוד, ירושלים", type: "neighborhood", center: [35.24632946043029, 31.76731685891176], id: "ירושלים/ראס אל עמוד" },
  { name: "נטעים, נתיבות", type: "neighborhood", center: [34.58289293491764, 31.426611677274668], id: "נתיבות/נטעים" },
  { name: "מרכז העיר, רחובות", type: "neighborhood", center: [34.81228260595855, 31.894349125286865], id: "רחובות/מרכז העיר" },
  { name: "רמת אשכול, אשקלון", type: "neighborhood", center: [34.59209951743639, 31.667576560599038], id: "אשקלון/רמת אשכול" },
  { name: "מע\"ר, בית שמש", type: "neighborhood", center: [34.987546528697706, 31.71056248142028], id: "בית שמש/מע\"ר" },
  { name: "מלכי ישראל, דימונה", type: "neighborhood", center: [35.03324883128026, 31.071308952286056], id: "דימונה/מלכי ישראל" },
  { name: "קרית השרון, נתניה", type: "neighborhood", center: [34.87378841001911, 32.301296715104456], id: "נתניה/קרית השרון" },
  { name: "שער העיר, לוד", type: "neighborhood", center: [34.87389719150275, 31.942539399603664], id: "לוד/שער העיר" },
  { name: "מגדל המים, קרית אונו", type: "neighborhood", center: [34.864973162749294, 32.062728017799614], id: "קרית אונו/מגדל המים" },
  { name: "אבו גוש מערב, אבו גוש", type: "neighborhood", center: [35.09762177915143, 31.812069801029885], id: "אבו גוש/אבו גוש מערב" },
  { name: "גני אלון/הזיתים, חדרה", type: "neighborhood", center: [34.908015939785344, 32.441142629540984], id: "חדרה/גני אלון;הזיתים" },
  { name: "אזור תעשיה, בני ברק", type: "neighborhood", center: [34.838296615726215, 32.103774620665064], id: "בני ברק/אזור תעשיה" },
  { name: "החורש, קרית גת", type: "neighborhood", center: [34.777078108470434, 31.60867558873029], id: "קרית גת/החורש" },
  { name: "גני הדר, פתח תקווה", type: "neighborhood", center: [34.9125366824632, 32.06938595064807], id: "פתח תקווה/גני הדר" },
  { name: "גבעת המטוס, ירושלים", type: "neighborhood", center: [35.20802650850297, 31.738319593741004], id: "ירושלים/גבעת המטוס" },
  { name: "שמואל הנביא, ירושלים", type: "neighborhood", center: [35.223885384561456, 31.79341034662659], id: "ירושלים/שמואל הנביא" },
  { name: "תל ברוך צפון, תל אביב יפו", type: "neighborhood", center: [34.817594617706675, 32.12292863540189], id: "תל אביב יפו/תל ברוך צפון" },
  { name: "רובע יזרעאל, עפולה", type: "neighborhood", center: [35.309841233615195, 32.615173147709186], id: "עפולה/רובע יזרעאל" },
  { name: "הפרחים (מגף), חריש", type: "neighborhood", center: [35.05119641475244, 32.45463745994581], id: "חריש/הפרחים (מגף)" },
  { name: "הזית, אפרת", type: "neighborhood", center: [35.16731780682209, 31.67248128684959], id: "אפרת/הזית" },
  { name: "מרכז העיר, חדרה", type: "neighborhood", center: [34.923492008593314, 32.43484608002541], id: "חדרה/מרכז העיר" },
  { name: "השכונה הדרומית, כפר ורדים", type: "neighborhood", center: [35.275645892072106, 32.991131634308964], id: "כפר ורדים/השכונה הדרומית" },
  { name: "מרכז המושבה, אבן יהודה", type: "neighborhood", center: [34.887394399758094, 32.26907408338997], id: "אבן יהודה/מרכז המושבה" },
  { name: "רמת החייל, תל אביב יפו", type: "neighborhood", center: [34.835129720466774, 32.11264377389638], id: "תל אביב יפו/רמת החייל" },
  { name: "שכונת הפארק, חדרה", type: "neighborhood", center: [34.93544231277779, 32.427083296162266], id: "חדרה/שכונת הפארק" },
  { name: "גבעת סביון החדשה, גני תקווה", type: "neighborhood", center: [34.88109080657938, 32.061575430833116], id: "גני תקווה/גבעת סביון החדשה" },
  { name: "השופטים, קרית גת", type: "neighborhood", center: [34.76257509519775, 31.616196043273465], id: "קרית גת/השופטים" },
  { name: "נוה ארזים, חולון", type: "neighborhood", center: [34.79414357911064, 32.01641090714745], id: "חולון/נוה ארזים" },
  { name: "נוה מנחם בגין, נהריה", type: "neighborhood", center: [35.090650596512326, 32.99359800065929], id: "נהריה/נוה מנחם בגין" },
  { name: "מרינה, אשדוד", type: "neighborhood", center: [34.63061781685158, 31.79586581968442], id: "אשדוד/מרינה" },
  { name: "נאות אשכול, שדרות", type: "neighborhood", center: [34.600113102838286, 31.524190577168053], id: "שדרות/נאות אשכול" },
  { name: "הרצליה פיתוח, הרצליה", type: "neighborhood", center: [34.80739214769173, 32.17492114514079], id: "הרצליה/הרצליה פיתוח" },
  { name: "רסקו, הוד השרון", type: "neighborhood", center: [34.87572073435444, 32.142842615964724], id: "הוד השרון/רסקו" },
  { name: "רמת יוקנעם, יוקנעם עילית", type: "neighborhood", center: [35.09767196997036, 32.6502278013589], id: "יוקנעם עילית/רמת יוקנעם" },
  { name: "בנה ביתך,  אמדר,אילת", type: "neighborhood", center: [34.94667066598922, 29.551995000267368], id: "אילת/בנה ביתך,  אמדר" },
  { name: "למפרט/פועלים ב, כפר סבא", type: "neighborhood", center: [34.899456090780454, 32.17868013374029], id: "כפר סבא/למפרט;פועלים ב" },
  { name: "קרית שמואל, טבריה", type: "neighborhood", center: [35.53137813215793, 32.796938816144355], id: "טבריה/קרית שמואל" },
  { name: "בני בנימין, כפר תבור", type: "neighborhood", center: [35.41892745405527, 32.696370221775744], id: "כפר תבור/בני בנימין" },
  { name: "אזור התעשיה, קרית מלאכי", type: "neighborhood", center: [34.753145602401744, 31.73631692845809], id: "קרית מלאכי/אזור התעשיה" },
  { name: "רובע רמות, באר שבע", type: "neighborhood", center: [34.810054772940944, 31.29502018797012], id: "באר שבע/רובע רמות" },
  { name: "המושבה הגרמנית, גדרה", type: "neighborhood", center: [34.77056458238268, 31.810134812563586], id: "גדרה/המושבה הגרמנית" },
  { name: "הקריה, בית שמש", type: "neighborhood", center: [34.99555041016434, 31.738865303461147], id: "בית שמש/הקריה" },
  { name: "נוה גולן, תל אביב יפו", type: "neighborhood", center: [34.751589859436635, 32.03619981147453], id: "תל אביב יפו/נוה גולן" },
  { name: "לבנה וידידיה, תל אביב יפו", type: "neighborhood", center: [34.8072082207818, 32.044016340894565], id: "תל אביב יפו/לבנה וידידיה" },
  { name: "גלילות דרום/פי גלילות, רמת השרון", type: "neighborhood", center: [34.80378488184003, 32.13407556906326], id: "רמת השרון/גלילות דרום;פי גלילות" },
  { name: "שיכון ג', טבריה", type: "neighborhood", center: [35.50842306978784, 32.782570313936226], id: "טבריה/שיכון ג'" },
  { name: "האוצר, חדרה", type: "neighborhood", center: [34.918545414891064, 32.44318519936515], id: "חדרה/האוצר" },
  { name: "רובע אפק, קצרין", type: "neighborhood", center: [35.684548912941224, 32.99375861682254], id: "קצרין/רובע אפק" },
  { name: "רביבים, ראשון לציון", type: "neighborhood", center: [34.821580260303016, 31.964784945123764], id: "ראשון לציון/רביבים" },
  { name: "אזור התעשיה הישן, כפר סבא", type: "neighborhood", center: [34.93009943362703, 32.17509729659707], id: "כפר סבא/אזור התעשיה הישן" },
  { name: "יוספטל, כפר סבא", type: "neighborhood", center: [34.94145002474881, 32.18320713062155], id: "כפר סבא/יוספטל" },
  { name: "נאות הפרחים, גן יבנה", type: "neighborhood", center: [34.7082498514765, 31.79211546379466], id: "גן יבנה/נאות הפרחים" },
  { name: "קרית שרת, רעננה", type: "neighborhood", center: [34.8561828155372, 32.195231903223515], id: "רעננה/קרית שרת" },
  { name: "בקעה, ירושלים", type: "neighborhood", center: [35.2193032649595, 31.758353517896573], id: "ירושלים/בקעה" },
  { name: "כפר גנים א, פתח תקווה", type: "neighborhood", center: [34.875398342377416, 32.08127291610768], id: "פתח תקווה/כפר גנים א" },
  { name: "גני מנחם, באר יעקב", type: "neighborhood", center: [34.837432519265334, 31.938492574149894], id: "באר יעקב/גני מנחם" },
  { name: "שכונה ו, באר שבע", type: "neighborhood", center: [34.779374717778474, 31.264544717258456], id: "באר שבע/שכונה ו" },
  { name: "הבלוט, אשקלון", type: "neighborhood", center: [34.578024637014586, 31.70356614647792], id: "אשקלון/הבלוט" },
  { name: "כרם התימנים, תל אביב יפו", type: "neighborhood", center: [34.76622183605487, 32.07027463837681], id: "תל אביב יפו/כרם התימנים" },
  { name: "רמב\"ם, טירת הכרמל", type: "neighborhood", center: [34.973608501821275, 32.765739523672856], id: "טירת הכרמל/רמב\"ם" },
  { name: "רותם, ערד", type: "neighborhood", center: [35.22658410082065, 31.264535121768276], id: "ערד/רותם" },
  { name: "שכונה 6 - הים, קיסריה", type: "neighborhood", center: [34.905893072028746, 32.52495008490827], id: "קיסריה/שכונה 6 - הים" },
  { name: "גבעת הרקפות, קרית אתא", type: "neighborhood", center: [35.12636091437886, 32.810408244165075], id: "קרית אתא/גבעת הרקפות" },
  { name: "שנלר, נצרת", type: "neighborhood", center: [35.28625018593439, 32.70060401350635], id: "נצרת/שנלר" },
  { name: "שכונה ד, מבשרת ציון", type: "neighborhood", center: [35.146294950496674, 31.807239014306095], id: "מבשרת ציון/שכונה ד" },
  { name: "גדרה מזרח, גדרה", type: "neighborhood", center: [34.790509828019964, 31.802902161404546], id: "גדרה/גדרה מזרח" },
  { name: "המשולש הגדול - קרית קריניצי החדשה, רמת גן", type: "neighborhood", center: [34.84525460865924, 32.05596455332884], id: "רמת גן/המשולש הגדול - קרית קריניצי החדשה" },
  { name: "גני מודיעין, מודיעין עילית", type: "neighborhood", center: [35.015558142645105, 31.929484566184136], id: "מודיעין עילית/גני מודיעין" },
  { name: "רמת בגין, טירת הכרמל", type: "neighborhood", center: [34.977588217393254, 32.760283855823516], id: "טירת הכרמל/רמת בגין" },
  { name: "חוף התכלת, הרצליה", type: "neighborhood", center: [34.799477677430055, 32.15361981342168], id: "הרצליה/חוף התכלת" },
  { name: "פלח שבע, באר שבע", type: "neighborhood", center: [34.76618097137966, 31.22886176658482], id: "באר שבע/פלח שבע" },
  { name: "רמות יצחק, נשר", type: "neighborhood", center: [35.03692062722575, 32.76831276094355], id: "נשר/רמות יצחק" },
  { name: "מורשה, רמת השרון", type: "neighborhood", center: [34.85587721666591, 32.131866729566944], id: "רמת השרון/מורשה" },
  { name: "אחוזת הנשיא, רחובות", type: "neighborhood", center: [34.821730843206346, 31.9072711945754], id: "רחובות/אחוזת הנשיא" },
  { name: "ארנונה (תלפיות), ירושלים", type: "neighborhood", center: [35.222200090796235, 31.749485746584913], id: "ירושלים/ארנונה (תלפיות)" },
  { name: "בית וגן, ירושלים", type: "neighborhood", center: [35.18636920437896, 31.767155800349954], id: "ירושלים/בית וגן" },
  { name: "הצפון החדש החלק הצפוני, תל אביב יפו", type: "neighborhood", center: [34.78913968427973, 32.093553611125614], id: "תל אביב יפו/הצפון החדש החלק הצפוני" },
  { name: "קרית רבין שלב ב', מגדל העמק", type: "neighborhood", center: [35.231902251292205, 32.690296263047834], id: "מגדל העמק/קרית רבין שלב ב'" },
  { name: "ניצני השרון, פרדס חנה כרכור", type: "neighborhood", center: [35.00111616246258, 32.470922418335746], id: "פרדס חנה כרכור/ניצני השרון" },
  { name: "ביל\"ו, רמלה", type: "neighborhood", center: [34.876030225315475, 31.923057893934697], id: "רמלה/ביל\"ו" },
  { name: "נווה גן, דימונה", type: "neighborhood", center: [35.0370807278104, 31.075863417787744], id: "דימונה/נווה גן" },
  { name: "שיכון עממי, הוד השרון", type: "neighborhood", center: [34.88230378752528, 32.15885851718774], id: "הוד השרון/שיכון עממי" },
  { name: "וייצמן, רמלה", type: "neighborhood", center: [34.86286371035343, 31.928392188311143], id: "רמלה/וייצמן" },
  { name: "מרכז העיר, קרית מלאכי", type: "neighborhood", center: [34.74655224273148, 31.730191297787783], id: "קרית מלאכי/מרכז העיר" },
  { name: "בית ישראל, ירושלים", type: "neighborhood", center: [35.22331331625679, 31.789294993647147], id: "ירושלים/בית ישראל" },
  { name: "רמת יוסף, בת ים", type: "neighborhood", center: [34.75800788282598, 32.019409450197024], id: "בת ים/רמת יוסף" },
  { name: "שיכוני תלפיות, ירושלים", type: "neighborhood", center: [35.21711135943412, 31.74907736724595], id: "ירושלים/שיכוני תלפיות" },
  { name: "רמת אלישיב, לוד", type: "neighborhood", center: [34.893636555482594, 31.954930089246744], id: "לוד/רמת אלישיב" },
  { name: "אתרי המקרא, קרית גת", type: "neighborhood", center: [34.753377509627335, 31.612306980328935], id: "קרית גת/אתרי המקרא" },
  { name: "ישמח משה, גני תקווה", type: "neighborhood", center: [34.8675812037669, 32.059953698844595], id: "גני תקווה/ישמח משה" },
  { name: "גבעת טל, ראש העין", type: "neighborhood", center: [34.96489450854006, 32.09475555157921], id: "ראש העין/גבעת טל" },
  { name: "פארק תעשייה מבוא ית\"ם, יוקנעם עילית", type: "neighborhood", center: [35.105241774524, 32.668229824055075], id: "יוקנעם עילית/פארק תעשייה מבוא ית\"ם" },
  { name: "נאות ים, עכו", type: "neighborhood", center: [35.08793350145309, 32.929650926918306], id: "עכו/נאות ים" },
  { name: "מתחם שידלובסקי, אור עקיבא", type: "neighborhood", center: [34.92588276652092, 32.50661158246294], id: "אור עקיבא/מתחם שידלובסקי" },
  { name: "ניר העמק, עפולה", type: "neighborhood", center: [35.27039688378093, 32.59595259710308], id: "עפולה/ניר העמק" },
  { name: "מע\"ר, גני תקווה", type: "neighborhood", center: [34.87649656708008, 32.06496455912438], id: "גני תקווה/מע\"ר" },
  { name: "יד אליהו, תל אביב יפו", type: "neighborhood", center: [34.79335566580447, 32.058723983975916], id: "תל אביב יפו/יד אליהו" },
  { name: "שיכון ותיקים, כפר סבא", type: "neighborhood", center: [34.892824656919366, 32.1809277435273], id: "כפר סבא/שיכון ותיקים" },
  { name: "נווה דוד, דימונה", type: "neighborhood", center: [35.0291710870424, 31.074068956891985], id: "דימונה/נווה דוד" },
  { name: "קרית נדבורנא, בני ברק", type: "neighborhood", center: [34.83524558504119, 32.07781771993801], id: "בני ברק/קרית נדבורנא" },
  { name: "נווה זמר, רעננה", type: "neighborhood", center: [34.86614336819276, 32.19543445679557], id: "רעננה/נווה זמר" },
  { name: "נאות שקמה, ראשון לציון", type: "neighborhood", center: [34.770925949841654, 31.977242022574284], id: "ראשון לציון/נאות שקמה" },
  { name: "שדה דב, תל אביב יפו", type: "neighborhood", center: [34.78225064534706, 32.1107161388701], id: "תל אביב יפו/שדה דב" },
  { name: "נווה מרחב, פרדס חנה כרכור", type: "neighborhood", center: [34.96032127194101, 32.467434608757955], id: "פרדס חנה כרכור/נווה מרחב" },
  { name: "רמות דוד, קרית גת", type: "neighborhood", center: [34.75300029764147, 31.617763900810427], id: "קרית גת/רמות דוד" },
  { name: "ואדי רקת, טבריה", type: "neighborhood", center: [35.52065495973322, 32.80410545158191], id: "טבריה/ואדי רקת" },
  { name: "שיכון ב', כפר יונה", type: "neighborhood", center: [34.94116987727182, 32.316935605625055], id: "כפר יונה/שיכון ב'" },
  { name: "עמידר, לוד", type: "neighborhood", center: [34.88370543692013, 31.94664393373673], id: "לוד/עמידר" },
  { name: "אוסישקין, נהריה", type: "neighborhood", center: [35.10161059206869, 33.00000168655872], id: "נהריה/אוסישקין" },
  { name: "הדר, כרמיאל", type: "neighborhood", center: [35.29640075240256, 32.91111398441992], id: "כרמיאל/הדר" },
  { name: "נוריות, ראשון לציון", type: "neighborhood", center: [34.83031358000841, 31.965598784843266], id: "ראשון לציון/נוריות" },
  { name: "קרית מיכה, חולון", type: "neighborhood", center: [34.758121995780414, 32.00477209823296], id: "חולון/קרית מיכה" },
  { name: "השחר, דימונה", type: "neighborhood", center: [35.0491700157127, 31.067118244743508], id: "דימונה/השחר" },
  { name: "צהלה, ירוחם", type: "neighborhood", center: [34.91668179678415, 30.987102097624895], id: "ירוחם/צהלה" },
  { name: "רמות רמז, חיפה", type: "neighborhood", center: [35.00850082054273, 32.7791351260561], id: "חיפה/רמות רמז" },
  { name: "יעלים, ערד", type: "neighborhood", center: [35.21336289470314, 31.258886743417424], id: "ערד/יעלים" },
  { name: "קרית ספר, מודיעין עילית", type: "neighborhood", center: [35.042551996339306, 31.930345533845195], id: "מודיעין עילית/קרית ספר" },
  { name: "נאות רחל, חולון", type: "neighborhood", center: [34.76537996209761, 32.01923442233247], id: "חולון/נאות רחל" },
  { name: "הרצליה ב', הרצליה", type: "neighborhood", center: [34.81713356745293, 32.17081101102653], id: "הרצליה/הרצליה ב'" },
  { name: "עכברה, צפת", type: "neighborhood", center: [35.49787999180308, 32.9401955719169], id: "צפת/עכברה" },
  { name: "קטמון א-ו, ירושלים", type: "neighborhood", center: [35.20620053852134, 31.75671186247806], id: "ירושלים/קטמון א-ו" },
  { name: "מרכז העיר, בת ים", type: "neighborhood", center: [34.74600552024552, 32.02053329993774], id: "בת ים/מרכז העיר" },
  { name: "קרית דוד רמז, רעננה", type: "neighborhood", center: [34.87650088500758, 32.18273014464126], id: "רעננה/קרית דוד רמז" },
  { name: "נוה אמירים, הרצליה", type: "neighborhood", center: [34.83837808446662, 32.15508174465774], id: "הרצליה/נוה אמירים" },
  { name: "נוף הדר,  השכונה הצבאית,כפר יונה", type: "neighborhood", center: [35.41892745405527, 32.696370221775744], id: "כפר יונה/נוף הדר,  השכונה הצבאית" },
  { name: "כפר אהרון החדשה, נס ציונה", type: "neighborhood", center: [34.79549165781638, 31.917388414104575], id: "נס ציונה/כפר אהרון החדשה" },
  { name: "פסגת שלומית, שלומי", type: "neighborhood", center: [35.14916814026982, 33.07956383119748], id: "שלומי/פסגת שלומית" },
  { name: "רום 2000, רעננה", type: "neighborhood", center: [34.85583547669216, 32.18363685170126], id: "רעננה/רום 2000" },
  { name: "נווה נוי, באר שבע", type: "neighborhood", center: [34.7855535976968, 31.230960832574073], id: "באר שבע/נווה נוי" },
  { name: "הדר יוסף, תל אביב יפו", type: "neighborhood", center: [34.82113660763634, 32.10924762644044], id: "תל אביב יפו/הדר יוסף" },
  { name: "קרית אליהו, חיפה", type: "neighborhood", center: [34.98571920887039, 32.82351979996546], id: "חיפה/קרית אליהו" },
  { name: "אורות, אור עקיבא", type: "neighborhood", center: [34.92332900298607, 32.49943907164583], id: "אור עקיבא/אורות" },
  { name: "עיר גנים א', ירושלים", type: "neighborhood", center: [35.17216138772471, 31.75680233977609], id: "ירושלים/עיר גנים א'" },
  { name: "יובלים, שוהם", type: "neighborhood", center: [34.94932114872367, 31.995373140747606], id: "שוהם/יובלים" },
  { name: "שז\"ר דקר, טירת הכרמל", type: "neighborhood", center: [34.968109272381426, 32.76403279774422], id: "טירת הכרמל/שז\"ר דקר" },
  { name: "שז\"ר, בית שמש", type: "neighborhood", center: [34.98534029513361, 31.715410894694614], id: "בית שמש/שז\"ר" },
  { name: "רובע ח, אשדוד", type: "neighborhood", center: [34.652613546952324, 31.78717073351416], id: "אשדוד/רובע ח" },
  { name: "פארק היין, זכרון יעקב", type: "neighborhood", center: [34.961144035864294, 32.553791207718035], id: "זכרון יעקב/פארק היין" },
  { name: "משואה, מעלה אדומים", type: "neighborhood", center: [35.300346610900405, 31.770612754007278], id: "מעלה אדומים/משואה" },
  { name: "יזרעאליה, חיפה", type: "neighborhood", center: [35.00966240733766, 32.793317395012245], id: "חיפה/יזרעאליה" },
  { name: "שכון ההסתדרות, בני ברק", type: "neighborhood", center: [34.83084539096979, 32.07761225997684], id: "בני ברק/שכון ההסתדרות" },
  { name: "נוה אשר, פרדס חנה כרכור", type: "neighborhood", center: [34.975993646081925, 32.46609292288485], id: "פרדס חנה כרכור/נוה אשר" },
  { name: "גיורא, אור יהודה", type: "neighborhood", center: [34.86471410385192, 32.03070461313023], id: "אור יהודה/גיורא" },
  { name: "יפה נוף, פרדס חנה כרכור", type: "neighborhood", center: [34.964541803778154, 32.47758601014008], id: "פרדס חנה כרכור/יפה נוף" },
  { name: "הנרקיסים, קרית מלאכי", type: "neighborhood", center: [34.743528147607776, 31.73484610466655], id: "קרית מלאכי/הנרקיסים" },
  { name: "רמת בלפור, מגדל העמק", type: "neighborhood", center: [35.245435353996676, 32.67452078291454], id: "מגדל העמק/רמת בלפור" },
  { name: "שכונה ה, באר שבע", type: "neighborhood", center: [34.77879089481143, 31.252989072120897], id: "באר שבע/שכונה ה" },
  { name: "צאלים, אילת", type: "neighborhood", center: [34.93598495117876, 29.56045569741836], id: "אילת/צאלים" },
  { name: "משכנות גבעת זאב, גבעת זאב", type: "neighborhood", center: [35.159957279175835, 31.863280822120856], id: "גבעת זאב/משכנות גבעת זאב" },
  { name: "קרית ספיר, כפר סבא", type: "neighborhood", center: [34.9074000601408, 32.17702021942008], id: "כפר סבא/קרית ספיר" },
  { name: "פסגת סלע, נס ציונה", type: "neighborhood", center: [34.78977276084674, 31.927339132749577], id: "נס ציונה/פסגת סלע" },
  { name: "גנים, גני תקווה", type: "neighborhood", center: [34.87044665322704, 32.06373010463154], id: "גני תקווה/גנים" },
  { name: "נווה ים, אשקלון", type: "neighborhood", center: [34.552005822183844, 31.657569220911455], id: "אשקלון/נווה ים" },
  { name: "גני הדר, נס ציונה", type: "neighborhood", center: [34.8086908100655, 31.934298176390488], id: "נס ציונה/גני הדר" },
  { name: "אברהם דנינו,  בן גוריון,עכו", type: "neighborhood", center: [35.09452693849825, 32.92463524705354], id: "עכו/אברהם דנינו,  בן גוריון" },
  { name: "בן ציון, נתניה", type: "neighborhood", center: [34.860049225335054, 32.31465201277969], id: "נתניה/בן ציון" },
  { name: "אברמוביץ, ראשון לציון", type: "neighborhood", center: [34.80125280064762, 31.96920774795602], id: "ראשון לציון/אברמוביץ" },
  { name: "רוממה, ירושלים", type: "neighborhood", center: [35.20458115921397, 31.794640162200785], id: "ירושלים/רוממה" },
  { name: "שיכוני המזרח, ראשון לציון", type: "neighborhood", center: [34.821844610990645, 31.953007764099503], id: "ראשון לציון/שיכוני המזרח" },
  { name: "שיכון צנחנים, רמת גן", type: "neighborhood", center: [34.83111260126083, 32.05550976988118], id: "רמת גן/שיכון צנחנים" },
  { name: "גני צהלה, תל אביב יפו", type: "neighborhood", center: [34.82765484478459, 32.12457652373136], id: "תל אביב יפו/גני צהלה" },
  { name: "דרום מזרח, רעננה", type: "neighborhood", center: [34.88363788039024, 32.17555279347953], id: "רעננה/דרום מזרח" },
  { name: "תל עמל, חיפה", type: "neighborhood", center: [35.012657895580745, 32.79808343630171], id: "חיפה/תל עמל" },
  { name: "אבישור, ערד", type: "neighborhood", center: [35.20678636605232, 31.25543715426261], id: "ערד/אבישור" },
  { name: "צמרת גדרה, גדרה", type: "neighborhood", center: [34.78282606812113, 31.80632739918575], id: "גדרה/צמרת גדרה" },
  { name: "תל צור, אבן יהודה", type: "neighborhood", center: [34.89242277713291, 32.27700691344954], id: "אבן יהודה/תל צור" },
  { name: "שעריה, פתח תקווה", type: "neighborhood", center: [34.89959463598906, 32.07272947376193], id: "פתח תקווה/שעריה" },
  { name: "נווה נוף, לוד", type: "neighborhood", center: [34.90176282202211, 31.94971241091287], id: "לוד/נווה נוף" },
  { name: "קרית הלאום, ראשון לציון", type: "neighborhood", center: [34.77963947849105, 31.969467010151586], id: "ראשון לציון/קרית הלאום" },
  { name: "אביב, ראש העין", type: "neighborhood", center: [34.95044965843505, 32.095773846771486], id: "ראש העין/אביב" },
  { name: "הגורן, עתלית", type: "neighborhood", center: [34.944252016426546, 32.69432142154874], id: "עתלית/הגורן" },
  { name: "צפון מערב, בת ים", type: "neighborhood", center: [34.74457856517667, 32.02714773655979], id: "בת ים/צפון מערב" },
  { name: "שיכון עובדים, גן יבנה", type: "neighborhood", center: [34.70960321388353, 31.783287270744832], id: "גן יבנה/שיכון עובדים" },
  { name: "נאות אפק, קרית ביאליק", type: "neighborhood", center: [35.10347069932804, 32.85303557597293], id: "קרית ביאליק/נאות אפק" },
  { name: "המפדה האזרחי, חולון", type: "neighborhood", center: [34.78990226876567, 32.0216279781638], id: "חולון/המפדה האזרחי" },
  { name: "פועלי הרכבת, גבעתיים", type: "neighborhood", center: [34.817571825624164, 32.07785421395716], id: "גבעתיים/פועלי הרכבת" },
  { name: "אחוזה, חיפה", type: "neighborhood", center: [34.98663868069093, 32.789651491502546], id: "חיפה/אחוזה" },
  { name: "בר לב, מזכרת בתיה", type: "neighborhood", center: [34.84645688171553, 31.845710434626813], id: "מזכרת בתיה/בר לב" },
  { name: "קרית ים ד, קרית ים", type: "neighborhood", center: [35.08021306730513, 32.85325682772038], id: "קרית ים/קרית ים ד" },
  { name: "הרצל, באר יעקב", type: "neighborhood", center: [34.83333730736967, 31.94469900795391], id: "באר יעקב/הרצל" },
  { name: "מכבי, אבן יהודה", type: "neighborhood", center: [34.89367611504151, 32.274022230987924], id: "אבן יהודה/מכבי" },
  { name: "נווה כרמית, נס ציונה", type: "neighborhood", center: [34.79671249789796, 31.93071637127323], id: "נס ציונה/נווה כרמית" },
  { name: "מתחם הולילנד, ירושלים", type: "neighborhood", center: [35.189019130244034, 31.756585644755102], id: "ירושלים/מתחם הולילנד" },
  { name: "מרכז העיר דרום, נתניה", type: "neighborhood", center: [34.85461444775883, 32.32335981775501], id: "נתניה/מרכז העיר דרום" },
  { name: "דדו, אופקים", type: "neighborhood", center: [34.6162293575482, 31.319871761951582], id: "אופקים/דדו" },
  { name: "גבעת המבתר, ירושלים", type: "neighborhood", center: [35.231713724023244, 31.803536684816827], id: "ירושלים/גבעת המבתר" },
  { name: "מעוף, ערד", type: "neighborhood", center: [35.213328533088024, 31.268688620243818], id: "ערד/מעוף" },
  { name: "נאות בן גוריון, יבנה", type: "neighborhood", center: [34.743501035612624, 31.87483136219098], id: "יבנה/נאות בן גוריון" },
  { name: "חצרות הדר, כפר סבא", type: "neighborhood", center: [34.919424192343406, 32.17567737080648], id: "כפר סבא/חצרות הדר" },
  { name: "השכונה הצפונית, קרית אתא", type: "neighborhood", center: [35.10580666695435, 32.82075532800765], id: "קרית אתא/השכונה הצפונית" },
  { name: "רמת אברהם, בית שמש", type: "neighborhood", center: [34.98305152712968, 31.719235514122186], id: "בית שמש/רמת אברהם" },
  { name: "רמת אשכול, ירושלים", type: "neighborhood", center: [35.22477357698131, 31.802562343583595], id: "ירושלים/רמת אשכול" },
  { name: "נווה עובד, גן יבנה", type: "neighborhood", center: [34.706969618630175, 31.77988665802922], id: "גן יבנה/נווה עובד" },
  { name: "סביוני הפארק, נס ציונה", type: "neighborhood", center: [34.80251259153765, 31.935094588608163], id: "נס ציונה/סביוני הפארק" },
  { name: "נוה פז, חיפה", type: "neighborhood", center: [35.01843467880301, 32.7956923407954], id: "חיפה/נוה פז" },
  { name: "גבעת משואה, ירושלים", type: "neighborhood", center: [35.169546806454846, 31.74933633525783], id: "ירושלים/גבעת משואה" },
  { name: "שז\"ר, אור עקיבא", type: "neighborhood", center: [34.91692419040737, 32.51215472675412], id: "אור עקיבא/שז\"ר" },
  { name: "נווה רבין, שלומי", type: "neighborhood", center: [35.151648410590255, 33.07934291506173], id: "שלומי/נווה רבין" },
  { name: "מישור הגפן, אופקים", type: "neighborhood", center: [34.61074819462848, 31.31091855560243], id: "אופקים/מישור הגפן" },
  { name: "קטמון ח-ט, ירושלים", type: "neighborhood", center: [35.195689529922504, 31.75430919104381], id: "ירושלים/קטמון ח-ט" },
  { name: "ורד העמקים, יוקנעם עילית", type: "neighborhood", center: [35.08938163929757, 32.64730830683251], id: "יוקנעם עילית/ורד העמקים" },
  { name: "אזור תעשיה דרום, קרית גת", type: "neighborhood", center: [34.78332953714592, 31.596248195280268], id: "קרית גת/אזור תעשיה דרום" },
  { name: "שכון א, בני ברק", type: "neighborhood", center: [34.82914672184962, 32.08084804362163], id: "בני ברק/שכון א" },
  { name: "רמת שאול, חיפה", type: "neighborhood", center: [34.96921836652462, 32.82238291841857], id: "חיפה/רמת שאול" },
  { name: "רמת פנקס, אור יהודה", type: "neighborhood", center: [34.84136704621986, 32.03468871082714], id: "אור יהודה/רמת פנקס" },
  { name: "שכונה 7 - הציפורים, קיסריה", type: "neighborhood", center: [34.910191610388054, 32.525179108784556], id: "קיסריה/שכונה 7 - הציפורים" },
  { name: "אלון, רמת השרון", type: "neighborhood", center: [34.84418377977164, 32.147349956134526], id: "רמת השרון/אלון" },
  { name: "גבעת מיכאל, נס ציונה", type: "neighborhood", center: [34.79172175739194, 31.932880188837537], id: "נס ציונה/גבעת מיכאל" },
  { name: "קרית עבודה, חולון", type: "neighborhood", center: [34.77322528520139, 32.01850210479249], id: "חולון/קרית עבודה" },
  { name: "גבעת הזיתים, לוד", type: "neighborhood", center: [34.89556650527005, 31.946180771235998], id: "לוד/גבעת הזיתים" },
  { name: "ג'ואריש, רמלה", type: "neighborhood", center: [34.84686690284494, 31.92306041690536], id: "רמלה/ג'ואריש" },
  { name: "ז'בוטינסקי, אשקלון", type: "neighborhood", center: [34.55855382182436, 31.65385253342587], id: "אשקלון/ז'בוטינסקי" },
  { name: "השופטים, רמלה", type: "neighborhood", center: [34.87372294639816, 31.929482647935245], id: "רמלה/השופטים" },
  { name: "מתחם החותרים, טירת הכרמל", type: "neighborhood", center: [34.96608664194354, 32.75356760138179], id: "טירת הכרמל/מתחם החותרים" },
  { name: "נווה הברון, זכרון יעקב", type: "neighborhood", center: [34.949105484084775, 32.56483310790784], id: "זכרון יעקב/נווה הברון" },
  { name: "הר חומה, ירושלים", type: "neighborhood", center: [35.22402803399365, 31.722384922927827], id: "ירושלים/הר חומה" },
  { name: "נאות הנביאים, שדרות", type: "neighborhood", center: [34.587294201663184, 31.534540950083223], id: "שדרות/נאות הנביאים" },
  { name: "ה' באייר, ראש העין", type: "neighborhood", center: [34.97395382134946, 32.09675703615494], id: "ראש העין/ה' באייר" },
  { name: "רמת הדר, הוד השרון", type: "neighborhood", center: [34.880798207695904, 32.14147844976738], id: "הוד השרון/רמת הדר" },
  { name: "ביצרון, תל אביב יפו", type: "neighborhood", center: [34.795732421789964, 32.067637408863206], id: "תל אביב יפו/ביצרון" },
  { name: "רובע קדמה, קצרין", type: "neighborhood", center: [35.69585794938954, 32.98922728691628], id: "קצרין/רובע קדמה" },
  { name: "מ.פ. שועפט, ירושלים", type: "neighborhood", center: [35.248544927569746, 31.8097432722719], id: "ירושלים/מ.פ. שועפט" },
  { name: "שכונה ט, באר שבע", type: "neighborhood", center: [34.77368788171989, 31.24599941756075], id: "באר שבע/שכונה ט" },
  { name: "פארק ת.מ.ר, רחובות", type: "neighborhood", center: [34.806863319122186, 31.91170632413047], id: "רחובות/פארק ת.מ.ר" },
  { name: "רמת עמידר, רמת גן", type: "neighborhood", center: [34.83591211731455, 32.06794019120883], id: "רמת גן/רמת עמידר" },
  { name: "אוניברסיטת בן גוריון, באר שבע", type: "neighborhood", center: [34.802327535676724, 31.26279841985905], id: "באר שבע/אוניברסיטת בן גוריון" },
  { name: "יד אלעזר, נס ציונה", type: "neighborhood", center: [34.809888801440465, 31.9357919921157], id: "נס ציונה/יד אלעזר" },
  { name: "בנה ביתך, קדימה צורן", type: "neighborhood", center: [34.916660141522144, 32.26824296586004], id: "קדימה צורן/בנה ביתך" },
  { name: "פארק הנחל, באר שבע", type: "neighborhood", center: [34.81982866095694, 31.23087688539898], id: "באר שבע/פארק הנחל" },
  { name: "מרכז העיר, אילת", type: "neighborhood", center: [34.951546308885526, 29.55631090859766], id: "אילת/מרכז העיר" },
  { name: "הבוכרים, ירושלים", type: "neighborhood", center: [35.219483176374986, 31.792869676450383], id: "ירושלים/הבוכרים" },
  { name: "עזרא והארגזים, תל אביב יפו", type: "neighborhood", center: [34.79464966499284, 32.044728674826814], id: "תל אביב יפו/עזרא והארגזים" },
  { name: "קרית נחום (עיר גנים), קרית אתא", type: "neighborhood", center: [35.073588938982304, 32.80408544367175], id: "קרית אתא/קרית נחום (עיר גנים)" },
  { name: "גולן, רמת השרון", type: "neighborhood", center: [34.83484819254209, 32.1424694668902], id: "רמת השרון/גולן" },
  { name: "נווה נוי, נתיבות", type: "neighborhood", center: [34.58191402779365, 31.413622691566307], id: "נתיבות/נווה נוי" },
  { name: "שיכון עממי ג', תל אביב יפו", type: "neighborhood", center: [34.805799467928765, 32.06235148167789], id: "תל אביב יפו/שיכון עממי ג'" },
  { name: "אזור תעשיה צפון, בני ברק", type: "neighborhood", center: [34.82767275768557, 32.0977893105632], id: "בני ברק/אזור תעשיה צפון" },
  { name: "מרכז העיר, עכו", type: "neighborhood", center: [35.07511438517873, 32.92635366838257], id: "עכו/מרכז העיר" },
  { name: "נוף גלים, נתניה", type: "neighborhood", center: [34.84536997915035, 32.29689127810381], id: "נתניה/נוף גלים" },
  { name: "רובע א, אשדוד", type: "neighborhood", center: [34.644564688414356, 31.808909868277123], id: "אשדוד/רובע א" },
  { name: "נאות אשכול, גן יבנה", type: "neighborhood", center: [34.70142545968317, 31.794216550999955], id: "גן יבנה/נאות אשכול" },
  { name: "עין שרה, נהריה", type: "neighborhood", center: [35.08934341994107, 32.988787180226204], id: "נהריה/עין שרה" },
  { name: "שכונה ד, באר שבע", type: "neighborhood", center: [34.792709421643025, 31.267538337055758], id: "באר שבע/שכונה ד" },
  { name: "רובע 11, קצרין", type: "neighborhood", center: [35.68009339116575, 32.988609022087616], id: "קצרין/רובע 11" },
  { name: "מתחם גן העיר, בת ים", type: "neighborhood", center: [34.74143748210708, 32.0097707259889], id: "בת ים/מתחם גן העיר" },
  { name: "אפקה, תל אביב יפו", type: "neighborhood", center: [34.806566197238524, 32.12044279012013], id: "תל אביב יפו/אפקה" },
  { name: "נוה ישראל, הרצליה", type: "neighborhood", center: [34.83867722649959, 32.15933681730108], id: "הרצליה/נוה ישראל" },
  { name: "הדר, רמת השרון", type: "neighborhood", center: [34.8411163770443, 32.14110192641203], id: "רמת השרון/הדר" },
  { name: "הר חוצבים, ירושלים", type: "neighborhood", center: [35.21034272899959, 31.803471594630402], id: "ירושלים/הר חוצבים" },
  { name: "תוכנית ל, תל אביב יפו", type: "neighborhood", center: [34.79042072461803, 32.10756338095329], id: "תל אביב יפו/תוכנית ל" },
  { name: "מרכז העיר, לוד", type: "neighborhood", center: [34.89367313836045, 31.950959357550044], id: "לוד/מרכז העיר" },
  { name: "רחובות המדע, רחובות", type: "neighborhood", center: [34.82249912056008, 31.902799453054662], id: "רחובות/רחובות המדע" },
  { name: "קרית אליעזר פרי, פתח תקווה", type: "neighborhood", center: [34.899288555170536, 32.086281759643754], id: "פתח תקווה/קרית אליעזר פרי" },
  { name: "מכבי, רמלה", type: "neighborhood", center: [34.867465754897715, 31.940204754542222], id: "רמלה/מכבי" },
  { name: "סיטי, גבעתיים", type: "neighborhood", center: [34.80008159537594, 32.08015558041969], id: "גבעתיים/סיטי" },
  { name: "גבעת טל, קרית אתא", type: "neighborhood", center: [35.1392763856202, 32.80370478498493], id: "קרית אתא/גבעת טל" },
  { name: "אזור תעשיה צפוני, לוד", type: "neighborhood", center: [34.89722163549529, 31.965763243931853], id: "לוד/אזור תעשיה צפוני" },
  { name: "גלי הים, נתניה", type: "neighborhood", center: [34.846053895299946, 32.30016635921873], id: "נתניה/גלי הים" },
  { name: "שכונה 8 - הביכורים, קיסריה", type: "neighborhood", center: [34.90668917694348, 32.50702388513598], id: "קיסריה/שכונה 8 - הביכורים" },
  { name: "האירוסים, כרמיאל", type: "neighborhood", center: [35.2899267308777, 32.90687147406659], id: "כרמיאל/האירוסים" },
  { name: "אזור תעשייה, גן יבנה", type: "neighborhood", center: [34.70362736251162, 31.774667775618852], id: "גן יבנה/אזור תעשייה" },
  { name: "שיכון דן, תל אביב יפו", type: "neighborhood", center: [34.828834085525045, 32.111417349033054], id: "תל אביב יפו/שיכון דן" },
  { name: "קריית חזני, פתח תקווה", type: "neighborhood", center: [34.89677096665823, 32.0923767287634], id: "פתח תקווה/קריית חזני" },
  { name: "הירוקה המערבית, הרצליה", type: "neighborhood", center: [34.83361552409001, 32.175358109217896], id: "הרצליה/הירוקה המערבית" },
  { name: "ברית כהונה, נתיבות", type: "neighborhood", center: [34.59792971832444, 31.426536085403505], id: "נתיבות/ברית כהונה" },
  { name: "רמת סמל, נס ציונה", type: "neighborhood", center: [34.79787687742246, 31.934462683751697], id: "נס ציונה/רמת סמל" },
  { name: "נוף הגלבוע, בית שאן", type: "neighborhood", center: [35.49105554722463, 32.49593713295021], id: "בית שאן/נוף הגלבוע" },
  { name: "רחביה, ירושלים", type: "neighborhood", center: [35.2126523592796, 31.77361163958675], id: "ירושלים/רחביה" },
  { name: "מורשת, מודיעין מכבים רעות", type: "neighborhood", center: [34.98394080866265, 31.90254636994967], id: "מודיעין מכבים רעות/מורשת" },
  { name: "מרום ראשון, ראשון לציון", type: "neighborhood", center: [34.82383572987182, 31.969013939148113], id: "ראשון לציון/מרום ראשון" },
  { name: "מאה שערים, ירושלים", type: "neighborhood", center: [35.22205387647306, 31.786571164550793], id: "ירושלים/מאה שערים" },
  { name: "יד חרוצים, תל אביב יפו", type: "neighborhood", center: [34.78419567346857, 32.06282927147674], id: "תל אביב יפו/יד חרוצים" },
  { name: "מצפה נבו, מעלה אדומים", type: "neighborhood", center: [35.30195826954075, 31.792841131125492], id: "מעלה אדומים/מצפה נבו" },
  { name: "נווה חורש, דימונה", type: "neighborhood", center: [35.0405807290144, 31.079873166097432], id: "דימונה/נווה חורש" },
  { name: "שכונה 12, קיסריה", type: "neighborhood", center: [34.90584576798049, 32.48376269286462], id: "קיסריה/שכונה 12" },
  { name: "כרמל מערבי, חיפה", type: "neighborhood", center: [34.9732468205444, 32.81062369934075], id: "חיפה/כרמל מערבי" },
  { name: "רמת אילן, גבעת שמואל", type: "neighborhood", center: [34.84719342830475, 32.06841005149252], id: "גבעת שמואל/רמת אילן" },
  { name: "נאות קטיף, דימונה", type: "neighborhood", center: [35.04111543986605, 31.070737961896143], id: "דימונה/נאות קטיף" },
  { name: "פארק הסייבר CyberSpark, באר שבע", type: "neighborhood", center: [34.81688938913989, 31.261745435130806], id: "באר שבע/פארק הסייבר CyberSpark" },
  { name: "פרפר, קרית ביאליק", type: "neighborhood", center: [35.09469894630143, 32.84321615254255], id: "קרית ביאליק/פרפר" },
  { name: "יוספטל, פתח תקווה", type: "neighborhood", center: [34.904666926079656, 32.09377257645294], id: "פתח תקווה/יוספטל" },
  { name: "בסמת טבעון, בסמת טבעון", type: "neighborhood", center: [35.14782763835728, 32.74100119419994], id: "בסמת טבעון/בסמת טבעון" },
  { name: "הרי\"ף,  בנה ביתך,אופקים", type: "neighborhood", center: [34.623776727057844, 31.312629311333477], id: "אופקים/הרי\"ף,  בנה ביתך" },
  { name: "נגה, תל אביב יפו", type: "neighborhood", center: [34.762487353345534, 32.05596459947245], id: "תל אביב יפו/נגה" },
  { name: "קהילת העבריים, דימונה", type: "neighborhood", center: [35.04119383409473, 31.067242723241048], id: "דימונה/קהילת העבריים" },
  { name: "קרית הרצוג, בני ברק", type: "neighborhood", center: [34.84279936660135, 32.09829906841861], id: "בני ברק/קרית הרצוג" },
  { name: "נוף לכרמל, יוקנעם עילית", type: "neighborhood", center: [35.105046392486116, 32.657336294441734], id: "יוקנעם עילית/נוף לכרמל" },
  { name: "התקווה, תל אביב יפו", type: "neighborhood", center: [34.791588410528036, 32.05056212317917], id: "תל אביב יפו/התקווה" },
  { name: "קרית ויז'ניץ, בני ברק", type: "neighborhood", center: [34.8364651497425, 32.07488889269712], id: "בני ברק/קרית ויז'ניץ" },
  { name: "עתיר ידע, כפר סבא", type: "neighborhood", center: [34.9319313616345, 32.16367481890924], id: "כפר סבא/עתיר ידע" },
  { name: "חמניות, שוהם", type: "neighborhood", center: [34.947259592788775, 32.0034194429846], id: "שוהם/חמניות" },
  { name: "רמת הדר החדשה, גבעת שמואל", type: "neighborhood", center: [34.853379861154835, 32.07009855313744], id: "גבעת שמואל/רמת הדר החדשה" },
  { name: "צוקי אביב, תל אביב יפו", type: "neighborhood", center: [34.79001509537081, 32.1306909211345], id: "תל אביב יפו/צוקי אביב" },
  { name: "כבביר, חיפה", type: "neighborhood", center: [34.9698014543401, 32.80603537552172], id: "חיפה/כבביר" },
  { name: "שכונה ג, אריאל", type: "neighborhood", center: [35.185001917241415, 32.10671123146281], id: "אריאל/שכונה ג" },
  { name: "נאות גולדה, נתניה", type: "neighborhood", center: [34.85335068203143, 32.27703173554903], id: "נתניה/נאות גולדה" },
  { name: "א טור, ירושלים", type: "neighborhood", center: [35.248674188849385, 31.77589516816047], id: "ירושלים/א טור" },
  { name: "פארק תעשיה אפק, ראש העין", type: "neighborhood", center: [34.96840386749445, 32.10708388159078], id: "ראש העין/פארק תעשיה אפק" },
  { name: "שבזי, כפר סבא", type: "neighborhood", center: [34.90512017147308, 32.19011935301793], id: "כפר סבא/שבזי" },
  { name: "הלבנון, קרית שמונה", type: "neighborhood", center: [35.57093373850215, 33.22069267988775], id: "קרית שמונה/הלבנון" },
  { name: "נוה צה\"ל, תל אביב יפו", type: "neighborhood", center: [34.79798030201158, 32.05190955212101], id: "תל אביב יפו/נוה צה\"ל" },
  { name: "רמת אלמוגי, חיפה", type: "neighborhood", center: [35.00316746520746, 32.776394972979766], id: "חיפה/רמת אלמוגי" },
  { name: "נחלת יצחק, תל אביב יפו", type: "neighborhood", center: [34.7980368647452, 32.07527187938007], id: "תל אביב יפו/נחלת יצחק" },
  { name: "חן הצפון, פתח תקווה", type: "neighborhood", center: [34.89999609952937, 32.102078453699825], id: "פתח תקווה/חן הצפון" },
  { name: "רמת נבון, כרמיאל", type: "neighborhood", center: [35.26830463334558, 32.90962143419327], id: "כרמיאל/רמת נבון" },
  { name: "מרכז אזרחי, באר שבע", type: "neighborhood", center: [34.79947556705247, 31.24483991013433], id: "באר שבע/מרכז אזרחי" },
  { name: "אור ים, אור עקיבא", type: "neighborhood", center: [34.91983265595681, 32.48894175628998], id: "אור עקיבא/אור ים" },
  { name: "נוה הדרים, ראשון לציון", type: "neighborhood", center: [34.81654942938105, 31.958909051623383], id: "ראשון לציון/נוה הדרים" },
  { name: "גאולים, טבריה", type: "neighborhood", center: [35.5388234843998, 32.781304288656024], id: "טבריה/גאולים" },
  { name: "פנינת העמק, מגדל העמק", type: "neighborhood", center: [35.247202908614504, 32.67893640765136], id: "מגדל העמק/פנינת העמק" },
  { name: "גני צבי, הוד השרון", type: "neighborhood", center: [34.87792955979359, 32.14874725609957], id: "הוד השרון/גני צבי" },
  { name: "רמת בית שמש ד-1, בית שמש", type: "neighborhood", center: [34.97358347858401, 31.7076813252215], id: "בית שמש/רמת בית שמש ד-1" },
  { name: "בנימינה, בנימינה גבעת עדה", type: "neighborhood", center: [34.94845178732811, 32.51943755081307], id: "בנימינה גבעת עדה/בנימינה" },
  { name: "נווה יוני נתניהו, עכו", type: "neighborhood", center: [35.08825855370782, 32.92556530132282], id: "עכו/נווה יוני נתניהו" },
  { name: "שכונה צפון מזרחית, נהריה", type: "neighborhood", center: [35.109320550674234, 33.015207656181815], id: "נהריה/שכונה צפון מזרחית" },
  { name: "נוה שאנן, חיפה", type: "neighborhood", center: [35.01806712134205, 32.78672172081531], id: "חיפה/נוה שאנן" },
  { name: "גבעת התמר, אפרת", type: "neighborhood", center: [35.16433343995993, 31.679561720656036], id: "אפרת/גבעת התמר" },
  { name: "מרכז כרכור, פרדס חנה כרכור", type: "neighborhood", center: [34.99267520288941, 32.4750087223279], id: "פרדס חנה כרכור/מרכז כרכור" },
  { name: "נאות יהודית, חולון", type: "neighborhood", center: [34.794042632918185, 32.0126356530376], id: "חולון/נאות יהודית" },
  { name: "משואה (גבעת C), מודיעין מכבים רעות", type: "neighborhood", center: [35.00873899281582, 31.894629321264475], id: "מודיעין מכבים רעות/משואה (גבעת C)" },
  { name: "נווה שלו, זכרון יעקב", type: "neighborhood", center: [34.95341503591626, 32.579586188049284], id: "זכרון יעקב/נווה שלו" },
  { name: "שיכון חברת חשמל, גבעתיים", type: "neighborhood", center: [34.80771244266775, 32.06334838270345], id: "גבעתיים/שיכון חברת חשמל" },
  { name: "צמח השדה, מעלה אדומים", type: "neighborhood", center: [35.302253048839546, 31.767243756603314], id: "מעלה אדומים/צמח השדה" },
  { name: "רובע ד, אשדוד", type: "neighborhood", center: [34.64074058563594, 31.80064903636028], id: "אשדוד/רובע ד" },
  { name: "רמת נריה, בית שמש", type: "neighborhood", center: [34.989136182963236, 31.735757410256564], id: "בית שמש/רמת נריה" },
  { name: "קרית גנים, באר שבע", type: "neighborhood", center: [34.78533492121225, 31.255039374102267], id: "באר שבע/קרית גנים" },
  { name: "מלכי ישראל, קרית גת", type: "neighborhood", center: [34.75761048044844, 31.615528824535804], id: "קרית גת/מלכי ישראל" },
  { name: "קריית כרמים, ראשון לציון", type: "neighborhood", center: [34.77916391163607, 31.974193842757664], id: "ראשון לציון/קריית כרמים" },
  { name: "האצטדיון, רמת גן", type: "neighborhood", center: [34.82504948065684, 32.10094260929954], id: "רמת גן/האצטדיון" },
  { name: "אושיות, רחובות", type: "neighborhood", center: [34.82157886326511, 31.882459998881817], id: "רחובות/אושיות" },
  { name: "סיגליות, באר שבע", type: "neighborhood", center: [34.751161645754586, 31.259994051897905], id: "באר שבע/סיגליות" },
  { name: "שיכון מפ\"ם, פתח תקווה", type: "neighborhood", center: [34.888453421737005, 32.07505464244273], id: "פתח תקווה/שיכון מפ\"ם" },
  { name: "המע\"ר, חריש", type: "neighborhood", center: [35.05573902449275, 32.46139197194184], id: "חריש/המע\"ר" },
  { name: "שיכון הפועל המזרחי, פתח תקווה", type: "neighborhood", center: [34.902084583482186, 32.09867760030735], id: "פתח תקווה/שיכון הפועל המזרחי" },
  { name: "רמת מנחם בגין, צפת", type: "neighborhood", center: [35.51070626091156, 32.95998954648618], id: "צפת/רמת מנחם בגין" },
  { name: "רובע בתרא, קצרין", type: "neighborhood", center: [35.68175686174211, 32.996259540587886], id: "קצרין/רובע בתרא" },
  { name: "רמב\"ם, חדרה", type: "neighborhood", center: [34.91374400730232, 32.438473142723524], id: "חדרה/רמב\"ם" },
  { name: "קרית מנחם, ירושלים", type: "neighborhood", center: [35.16547107144271, 31.758301579108313], id: "ירושלים/קרית מנחם" },
  { name: "קרית צאנז, נתניה", type: "neighborhood", center: [34.85726119492185, 32.344091457623534], id: "נתניה/קרית צאנז" },
  { name: "קרית חתני פרס נובל, ראשון לציון", type: "neighborhood", center: [34.76506399942382, 31.968787717137918], id: "ראשון לציון/קרית חתני פרס נובל" },
  { name: "הגפן, ביתר עילית", type: "neighborhood", center: [35.115228893784696, 31.702969737360714], id: "ביתר עילית/הגפן" },
  { name: "נאות שקד, נתניה", type: "neighborhood", center: [34.850031649913625, 32.29624020301457], id: "נתניה/נאות שקד" },
  { name: "הכרם ב, עכו", type: "neighborhood", center: [35.08449699602564, 32.94374541178212], id: "עכו/הכרם ב" },
  { name: "רמת ורבר, פתח תקווה", type: "neighborhood", center: [34.87323824017863, 32.0877532776476], id: "פתח תקווה/רמת ורבר" },
  { name: "קרית בן צבי (רסקו), רעננה", type: "neighborhood", center: [34.87394727505269, 32.19569353451112], id: "רעננה/קרית בן צבי (רסקו)" },
  { name: "האקליפטוס, בית שאן", type: "neighborhood", center: [35.490571761158364, 32.49274476861177], id: "בית שאן/האקליפטוס" },
  { name: "נאות הרצל, נתניה", type: "neighborhood", center: [34.868586318894394, 32.33520055349129], id: "נתניה/נאות הרצל" },
  { name: "ההעפלה, קרית גת", type: "neighborhood", center: [34.7585958088218, 31.60403167297243], id: "קרית גת/ההעפלה" },
  { name: "שכונה 11 - אבני חן, קיסריה", type: "neighborhood", center: [34.90656107772019, 32.488219307410056], id: "קיסריה/שכונה 11 - אבני חן" },
  { name: "קרית אלון, פתח תקווה", type: "neighborhood", center: [34.90422903432518, 32.08848002234125], id: "פתח תקווה/קרית אלון" },
  { name: "נחלאות, ירושלים", type: "neighborhood", center: [35.21148754722998, 31.78306669681739], id: "ירושלים/נחלאות" },
  { name: "נחלת עדה, הרצליה", type: "neighborhood", center: [34.8377307252042, 32.184144904122604], id: "הרצליה/נחלת עדה" },
  { name: "הורד, מגדל העמק", type: "neighborhood", center: [35.2380424202897, 32.672575542069225], id: "מגדל העמק/הורד" },
  { name: "גן חק\"ל, רמלה", type: "neighborhood", center: [34.88878084967993, 31.9295899196663], id: "רמלה/גן חק\"ל" },
  { name: "רמת אהרון, רחובות", type: "neighborhood", center: [34.825394182455526, 31.883982315720097], id: "רחובות/רמת אהרון" },
  { name: "נווה עוז הירוקה, פתח תקווה", type: "neighborhood", center: [34.85849994339747, 32.08282596957988], id: "פתח תקווה/נווה עוז הירוקה" },
  { name: "מעלות דפנה, ירושלים", type: "neighborhood", center: [35.22731735675755, 31.797518506588112], id: "ירושלים/מעלות דפנה" },
  { name: "רמת אשכול, לוד", type: "neighborhood", center: [34.90106628243854, 31.954074285106977], id: "לוד/רמת אשכול" },
  { name: "גני רבין, אור עקיבא", type: "neighborhood", center: [34.91928420929101, 32.4982565245343], id: "אור עקיבא/גני רבין" },
  { name: "מתחם 1200, הוד השרון", type: "neighborhood", center: [34.9007717758839, 32.16699232757952], id: "הוד השרון/מתחם 1200" },
  { name: "חבצלת הצעירה, קרית עקרון", type: "neighborhood", center: [34.81909732631604, 31.866379137320838], id: "קרית עקרון/חבצלת הצעירה" },
  { name: "הסיטי, אשקלון", type: "neighborhood", center: [34.55775690389128, 31.6687260325197], id: "אשקלון/הסיטי" },
  { name: "הדרי סמל, נס ציונה", type: "neighborhood", center: [34.791501139209466, 31.930757422059095], id: "נס ציונה/הדרי סמל" },
  { name: "לב תל אביב החלק המערבי, תל אביב יפו", type: "neighborhood", center: [34.76994169806475, 32.07424019237352], id: "תל אביב יפו/לב תל אביב החלק המערבי" },
  { name: "נאות כרמל, טירת הכרמל", type: "neighborhood", center: [34.97454858412238, 32.77033129535147], id: "טירת הכרמל/נאות כרמל" },
  { name: "העיר העתיקה, עכו", type: "neighborhood", center: [35.06959887393054, 32.92233605601571], id: "עכו/העיר העתיקה" },
  { name: "נווה ניר, נס ציונה", type: "neighborhood", center: [34.79427597894544, 31.92962152637056], id: "נס ציונה/נווה ניר" },
  { name: "2005, רעננה", type: "neighborhood", center: [34.8590200161646, 32.18089163564425], id: "רעננה/2005" },
  { name: "אליהו הנביא, ירוחם", type: "neighborhood", center: [34.92757633955985, 30.986270057756037], id: "ירוחם/אליהו הנביא" },
  { name: "נחלת יהודה, ראשון לציון", type: "neighborhood", center: [34.805046450851854, 31.983011807202836], id: "ראשון לציון/נחלת יהודה" },
  { name: "רמת יצחק, רמת גן", type: "neighborhood", center: [34.82777213750832, 32.07399826066287], id: "רמת גן/רמת יצחק" },
  { name: "נוף מדבר, ירוחם", type: "neighborhood", center: [34.92166944766169, 30.986352518568488], id: "ירוחם/נוף מדבר" },
  { name: "סביוני עתלית, עתלית", type: "neighborhood", center: [34.94913998101882, 32.69733407915795], id: "עתלית/סביוני עתלית" },
  { name: "קרית שחקים, הרצליה", type: "neighborhood", center: [34.82053633926516, 32.163161469217684], id: "הרצליה/קרית שחקים" },
  { name: "מרום נווה, רמת גן", type: "neighborhood", center: [34.83074883975082, 32.07205006645912], id: "רמת גן/מרום נווה" },
  { name: "הר נוף, דימונה", type: "neighborhood", center: [35.0325190101941, 31.08552242990792], id: "דימונה/הר נוף" },
  { name: "נווה עובד, פרדס חנה כרכור", type: "neighborhood", center: [35.00212271205333, 32.47765058326565], id: "פרדס חנה כרכור/נווה עובד" },
  { name: "הל\"ה, גבעתיים", type: "neighborhood", center: [34.80470001622818, 32.07386883217611], id: "גבעתיים/הל\"ה" },
  { name: "תל ברוך, תל אביב יפו", type: "neighborhood", center: [34.81463219427184, 32.11845774231265], id: "תל אביב יפו/תל ברוך" },
  { name: "הדגל, נס ציונה", type: "neighborhood", center: [34.807502116356275, 31.92202274118594], id: "נס ציונה/הדגל" },
  { name: "סביוני ים, קרית ים", type: "neighborhood", center: [35.084400511003224, 32.85836783305328], id: "קרית ים/סביוני ים" },
  { name: "שחמון, אילת", type: "neighborhood", center: [34.93272647884211, 29.544720328994558], id: "אילת/שחמון" },
  { name: "תל השומר, רמת גן", type: "neighborhood", center: [34.84585696307905, 32.04745332583431], id: "רמת גן/תל השומר" },
  { name: "עין כרם, ירושלים", type: "neighborhood", center: [35.161252932402185, 31.766911714011957], id: "ירושלים/עין כרם" },
  { name: "רביבים, תל אביב יפו", type: "neighborhood", center: [34.829357648044414, 32.11666698280274], id: "תל אביב יפו/רביבים" },
  { name: "הוותיקה, קרית עקרון", type: "neighborhood", center: [34.82430931541078, 31.86091314003439], id: "קרית עקרון/הוותיקה" },
  { name: "הארזים, אופקים", type: "neighborhood", center: [34.61864653730159, 31.310962910632988], id: "אופקים/הארזים" },
  { name: "נוף סביון, גני תקווה", type: "neighborhood", center: [34.86899347499942, 32.06167920970684], id: "גני תקווה/נוף סביון" },
  { name: "מוריה (בוכמן דרום), מודיעין מכבים רעות", type: "neighborhood", center: [35.00675614644288, 31.881494871619076], id: "מודיעין מכבים רעות/מוריה (בוכמן דרום)" },
  { name: "רסקו, נהריה", type: "neighborhood", center: [35.100822994492184, 33.022655644008566], id: "נהריה/רסקו" },
  { name: "נחלים, כפר ורדים", type: "neighborhood", center: [35.256763132611184, 32.99446492013542], id: "כפר ורדים/נחלים" },
  { name: "מזרעה צפון, מזרעה", type: "neighborhood", center: [35.1057762102202, 32.9842031203595], id: "מזרעה/מזרעה צפון" },
  { name: "חצרות המושבה, רחובות", type: "neighborhood", center: [34.792953399864246, 31.892659208506362], id: "רחובות/חצרות המושבה" },
  { name: "נס לגויים, תל אביב יפו", type: "neighborhood", center: [34.764919601423216, 32.045656183349166], id: "תל אביב יפו/נס לגויים" },
  { name: "גאולה, ירושלים", type: "neighborhood", center: [35.21923616768575, 31.78920048528916], id: "ירושלים/גאולה" },
  { name: "הר הרצל, ירושלים", type: "neighborhood", center: [35.173971914151, 31.774114734324804], id: "ירושלים/הר הרצל" },
  { name: "עמידר, טירת הכרמל", type: "neighborhood", center: [34.970543124131424, 32.761321858193426], id: "טירת הכרמל/עמידר" },
  { name: "בנות חיל, ראשון לציון", type: "neighborhood", center: [34.79422797884102, 31.97394225822536], id: "ראשון לציון/בנות חיל" },
  { name: "נוף העמק, יוקנעם עילית", type: "neighborhood", center: [35.109110801342965, 32.659702809178796], id: "יוקנעם עילית/נוף העמק" },
  { name: "משקפיים, בית שמש", type: "neighborhood", center: [35.00382933513374, 31.714004539505154], id: "בית שמש/משקפיים" },
  { name: "אפיקי דימרי, אופקים", type: "neighborhood", center: [34.638034983603134, 31.296457825221395], id: "אופקים/אפיקי דימרי" },
  { name: "קרית אונו הותיקה, קרית אונו", type: "neighborhood", center: [34.855033286219495, 32.06282004456575], id: "קרית אונו/קרית אונו הותיקה" },
  { name: "ערבה, אילת", type: "neighborhood", center: [34.94462752215529, 29.5605461395954], id: "אילת/ערבה" },
  { name: "נוה אחיעזר, בני ברק", type: "neighborhood", center: [34.84018419375801, 32.07296843834895], id: "בני ברק/נוה אחיעזר" },
  { name: "ברנדייס, חדרה", type: "neighborhood", center: [34.91904680299982, 32.42206174073973], id: "חדרה/ברנדייס" },
  { name: "בת גלים, חיפה", type: "neighborhood", center: [34.97984255885558, 32.8331106558423], id: "חיפה/בת גלים" },
  { name: "קרית שפרינצק, חיפה", type: "neighborhood", center: [34.962240713227835, 32.820019113204474], id: "חיפה/קרית שפרינצק" },
  { name: "נווה מנחם (נחל עשן), באר שבע", type: "neighborhood", center: [34.76165142981523, 31.266105395167926], id: "באר שבע/נווה מנחם (נחל עשן)" },
  { name: "נאות אשקלון, אשקלון", type: "neighborhood", center: [34.57896356106105, 31.66307873406891], id: "אשקלון/נאות אשקלון" },
  { name: "חמודות, שלומי", type: "neighborhood", center: [35.146485656570086, 33.0632079079111], id: "שלומי/חמודות" },
  { name: "כצנלסון, ראשון לציון", type: "neighborhood", center: [34.79373368663739, 31.967958321595273], id: "ראשון לציון/כצנלסון" },
  { name: "חבצלת, רחובות", type: "neighborhood", center: [34.8148524422394, 31.87525324673406], id: "רחובות/חבצלת" },
  { name: "גבעת כצנלסון,  גבעת שרת וקרית עשור,נהריה", type: "neighborhood", center: [35.104958419476795, 33.01033619120577], id: "נהריה/גבעת כצנלסון,  גבעת שרת וקרית עשור" },
  { name: "שלב ג', כפר ורדים", type: "neighborhood", center: [35.24728488608875, 33.004585143904514], id: "כפר ורדים/שלב ג'" },
  { name: "כפר אז\"ר, רמת גן", type: "neighborhood", center: [34.84048178240173, 32.05713954919779], id: "רמת גן/כפר אז\"ר" },
  { name: "רמב\"ם, ראש העין", type: "neighborhood", center: [34.940448916499186, 32.0990377219036], id: "ראש העין/רמב\"ם" },
  { name: "טמרה דרום, טמרה", type: "neighborhood", center: [35.189433137029305, 32.84512615603213], id: "טמרה/טמרה דרום" },
  { name: "קרית אליהו גולומב, רעננה", type: "neighborhood", center: [34.878106183779565, 32.188677899409825], id: "רעננה/קרית אליהו גולומב" },
  { name: "זאב, נצרת עילית", type: "neighborhood", center: [35.32765133658103, 32.70337115513732], id: "נצרת עילית/זאב" },
  { name: "שרת, לוד", type: "neighborhood", center: [34.888266296226696, 31.9514293240617], id: "לוד/שרת" },
  { name: "רחובות הצעירה, רחובות", type: "neighborhood", center: [34.792440830395904, 31.88459865383651], id: "רחובות/רחובות הצעירה" },
  { name: "רמת הנשיא, בת ים", type: "neighborhood", center: [34.75538299753253, 32.009500971869215], id: "בת ים/רמת הנשיא" },
  { name: "מרכז העיר, נהריה", type: "neighborhood", center: [35.09361690798808, 33.00616818337356], id: "נהריה/מרכז העיר" },
  { name: "נאות המכבים, גן יבנה", type: "neighborhood", center: [34.69301537578729, 31.783009002566335], id: "גן יבנה/נאות המכבים" },
  { name: "קרית הישיבה, בני ברק", type: "neighborhood", center: [34.83361958776642, 32.08307421248898], id: "בני ברק/קרית הישיבה" },
  { name: "הגבעה, אבן יהודה", type: "neighborhood", center: [34.88952342380698, 32.27368494767282], id: "אבן יהודה/הגבעה" },
  { name: "אגמים, אשקלון", type: "neighborhood", center: [34.56567645835566, 31.649085189529206], id: "אשקלון/אגמים" },
  { name: "נוה דורון, באר יעקב", type: "neighborhood", center: [34.84576509222558, 31.936438641512066], id: "באר יעקב/נוה דורון" },
  { name: "אזור תעשיה נווה נאמן, הוד השרון", type: "neighborhood", center: [34.89646660125798, 32.13175917910901], id: "הוד השרון/אזור תעשיה נווה נאמן" },
  { name: "נוה פרדסים, פרדס חנה כרכור", type: "neighborhood", center: [34.95362330346121, 32.46749325958798], id: "פרדס חנה כרכור/נוה פרדסים" },
  { name: "גנים א, אילת", type: "neighborhood", center: [34.94125040960344, 29.551063822134694], id: "אילת/גנים א" },
  { name: "רמת חן, נתניה", type: "neighborhood", center: [34.85823331133821, 32.31043016565627], id: "נתניה/רמת חן" },
  { name: "נווה איתמר, נתניה", type: "neighborhood", center: [34.87396287713657, 32.326287693081824], id: "נתניה/נווה איתמר" },
  { name: "גבעת סביון, בית שמש", type: "neighborhood", center: [34.976640947447564, 31.74329061819122], id: "בית שמש/גבעת סביון" },
  { name: "אזור תעשיה ב, חולון", type: "neighborhood", center: [34.8073020267926, 32.01441191374329], id: "חולון/אזור תעשיה ב" },
  { name: "שכונה מזרחית, ביר אל-מכסור", type: "neighborhood", center: [35.22791487898107, 32.772985814013936], id: "ביר אל-מכסור/שכונה מזרחית" },
  { name: "אחיסמך, לוד", type: "neighborhood", center: [34.89838958615955, 31.938588472220946], id: "לוד/אחיסמך" },
  { name: "פלוס 200, טבריה", type: "neighborhood", center: [35.517497517593306, 32.78460839026004], id: "טבריה/פלוס 200" },
  { name: "הצפון הישן החלק הדרום מזרחי, תל אביב יפו", type: "neighborhood", center: [34.7774882206967, 32.07754698875051], id: "תל אביב יפו/הצפון הישן החלק הדרום מזרחי" },
  { name: "עיסאוויה, ירושלים", type: "neighborhood", center: [35.25049829374042, 31.798257248820406], id: "ירושלים/עיסאוויה" },
  { name: "מרכז העיר ב', רמת גן", type: "neighborhood", center: [34.81607590035154, 32.08168089154407], id: "רמת גן/מרכז העיר ב'" },
  { name: "אבן עזרא, אשקלון", type: "neighborhood", center: [34.59493852591713, 31.673098084557644], id: "אשקלון/אבן עזרא" },
  { name: "גבעת רם, ירושלים", type: "neighborhood", center: [35.197437834790236, 31.77325757396082], id: "ירושלים/גבעת רם" },
  { name: "גני סביון, גני תקווה", type: "neighborhood", center: [34.878498737815065, 32.06551212859038], id: "גני תקווה/גני סביון" },
  { name: "שכון ה', בני ברק", type: "neighborhood", center: [34.84221352917211, 32.09030005171496], id: "בני ברק/שכון ה'" },
  { name: "קדמת ראשון, ראשון לציון", type: "neighborhood", center: [34.81289083000875, 31.97139462271234], id: "ראשון לציון/קדמת ראשון" },
  { name: "הראשונים, ראשון לציון", type: "neighborhood", center: [34.806388080984476, 31.955763912347805], id: "ראשון לציון/הראשונים" },
  { name: "המרינה, הרצליה", type: "neighborhood", center: [34.794423681719174, 32.161364687536484], id: "הרצליה/המרינה" },
  { name: "רובע ו, אשדוד", type: "neighborhood", center: [34.655691735393304, 31.793488066297027], id: "אשדוד/רובע ו" },
  { name: "סטלה מריס, חיפה", type: "neighborhood", center: [34.971367460862545, 32.82623023812739], id: "חיפה/סטלה מריס" },
  { name: "אגמים מזרח, אשקלון", type: "neighborhood", center: [34.575190952776, 31.650010280738687], id: "אשקלון/אגמים מזרח" },
  { name: "פרחי הדר, פרדס חנה כרכור", type: "neighborhood", center: [34.98065590651115, 32.465368431459204], id: "פרדס חנה כרכור/פרחי הדר" },
  { name: "נחלת בנימין, תל אביב יפו", type: "neighborhood", center: [34.769362074935984, 32.066312014324936], id: "תל אביב יפו/נחלת בנימין" },
  { name: "עין גנים, פתח תקווה", type: "neighborhood", center: [34.89400440411584, 32.08833350541734], id: "פתח תקווה/עין גנים" },
  { name: "רמת אשכול, חיפה", type: "neighborhood", center: [34.984599508144655, 32.779175806412546], id: "חיפה/רמת אשכול" },
  { name: "קרית רבין, מגדל העמק", type: "neighborhood", center: [35.24039845176437, 32.68563626992228], id: "מגדל העמק/קרית רבין" },
  { name: "נחליאל/גבעת ביל\"ו, חדרה", type: "neighborhood", center: [34.92816336920863, 32.44271125317864], id: "חדרה/נחליאל;גבעת ביל\"ו" },
  { name: "דרום רוטשילד, תל אביב יפו", type: "neighborhood", center: [34.773430137570976, 32.06256983568228], id: "תל אביב יפו/דרום רוטשילד" },
  { name: "רמת ספיר, חיפה", type: "neighborhood", center: [35.00448739926915, 32.78630883478411], id: "חיפה/רמת ספיר" },
  { name: "קרית קריניצי, רמת גן", type: "neighborhood", center: [34.847381665346994, 32.05427469297659], id: "רמת גן/קרית קריניצי" },
  { name: "התמר, ביתר עילית", type: "neighborhood", center: [35.10261660397857, 31.697738940453355], id: "ביתר עילית/התמר" },
  { name: "נחלת שמעון, בני ברק", type: "neighborhood", center: [34.83603808031605, 32.09856378883622], id: "בני ברק/נחלת שמעון" },
  { name: "נוף כנרת, טבריה", type: "neighborhood", center: [35.50070826447775, 32.7842648305553], id: "טבריה/נוף כנרת" },
  { name: "שכונה 4 - ההדרים, קיסריה", type: "neighborhood", center: [34.904263922466455, 32.51691741106487], id: "קיסריה/שכונה 4 - ההדרים" },
  { name: "גבעת עדה, בנימינה גבעת עדה", type: "neighborhood", center: [35.004354311808655, 32.52022115818451], id: "בנימינה גבעת עדה/גבעת עדה" },
  { name: "הדקל, אפרת", type: "neighborhood", center: [35.15607734721626, 31.663369759015318], id: "אפרת/הדקל" },
  { name: "הדס, ביתר עילית", type: "neighborhood", center: [35.10744955188535, 31.6917742442707], id: "ביתר עילית/הדס" },
  { name: "כלי שיר, מעלה אדומים", type: "neighborhood", center: [35.29687990536522, 31.78196461901361], id: "מעלה אדומים/כלי שיר" },
  { name: "העיר העתיקה, טבריה", type: "neighborhood", center: [35.54250929977556, 32.786706902103276], id: "טבריה/העיר העתיקה" },
  { name: "מרכז העיר, פתח תקווה", type: "neighborhood", center: [34.884304991793805, 32.08978674319293], id: "פתח תקווה/מרכז העיר" },
  { name: "המושבה, מזכרת בתיה", type: "neighborhood", center: [34.84024518766576, 31.85316087582829], id: "מזכרת בתיה/המושבה" },
  { name: "תל גנים, רמת גן", type: "neighborhood", center: [34.818436525385664, 32.06570500803383], id: "רמת גן/תל גנים" },
  { name: "מתחם מערבי, טייבה", type: "neighborhood", center: [35.00210389578339, 32.27660634032237], id: "טייבה/מתחם מערבי" },
  { name: "מגדים, כרמיאל", type: "neighborhood", center: [35.29203823773542, 32.92005883744619], id: "כרמיאל/מגדים" },
  { name: "הפרחים, הוד השרון", type: "neighborhood", center: [34.87846878911978, 32.15649666961509], id: "הוד השרון/הפרחים" },
  { name: "המרכז הנפתי, אשקלון", type: "neighborhood", center: [34.56582515478096, 31.664267258385546], id: "אשקלון/המרכז הנפתי" },
  { name: "ואדי ג'וז, ירושלים", type: "neighborhood", center: [35.23871745370866, 31.789873234544057], id: "ירושלים/ואדי ג'וז" },
  { name: "מרכז העיר א', רמת גן", type: "neighborhood", center: [34.81015726248695, 32.08197230309281], id: "רמת גן/מרכז העיר א'" },
  { name: "שמבור, חיפה", type: "neighborhood", center: [34.98479240541939, 32.792813979077344], id: "חיפה/שמבור" },
  { name: "כרמי גת, קרית גת", type: "neighborhood", center: [34.77180070146888, 31.627625270837612], id: "קרית גת/כרמי גת" },
  { name: "הדקל, ביתר עילית", type: "neighborhood", center: [35.11467143003753, 31.693680788359124], id: "ביתר עילית/הדקל" },
  { name: "הרדוף, ערד", type: "neighborhood", center: [35.22411801350587, 31.257813893945126], id: "ערד/הרדוף" },
  { name: "המשולש, כפר סבא", type: "neighborhood", center: [34.89142175502978, 32.173672092349825], id: "כפר סבא/המשולש" },
  { name: "נווה צדק, תל אביב יפו", type: "neighborhood", center: [34.76488278590467, 32.06108264122724], id: "תל אביב יפו/נווה צדק" },
  { name: "נוה התות, פרדס חנה כרכור", type: "neighborhood", center: [34.98337671124228, 32.46770450399525], id: "פרדס חנה כרכור/נוה התות" },
  { name: "רמת פולג, נתניה", type: "neighborhood", center: [34.84571925462877, 32.27274443934059], id: "נתניה/רמת פולג" },
  { name: "הנחלים (ספדיה), מודיעין מכבים רעות", type: "neighborhood", center: [35.01680277221523, 31.898804017941107], id: "מודיעין מכבים רעות/הנחלים (ספדיה)" },
  { name: "רובע יג, אשדוד", type: "neighborhood", center: [34.64401796469858, 31.77544706135448], id: "אשדוד/רובע יג" },
  { name: "נאות גנים, נתניה", type: "neighborhood", center: [34.884947282674496, 32.315652634213194], id: "נתניה/נאות גנים" },
  { name: "רבי עקיבא, טבריה", type: "neighborhood", center: [35.52397224729529, 32.78975214419322], id: "טבריה/רבי עקיבא" },
  { name: "קורן, פרדס חנה כרכור", type: "neighborhood", center: [34.993449399573464, 32.48035596506995], id: "פרדס חנה כרכור/קורן" },
  { name: "אזור תעשייה, כרמיאל", type: "neighborhood", center: [35.32348974555421, 32.92396219975591], id: "כרמיאל/אזור תעשייה" },
  { name: "בן צבי, טירת הכרמל", type: "neighborhood", center: [34.97132419038763, 32.75186757588054], id: "טירת הכרמל/בן צבי" },
  { name: "רסקו(גבעת הורדים), ירושלים", type: "neighborhood", center: [35.20497699699144, 31.763786763423674], id: "ירושלים/רסקו(גבעת הורדים)" },
  { name: "רמת בן צבי, נס ציונה", type: "neighborhood", center: [34.79488039960912, 31.93398812759372], id: "נס ציונה/רמת בן צבי" },
  { name: "אפרידר, אשקלון", type: "neighborhood", center: [34.56667511151189, 31.674591259889436], id: "אשקלון/אפרידר" },
  { name: "נחלת הר חב\"ד, קרית מלאכי", type: "neighborhood", center: [34.73787477237045, 31.730133626816137], id: "קרית מלאכי/נחלת הר חב\"ד" },
  { name: "חלומות, פרדס חנה כרכור", type: "neighborhood", center: [34.98447509412874, 32.47926675208722], id: "פרדס חנה כרכור/חלומות" },
  { name: "טללים, ערד", type: "neighborhood", center: [35.2057461899448, 31.257743607141734], id: "ערד/טללים" },
  { name: "קרית רבין, חולון", type: "neighborhood", center: [34.77310279964851, 32.002592008690335], id: "חולון/קרית רבין" },
  { name: "מגדיאל, הוד השרון", type: "neighborhood", center: [34.90563257908556, 32.16037522586837], id: "הוד השרון/מגדיאל" },
  { name: "פארק הים, בת ים", type: "neighborhood", center: [34.73925260464177, 32.00498462093217], id: "בת ים/פארק הים" },
  { name: "נווה רותם, פרדס חנה כרכור", type: "neighborhood", center: [34.94818203049444, 32.46192803905135], id: "פרדס חנה כרכור/נווה רותם" },
  { name: "גבעת רמב\"ם, גבעתיים", type: "neighborhood", center: [34.80554111878158, 32.06920950263895], id: "גבעתיים/גבעת רמב\"ם" },
  { name: "נוה אלון (עמידר), נהריה", type: "neighborhood", center: [35.09582251821536, 33.02048549543638], id: "נהריה/נוה אלון (עמידר)" },
  { name: "כפר אוריאל, גדרה", type: "neighborhood", center: [34.78317788526007, 31.81513626176329], id: "גדרה/כפר אוריאל" },
  { name: "כרמליה, חיפה", type: "neighborhood", center: [34.97653118675067, 32.79762329496347], id: "חיפה/כרמליה" },
  { name: "נאות רבין-יבנה הירוקה, יבנה", type: "neighborhood", center: [34.73518605810178, 31.86586702774691], id: "יבנה/נאות רבין-יבנה הירוקה" },
  { name: "שכונת הראשונים, רמת גן", type: "neighborhood", center: [34.803565814104516, 32.08077511693846], id: "רמת גן/שכונת הראשונים" },
  { name: "אבני החושן, מעלה אדומים", type: "neighborhood", center: [35.301473051648635, 31.775122701738653], id: "מעלה אדומים/אבני החושן" },
  { name: "שכונה 2 - החורש, קיסריה", type: "neighborhood", center: [34.90172552585699, 32.5098797138116], id: "קיסריה/שכונה 2 - החורש" },
  { name: "קרית שמחה, ראשון לציון", type: "neighborhood", center: [34.809780003025885, 31.95224145377382], id: "ראשון לציון/קרית שמחה" },
  { name: "נחלת עילית, ראשון לציון", type: "neighborhood", center: [34.80012445371733, 31.987671019189037], id: "ראשון לציון/נחלת עילית" },
  { name: "גאולים, כפר סבא", type: "neighborhood", center: [34.925051001980165, 32.16827813492572], id: "כפר סבא/גאולים" },
  { name: "רמות, באר שבע", type: "neighborhood", center: [34.81180019581019, 31.27246653474097], id: "באר שבע/רמות" },
  { name: "אשכול, כרמיאל", type: "neighborhood", center: [35.30120787172768, 32.907781263859114], id: "כרמיאל/אשכול" },
  { name: "יוספטל, נהריה", type: "neighborhood", center: [35.10324046233535, 33.006540163990486], id: "נהריה/יוספטל" },
  { name: "אדמית,  יערית,שלומי", type: "neighborhood", center: [35.14062025613317, 33.08244435855905], id: "שלומי/אדמית,  יערית" },
  { name: "נווה אביב, עכו", type: "neighborhood", center: [35.09388579268789, 32.93617227886841], id: "עכו/נווה אביב" },
  { name: "שכונה ג, מבשרת ציון", type: "neighborhood", center: [35.15774904308492, 31.802573889995422], id: "מבשרת ציון/שכונה ג" },
  { name: "הצפון החדש סביבת כיכר המדינה, תל אביב יפו", type: "neighborhood", center: [34.78914882126068, 32.08739983612202], id: "תל אביב יפו/הצפון החדש סביבת כיכר המדינה" },
  { name: "גני יער, לוד", type: "neighborhood", center: [34.903508531546215, 31.945121086209582], id: "לוד/גני יער" },
  { name: "שכונת הלל, רמת גן", type: "neighborhood", center: [34.82346495873675, 32.08152359954865], id: "רמת גן/שכונת הלל" },
  { name: "אגן האיילות, גבעת זאב", type: "neighborhood", center: [35.15090851084117, 31.86373323707391], id: "גבעת זאב/אגן האיילות" },
  { name: "העמק, צור הדסה", type: "neighborhood", center: [35.09781370333293, 31.71438638388642], id: "צור הדסה/העמק" },
  { name: "גנים צפון, גני תקווה", type: "neighborhood", center: [34.872658785613055, 32.0659527868015], id: "גני תקווה/גנים צפון" },
  { name: "השכונה הדרומית, קרית אתא", type: "neighborhood", center: [35.12391211653307, 32.80218967806095], id: "קרית אתא/השכונה הדרומית" },
  { name: "רמת בית שמש ב', בית שמש", type: "neighborhood", center: [34.991855168694144, 31.72758568881138], id: "בית שמש/רמת בית שמש ב'" },
  { name: "קדימה, קדימה צורן", type: "neighborhood", center: [34.91265092943011, 32.27873172224865], id: "קדימה צורן/קדימה" },
  { name: "נווה שולמית, גדרה", type: "neighborhood", center: [34.78116632204115, 31.820110765713558], id: "גדרה/נווה שולמית" },
  { name: "גרינפארק החדשה, הוד השרון", type: "neighborhood", center: [34.890721183084935, 32.147659857315], id: "הוד השרון/גרינפארק החדשה" },
  { name: "קרית בנימין, קרית אתא", type: "neighborhood", center: [35.09293675787648, 32.798317271305145], id: "קרית אתא/קרית בנימין" },
  { name: "נתיבות מערב, נתיבות", type: "neighborhood", center: [34.56956571505509, 31.417081860773443], id: "נתיבות/נתיבות מערב" },
  { name: "ממילא, ירושלים", type: "neighborhood", center: [35.22498460419061, 31.77697613283703], id: "ירושלים/ממילא" },
  { name: "מרכז העיר דרום, כפר סבא", type: "neighborhood", center: [34.90631901946217, 32.17272181762206], id: "כפר סבא/מרכז העיר דרום" },
  { name: "נוה חן, תל אביב יפו", type: "neighborhood", center: [34.80997385528687, 32.052756235228856], id: "תל אביב יפו/נוה חן" },
  { name: "סירקין, כפר סבא", type: "neighborhood", center: [34.90028069500961, 32.18534960467188], id: "כפר סבא/סירקין" },
  { name: "המושבה הירוקה, אבן יהודה", type: "neighborhood", center: [34.891786165371286, 32.266746240994486], id: "אבן יהודה/המושבה הירוקה" },
  { name: "גבעת עדן, זכרון יעקב", type: "neighborhood", center: [34.95053412419149, 32.5861104565126], id: "זכרון יעקב/גבעת עדן" },
  { name: "גבעת אולגה ג' ו-ד', חדרה", type: "neighborhood", center: [34.88419704399866, 32.43595159775044], id: "חדרה/גבעת אולגה ג' ו-ד'" },
  { name: "רמות ב', באר שבע", type: "neighborhood", center: [34.802279556437675, 31.283807477945565], id: "באר שבע/רמות ב'" },
  { name: "צפון הכרם, עכו", type: "neighborhood", center: [35.07881707543076, 32.94374368999754], id: "עכו/צפון הכרם" },
  { name: "גני שמואל, קרית מלאכי", type: "neighborhood", center: [34.746136710015925, 31.726331989224573], id: "קרית מלאכי/גני שמואל" },
  { name: "מתחם 531, הוד השרון", type: "neighborhood", center: [34.917146662784255, 32.16397401856422], id: "הוד השרון/מתחם 531" },
  { name: "מרכז הכרמל, חיפה", type: "neighborhood", center: [34.987796658687415, 32.80198607139572], id: "חיפה/מרכז הכרמל" },
  { name: "נווה גנים ומוצקין הצעירה, קרית מוצקין", type: "neighborhood", center: [35.09126672538648, 32.854661549220985], id: "קרית מוצקין/נווה גנים ומוצקין הצעירה" },
  { name: "אחוזת גדרה, גדרה", type: "neighborhood", center: [34.77933523256134, 31.800853475063914], id: "גדרה/אחוזת גדרה" },
  { name: "תלמי מנשה, באר יעקב", type: "neighborhood", center: [34.84864759889466, 31.945483126992112], id: "באר יעקב/תלמי מנשה" },
  { name: "גבעת הרצל, תל אביב יפו", type: "neighborhood", center: [34.764455883723556, 32.05069890729137], id: "תל אביב יפו/גבעת הרצל" },
  { name: "מע\"ר צפוני, תל אביב יפו", type: "neighborhood", center: [34.7934275080152, 32.07686989100854], id: "תל אביב יפו/מע\"ר צפוני" },
  { name: "יד לבנים, רמת גן", type: "neighborhood", center: [34.822190895956595, 32.07689847292562], id: "רמת גן/יד לבנים" },
  { name: "שכונה ב, מבשרת ציון", type: "neighborhood", center: [35.14805188157177, 31.803610241228522], id: "מבשרת ציון/שכונה ב" },
  { name: "מרכז העיר, ירושלים", type: "neighborhood", center: [35.219456150157725, 31.78112971028346], id: "ירושלים/מרכז העיר" },
  { name: "ש\"י עגנון, אשקלון", type: "neighborhood", center: [34.5586078027955, 31.659481871786348], id: "אשקלון/ש\"י עגנון" },
  { name: "בן גוריון, ירוחם", type: "neighborhood", center: [34.930805751626814, 30.98437178962051], id: "ירוחם/בן גוריון" },
  { name: "לסטר, רעננה", type: "neighborhood", center: [34.885006232984054, 32.18188806164396], id: "רעננה/לסטר" },
  { name: "קרית וולפסון, עכו", type: "neighborhood", center: [35.07682237862466, 32.93186256573738], id: "עכו/קרית וולפסון" },
  { name: "גבעת העיריות, גדרה", type: "neighborhood", center: [34.775300757257824, 31.80803953513715], id: "גדרה/גבעת העיריות" },
  { name: "מב\"ת צפון, ראשון לציון", type: "neighborhood", center: [34.81183317871628, 31.978810547989674], id: "ראשון לציון/מב\"ת צפון" },
  { name: "עמק הכרמל, נשר", type: "neighborhood", center: [35.041352696057395, 32.775031622051436], id: "נשר/עמק הכרמל" },
  { name: "נוף יזרעאל, נצרת עילית", type: "neighborhood", center: [35.326010222440445, 32.70609720337053], id: "נצרת עילית/נוף יזרעאל" },
  { name: "רמת גולדה, חיפה", type: "neighborhood", center: [35.001675171339556, 32.773885608864916], id: "חיפה/רמת גולדה" },
  { name: "מישור הנוף, ראשון לציון", type: "neighborhood", center: [34.81119997642637, 31.951155711021478], id: "ראשון לציון/מישור הנוף" },
  { name: "חלום ראשון, ראשון לציון", type: "neighborhood", center: [34.813384804771616, 31.9474414314258], id: "ראשון לציון/חלום ראשון" },
  { name: "גני איריס, נס ציונה", type: "neighborhood", center: [34.790808837907406, 31.935318950083], id: "נס ציונה/גני איריס" },
  { name: "נוף חרמון, קרית שמונה", type: "neighborhood", center: [35.56684673365521, 33.21718540689208], id: "קרית שמונה/נוף חרמון" },
  { name: "מול היקב, זכרון יעקב", type: "neighborhood", center: [34.95930104087681, 32.56834929116747], id: "זכרון יעקב/מול היקב" },
  { name: "קרית נורדאו, נתניה", type: "neighborhood", center: [34.853064165759214, 32.282898679025685], id: "נתניה/קרית נורדאו" },
  { name: "חורשת האקליפטוס, טבריה", type: "neighborhood", center: [35.50677272669188, 32.78680903258435], id: "טבריה/חורשת האקליפטוס" },
  { name: "שער הים, ראשון לציון", type: "neighborhood", center: [34.735517016400415, 31.99919727775231], id: "ראשון לציון/שער הים" },
  { name: "רוממה החדשה, חיפה", type: "neighborhood", center: [34.9947409609962, 32.785699873441814], id: "חיפה/רוממה החדשה" },
  { name: "יגאל אלון, קרית מלאכי", type: "neighborhood", center: [34.74781809101067, 31.736335040822127], id: "קרית מלאכי/יגאל אלון" },
  { name: "אבן גבירול, רחובות", type: "neighborhood", center: [34.77429073492879, 31.895420941028323], id: "רחובות/אבן גבירול" },
  { name: "קרית משה, רחובות", type: "neighborhood", center: [34.78718681038229, 31.88431932887593], id: "רחובות/קרית משה" },
  { name: "שיכון ממשלתי, אור יהודה", type: "neighborhood", center: [34.84666957223916, 32.030879740081204], id: "אור יהודה/שיכון ממשלתי" },
  { name: "פארק צמרת, תל אביב יפו", type: "neighborhood", center: [34.79740919552922, 32.08840158552059], id: "תל אביב יפו/פארק צמרת" },
  { name: "אזור תעשיה צפוני, אשדוד", type: "neighborhood", center: [34.67541342206576, 31.843761214013476], id: "אשדוד/אזור תעשיה צפוני" },
  { name: "מנשיה, תל אביב יפו", type: "neighborhood", center: [34.76191268401353, 32.064099983308054], id: "תל אביב יפו/מנשיה" },
  { name: "חב\"ד, קרית מלאכי", type: "neighborhood", center: [34.74206179859204, 31.728208921831055], id: "קרית מלאכי/חב\"ד" },
  { name: "רמת בית שמש ג', בית שמש", type: "neighborhood", center: [34.98688485983396, 31.704386019253686], id: "בית שמש/רמת בית שמש ג'" },
  { name: "טנא, כרמיאל", type: "neighborhood", center: [35.30024756699084, 32.91734429320014], id: "כרמיאל/טנא" },
  { name: "גבעת רם, קרית אתא", type: "neighborhood", center: [35.13475421517836, 32.801036400499456], id: "קרית אתא/גבעת רם" },
  { name: "נאות יצחק רבין, מזכרת בתיה", type: "neighborhood", center: [34.850560424908416, 31.853362218572485], id: "מזכרת בתיה/נאות יצחק רבין" },
  { name: "גבעת התור, נס ציונה", type: "neighborhood", center: [34.795355036123695, 31.93923002508381], id: "נס ציונה/גבעת התור" },
  { name: "אורים, אילת", type: "neighborhood", center: [34.94045160615141, 29.563718093618892], id: "אילת/אורים" },
  { name: "רמת אפרים, נתניה", type: "neighborhood", center: [34.86307836738809, 32.321496257660776], id: "נתניה/רמת אפרים" },
  { name: "העיר העתיקה, לוד", type: "neighborhood", center: [34.89744450398165, 31.95535658330965], id: "לוד/העיר העתיקה" },
  { name: "קרית רבין, בית שאן", type: "neighborhood", center: [35.49366862186205, 32.48786861566685], id: "בית שאן/קרית רבין" },
  { name: "גבעת מכוש, כרמיאל", type: "neighborhood", center: [35.289595237670376, 32.90133315610793], id: "כרמיאל/גבעת מכוש" },
  { name: "רצועת הנופש מערב, רמת השרון", type: "neighborhood", center: [34.81685409352354, 32.13248752615369], id: "רמת השרון/רצועת הנופש מערב" },
  { name: "כפר המכביה, רמת גן", type: "neighborhood", center: [34.82719662012859, 32.05482341795822], id: "רמת גן/כפר המכביה" },
  { name: "תל גיבורים, חולון", type: "neighborhood", center: [34.765932348011965, 32.0310521380519], id: "חולון/תל גיבורים" },
  { name: "ליפתא, ירושלים", type: "neighborhood", center: [35.1949978187967, 31.795195717630307], id: "ירושלים/ליפתא" },
  { name: "שער העיר, נתיבות", type: "neighborhood", center: [34.577323573126286, 31.437862672576372], id: "נתיבות/שער העיר" },
  { name: "הרצליה הצעירה, הרצליה", type: "neighborhood", center: [34.847456108500815, 32.15534180611094], id: "הרצליה/הרצליה הצעירה" },
  { name: "מצפה העמק, מגדל העמק", type: "neighborhood", center: [35.232985905370214, 32.67138748386019], id: "מגדל העמק/מצפה העמק" },
  { name: "אבני חן, רחובות", type: "neighborhood", center: [34.82386287355341, 31.896022144921126], id: "רחובות/אבני חן" },
  { name: "ההסתדרות, אור יהודה", type: "neighborhood", center: [34.8630591466425, 32.02560945248249], id: "אור יהודה/ההסתדרות" },
  { name: "שפרינצק, אור יהודה", type: "neighborhood", center: [34.856884050412255, 32.02460314488984], id: "אור יהודה/שפרינצק" },
  { name: "נאות אפקה א, תל אביב יפו", type: "neighborhood", center: [34.821089057674826, 32.11497277291257], id: "תל אביב יפו/נאות אפקה א" },
  { name: "מקוב, רחובות", type: "neighborhood", center: [34.82801629040144, 31.884107038045887], id: "רחובות/מקוב" },
  { name: "קנדי, פרדס חנה כרכור", type: "neighborhood", center: [34.98350833816508, 32.477425637556706], id: "פרדס חנה כרכור/קנדי" },
  { name: "פלורנטין, תל אביב יפו", type: "neighborhood", center: [34.76928458785889, 32.0575692349347], id: "תל אביב יפו/פלורנטין" },
  { name: "שכונה ה' ו-ו', מבשרת ציון", type: "neighborhood", center: [35.15231318920568, 31.808062168740648], id: "מבשרת ציון/שכונה ה' ו-ו'" },
  { name: "לוד הצעירה, לוד", type: "neighborhood", center: [34.89138286121472, 31.939497366861655], id: "לוד/לוד הצעירה" },
  { name: "נמל תל אביב, תל אביב יפו", type: "neighborhood", center: [34.77461340011957, 32.09786835687326], id: "תל אביב יפו/נמל תל אביב" },
  { name: "רמת צבי, זכרון יעקב", type: "neighborhood", center: [34.95846300069809, 32.56639046696265], id: "זכרון יעקב/רמת צבי" },
  { name: "הר שלום, בני ברק", type: "neighborhood", center: [34.826383709416135, 32.08601157342872], id: "בני ברק/הר שלום" },
  { name: "קרית בורוכוב, רמת גן", type: "neighborhood", center: [34.819631954037845, 32.06898588388897], id: "רמת גן/קרית בורוכוב" },
  { name: "שכונת הגפן, רמת גן", type: "neighborhood", center: [34.81212143218028, 32.08892195066296], id: "רמת גן/שכונת הגפן" },
  { name: "כרמים, נצרת עילית", type: "neighborhood", center: [35.311552781676525, 32.71123282779375], id: "נצרת עילית/כרמים" },
  { name: "נאות הגולף, קיסריה", type: "neighborhood", center: [34.9013508651587, 32.48781977882058], id: "קיסריה/נאות הגולף" },
  { name: "השכונה הצבאית, כפר יונה", type: "neighborhood", center: [34.93450819735931, 32.30867486097429], id: "כפר יונה/השכונה הצבאית" },
  { name: "שכונה ה, אריאל", type: "neighborhood", center: [35.20584852690284, 32.10379057811359], id: "אריאל/שכונה ה" },
  { name: "מרכז דרום, רעננה", type: "neighborhood", center: [34.87147467261482, 32.177438448864976], id: "רעננה/מרכז דרום" },
  { name: "שכונת הגבעה, ירוחם", type: "neighborhood", center: [34.92336461413881, 30.99075429945181], id: "ירוחם/שכונת הגבעה" },
  { name: "רובע טו, אשדוד", type: "neighborhood", center: [34.62926202490816, 31.772926335868263], id: "אשדוד/רובע טו" },
  { name: "נווה אביב, דימונה", type: "neighborhood", center: [35.033094832237516, 31.075804915606362], id: "דימונה/נווה אביב" },
  { name: "אחדות, פתח תקווה", type: "neighborhood", center: [34.892462009419965, 32.073545781637016], id: "פתח תקווה/אחדות" },
  { name: "שכונה 5 - הפרחים, קיסריה", type: "neighborhood", center: [34.90534339079207, 32.520669319991356], id: "קיסריה/שכונה 5 - הפרחים" },
  { name: "הרובע הארמני, ירושלים", type: "neighborhood", center: [35.22908721653821, 31.774816987625478], id: "ירושלים/הרובע הארמני" },
  { name: "רמת רזים דרום, צפת", type: "neighborhood", center: [35.51320887593544, 32.956027943824], id: "צפת/רמת רזים דרום" },
  { name: "גבעת סביון, גני תקווה", type: "neighborhood", center: [34.868911118662396, 32.058164320152855], id: "גני תקווה/גבעת סביון" },
  { name: "נווה רבין, אור יהודה", type: "neighborhood", center: [34.87153683276867, 32.02607224199378], id: "אור יהודה/נווה רבין" },
  { name: "רמב\"ם, באר יעקב", type: "neighborhood", center: [34.8386621178826, 31.943085947548823], id: "באר יעקב/רמב\"ם" },
  { name: "שכון ב ושכון ד, בני ברק", type: "neighborhood", center: [34.829711326441384, 32.083886723849595], id: "בני ברק/שכון ב ושכון ד" },
  { name: "גילץ הירוקה, אבן יהודה", type: "neighborhood", center: [34.8927496702615, 32.271220156469425], id: "אבן יהודה/גילץ הירוקה" },
  { name: "בנה ביתך, קרית ים", type: "neighborhood", center: [35.076583043425615, 32.8450394848179], id: "קרית ים/בנה ביתך" },
  { name: "כפר הסטודנטים, דימונה", type: "neighborhood", center: [35.046146380437904, 31.075170734444946], id: "דימונה/כפר הסטודנטים" },
  { name: "קרית ההגנה, רחובות", type: "neighborhood", center: [34.83103370630126, 31.886039188406205], id: "רחובות/קרית ההגנה" },
  { name: "רמת בית שמש ד 5, בית שמש", type: "neighborhood", center: [34.95240262716586, 31.704893890089313], id: "בית שמש/רמת בית שמש ד 5" },
  { name: "המבואות הדרומיים, חיפה", type: "neighborhood", center: [34.96768515582717, 32.77711120532767], id: "חיפה/המבואות הדרומיים" },
  { name: "מרכז העיר, יהוד מונוסון", type: "neighborhood", center: [34.88727750868259, 32.033243260598205], id: "יהוד מונוסון/מרכז העיר" },
  { name: "חכמי ישראל (תשלוז), דימונה", type: "neighborhood", center: [35.04254974037871, 31.074567434678375], id: "דימונה/חכמי ישראל (תשלוז)" },
  { name: "מורדות לינקולן ותל אהרון, חיפה", type: "neighborhood", center: [34.974432816444484, 32.78599578763657], id: "חיפה/מורדות לינקולן ותל אהרון" },
  { name: "מנחם בגין, עכו", type: "neighborhood", center: [35.077938411853424, 32.93800591415341], id: "עכו/מנחם בגין" },
  { name: "בילינסון, פתח תקווה", type: "neighborhood", center: [34.917154651786625, 32.08047195331855], id: "פתח תקווה/בילינסון" },
  { name: "השכונה הירוקה, כפר סבא", type: "neighborhood", center: [34.89107590403272, 32.19634941262103], id: "כפר סבא/השכונה הירוקה" },
  { name: "ברנע ב, אשקלון", type: "neighborhood", center: [34.5783034395957, 31.695765504041955], id: "אשקלון/ברנע ב" },
  { name: "רובע 12, ערד", type: "neighborhood", center: [35.19150030413999, 31.25553672451857], id: "ערד/רובע 12" },
  { name: "בנה ביתך, שלומי", type: "neighborhood", center: [35.15237009297046, 33.078046209393115], id: "שלומי/בנה ביתך" },
  { name: "שכונה לדוגמא, דימונה", type: "neighborhood", center: [35.03652414748608, 31.07255881118636], id: "דימונה/שכונה לדוגמא" },
  { name: "רמת אפעל, רמת גן", type: "neighborhood", center: [34.835244610499785, 32.04706282365883], id: "רמת גן/רמת אפעל" },
  { name: "הבילויים, רמת גן", type: "neighborhood", center: [34.82378110342297, 32.06166328020803], id: "רמת גן/הבילויים" },
  { name: "בן גוריון, טבריה", type: "neighborhood", center: [35.51551907221686, 32.78158721713974], id: "טבריה/בן גוריון" },
  { name: "בן דור, נשר", type: "neighborhood", center: [35.03379109723466, 32.7756836990088], id: "נשר/בן דור" },
  { name: "אזור תעשיה א, חולון", type: "neighborhood", center: [34.79732002185674, 32.02310869247838], id: "חולון/אזור תעשיה א" },
  { name: "עפולה הצעירה, עפולה", type: "neighborhood", center: [35.32444255621251, 32.63871129625198], id: "עפולה/עפולה הצעירה" },
  { name: "מרכז העיר מזרח, נס ציונה", type: "neighborhood", center: [34.80461575422933, 31.928831844618994], id: "נס ציונה/מרכז העיר מזרח" },
  { name: "ניות, ירושלים", type: "neighborhood", center: [35.20317780785387, 31.77011478571112], id: "ירושלים/ניות" },
  { name: "רובע 6, ערד", type: "neighborhood", center: [35.201169465738474, 31.271094376062006], id: "ערד/רובע 6" },
  { name: "השומר, ראשון לציון", type: "neighborhood", center: [34.81179216026801, 31.960686392156106], id: "ראשון לציון/השומר" },
  { name: "המיצדים, מעלה אדומים", type: "neighborhood", center: [35.30360877323336, 31.780673866170275], id: "מעלה אדומים/המיצדים" },
  { name: "עמידר/ניצנה, בת ים", type: "neighborhood", center: [34.75576126119929, 32.028041483931965], id: "בת ים/עמידר;ניצנה" },
  { name: "נופי הסלע, מעלה אדומים", type: "neighborhood", center: [35.3108565223984, 31.78426568748915], id: "מעלה אדומים/נופי הסלע" },
  { name: "הירוקה המרכזית, הרצליה", type: "neighborhood", center: [34.84174926719174, 32.17213997659326], id: "הרצליה/הירוקה המרכזית" },
  { name: "מגדים, קרית גת", type: "neighborhood", center: [34.767371094998474, 31.604067402008717], id: "קרית גת/מגדים" },
  { name: "רמת אלחנן, בני ברק", type: "neighborhood", center: [34.84213083028621, 32.07956308471555], id: "בני ברק/רמת אלחנן" },
  { name: "ניסן, חדרה", type: "neighborhood", center: [34.92440318372374, 32.427941902513055], id: "חדרה/ניסן" },
  { name: "גבעת אלונים, קרית אתא", type: "neighborhood", center: [35.13878089272998, 32.814369804692376], id: "קרית אתא/גבעת אלונים" },
  { name: "המייסדים, הוד השרון", type: "neighborhood", center: [34.88297550877292, 32.13994683187301], id: "הוד השרון/המייסדים" },
  { name: "גרין פארק, מודיעין עילית", type: "neighborhood", center: [35.05124164743383, 31.923872912959563], id: "מודיעין עילית/גרין פארק" },
  { name: "מכון ויצמן, רחובות", type: "neighborhood", center: [34.81049011833702, 31.907395795589853], id: "רחובות/מכון ויצמן" },
  { name: "רמת רבין, כרמיאל", type: "neighborhood", center: [35.27916878641316, 32.90408919415334], id: "כרמיאל/רמת רבין" },
  { name: "דון יוסף הנשיא, טבריה", type: "neighborhood", center: [35.537045313711914, 32.794483791416226], id: "טבריה/דון יוסף הנשיא" },
  { name: "רמת דניה, ירושלים", type: "neighborhood", center: [35.18084390900482, 31.762166783113162], id: "ירושלים/רמת דניה" },
  { name: "שבזי, ראש העין", type: "neighborhood", center: [34.94893936652572, 32.09007283659869], id: "ראש העין/שבזי" },
  { name: "מתחם נגבה, רמת גן", type: "neighborhood", center: [34.82493765645092, 32.06701621362461], id: "רמת גן/מתחם נגבה" },
  { name: "כרמים, שוהם", type: "neighborhood", center: [34.95074646907123, 32.00861969776229], id: "שוהם/כרמים" },
  { name: "תקומה,  הראשונים,כפר סבא", type: "neighborhood", center: [34.91774313724884, 32.17080932575151], id: "כפר סבא/תקומה,  הראשונים" },
  { name: "רובע י, אשדוד", type: "neighborhood", center: [34.6489627648234, 31.781722401353846], id: "אשדוד/רובע י" },
  { name: "המושבה, גדרה", type: "neighborhood", center: [34.77348959432507, 31.814971224161958], id: "גדרה/המושבה" },
  { name: "יפו ד, תל אביב יפו", type: "neighborhood", center: [34.755269673723696, 32.03415894862501], id: "תל אביב יפו/יפו ד" },
  { name: "המרכז השקט, פתח תקווה", type: "neighborhood", center: [34.886288553835605, 32.08198363299041], id: "פתח תקווה/המרכז השקט" },
  { name: "הפרחים, קרית גת", type: "neighborhood", center: [34.766841756413974, 31.609006991726712], id: "קרית גת/הפרחים" },
  { name: "מגדל המים, בית שמש", type: "neighborhood", center: [34.99449310341586, 31.74629234391441], id: "בית שמש/מגדל המים" },
  { name: "נאות שדה, רעננה", type: "neighborhood", center: [34.87744941379623, 32.193459284480646], id: "רעננה/נאות שדה" },
  { name: "אזור התעשייה הצפוני, אור יהודה", type: "neighborhood", center: [34.85007673933832, 32.034137413500375], id: "אור יהודה/אזור התעשייה הצפוני" },
  { name: "גאולים / בנה ביתך, עפולה", type: "neighborhood", center: [35.27949969017287, 32.60932523585352], id: "עפולה/גאולים ; בנה ביתך" },
  { name: "גלי כרמל,  ז'בוטינסקי,טירת הכרמל", type: "neighborhood", center: [34.964394487214626, 32.76409627316745], id: "טירת הכרמל/גלי כרמל,  ז'בוטינסקי" },
  { name: "קרית יערים, גבעת זאב", type: "neighborhood", center: [35.16854126189391, 31.857418762384796], id: "גבעת זאב/קרית יערים" },
  { name: "כרמית, מיתר", type: "neighborhood", center: [34.89453668604532, 31.332610610458445], id: "מיתר/כרמית" },
  { name: "אופיר, אילת", type: "neighborhood", center: [34.94263731399327, 29.5573459501149], id: "אילת/אופיר" },
  { name: "הצפון הישן החלק הצפוני, תל אביב יפו", type: "neighborhood", center: [34.77816314861785, 32.09255087251064], id: "תל אביב יפו/הצפון הישן החלק הצפוני" },
  { name: "קרית משה, טבריה", type: "neighborhood", center: [35.52906138115811, 32.79025621386491], id: "טבריה/קרית משה" },
  { name: "יפו ג, תל אביב יפו", type: "neighborhood", center: [34.748250015251045, 32.032539878806794], id: "תל אביב יפו/יפו ג" },
  { name: "שער הדרום, קרית גת", type: "neighborhood", center: [34.75772166337253, 31.601332916057846], id: "קרית גת/שער הדרום" },
  { name: "אזור תעשיה ג, חולון", type: "neighborhood", center: [34.80347593299996, 32.00842317352994], id: "חולון/אזור תעשיה ג" },
  { name: "212 מערב, אבן יהודה", type: "neighborhood", center: [34.87871686192689, 32.26801627858856], id: "אבן יהודה/212 מערב" },
  { name: "הגליל, כרמיאל", type: "neighborhood", center: [35.28777224161756, 32.911095956713304], id: "כרמיאל/הגליל" },
  { name: "רמת הדר, חיפה", type: "neighborhood", center: [34.99793601173902, 32.80038689285738], id: "חיפה/רמת הדר" },
  { name: "המחנה, קרית מלאכי", type: "neighborhood", center: [34.7412472921902, 31.72222348524695], id: "קרית מלאכי/המחנה" },
  { name: "פרחי ארצנו, דימונה", type: "neighborhood", center: [35.033369071852306, 31.062281418692358], id: "דימונה/פרחי ארצנו" },
  { name: "מערב שדרות, שדרות", type: "neighborhood", center: [34.58563459578821, 31.52372139264009], id: "שדרות/מערב שדרות" },
  { name: "שכונה מזרחית, פוריידיס", type: "neighborhood", center: [34.96105051655974, 32.59787986150054], id: "פוריידיס/שכונה מזרחית" },
  { name: "רמת אליהו, ראשון לציון", type: "neighborhood", center: [34.787928969885876, 31.983700223663668], id: "ראשון לציון/רמת אליהו" },
  { name: "קרית אריה, בית שמש", type: "neighborhood", center: [34.986474446138544, 31.740670347397597], id: "בית שמש/קרית אריה" },
  { name: "נווה גן, פתח תקווה", type: "neighborhood", center: [34.873005381503404, 32.09482120428272], id: "פתח תקווה/נווה גן" },
  { name: "מוצקין הותיקה, קרית מוצקין", type: "neighborhood", center: [35.07652155852439, 32.830838748031], id: "קרית מוצקין/מוצקין הותיקה" },
  { name: "רובע 13, קצרין", type: "neighborhood", center: [35.70070714309465, 32.98250739232661], id: "קצרין/רובע 13" },
  { name: "בן גוריון, אופקים", type: "neighborhood", center: [34.62427306860328, 31.306879144945142], id: "אופקים/בן גוריון" },
  { name: "מוסררה, ירושלים", type: "neighborhood", center: [35.226096353296704, 31.782794617868337], id: "ירושלים/מוסררה" },
  { name: "המושבה הגרמנית, גן יבנה", type: "neighborhood", center: [34.717887222630935, 31.79068475959992], id: "גן יבנה/המושבה הגרמנית" },
  { name: "רובע ה, אשדוד", type: "neighborhood", center: [34.64827084104645, 31.79689792277399], id: "אשדוד/רובע ה" },
  { name: "נוף הגלעד, בית שאן", type: "neighborhood", center: [35.505665255978954, 32.48972892784077], id: "בית שאן/נוף הגלעד" },
  { name: "מב\"ת מערב, ראשון לציון", type: "neighborhood", center: [34.76413130040518, 31.99017006075675], id: "ראשון לציון/מב\"ת מערב" },
  { name: "תל שלום, פרדס חנה כרכור", type: "neighborhood", center: [34.98290243624767, 32.47232769591126], id: "פרדס חנה כרכור/תל שלום" },
  { name: "ימין משה, ירושלים", type: "neighborhood", center: [35.225170171007505, 31.772520035612462], id: "ירושלים/ימין משה" },
  { name: "גבעת שרת, בית שמש", type: "neighborhood", center: [34.97905263061681, 31.73804870233731], id: "בית שמש/גבעת שרת" },
  { name: "גבעת נשר, נשר", type: "neighborhood", center: [35.048104600539304, 32.76255371258429], id: "נשר/גבעת נשר" },
  { name: "פינת עדן, נצרת עילית", type: "neighborhood", center: [35.32722454456822, 32.70018162011357], id: "נצרת עילית/פינת עדן" },
  { name: "נאות שמיר, יבנה", type: "neighborhood", center: [34.725017443692195, 31.864168440013454], id: "יבנה/נאות שמיר" },
  { name: "נווה שלום, נתניה", type: "neighborhood", center: [34.85839988146047, 32.347077841718736], id: "נתניה/נווה שלום" },
  { name: "א.ת. תלפיות, ירושלים", type: "neighborhood", center: [35.21254569871978, 31.74942327041118], id: "ירושלים/א.ת. תלפיות" },
  { name: "באר גנים, אבן יהודה", type: "neighborhood", center: [34.877782657230256, 32.27207613070158], id: "אבן יהודה/באר גנים" },
  { name: "פת, ירושלים", type: "neighborhood", center: [35.20075350424677, 31.750827018573688], id: "ירושלים/פת" },
  { name: "יפה נוף, כפר יונה", type: "neighborhood", center: [34.93373301738523, 32.322826014836814], id: "כפר יונה/יפה נוף" },
  { name: "כלניות, טירת הכרמל", type: "neighborhood", center: [34.96683962844577, 32.758913420040166], id: "טירת הכרמל/כלניות" },
  { name: "חזית הים הדרומית, נתניה", type: "neighborhood", center: [34.83962667908622, 32.28745704758134], id: "נתניה/חזית הים הדרומית" },
  { name: "הירוקה המזרחית, הרצליה", type: "neighborhood", center: [34.85044871181628, 32.171386906696625], id: "הרצליה/הירוקה המזרחית" },
  { name: "תל גנים, גבעתיים", type: "neighborhood", center: [34.814153518247856, 32.062031371004565], id: "גבעתיים/תל גנים" },
  { name: "נעורים, ראשון לציון", type: "neighborhood", center: [34.81585715151752, 31.973115551983437], id: "ראשון לציון/נעורים" },
  { name: "וואלי (valley), נס ציונה", type: "neighborhood", center: [34.78888597794038, 31.913210930699847], id: "נס ציונה/וואלי (valley)" },
  { name: "גבעת מרדכי, ירושלים", type: "neighborhood", center: [35.19694460038353, 31.762892578102658], id: "ירושלים/גבעת מרדכי" },
  { name: "רובע יא, אשדוד", type: "neighborhood", center: [34.63080467967804, 31.783384722343644], id: "אשדוד/רובע יא" },
  { name: "הארי גרינטל, נתניה", type: "neighborhood", center: [34.852312964105955, 32.31582345141566], id: "נתניה/הארי גרינטל" },
  { name: "שיכון בבלי, תל אביב יפו", type: "neighborhood", center: [34.797817225714105, 32.09511915573011], id: "תל אביב יפו/שיכון בבלי" },
  { name: "דניה, חיפה", type: "neighborhood", center: [34.99372975054252, 32.76627973203155], id: "חיפה/דניה" },
  { name: "קדמת ים, חדרה", type: "neighborhood", center: [34.8863800022939, 32.445280684166825], id: "חדרה/קדמת ים" },
  { name: "הר יונה ג', נצרת עילית", type: "neighborhood", center: [35.351156720792375, 32.730348535296685], id: "נצרת עילית/הר יונה ג'" },
  { name: "רמתיים, הוד השרון", type: "neighborhood", center: [34.8891697850389, 32.15926636143458], id: "הוד השרון/רמתיים" },
  { name: "מרכז העיר מערב, נס ציונה", type: "neighborhood", center: [34.7959020599254, 31.92663369182236], id: "נס ציונה/מרכז העיר מערב" },
  { name: "רמת אביב, תל אביב יפו", type: "neighborhood", center: [34.79648694116086, 32.10853182698665], id: "תל אביב יפו/רמת אביב" },
  { name: "נווה משה, עתלית", type: "neighborhood", center: [34.94775749935018, 32.69135121719935], id: "עתלית/נווה משה" },
  { name: "נווה יצחק רבין (נהריה הירוקה), נהריה", type: "neighborhood", center: [35.10849116804735, 33.00481316021745], id: "נהריה/נווה יצחק רבין (נהריה הירוקה)" },
  { name: "שכונה יא, באר שבע", type: "neighborhood", center: [34.76735508764802, 31.25840141384703], id: "באר שבע/שכונה יא" },
  { name: "אגם 3, נתניה", type: "neighborhood", center: [34.85686087547528, 32.29143744653166], id: "נתניה/אגם 3" },
  { name: "כלניות, ראשון לציון", type: "neighborhood", center: [34.8213767326462, 31.945496467041828], id: "ראשון לציון/כלניות" },
  { name: "שער הגיא, יוקנעם עילית", type: "neighborhood", center: [35.07889277785575, 32.637405825168614], id: "יוקנעם עילית/שער הגיא" },
  { name: "רמת מוצא, ירושלים", type: "neighborhood", center: [35.1687292417199, 31.792717089789868], id: "ירושלים/רמת מוצא" },
  { name: "גבעתי, רחובות", type: "neighborhood", center: [34.80757973965922, 31.88316705513615], id: "רחובות/גבעתי" },
  { name: "נריה, טלמון", type: "neighborhood", center: [35.12550626585491, 31.954218785602308], id: "טלמון/נריה" },
  { name: "נוה ירק, לוד", type: "neighborhood", center: [34.90204071443583, 31.957899355438013], id: "לוד/נוה ירק" },
  { name: "גבעת האלונים, כפר יונה", type: "neighborhood", center: [34.92602473136668, 32.32302914716993], id: "כפר יונה/גבעת האלונים" },
  { name: "נוף קדומים, בית שאן", type: "neighborhood", center: [35.496170342887964, 32.50287533013861], id: "בית שאן/נוף קדומים" },
  { name: "המשולש החקלאי, אשדוד", type: "neighborhood", center: [34.67562745297538, 31.796674199200595], id: "אשדוד/המשולש החקלאי" },
  { name: "בנה ביתך, צפת", type: "neighborhood", center: [35.49808155093897, 32.954462497229095], id: "צפת/בנה ביתך" },
  { name: "שרונה, כפר יונה", type: "neighborhood", center: [34.94745487321697, 32.31387593525802], id: "כפר יונה/שרונה" },
  { name: "אזור תעשיה קלה, אשדוד", type: "neighborhood", center: [34.66740474080472, 31.81049536838445], id: "אשדוד/אזור תעשיה קלה" },
  { name: "פסגת זאב, ירושלים", type: "neighborhood", center: [35.24578711910002, 31.82491196928726], id: "ירושלים/פסגת זאב" },
  { name: "משכנות, רמלה", type: "neighborhood", center: [34.86340235802266, 31.932797458761847], id: "רמלה/משכנות" },
  { name: "נאות אריאל שרון, קרית אונו", type: "neighborhood", center: [34.84142570639981, 32.06263083827583], id: "קרית אונו/נאות אריאל שרון" },
  { name: "שכונה א, אריאל", type: "neighborhood", center: [35.16988658711411, 32.10351155970684], id: "אריאל/שכונה א" },
  { name: "הרקפות, ראש העין", type: "neighborhood", center: [34.95664945443792, 32.094107884834656], id: "ראש העין/הרקפות" },
  { name: "סביוני נצר סירני, נס ציונה", type: "neighborhood", center: [34.81168388963458, 31.923076865310115], id: "נס ציונה/סביוני נצר סירני" },
  { name: "בן גוריון, לוד", type: "neighborhood", center: [34.89160749558284, 31.94383416744577], id: "לוד/בן גוריון" },
  { name: "רסקו א, חולון", type: "neighborhood", center: [34.782816886620104, 32.019432013122675], id: "חולון/רסקו א" },
  { name: "זכרון משה, ירושלים", type: "neighborhood", center: [35.21577141434681, 31.787030696282446], id: "ירושלים/זכרון משה" },
  { name: "קידמת פרדס חנה, פרדס חנה כרכור", type: "neighborhood", center: [34.981806612376815, 32.470289028345775], id: "פרדס חנה כרכור/קידמת פרדס חנה" },
  { name: "נווה הדרים, אשקלון", type: "neighborhood", center: [34.57941367250292, 31.67185037085583], id: "אשקלון/נווה הדרים" },
  { name: "הזמר העברי, כפר סבא", type: "neighborhood", center: [34.89324797423771, 32.188148956356116], id: "כפר סבא/הזמר העברי" },
  { name: "המושבה הגרמנית, חיפה", type: "neighborhood", center: [34.98955327835281, 32.82131951320744], id: "חיפה/המושבה הגרמנית" },
  { name: "רמות צהלה, תל אביב יפו", type: "neighborhood", center: [34.82841084222667, 32.12077635102747], id: "תל אביב יפו/רמות צהלה" },
  { name: "מרכז העיר, עפולה", type: "neighborhood", center: [35.28863951891156, 32.61065775088536], id: "עפולה/מרכז העיר" },
  { name: "בית הכרם, ירושלים", type: "neighborhood", center: [35.18923454475245, 31.778825915303994], id: "ירושלים/בית הכרם" },
  { name: "גליקסון, קרית גת", type: "neighborhood", center: [34.77554660600338, 31.613254693863116], id: "קרית גת/גליקסון" },
  { name: "הר כתרון, צור הדסה", type: "neighborhood", center: [35.08902488114245, 31.71864863836552], id: "צור הדסה/הר כתרון" },
  { name: "קרית הממשלה, נצרת עילית", type: "neighborhood", center: [35.309950385001486, 32.70092749126088], id: "נצרת עילית/קרית הממשלה" },
  { name: "שפירא, אופקים", type: "neighborhood", center: [34.62702943275293, 31.300417544433014], id: "אופקים/שפירא" },
  { name: "גבעת שר, מודיעין מכבים רעות", type: "neighborhood", center: [34.99400056330292, 31.89040357157208], id: "מודיעין מכבים רעות/גבעת שר" },
  { name: "המשתלה, תל אביב יפו", type: "neighborhood", center: [34.8326505753705, 32.127101716963466], id: "תל אביב יפו/המשתלה" },
  { name: "מרינה, אשקלון", type: "neighborhood", center: [34.55621076589434, 31.676462124545132], id: "אשקלון/מרינה" },
  { name: "חוטר, באר יעקב", type: "neighborhood", center: [34.830077049918465, 31.93084193155804], id: "באר יעקב/חוטר" },
  { name: "גני שרון, כפר סבא", type: "neighborhood", center: [34.92960612111655, 32.184907701830284], id: "כפר סבא/גני שרון" },
  { name: "שז\"ר, רעננה", type: "neighborhood", center: [34.86983805726423, 32.190673004202026], id: "רעננה/שז\"ר" },
  { name: "עמישב, פתח תקווה", type: "neighborhood", center: [34.91294496447244, 32.07357325460017], id: "פתח תקווה/עמישב" },
  { name: "הצפון החדש החלק הדרומי, תל אביב יפו", type: "neighborhood", center: [34.78674008347331, 32.080368759027344], id: "תל אביב יפו/הצפון החדש החלק הדרומי" },
  { name: "יובלים, פרדס חנה כרכור", type: "neighborhood", center: [34.98955363952374, 32.483366890537454], id: "פרדס חנה כרכור/יובלים" },
  { name: "נאות המייסדים, שדרות", type: "neighborhood", center: [34.5932673803955, 31.527822381625402], id: "שדרות/נאות המייסדים" },
  { name: "מצפה ברנע, אשקלון", type: "neighborhood", center: [34.57936341250946, 31.68821381229595], id: "אשקלון/מצפה ברנע" },
  { name: "רכס טורעאן, טורעאן", type: "neighborhood", center: [35.377937196861346, 32.78275001685541], id: "טורעאן/רכס טורעאן" },
  { name: "אזור תעשיה גבעת שאול, ירושלים", type: "neighborhood", center: [35.18251401143822, 31.792952852782378], id: "ירושלים/אזור תעשיה גבעת שאול" },
  { name: "צפון שדרות, שדרות", type: "neighborhood", center: [34.59232221414798, 31.535255463191486], id: "שדרות/צפון שדרות" },
  { name: "הפרחים, כפר ורדים", type: "neighborhood", center: [35.266810704680914, 32.999051425694894], id: "כפר ורדים/הפרחים" },
  { name: "הפארק, כפר סבא", type: "neighborhood", center: [34.92265749794365, 32.17865380664661], id: "כפר סבא/הפארק" },
  { name: "גבעת אביב, גדרה", type: "neighborhood", center: [34.78375994793098, 31.819141689059247], id: "גדרה/גבעת אביב" },
  { name: "נוה אביבים, תל אביב יפו", type: "neighborhood", center: [34.79731732441549, 32.11748493895325], id: "תל אביב יפו/נוה אביבים" },
  { name: "קטמון הישנה, ירושלים", type: "neighborhood", center: [35.21138594088123, 31.76482786855241], id: "ירושלים/קטמון הישנה" },
  { name: "כפר הבפטיסטים, פתח תקווה", type: "neighborhood", center: [34.91310049431139, 32.11340409498159], id: "פתח תקווה/כפר הבפטיסטים" },
  { name: "מרכז, הרצליה", type: "neighborhood", center: [34.841122479485605, 32.165090585997554], id: "הרצליה/מרכז" },
  { name: "נווה עופר, תל אביב יפו", type: "neighborhood", center: [34.76612211110952, 32.040984166874786], id: "תל אביב יפו/נווה עופר" },
  { name: "שוק הפשפשים וצפון יפו, תל אביב יפו", type: "neighborhood", center: [34.75692077723963, 32.05345243817872], id: "תל אביב יפו/שוק הפשפשים וצפון יפו" },
  { name: "נווה אברהם, קרית אתא", type: "neighborhood", center: [35.087202189168025, 32.78864409679358], id: "קרית אתא/נווה אברהם" },
  { name: "קרית חיים מזרחית, חיפה", type: "neighborhood", center: [35.070289969845284, 32.82162313796911], id: "חיפה/קרית חיים מזרחית" },
  { name: "נווה רמז, חולון", type: "neighborhood", center: [34.77304795884223, 32.01105043004753], id: "חולון/נווה רמז" },
  { name: "נאות אשכול, יבנה", type: "neighborhood", center: [34.74656139567394, 31.873926213609256], id: "יבנה/נאות אשכול" },
  { name: "אונו הירוקה, קרית אונו", type: "neighborhood", center: [34.863172501487945, 32.058416760131394], id: "קרית אונו/אונו הירוקה" },
  { name: "אבו תור, ירושלים", type: "neighborhood", center: [35.23700713022401, 31.76043484910504], id: "ירושלים/אבו תור" },
  { name: "אזור תעשיה צפוני, קרית שמונה", type: "neighborhood", center: [35.586937983859265, 33.216265172118106], id: "קרית שמונה/אזור תעשיה צפוני" },
  { name: "הר הצופים, ירושלים", type: "neighborhood", center: [35.24440501213419, 31.792826228103568], id: "ירושלים/הר הצופים" },
  { name: "רננים, ערד", type: "neighborhood", center: [35.223392757648476, 31.247296239201905], id: "ערד/רננים" },
  { name: "רמת אביב החדשה, תל אביב יפו", type: "neighborhood", center: [34.79188244960076, 32.117659457897624], id: "תל אביב יפו/רמת אביב החדשה" },
  { name: "טוברוק והמע\"ר חדש, נתניה", type: "neighborhood", center: [34.850868420354416, 32.30775326036625], id: "נתניה/טוברוק והמע\"ר חדש" },
  { name: "בורוכוב, גבעתיים", type: "neighborhood", center: [34.808162781555254, 32.07770967169974], id: "גבעתיים/בורוכוב" },
  { name: "האתרוג, ביתר עילית", type: "neighborhood", center: [35.12300803825955, 31.697434966141707], id: "ביתר עילית/האתרוג" },
  { name: "אזורי גדרה, גדרה", type: "neighborhood", center: [34.78584123722696, 31.81007316372628], id: "גדרה/אזורי גדרה" },
  { name: "חליסה, חיפה", type: "neighborhood", center: [35.00733612403801, 32.80121524052858], id: "חיפה/חליסה" },
  { name: "גן ישראל, מגדל העמק", type: "neighborhood", center: [35.232966043094834, 32.68050892828416], id: "מגדל העמק/גן ישראל" },
  { name: "מעוז ציון ב', מבשרת ציון", type: "neighborhood", center: [35.14075885189008, 31.794259618421272], id: "מבשרת ציון/מעוז ציון ב'" },
  { name: "המושבה החדשה, באר יעקב", type: "neighborhood", center: [34.83245871807878, 31.935145220798432], id: "באר יעקב/המושבה החדשה" },
  { name: "לוי אשכול, נתיבות", type: "neighborhood", center: [34.589055678095285, 31.418778186808126], id: "נתיבות/לוי אשכול" },
  { name: "נווה אלונים, אשקלון", type: "neighborhood", center: [34.55594078905073, 31.657045078648192], id: "אשקלון/נווה אלונים" },
  { name: "הר הזיתים, ירושלים", type: "neighborhood", center: [35.24847071861233, 31.784215058745865], id: "ירושלים/הר הזיתים" },
  { name: "האירוסים, ירוחם", type: "neighborhood", center: [34.93411276683991, 30.995002473062893], id: "ירוחם/האירוסים" },
  { name: "אזור תעשיה קרית אריה, פתח תקווה", type: "neighborhood", center: [34.85720277964625, 32.09714244276339], id: "פתח תקווה/אזור תעשיה קרית אריה" },
  { name: "בן גוריון,  נווה אלון,אור עקיבא", type: "neighborhood", center: [34.919432411560464, 32.50863456186678], id: "אור עקיבא/בן גוריון,  נווה אלון" },
  { name: "נווה אפרים, יהוד מונוסון", type: "neighborhood", center: [34.873409458760385, 32.029173880616554], id: "יהוד מונוסון/נווה אפרים" },
  { name: "בת גנים, פתח תקווה", type: "neighborhood", center: [34.88120243060647, 32.07280090103208], id: "פתח תקווה/בת גנים" },
  { name: "C1, עפולה", type: "neighborhood", center: [35.29392790296298, 32.61704908952133], id: "עפולה/C1" },
  { name: "גני תקווה מערב, גני תקווה", type: "neighborhood", center: [34.86823892660127, 32.06500787312033], id: "גני תקווה/גני תקווה מערב" },
  { name: "גבעת נוף, נס ציונה", type: "neighborhood", center: [34.80785858208804, 31.923758607358305], id: "נס ציונה/גבעת נוף" },
  { name: "צהלון ושיכוני חסכון, תל אביב יפו", type: "neighborhood", center: [34.75408227792257, 32.04209429768432], id: "תל אביב יפו/צהלון ושיכוני חסכון" },
  { name: "פרחים/דגניה, כפר סבא", type: "neighborhood", center: [34.89557357306803, 32.17480973109146], id: "כפר סבא/פרחים;דגניה" },
  { name: "רסקו ב, חולון", type: "neighborhood", center: [34.788023531061555, 32.01361162202212], id: "חולון/רסקו ב" },
  { name: "המלונות, אילת", type: "neighborhood", center: [34.96469386605605, 29.552164425351318], id: "אילת/המלונות" },
  { name: "כרמי הנדיב, קרית מלאכי", type: "neighborhood", center: [34.73676565167639, 31.72606796369346], id: "קרית מלאכי/כרמי הנדיב" },
  { name: "שכונה ט, מבשרת ציון", type: "neighborhood", center: [35.164599802418, 31.79958485938436], id: "מבשרת ציון/שכונה ט" },
  { name: "רובע 10/חן, קצרין", type: "neighborhood", center: [35.67720313653447, 32.99247908638213], id: "קצרין/רובע 10;חן" },
  { name: "נווה יהודה, רחובות", type: "neighborhood", center: [34.80239555464567, 31.901432009095124], id: "רחובות/נווה יהודה" },
  { name: "רובע ט, אשדוד", type: "neighborhood", center: [34.66134209055119, 31.78379710121299], id: "אשדוד/רובע ט" },
  { name: "רמלה מערב, רמלה", type: "neighborhood", center: [34.84974045318034, 31.930900396937083], id: "רמלה/רמלה מערב" },
  { name: "אזור תעשיה צפון, בית שמש", type: "neighborhood", center: [34.9953574263334, 31.758012630373784], id: "בית שמש/אזור תעשיה צפון" },
  { name: "נווה אלון, עכו", type: "neighborhood", center: [35.09302683414255, 32.93035250723855], id: "עכו/נווה אלון" },
  { name: "רעות, מודיעין מכבים רעות", type: "neighborhood", center: [35.01756196510361, 31.88786440104165], id: "מודיעין מכבים רעות/רעות" },
  { name: "הדר, הוד השרון", type: "neighborhood", center: [34.8881503059444, 32.15259201156398], id: "הוד השרון/הדר" },
  { name: "ואדי ניסנאס, חיפה", type: "neighborhood", center: [34.99503508402755, 32.81658569899679], id: "חיפה/ואדי ניסנאס" },
  { name: "הבנים, קרית גת", type: "neighborhood", center: [34.7593974019517, 31.620596826903824], id: "קרית גת/הבנים" },
  { name: "הרובע היהודי, ירושלים", type: "neighborhood", center: [35.23265572811212, 31.7751971836613], id: "ירושלים/הרובע היהודי" },
  { name: "נווה חוף, ראשון לציון", type: "neighborhood", center: [34.74324033244542, 31.996579964566234], id: "ראשון לציון/נווה חוף" },
  { name: "המע\"ר, מודיעין מכבים רעות", type: "neighborhood", center: [35.007300582508286, 31.905765513441363], id: "מודיעין מכבים רעות/המע\"ר" },
  { name: "מורדות טבריה, טבריה", type: "neighborhood", center: [35.524544636469656, 32.78484131582502], id: "טבריה/מורדות טבריה" },
  { name: "נוה רחל, בית שאן", type: "neighborhood", center: [35.4911175596828, 32.505219135643806], id: "בית שאן/נוה רחל" },
  { name: "מבוא שקד, ערד", type: "neighborhood", center: [35.21953610605296, 31.26317773968736], id: "ערד/מבוא שקד" },
  { name: "אזור התעשייה הדרומי, מגדל העמק", type: "neighborhood", center: [35.238526727253706, 32.66563727010081], id: "מגדל העמק/אזור התעשייה הדרומי" },
  { name: "נוף הטיילת, נתניה", type: "neighborhood", center: [34.84588527251257, 32.311171313037164], id: "נתניה/נוף הטיילת" },
  { name: "כוכב יאיר, כוכב יאיר צור יגאל", type: "neighborhood", center: [34.998684323700346, 32.22611479579787], id: "כוכב יאיר צור יגאל/כוכב יאיר" },
  { name: "הראשונים, ערד", type: "neighborhood", center: [35.21601724919136, 31.25742846158021], id: "ערד/הראשונים" },
  { name: "נוה שרת, תל אביב יפו", type: "neighborhood", center: [34.84077584739426, 32.11881591323273], id: "תל אביב יפו/נוה שרת" },
  { name: "קרית משה, נתיבות", type: "neighborhood", center: [34.58562463818321, 31.421575498852082], id: "נתיבות/קרית משה" },
  { name: "אופק, ירוחם", type: "neighborhood", center: [34.922769382303, 30.980886813627883], id: "ירוחם/אופק" },
  { name: "נווה ים, ראשון לציון", type: "neighborhood", center: [34.780299821286654, 31.984626989598688], id: "ראשון לציון/נווה ים" },
  { name: "רמת רחל, ירושלים", type: "neighborhood", center: [35.218037635504885, 31.73918560675345], id: "ירושלים/רמת רחל" },
  { name: "שיינקין, גבעתיים", type: "neighborhood", center: [34.814253438562986, 32.074974245438355], id: "גבעתיים/שיינקין" },
  { name: "קרית שרה, צפת", type: "neighborhood", center: [35.510715955600986, 32.980126813822565], id: "צפת/קרית שרה" },
  { name: "רמת ישראל, תל אביב יפו", type: "neighborhood", center: [34.800681704993536, 32.06804599649043], id: "תל אביב יפו/רמת ישראל" },
  { name: "נוה גנים, פרדס חנה כרכור", type: "neighborhood", center: [34.95557656891787, 32.47006793583811], id: "פרדס חנה כרכור/נוה גנים" },
  { name: "שלום עליכם, מגדל העמק", type: "neighborhood", center: [35.237289333366334, 32.67728166679653], id: "מגדל העמק/שלום עליכם" },
  { name: "הגן הטכנולוגי מלחה, ירושלים", type: "neighborhood", center: [35.186703359456374, 31.74990308328927], id: "ירושלים/הגן הטכנולוגי מלחה" },
  { name: "קרית הלאום, ירושלים", type: "neighborhood", center: [35.20369354761589, 31.779855137432147], id: "ירושלים/קרית הלאום" },
  { name: "פסגת אונו, קרית אונו", type: "neighborhood", center: [34.86425233318212, 32.06652778310427], id: "קרית אונו/פסגת אונו" },
  { name: "מרכז המושבה, גן יבנה", type: "neighborhood", center: [34.71070526310099, 31.79009505363553], id: "גן יבנה/מרכז המושבה" },
  { name: "הר שמואל, גבעת זאב", type: "neighborhood", center: [35.17096320907604, 31.835142182996695], id: "גבעת זאב/הר שמואל" },
  { name: "רחובות ההולנדית, רחובות", type: "neighborhood", center: [34.78016774218586, 31.89282592708095], id: "רחובות/רחובות ההולנדית" },
  { name: "טבריה המושבה (מתחם הזורעים), טבריה", type: "neighborhood", center: [35.5141359064356, 32.77291014591417], id: "טבריה/טבריה המושבה (מתחם הזורעים)" },
  { name: "הנביאים, קרית גת", type: "neighborhood", center: [34.764893112367524, 31.6123545278343], id: "קרית גת/הנביאים" },
  { name: "רובע נווה, קצרין", type: "neighborhood", center: [35.69103903554813, 32.99060355163437], id: "קצרין/רובע נווה" },
  { name: "רמת הדר, גבעת שמואל", type: "neighborhood", center: [34.8545573618756, 32.07559734379976], id: "גבעת שמואל/רמת הדר" },
  { name: "המושבה הגרמנית, ירושלים", type: "neighborhood", center: [35.21877560698023, 31.764531330982653], id: "ירושלים/המושבה הגרמנית" },
  { name: "רמת בית שמש ד 2, בית שמש", type: "neighborhood", center: [34.965493666013884, 31.712526658976607], id: "בית שמש/רמת בית שמש ד 2" },
  { name: "הרקפות, באר שבע", type: "neighborhood", center: [34.77049197440208, 31.288373846291417], id: "באר שבע/הרקפות" },
  { name: "ערבה, דימונה", type: "neighborhood", center: [35.02848333563374, 31.059971293324114], id: "דימונה/ערבה" },
  { name: "נווה אליאס, טירת הכרמל", type: "neighborhood", center: [34.97357540977298, 32.76114790489677], id: "טירת הכרמל/נווה אליאס" },
  { name: "רמת ידין, נתניה", type: "neighborhood", center: [34.85774088504496, 32.298132025199585], id: "נתניה/רמת ידין" },
  { name: "מוטה גור, גן יבנה", type: "neighborhood", center: [34.72022398932125, 31.793646385970618], id: "גן יבנה/מוטה גור" },
  { name: "הרובע הנוצרי, ירושלים", type: "neighborhood", center: [35.22862078304827, 31.778735443187845], id: "ירושלים/הרובע הנוצרי" },
  { name: "שגיא, כרמיאל", type: "neighborhood", center: [35.29384115591381, 32.91553454279865], id: "כרמיאל/שגיא" },
  { name: "שקמים, אשקלון", type: "neighborhood", center: [34.562443138880724, 31.652920575357175], id: "אשקלון/שקמים" },
  { name: "אם המושבות החדשה, פתח תקווה", type: "neighborhood", center: [34.880055705899565, 32.10319838155679], id: "פתח תקווה/אם המושבות החדשה" },
  { name: "העיר העתיקה, רמלה", type: "neighborhood", center: [34.87184868094613, 31.92512810573136], id: "רמלה/העיר העתיקה" },
  { name: "חלמיש, ערד", type: "neighborhood", center: [35.20378307495448, 31.260373883854424], id: "ערד/חלמיש" },
  { name: "מרכז העיר, קרית ביאליק", type: "neighborhood", center: [35.08791988597318, 32.8337208845174], id: "קרית ביאליק/מרכז העיר" },
  { name: "חיסכון ג', לוד", type: "neighborhood", center: [34.889485029633946, 31.946691260026256], id: "לוד/חיסכון ג'" },
  { name: "רובע העסקים, בת ים", type: "neighborhood", center: [34.7472530720507, 32.007917885383904], id: "בת ים/רובע העסקים" },
  { name: "קרית פרוסטיג, קרית אתא", type: "neighborhood", center: [35.09336100180778, 32.807408119224775], id: "קרית אתא/קרית פרוסטיג" },
  { name: "טללים, שוהם", type: "neighborhood", center: [34.94672185285141, 32.00761536938222], id: "שוהם/טללים" },
  { name: "הדקל, אילת", type: "neighborhood", center: [34.94838100613879, 29.56336765336952], id: "אילת/הדקל" },
  { name: "באב א זהרה, ירושלים", type: "neighborhood", center: [35.23305836625198, 31.787146990239], id: "ירושלים/באב א זהרה" },
  { name: "נווה זית, לוד", type: "neighborhood", center: [34.88553849166368, 31.942857779099803], id: "לוד/נווה זית" },
  { name: "עפולה הירוקה, עפולה", type: "neighborhood", center: [35.31463541788538, 32.63583396418336], id: "עפולה/עפולה הירוקה" },
  { name: "כפר אהרון הישנה, נס ציונה", type: "neighborhood", center: [34.792713678906985, 31.911496023742394], id: "נס ציונה/כפר אהרון הישנה" },
  { name: "אלמוגים, קרית ים", type: "neighborhood", center: [35.07251378880231, 32.854246020089], id: "קרית ים/אלמוגים" },
  { name: "סיטי, אשדוד", type: "neighborhood", center: [34.64337677330368, 31.78837228147999], id: "אשדוד/סיטי" },
  { name: "אלי כהן, ירוחם", type: "neighborhood", center: [34.93422338715923, 30.989421541414796], id: "ירוחם/אלי כהן" },
  { name: "שיכון רמז, פרדס חנה כרכור", type: "neighborhood", center: [34.95238998640361, 32.46403133712966], id: "פרדס חנה כרכור/שיכון רמז" },
  { name: "רובע ב, אשדוד", type: "neighborhood", center: [34.65409502569924, 31.80491464956443], id: "אשדוד/רובע ב" },
  { name: "מאי, חיפה", type: "neighborhood", center: [34.99265034514353, 32.819231408286385], id: "חיפה/מאי" },
  { name: "שכונה י\"א, מבשרת ציון", type: "neighborhood", center: [35.13546268879932, 31.796181362613165], id: "מבשרת ציון/שכונה י\"א" },
  { name: "נווה שרת, זכרון יעקב", type: "neighborhood", center: [34.963573193755515, 32.56056629270503], id: "זכרון יעקב/נווה שרת" },
  { name: "רמת דן וגבעת הדר, רמלה", type: "neighborhood", center: [34.8536312888862, 31.93101034648888], id: "רמלה/רמת דן וגבעת הדר" },
  { name: "מבואה דרומית, באר שבע", type: "neighborhood", center: [34.799678114289236, 31.23127192939468], id: "באר שבע/מבואה דרומית" },
  { name: "שכונה מ-3, בית שמש", type: "neighborhood", center: [34.99678308657222, 31.706651011339403], id: "בית שמש/שכונה מ-3" },
  { name: "אמירי גדרה, גדרה", type: "neighborhood", center: [34.78084015972756, 31.803197199746165], id: "גדרה/אמירי גדרה" },
  { name: "תל חיים, תל אביב יפו", type: "neighborhood", center: [34.802069474225895, 32.06147622467619], id: "תל אביב יפו/תל חיים" },
  { name: "איילת הכרמל, טירת הכרמל", type: "neighborhood", center: [34.97568628308706, 32.75418484135736], id: "טירת הכרמל/איילת הכרמל" },
  { name: "יוספטל, דימונה", type: "neighborhood", center: [35.036713073933754, 31.06537864779957], id: "דימונה/יוספטל" },
  { name: "מרכז העיר צפון, נתניה", type: "neighborhood", center: [34.85934202481157, 32.335036385132696], id: "נתניה/מרכז העיר צפון" },
  { name: "שיכון פועלים א', הוד השרון", type: "neighborhood", center: [34.881986878554116, 32.16082582024047], id: "הוד השרון/שיכון פועלים א'" },
  { name: "נווה העמק, ירוחם", type: "neighborhood", center: [34.92041167424655, 30.990705053178687], id: "ירוחם/נווה העמק" },
  { name: "ארמון הנציב, ירושלים", type: "neighborhood", center: [35.23287385048068, 31.74804737751718], id: "ירושלים/ארמון הנציב" },
  { name: "שלום, נצרת עילית", type: "neighborhood", center: [35.31355340016595, 32.692023024190014], id: "נצרת עילית/שלום" },
  { name: "נווה הדרים, פרדס חנה כרכור", type: "neighborhood", center: [34.95447759968828, 32.47551694097786], id: "פרדס חנה כרכור/נווה הדרים" },
  { name: "אוניברסיטת חיפה, חיפה", type: "neighborhood", center: [35.01982463317235, 32.761544004198164], id: "חיפה/אוניברסיטת חיפה" },
  { name: "נחלים, עתלית", type: "neighborhood", center: [34.94948825687202, 32.70349718366078], id: "עתלית/נחלים" },
  { name: "מישור אדומים, מעלה אדומים", type: "neighborhood", center: [35.332912680526846, 31.793370702446918], id: "מעלה אדומים/מישור אדומים" },
  { name: "נווה גן/קרית שאול, רמת השרון", type: "neighborhood", center: [34.81773584914993, 32.12483848400358], id: "רמת השרון/נווה גן;קרית שאול" },
  { name: "אזור התעשיה, רמלה", type: "neighborhood", center: [34.887438765646884, 31.92550534532192], id: "רמלה/אזור התעשיה" },
  { name: "שעריים, רחובות", type: "neighborhood", center: [34.81424019348516, 31.887321858110973], id: "רחובות/שעריים" },
  { name: "הגורן, זכרון יעקב", type: "neighborhood", center: [34.9560967927482, 32.5689848159948], id: "זכרון יעקב/הגורן" },
  { name: "נוף גנים, בית שאן", type: "neighborhood", center: [35.49390817331013, 32.498312875499536], id: "בית שאן/נוף גנים" },
  { name: "קרית היובל, ירושלים", type: "neighborhood", center: [35.17486353944364, 31.762364536091916], id: "ירושלים/קרית היובל" },
  { name: "מרכז העיר, ירוחם", type: "neighborhood", center: [34.92808634278403, 30.98834772788129], id: "ירוחם/מרכז העיר" },
  { name: "ממשית, דימונה", type: "neighborhood", center: [35.03927884893497, 31.058880924785626], id: "דימונה/ממשית" },
  { name: "גליל ים החדשה, הרצליה", type: "neighborhood", center: [34.821876806871096, 32.15801357346594], id: "הרצליה/גליל ים החדשה" },
  { name: "לב הפארק, רעננה", type: "neighborhood", center: [34.8511547014319, 32.193064474226205], id: "רעננה/לב הפארק" },
  { name: "כהן, טירת הכרמל", type: "neighborhood", center: [34.9726376764189, 32.75693578360398], id: "טירת הכרמל/כהן" },
  { name: "מעלה נוף, טירת הכרמל", type: "neighborhood", center: [34.976620184712836, 32.765624364115084], id: "טירת הכרמל/מעלה נוף" },
  { name: "אזור מלאכה, יבנה", type: "neighborhood", center: [34.74264298627964, 31.864976013465483], id: "יבנה/אזור מלאכה" },
  { name: "רמת עופר / גבעת זמר, חיפה", type: "neighborhood", center: [35.002906634813, 32.782016601887754], id: "חיפה/רמת עופר ; גבעת זמר" },
  { name: "קרית האמנים, רמלה", type: "neighborhood", center: [34.871968559825916, 31.916260210463363], id: "רמלה/קרית האמנים" },
  { name: "ברושים, שוהם", type: "neighborhood", center: [34.942861493005516, 31.998972786652235], id: "שוהם/ברושים" },
  { name: "חלומות זכרון, זכרון יעקב", type: "neighborhood", center: [394.96361807598663, 32.56623235830503], id: "זכרון יעקב/חלומות זכרון" },
  { name: "תל חנן, נשר", type: "neighborhood", center: [35.04514864383386, 32.77022440902478], id: "נשר/תל חנן" },
  { name: "אזור תעשיה ספיר, נתניה", type: "neighborhood", center: [34.86236760597587, 32.28352564404232], id: "נתניה/אזור תעשיה ספיר" },
  { name: "השחר, גבעת שמואל", type: "neighborhood", center: [34.84471953181242, 32.07445718885813], id: "גבעת שמואל/השחר" },
  { name: "יעקב, זכרון יעקב", type: "neighborhood", center: [34.94857799148554, 32.569365193757946], id: "זכרון יעקב/יעקב" },
  { name: "צפונית, נצרת עילית", type: "neighborhood", center: [35.31952453965382, 32.70798466245556], id: "נצרת עילית/צפונית" },
  { name: "רמת חן, חיפה", type: "neighborhood", center: [35.008586364851716, 32.784885665285486], id: "חיפה/רמת חן" },
  { name: "שטח 9 (בן צבי), גבעתיים", type: "neighborhood", center: [34.81274863653878, 32.06696843869266], id: "גבעתיים/שטח 9 (בן צבי)" },
  { name: "גן הורדים, אשקלון", type: "neighborhood", center: [34.57046501252731, 31.658751804302565], id: "אשקלון/גן הורדים" },
  { name: "מילצ'ן, רחובות", type: "neighborhood", center: [34.81409290656502, 31.88272619845133], id: "רחובות/מילצ'ן" },
  { name: "המרכז האזרחי, רמלה", type: "neighborhood", center: [34.866988909217106, 31.927266584144895], id: "רמלה/המרכז האזרחי" },
  { name: "לב העיר, קרית מוצקין", type: "neighborhood", center: [35.08217563200814, 32.84074116820629], id: "קרית מוצקין/לב העיר" },
  { name: "כ\"א, שוהם", type: "neighborhood", center: [34.94424681739203, 31.985578333378676], id: "שוהם/כ\"א" },
  { name: "מרכז המושבה, פרדס חנה כרכור", type: "neighborhood", center: [34.97235223506504, 32.47378451296997], id: "פרדס חנה כרכור/מרכז המושבה" },
  { name: "פארק החורשות, תל אביב יפו", type: "neighborhood", center: [34.77071866547403, 32.04719127453351], id: "תל אביב יפו/פארק החורשות" },
  { name: "החרוזים, רמת גן", type: "neighborhood", center: [34.80474442202469, 32.08997060842239], id: "רמת גן/החרוזים" },
  { name: "גבעת הסביונים, קרית מלאכי", type: "neighborhood", center: [34.74358263664016, 31.73916814178763], id: "קרית מלאכי/גבעת הסביונים" },
  { name: "אבו טור, ירושלים", type: "neighborhood", center: [35.22653069126338, 31.767233730201152], id: "ירושלים/אבו טור" },
  { name: "ברנע, אשקלון", type: "neighborhood", center: [34.5703207352999, 31.68727719284085], id: "אשקלון/ברנע" },
  { name: "הספארי והפארק הלאומי, רמת גן", type: "neighborhood", center: [34.824668749516825, 32.046306881880014], id: "רמת גן/הספארי והפארק הלאומי" },
  { name: "מקור חיים, ירושלים", type: "neighborhood", center: [35.21185509377692, 31.754294397389568], id: "ירושלים/מקור חיים" },
  { name: "כרם אברהם, ירושלים", type: "neighborhood", center: [35.21644919124905, 31.79099763966239], id: "ירושלים/כרם אברהם" },
  { name: "נווה יהושע, רמת גן", type: "neighborhood", center: [34.83152445447605, 32.06042036113449], id: "רמת גן/נווה יהושע" },
  { name: "רמת כנרת, טבריה", type: "neighborhood", center: [35.52333812880826, 32.79436002339059], id: "טבריה/רמת כנרת" },
  { name: "הגליל, נצרת", type: "neighborhood", center: [35.28573972822568, 32.72064623174441], id: "נצרת/הגליל" },
  { name: "קרית חיים מערבית, חיפה", type: "neighborhood", center: [35.0584284972247, 32.832228039145875], id: "חיפה/קרית חיים מערבית" },
  { name: "גיורא, גבעת שמואל", type: "neighborhood", center: [34.84823333256472, 32.07998153328112], id: "גבעת שמואל/גיורא" },
  { name: "ג'ת, גת", type: "neighborhood", center: [35.03631829963204, 32.39965891868131], id: "גת/ג'ת" },
  { name: "רובע טז, אשדוד", type: "neighborhood", center: [34.63821993185276, 31.768684906839738], id: "אשדוד/רובע טז" },
  { name: "שקד, ירוחם", type: "neighborhood", center: [34.934324394815675, 30.982882329421056], id: "ירוחם/שקד" },
  { name: "רמת התשבי, חיפה", type: "neighborhood", center: [34.97580478625919, 32.81464440131045], id: "חיפה/רמת התשבי" },
  { name: "אזור תעשיה מצפה ספיר, כוכב יאיר צור יגאל", type: "neighborhood", center: [34.98359642860995, 32.218279995338236], id: "כוכב יאיר צור יגאל/אזור תעשיה מצפה ספיר" },
  { name: "נאות הדרים, גן יבנה", type: "neighborhood", center: [34.71493256185069, 31.786846424513126], id: "גן יבנה/נאות הדרים" },
  { name: "כפר אריה, ראשון לציון", type: "neighborhood", center: [34.825783592806076, 31.945601069289115], id: "ראשון לציון/כפר אריה" },
  { name: "שיכון אשר, רעננה", type: "neighborhood", center: [34.86042020180187, 32.18716225970983], id: "רעננה/שיכון אשר" },
  { name: "סנהדריה, ירושלים", type: "neighborhood", center: [35.21670514927851, 31.79939789628772], id: "ירושלים/סנהדריה" },
  { name: "כפר אברהם, פתח תקווה", type: "neighborhood", center: [34.89879407198294, 32.095072278556735], id: "פתח תקווה/כפר אברהם" },
  { name: "ממשלתי, לוד", type: "neighborhood", center: [34.88011207973042, 31.943556691701165], id: "לוד/ממשלתי" },
  { name: "החורש, נתיבות", type: "neighborhood", center: [34.574053200490134, 31.424782490770465], id: "נתיבות/החורש" },
  { name: "גני אור, אור עקיבא", type: "neighborhood", center: [34.92334921584635, 32.50369739242418], id: "אור עקיבא/גני אור" },
  { name: "צפון מרכז העיר, נהריה", type: "neighborhood", center: [35.100608263606475, 33.01307181375822], id: "נהריה/צפון מרכז העיר" },
  { name: "צורן, קדימה צורן", type: "neighborhood", center: [34.94513521573947, 32.28807248612939], id: "קדימה צורן/צורן" },
  { name: "נוף הרים, שלומי", type: "neighborhood", center: [35.148182714119336, 33.06983911916639], id: "שלומי/נוף הרים" },
  { name: "כפר הילדים, עפולה", type: "neighborhood", center: [35.32121578138953, 32.624985727155305], id: "עפולה/כפר הילדים" },
  { name: "נווה מנחם, גבעת זאב", type: "neighborhood", center: [35.17681652137646, 31.86306818661484], id: "גבעת זאב/נווה מנחם" },
  { name: "עין התכלת, נתניה", type: "neighborhood", center: [34.86106452893399, 32.350555069987195], id: "נתניה/עין התכלת" },
  { name: "עיר תחתית, חיפה", type: "neighborhood", center: [34.99966696110749, 32.817954097441486], id: "חיפה/עיר תחתית" },
  { name: "גבעת שמשון, גן יבנה", type: "neighborhood", center: [34.70194138893625, 31.78021133966621], id: "גן יבנה/גבעת שמשון" },
  { name: "גבעת האירוסים, נתניה", type: "neighborhood", center: [34.847764617000024, 32.28618786944199], id: "נתניה/גבעת האירוסים" },
  { name: "סילואן, ירושלים", type: "neighborhood", center: [35.238341757034604, 31.76930143996818], id: "ירושלים/סילואן" },
  { name: "שפרעם מזרח, שפרעם", type: "neighborhood", center: [35.19670207749084, 32.79745241079682], id: "שפרעם/שפרעם מזרח" },
  { name: "נחל בקע, באר שבע", type: "neighborhood", center: [34.78131240583917, 31.224464904847235], id: "באר שבע/נחל בקע" },
  { name: "נאות אביבים, קרית עקרון", type: "neighborhood", center: [34.81865832407497, 31.86176699848433], id: "קרית עקרון/נאות אביבים" },
  { name: "יפה נוף, עתלית", type: "neighborhood", center: [34.94516787221445, 32.69929813116584], id: "עתלית/יפה נוף" },
  { name: "אזור התעשייה שגיא 2000, מגדל העמק", type: "neighborhood", center: [35.23842608297552, 32.657359194675976], id: "מגדל העמק/אזור התעשייה שגיא 2000" },
  { name: "קרית אילון, חולון", type: "neighborhood", center: [34.779182515297634, 32.008733442565344], id: "חולון/קרית אילון" },
  { name: "נווה דוד, חיפה", type: "neighborhood", center: [34.960266946017896, 32.80915415984913], id: "חיפה/נווה דוד" },
  { name: "האגרוף, עכו", type: "neighborhood", center: [35.08720307038442, 32.933120387324834], id: "עכו/האגרוף" },
  { name: "קרית ביאליסטוק, יהוד מונוסון", type: "neighborhood", center: [34.89533990930487, 32.036501717615444], id: "יהוד מונוסון/קרית ביאליסטוק" },
  { name: "העיר העתיקה ורסקו, באר שבע", type: "neighborhood", center: [34.788737465829065, 31.24137912551255], id: "באר שבע/העיר העתיקה ורסקו" },
  { name: "מעוז אביב א, תל אביב יפו", type: "neighborhood", center: [34.81419325674538, 32.1116655301996], id: "תל אביב יפו/מעוז אביב א" },
  { name: "פסגות ים, קרית ים", type: "neighborhood", center: [35.07304209221746, 32.8441798764241], id: "קרית ים/פסגות ים" },
  { name: "מגדל, אשקלון", type: "neighborhood", center: [34.5871494030483, 31.664952178073136], id: "אשקלון/מגדל" },
  { name: "גני ברנע, אשקלון", type: "neighborhood", center: [34.57793717257649, 31.682280587449572], id: "אשקלון/גני ברנע" },
  { name: "שיכון חדש, חולון", type: "neighborhood", center: [34.76829858265206, 32.011958718060335], id: "חולון/שיכון חדש" },
  { name: "מכבים, מודיעין מכבים רעות", type: "neighborhood", center: [35.03332202539016, 31.889786722556902], id: "מודיעין מכבים רעות/מכבים" },
  { name: "דוד רמז, טבריה", type: "neighborhood", center: [35.52371122658837, 32.80015840777058], id: "טבריה/דוד רמז" },
  { name: "נאות לון, באר שבע", type: "neighborhood", center: [34.762383670381645, 31.248279581772657], id: "באר שבע/נאות לון" },
  { name: "רובע יב, אשדוד", type: "neighborhood", center: [34.637389143296716, 31.77923855383668], id: "אשדוד/רובע יב" },
  { name: "צור באהר, ירושלים", type: "neighborhood", center: [35.23403951944924, 31.732744068092636], id: "ירושלים/צור באהר" },
  { name: "בית צפאפא, ירושלים", type: "neighborhood", center: [35.20056681834095, 31.742888540719623], id: "ירושלים/בית צפאפא" },
  { name: "ארלוזרוב, גבעתיים", type: "neighborhood", center: [34.812075968136035, 32.07105999725613], id: "גבעתיים/ארלוזרוב" },
  { name: "שכונה 3 - הצמרות, קיסריה", type: "neighborhood", center: [34.90328740953071, 32.51312815943941], id: "קיסריה/שכונה 3 - הצמרות" },
  { name: "קרית הטכניון, חיפה", type: "neighborhood", center: [35.02219137035229, 32.776186981285186], id: "חיפה/קרית הטכניון" },
  { name: "אזור א, קרית שמונה", type: "neighborhood", center: [35.57474846383271, 33.215698460326585], id: "קרית שמונה/אזור א" },
  { name: "אפריים/סלע, רחובות", type: "neighborhood", center: [34.79758137873509, 31.891793788870533], id: "רחובות/אפריים;סלע" },
  { name: "מעוז אביב ב', תל אביב יפו", type: "neighborhood", center: [34.81748621445247, 32.11237935408287], id: "תל אביב יפו/מעוז אביב ב'" },
  { name: "נווה חיים/ויצמן, חדרה", type: "neighborhood", center: [34.9026018068287, 32.44796327238673], id: "חדרה/נווה חיים;ויצמן" },
  { name: "צפון תלפיות, ירושלים", type: "neighborhood", center: [35.228172815824685, 31.758463680227763], id: "ירושלים/צפון תלפיות" },
  { name: "דניה, רחובות", type: "neighborhood", center: [34.820353167710124, 31.891570122685657], id: "רחובות/דניה" },
  { name: "גבעת הארזים, שלומי", type: "neighborhood", center: [35.15507029830371, 33.07627313237369], id: "שלומי/גבעת הארזים" },
  { name: "מרכז העיר, קרית גת", type: "neighborhood", center: [34.772749512830366, 31.608729160244692], id: "קרית גת/מרכז העיר" },
  { name: "אזור תעשייה קרית רכטמן, רחובות", type: "neighborhood", center: [34.78413745778667, 31.888868767448916], id: "רחובות/אזור תעשייה קרית רכטמן" },
  { name: "רהט דרום, רהט", type: "neighborhood", center: [34.751533733069785, 31.36876104013435], id: "רהט/רהט דרום" },
  { name: "המגינים (השמשוני דרום), מודיעין מכבים רעות", type: "neighborhood", center: [35.00041420299756, 31.90906525402287], id: "מודיעין מכבים רעות/המגינים (השמשוני דרום)" },
  { name: "קריית מנחם בגין, רמלה", type: "neighborhood", center: [34.8759968759683, 31.93773496110308], id: "רמלה/קריית מנחם בגין" },
  { name: "טירת שלום, נס ציונה", type: "neighborhood", center: [34.78427388241552, 31.909742747802195], id: "נס ציונה/טירת שלום" },
  { name: "אלי כהן, כפר סבא", type: "neighborhood", center: [34.92265193325669, 32.178624637409385], id: "כפר סבא/אלי כהן" },
  { name: "אזור תעשיה סגולה, פתח תקווה", type: "neighborhood", center: [34.893089769677196, 32.10670070492916], id: "פתח תקווה/אזור תעשיה סגולה" },
  { name: "דרוקר, אבן יהודה", type: "neighborhood", center: [34.88937699864892, 32.27930882671929], id: "אבן יהודה/דרוקר" },
  { name: "קטרה, גדרה", type: "neighborhood", center: [34.777076867116655, 31.823062274079955], id: "גדרה/קטרה" },
  { name: "נוה שאנן, תל אביב יפו", type: "neighborhood", center: [34.77897963348799, 32.05778230503894], id: "תל אביב יפו/נוה שאנן" },
  { name: "גילה, ירושלים", type: "neighborhood", center: [35.18906504718408, 31.733530421367487], id: "ירושלים/גילה" },
  { name: "מרכז העיר, הוד השרון", type: "neighborhood", center: [34.89540336954632, 32.15992610240044], id: "הוד השרון/מרכז העיר" },
  { name: "שכונת הפועלים, גדרה", type: "neighborhood", center: [34.778989251148374, 31.81445185455288], id: "גדרה/שכונת הפועלים" },
  { name: "הנביאים (השמשוני צפון), מודיעין מכבים רעות", type: "neighborhood", center: [35.004783113433795, 31.91304972884095], id: "מודיעין מכבים רעות/הנביאים (השמשוני צפון)" },
  { name: "גאולה, חיפה", type: "neighborhood", center: [35.00128055311508, 32.80308902548299], id: "חיפה/גאולה" },
  { name: "נווה דוד, רמלה", type: "neighborhood", center: [34.86145245518034, 31.938217520231497], id: "רמלה/נווה דוד" },
  { name: "השכונה הותיקה, גבעת שמואל", type: "neighborhood", center: [34.84827544781318, 32.07615893014033], id: "גבעת שמואל/השכונה הותיקה" },
  { name: "גבעת הצבר, נס ציונה", type: "neighborhood", center: [34.805023117241475, 31.93664495178967], id: "נס ציונה/גבעת הצבר" },
  { name: "כפר הנוער הדסים, אבן יהודה", type: "neighborhood", center: [34.88527820511848, 32.285951784756534], id: "אבן יהודה/כפר הנוער הדסים" },
  { name: "אג\"ש, רמלה", type: "neighborhood", center: [34.8688973137124, 31.921844033566305], id: "רמלה/אג\"ש" },
  { name: "בית אליעזר, חדרה", type: "neighborhood", center: [34.949842778157205, 32.42277599910764], id: "חדרה/בית אליעזר" },
  { name: "סלע, נס ציונה", type: "neighborhood", center: [34.790399062468104, 31.929041665597662], id: "נס ציונה/סלע" },
  { name: "קיבוץ גלויות, קרית מלאכי", type: "neighborhood", center: [34.75076299089652, 31.73000009794977], id: "קרית מלאכי/קיבוץ גלויות" },
  { name: "אזור תעשיה כבדה, אשדוד", type: "neighborhood", center: [34.66249924594237, 31.816598166382786], id: "אשדוד/אזור תעשיה כבדה" },
  { name: "פאר, טבריה", type: "neighborhood", center: [35.512735744563564, 32.7850246606002], id: "טבריה/פאר" },
  { name: "רובע ג, אשדוד", type: "neighborhood", center: [34.66185427367896, 31.79985194681825], id: "אשדוד/רובע ג" },
  { name: "אחוזת ברכפלד, מודיעין עילית", type: "neighborhood", center: [35.04200533281428, 31.939529041558966], id: "מודיעין עילית/אחוזת ברכפלד" },
  { name: "אזור מלאכה, יבנה", type: "neighborhood", center: [34.74264298627964, 31.864976013465483], id: "יבנה/אזור מלאכה" },
  { name: "באקה אל ע'רביה, באקה אל-גרביה", type: "neighborhood", center: [35.04156577900656, 32.419842253376984], id: "באקה אל-גרביה/באקה אל ע'רביה" },
  { name: "עפולה עילית, עפולה", type: "neighborhood", center: [35.32414451861098, 32.63330728520615], id: "עפולה/עפולה עילית" },
  { name: "נווה דקלים, ראשון לציון", type: "neighborhood", center: [34.761942514590054, 31.98204408288478], id: "ראשון לציון/נווה דקלים" },
  { name: "מתחם הרכס, עתלית", type: "neighborhood", center: [34.94339790173062, 32.70018699854897], id: "עתלית/מתחם הרכס" },
  { name: "ס\"ח, לוד", type: "neighborhood", center: [34.892444148139624, 31.96074351365396], id: "לוד/ס\"ח" },
  { name: "מכבי (אזור תעשייה), יהוד מונוסון", type: "neighborhood", center: [34.89425543574464, 32.02931781323365], id: "יהוד מונוסון/מכבי (אזור תעשייה)" },
  { name: "נווה הדר, הוד השרון", type: "neighborhood", center: [34.87435429929503, 32.13655466875297], id: "הוד השרון/נווה הדר" },
  { name: "רמת אלון, חיפה", type: "neighborhood", center: [35.013128623111925, 32.77581089476844], id: "חיפה/רמת אלון" },
  { name: "ורדים, שוהם", type: "neighborhood", center: [34.94710766643702, 31.995621495724258], id: "שוהם/ורדים" },
  { name: "צומת חולון, תל אביב יפו", type: "neighborhood", center: [34.77318840969715, 32.03751833489317], id: "תל אביב יפו/צומת חולון" },
  { name: "מרכז העיר, מעלה אדומים", type: "neighborhood", center: [35.29732446080671, 31.772348953677334], id: "מעלה אדומים/מרכז העיר" },
  { name: "מקור ברוך, ירושלים", type: "neighborhood", center: [35.210642778438604, 31.789195249654053], id: "ירושלים/מקור ברוך" },
  { name: "סביוני דניה, חיפה", type: "neighborhood", center: [35.011821105745774, 32.7696460561964], id: "חיפה/סביוני דניה" },
  { name: "אזור תעשיה, טבריה", type: "neighborhood", center: [35.502220025338, 32.77915592352081], id: "טבריה/אזור תעשיה" },
  { name: "היובל, רחובות", type: "neighborhood", center: [34.824925153077146, 31.888310938464752], id: "רחובות/היובל" },
  { name: "ספיר, נצרת עילית", type: "neighborhood", center: [35.321893625364666, 32.69764555113048], id: "נצרת עילית/ספיר" },
  { name: "שמשון/שלמה/ביאליק, חדרה", type: "neighborhood", center: [34.91219566518061, 32.42991233246545], id: "חדרה/שמשון;שלמה;ביאליק" },
  { name: "מורד ההר, שלומי", type: "neighborhood", center: [35.1548530929038, 33.08120879709691], id: "שלומי/מורד ההר" },
  { name: "עיר היין, אשקלון", type: "neighborhood", center: [34.59382721641516, 31.685544102360026], id: "אשקלון/עיר היין" },
  { name: "נוף השרון, נתניה", type: "neighborhood", center: [34.86429868251283, 32.31524859700696], id: "נתניה/נוף השרון" },
  { name: "גבעת אורנים, חיפה", type: "neighborhood", center: [34.99944134958986, 32.78504804003264], id: "חיפה/גבעת אורנים" },
  { name: "המושבה הישנה, באר יעקב", type: "neighborhood", center: [34.83511527837577, 31.93433724900651], id: "באר יעקב/המושבה הישנה" },
  { name: "כפר עקב, ירושלים", type: "neighborhood", center: [35.22199269084335, 31.873834073048585], id: "ירושלים/כפר עקב" },
  { name: "הטריז,  מחנה 80,פרדס חנה כרכור", type: "neighborhood", center: [34.98691962008661, 32.464760026633705], id: "פרדס חנה כרכור/הטריז,  מחנה 80" },
  { name: "קרית עתידים, תל אביב יפו", type: "neighborhood", center: [34.84357296405222, 32.11478038210434], id: "תל אביב יפו/קרית עתידים" },
  { name: "נאות שז\"ר, יבנה", type: "neighborhood", center: [34.7505138071116, 31.87149075445868], id: "יבנה/נאות שז\"ר" },
  { name: "הר נוף, ירושלים", type: "neighborhood", center: [35.17552320765138, 31.785303693273534], id: "ירושלים/הר נוף" },
  { name: "שקד, ערד", type: "neighborhood", center: [35.221045041537806, 31.266249780191426], id: "ערד/שקד" },
  { name: "שיכון ממשלתי, פרדס חנה כרכור", type: "neighborhood", center: [34.960441947815404, 32.474673843304], id: "פרדס חנה כרכור/שיכון ממשלתי" },
  { name: "היובל, אור עקיבא", type: "neighborhood", center: [34.9168324960121, 32.51807752611726], id: "אור עקיבא/היובל" },
  { name: "גבעת עמוס, נשר", type: "neighborhood", center: [35.032627016639346, 32.7730273129735], id: "נשר/גבעת עמוס" },
  { name: "נווה ספיר (קריית תמרים), עכו", type: "neighborhood", center: [35.09231686339927, 32.91735361404741], id: "עכו/נווה ספיר (קריית תמרים)" },
  { name: "דרום מערב, בת ים", type: "neighborhood", center: [34.74351843605888, 32.01403650339523], id: "בת ים/דרום מערב" },
  { name: "נאות עוזי, רעננה", type: "neighborhood", center: [34.84614872760438, 32.194186524224776], id: "רעננה/נאות עוזי" },
  { name: "אגמים, נתניה", type: "neighborhood", center: [34.848193413385786, 32.290434032564825], id: "נתניה/אגמים" },
  { name: "שכונה ג, באר שבע", type: "neighborhood", center: [34.806899005118886, 31.253732663264273], id: "באר שבע/שכונה ג" },
  { name: "לב גדרה, גדרה", type: "neighborhood", center: [34.78407091522302, 31.811560897115218], id: "גדרה/לב גדרה" },
  { name: "רמז, ראשון לציון", type: "neighborhood", center: [34.79598647221962, 31.960123056388436], id: "ראשון לציון/רמז" },
  { name: "גיל עמל, הוד השרון", type: "neighborhood", center: [34.918974119711244, 32.15728830520544], id: "הוד השרון/גיל עמל" },
  { name: "אם המושבות הותיקה, פתח תקווה", type: "neighborhood", center: [34.87338917839775, 32.10300295389976], id: "פתח תקווה/אם המושבות הותיקה" },
  { name: "נווה מאיר, רמלה", type: "neighborhood", center: [34.86401126806503, 31.921856024612318], id: "רמלה/נווה מאיר" },
  { name: "גבעה א, קרית אתא", type: "neighborhood", center: [35.11801106008802, 32.81246623577248], id: "קרית אתא/גבעה א" },
  { name: "212 מזרח, אבן יהודה", type: "neighborhood", center: [34.88293750520764, 32.26691382320441], id: "אבן יהודה/212 מזרח" },
  { name: "הכפר הירוק, רמת השרון", type: "neighborhood", center: [34.8278795763476, 32.133771679323715], id: "רמת השרון/הכפר הירוק" },
  { name: "רמת הטייסים, תל אביב יפו", type: "neighborhood", center: [34.80922716717635, 32.05741268292733], id: "תל אביב יפו/רמת הטייסים" },
  { name: "נווה עוז, פתח תקווה", type: "neighborhood", center: [34.86393490430317, 32.08023903018731], id: "פתח תקווה/נווה עוז" },
  { name: "בית אריה, בית אריה", type: "neighborhood", center: [35.052160212459086, 32.03569900316596], id: "בית אריה/בית אריה" },
  { name: "מרכז העיר צפון, כפר סבא", type: "neighborhood", center: [34.91515828958279, 32.17625762287005], id: "כפר סבא/מרכז העיר צפון" },
  { name: "גבים, ערד", type: "neighborhood", center: [35.20033569399624, 31.252957034543776], id: "ערד/גבים" },
  { name: "שייח ג'ראח, ירושלים", type: "neighborhood", center: [35.23231440251141, 31.793713593674806], id: "ירושלים/שייח ג'ראח" },
  { name: "B1+B2, עפולה", type: "neighborhood", center: [35.30577529167561, 32.62352312174883], id: "עפולה/B1+B2" },
  { name: "נווה סביון, אור יהודה", type: "neighborhood", center: [34.85721568261259, 32.03061226488234], id: "אור יהודה/נווה סביון" },
  { name: "אזור תעשייה אבן יהודה, אבן יהודה", type: "neighborhood", center: [34.892756042450976, 32.28159514201281], id: "אבן יהודה/אזור תעשייה אבן יהודה" },
  { name: "שיכון שלוש קומות, דימונה", type: "neighborhood", center: [35.029395736851065, 31.064118598077442], id: "דימונה/שיכון שלוש קומות" },
  { name: "נווה מגן, רמת השרון", type: "neighborhood", center: [34.84635439873187, 32.13351098317498], id: "רמת השרון/נווה מגן" },
  { name: "קרית דוד, רחובות", type: "neighborhood", center: [34.82584666497981, 31.891758537057008], id: "רחובות/קרית דוד" },
  { name: "גבעת המורה, עפולה", type: "neighborhood", center: [35.32892716169409, 32.621837539304174], id: "עפולה/גבעת המורה" },
  { name: "שיכון ותיקים, בת ים", type: "neighborhood", center: [34.75197746808498, 32.01846163617354], id: "בת ים/שיכון ותיקים" },
  { name: "מחנה גבעון, גבעת זאב", type: "neighborhood", center: [35.171271564279415, 31.84941011061542], id: "גבעת זאב/מחנה גבעון" },
  { name: "רמות וייצמן, יבנה", type: "neighborhood", center: [34.740193443395114, 31.877629877280867], id: "יבנה/רמות וייצמן" },
  { name: "מרכז העיר, קרית אתא", type: "neighborhood", center: [35.10792909346731, 32.80554855439519], id: "קרית אתא/מרכז העיר" },
  { name: "מוצקין/דובדבן הכפר/כיסופים, כפר סבא", type: "neighborhood", center: [34.91713999197938, 32.18130318495199], id: "כפר סבא/מוצקין;דובדבן הכפר;כיסופים" },
  { name: "נווה ציון, גדרה", type: "neighborhood", center: [34.78701475259383, 31.81808607684155], id: "גדרה/נווה ציון" },
  { name: "מתחם  2000, תל אביב יפו", type: "neighborhood", center: [34.79714357622546, 32.08353442982405], id: "תל אביב יפו/מתחם  2000" },
  { name: "נאות אשלים, ראשון לציון", type: "neighborhood", center: [34.773688736647195, 31.96558380744426], id: "ראשון לציון/נאות אשלים" },
  { name: "רמת בגין, חיפה", type: "neighborhood", center: [34.99165944209676, 32.77692504016202], id: "חיפה/רמת בגין" },
  { name: "שכונת ותיקים, אבן יהודה", type: "neighborhood", center: [34.888996087646866, 32.26166647278752], id: "אבן יהודה/שכונת ותיקים" },
  { name: "אבני חן, חריש", type: "neighborhood", center: [35.04571444653063, 32.46617482233277], id: "חריש/אבני חן" },
  { name: "קרול, פתח תקווה", type: "neighborhood", center: [34.88277029489428, 32.095503469212396], id: "פתח תקווה/קרול" },
  { name: "נחלת גנים, רמת גן", type: "neighborhood", center: [34.81945491322735, 32.09243009520181], id: "רמת גן/נחלת גנים" },
  { name: "עמידר, נס ציונה", type: "neighborhood", center: [34.792368734559616, 31.927458098760468], id: "נס ציונה/עמידר" },
  { name: "נווה יהונתן, רמלה", type: "neighborhood", center: [34.85617295825308, 31.924890004097428], id: "רמלה/נווה יהונתן" },
  { name: "בני ישראל, קרית גת", type: "neighborhood", center: [34.770622163558095, 31.616163221388344], id: "קרית גת/בני ישראל" },
  { name: "מהדרין, כפר יונה", type: "neighborhood", center: [34.92563915221513, 32.308638534282494], id: "כפר יונה/מהדרין" },
  { name: "אזורי חן, תל אביב יפו", type: "neighborhood", center: [34.793365384102486, 32.12763536078317], id: "תל אביב יפו/אזורי חן" },
  { name: "כפר גנים ג, פתח תקווה", type: "neighborhood", center: [34.87019438278732, 32.0742769030604], id: "פתח תקווה/כפר גנים ג" },
  { name: "מרכז העיר, גני תקווה", type: "neighborhood", center: [34.875626509658204, 32.0595500644957], id: "גני תקווה/מרכז העיר" },
  { name: "גבעת הסביונים, גן יבנה", type: "neighborhood", center: [34.71684412693767, 31.79589926012877], id: "גן יבנה/גבעת הסביונים" },
  { name: "אשכול, רמלה", type: "neighborhood", center: [34.8577039001118, 31.928975375597695], id: "רמלה/אשכול" },
  { name: "רמת גוראל, חיפה", type: "neighborhood", center: [34.98437365742083, 32.77294989413676], id: "חיפה/רמת גוראל" },
  { name: "הנחלים, מעלה אדומים", type: "neighborhood", center: [35.29837205792939, 31.777510293551558], id: "מעלה אדומים/הנחלים" },
  { name: "קרית רבין, נתניה", type: "neighborhood", center: [34.88281137107191, 32.306200263891135], id: "נתניה/קרית רבין" },
  { name: "עין הים, חיפה", type: "neighborhood", center: [34.96374556258506, 32.82772014682159], id: "חיפה/עין הים" },
  { name: "משכנות המוסיקה, ראש העין", type: "neighborhood", center: [34.941275415667704, 32.09715087646662], id: "ראש העין/משכנות המוסיקה" },
  { name: "סואחרה, ירושלים", type: "neighborhood", center: [35.24942474385346, 31.74671063707494], id: "ירושלים/סואחרה" },
  { name: "אזורי ויצמן, רחובות", type: "neighborhood", center: [34.82126347857676, 31.899549356550065], id: "רחובות/אזורי ויצמן" },
  { name: "נווה גנים, פתח תקווה", type: "neighborhood", center: [34.86647089580785, 32.083687479478684], id: "פתח תקווה/נווה גנים" },
  { name: "צהלה, תל אביב יפו", type: "neighborhood", center: [34.835562337293894, 32.12234741904326], id: "תל אביב יפו/צהלה" },
  { name: "נווה ניצנים, כפר יונה", type: "neighborhood", center: [34.93483942864528, 32.31163370340227], id: "כפר יונה/נווה ניצנים" },
  { name: "קרית ים ג, קרית ים", type: "neighborhood", center: [35.07158668858552, 32.85077148618751], id: "קרית ים/קרית ים ג" },
  { name: "שרונה, תל אביב יפו", type: "neighborhood", center: [34.78634225691006, 32.07276709750917], id: "תל אביב יפו/שרונה" },
  { name: "מגדים, מעלה אדומים", type: "neighborhood", center: [35.29206912040528, 31.7724147799695], id: "מעלה אדומים/מגדים" },
  { name: "קרית ויצמן, רעננה", type: "neighborhood", center: [34.868371475495266, 32.18547344018209], id: "רעננה/קרית ויצמן" },
  { name: "שכונה המזרחית, טמרה", type: "neighborhood", center: [35.2126671805557, 32.846480813710215], id: "טמרה/שכונה המזרחית" },
  { name: "גן מוטה גור, עכו", type: "neighborhood", center: [35.083680217884556, 32.93473877546618], id: "עכו/גן מוטה גור" },
  { name: "בית וגן, בת ים", type: "neighborhood", center: [34.752290817232925, 32.02389964157601], id: "בת ים/בית וגן" },
  { name: "אלונים, שוהם", type: "neighborhood", center: [34.94257715130891, 31.994878467192553], id: "שוהם/אלונים" },
  { name: "ורדיה, חיפה", type: "neighborhood", center: [34.99758676432791, 32.794252190573204], id: "חיפה/ורדיה" },
  { name: "סחלבים, שוהם", type: "neighborhood", center: [34.95101106360717, 32.0046909464566], id: "שוהם/סחלבים" },
  { name: "אזור תעשייה יבנה, יבנה", type: "neighborhood", center: [34.736821478329446, 31.892440070588194], id: "יבנה/אזור תעשייה יבנה" },
  { name: "קדמת דניה, נשר", type: "neighborhood", center: [35.01629390501535, 32.768317999796885], id: "נשר/קדמת דניה" },
  { name: "המכבים, גבעת זאב", type: "neighborhood", center: [35.16718492848293, 31.86347296697159], id: "גבעת זאב/המכבים" },
  { name: "שכונה 13 - הרקיע, קיסריה", type: "neighborhood", center: [34.913908366864746, 32.491889099466526], id: "קיסריה/שכונה 13 - הרקיע" },
  { name: "יהושפט, ערד", type: "neighborhood", center: [35.20932860304658, 31.263965154332094], id: "ערד/יהושפט" },
  { name: "טרומפלדור, נהריה", type: "neighborhood", center: [35.10796559334425, 33.022132309710905], id: "נהריה/טרומפלדור" },
  { name: "אזור תעשיה קרית אליעזר, נתניה", type: "neighborhood", center: [34.87536653555794, 32.31944319452983], id: "נתניה/אזור תעשיה קרית אליעזר" },
  { name: "קרית סביונים, יהוד מונוסון", type: "neighborhood", center: [34.87957118297624, 32.03320618883137], id: "יהוד מונוסון/קרית סביונים" },
  { name: "לב המושבה, נס ציונה", type: "neighborhood", center: [34.81206906188492, 31.92750288605464], id: "נס ציונה/לב המושבה" },
  { name: "נוה הדר, טבריה", type: "neighborhood", center: [35.526204384900716, 32.8030283867056], id: "טבריה/נוה הדר" },
  { name: "נעורים, ערד", type: "neighborhood", center: [35.21781163248702, 31.251398384475955], id: "ערד/נעורים" },
  { name: "אליעזר, כפר סבא", type: "neighborhood", center: [34.90773817330592, 32.188226867299996], id: "כפר סבא/אליעזר" },
  { name: "זרובבל, הרצליה", type: "neighborhood", center: [34.82955504806053, 32.16584439998821], id: "הרצליה/זרובבל" },
  { name: "שכונה ב, אריאל", type: "neighborhood", center: [35.17694027911138, 32.107083170176445], id: "אריאל/שכונה ב" },
  { name: "רסקו, לוד", type: "neighborhood", center: [34.88504957207579, 31.94932444646774], id: "לוד/רסקו" },
  { name: "חפציבה, חדרה", type: "neighborhood", center: [34.898148802099314, 32.45930378612919], id: "חדרה/חפציבה" },
  { name: "אזור תעשיה רמת סיב, פתח תקווה", type: "neighborhood", center: [34.856876746208485, 32.08867764862927], id: "פתח תקווה/אזור תעשיה רמת סיב" },
  { name: "בית חנינא, ירושלים", type: "neighborhood", center: [35.225800949128896, 31.828457021643352], id: "ירושלים/בית חנינא" },
  { name: "גבעת כלניות, יוקנעם עילית", type: "neighborhood", center: [35.09813273110131, 32.64550848977692], id: "יוקנעם עילית/גבעת כלניות" },
  { name: "רמת שרת, ירושלים", type: "neighborhood", center: [35.18683364650333, 31.75877761318235], id: "ירושלים/רמת שרת" },
  { name: "התאנה, אפרת", type: "neighborhood", center: [35.15199273373567, 31.65289119255606], id: "אפרת/התאנה" },
  { name: "הציפורים, מודיעין מכבים רעות", type: "neighborhood", center: [34.996470149988866, 31.896738709577896], id: "מודיעין מכבים רעות/הציפורים" },
  { name: "בן גוריון, נצרת עילית", type: "neighborhood", center: [35.31054414204404, 32.694474319108835], id: "נצרת עילית/בן גוריון" },
  { name: "גרינפארק, הוד השרון", type: "neighborhood", center: [34.888440162705926, 32.145196672687156], id: "הוד השרון/גרינפארק" },
  { name: "משכנות אומנים (כורדני), קרית מוצקין", type: "neighborhood", center: [35.08676489207983, 32.84996134150083], id: "קרית מוצקין/משכנות אומנים (כורדני)" },
  { name: "נווה ים ד, אשקלון", type: "neighborhood", center: [34.55574802149442, 31.66301729817563], id: "אשקלון/נווה ים ד" },
  { name: "הר הצופים, קרית שמונה", type: "neighborhood", center: [35.56837527450976, 33.2224963293465], id: "קרית שמונה/הר הצופים" },
  { name: "פסגת רמות, באר שבע", type: "neighborhood", center: [34.812805341371195, 31.279746332115092], id: "באר שבע/פסגת רמות" },
  { name: "גבעת רוקח, בני ברק", type: "neighborhood", center: [34.828035370178746, 32.08958201887196], id: "בני ברק/גבעת רוקח" },
  { name: "נווה זאב, באר שבע", type: "neighborhood", center: [34.769293053180085, 31.237218280537466], id: "באר שבע/נווה זאב" },
  { name: "בן גוריון, אשקלון", type: "neighborhood", center: [34.564797762986736, 31.65980500227458], id: "אשקלון/בן גוריון" },
  { name: "נווה משה, גדרה", type: "neighborhood", center: [34.77680809392628, 31.819870114196334], id: "גדרה/נווה משה" },
  { name: "דרום, קרית ביאליק", type: "neighborhood", center: [35.07981029198438, 32.81991508794267], id: "קרית ביאליק/דרום" },
  { name: "נווה פרחים, עתלית", type: "neighborhood", center: [34.936016422932965, 32.684992296385595], id: "עתלית/נווה פרחים" },
  { name: "מתחם צפוני, עראבה", type: "neighborhood", center: [35.349725423070254, 32.850953232315376], id: "עראבה/מתחם צפוני" },
  { name: "נווה אילן, יבנה", type: "neighborhood", center: [34.730898915352434, 31.879255175482246], id: "יבנה/נווה אילן" },
  { name: "השכונה הוותיקה, צור הדסה", type: "neighborhood", center: [35.09778057310207, 31.718914146194486], id: "צור הדסה/השכונה הוותיקה" },
  { name: "רמת הנשיא, חיפה", type: "neighborhood", center: [34.96267489743204, 32.812774929307935], id: "חיפה/רמת הנשיא" },
  { name: "ג', כפר ורדים", type: "neighborhood", center: [35.27157931172268, 32.99194423610099], id: "כפר ורדים/ג'" },
  { name: "שפירא, תל אביב יפו", type: "neighborhood", center: [34.779506629761826, 32.051130703697304], id: "תל אביב יפו/שפירא" },
  { name: "צפון מערב העיר, רחובות", type: "neighborhood", center: [34.80594710558141, 31.898556207827983], id: "רחובות/צפון מערב העיר" },
  { name: "שיכון א', כפר יונה", type: "neighborhood", center: [34.92809279758924, 32.315540343849456], id: "כפר יונה/שיכון א'" },
  { name: "עופרים, בית אריה", type: "neighborhood", center: [35.0438182078303, 32.01848443884741], id: "בית אריה/עופרים" },
  { name: "מורד הנחל, שלומי", type: "neighborhood", center: [35.14707574296784, 33.080701850823175], id: "שלומי/מורד הנחל" },
  { name: "עין יעקב, אבן יהודה", type: "neighborhood", center: [34.88249106637071, 32.2807690078134], id: "אבן יהודה/עין יעקב" },
  { name: "נווה רום, רמת השרון", type: "neighborhood", center: [34.829374712965546, 32.14291964591118], id: "רמת השרון/נווה רום" },
  { name: "אשכול, נצרת עילית", type: "neighborhood", center: [35.315239196988244, 32.690427803410195], id: "נצרת עילית/אשכול" },
  { name: "שערי חסד, ירושלים", type: "neighborhood", center: [35.21115303999187, 31.77782745576092], id: "ירושלים/שערי חסד" },
  { name: "כרמל צפוני, חיפה", type: "neighborhood", center: [34.982157802374424, 32.812502289797344], id: "חיפה/כרמל צפוני" },
  { name: "נווה אפריים, פרדס חנה כרכור", type: "neighborhood", center: [34.9882653147737, 32.470422474932676], id: "פרדס חנה כרכור/נווה אפריים" },
  { name: "בסט, דימונה", type: "neighborhood", center: [35.04079586456088, 31.065392871926935], id: "דימונה/בסט" },
  { name: "כוכב הצפון, אשקלון", type: "neighborhood", center: [34.58771279334654, 31.671507342974675], id: "אשקלון/כוכב הצפון" },
  { name: "אזור תעשיה עבר הירקון, תל אביב יפו", type: "neighborhood", center: [34.8384048489591, 32.109098407953724], id: "תל אביב יפו/אזור תעשיה עבר הירקון" },
  { name: "רמט, לוד", type: "neighborhood", center: [34.88353922184566, 31.95041166714915], id: "לוד/רמט" },
  { name: "הדסים, שוהם", type: "neighborhood", center: [34.93987483449765, 31.98826427424523], id: "שוהם/הדסים" },
  { name: "פנחס ספיר, מגדל העמק", type: "neighborhood", center: [35.23477281737715, 32.682585871335014], id: "מגדל העמק/פנחס ספיר" },
];
