
export const partiesMapColors = [
    "match",
    ["get", "max_party"],
    ["likud"],
    "#4d97d2",
    ["ihud_yamin"],
    "#1c4f95",
    ["avoda"],
    "#a93535",
    ["hadash"],
    "#629434",
    ["kulanu"],
    "#4db5d2",
    ["yamin_hadash"],
    "#5f8eba",
    ["zehut"],
    "#7a8692",
    ["raam_balad"],
    "#09732b",
    ["shas"],
    "#613d32",
    ["israel_beiteinu"],
    "#3c70b5",
    ["meretz"],
    "#ca004c",
    ["tora"],
    "#373737",
    ["kahol_lavan"],
    "#dc981f",
    ["other"],
    "hsl(0, 1%, 51%)",
    "#e0e0e0"
]

export const paint = {
  "fill-antialias": true,
  "fill-color": partiesMapColors,
  "fill-opacity": 
  ["interpolate", ["linear"], ["zoom"], 7, ["case", ["boolean", ["feature-state", "hover"], false], 0.7, 0.2], 9, ["case", ["boolean", ["feature-state", "hover"], false], 0.7, 0.5]]

  // ["case", ["boolean", ["feature-state", "selected"], false], 0, 1]

};

export const linePaint = {
  "line-color": partiesMapColors,
  "line-opacity": 1,
  "line-width": ["interpolate",["linear"],["zoom"],10,3,12,6],
  "line-offset": ["interpolate",["linear"],["zoom"],7,-3,9,-1]
};